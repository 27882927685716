<div class="block-header mt-n3 content-container">
  <div class="row">
    <div class="col-3 m-t-15">
      <!-- <form class="register-form filter-form" [formGroup]="filtersForm">
        <mat-form-field class="example-full-width m-l-15" appearance="outline">
          <input matInput placeholder="dd/mm/yyyy" [matDatepicker]="date_start" formControlName="created_at"
            (click)="date_start.open()" (dateInput)="formFilters($event)" (dateChange)="formFilters($event)">
          <mat-datepicker-toggle matSuffix [for]="date_start"></mat-datepicker-toggle>
          <mat-datepicker #date_start></mat-datepicker>
        </mat-form-field>
      </form> -->
    </div>
    <div class="col-9 d-flex justify-content-end" *ngIf="rolePermission.edit">
      <div class="icon-button-demo m-t-15">
        <button
          mat-mini-fab
          style="background-color: #fd7e14 !important"
          title="Submit Report"
          (click)="submitReport()"
          *ngIf="loggedUser['role'] !== 7"
        >
          <mat-icon class="col-white">summarize</mat-icon>
        </button>
      </div>
      <div class="icon-button-demo m-t-15">
        <button
          mat-mini-fab
          color="primary"
          title="Validate"
          (click)="validate()"
          *ngIf="loggedUser['role'] !== 7"
        >
          <mat-icon class="col-white">fact_check</mat-icon>
        </button>
      </div>
      <div class="icon-button-demo m-t-15">
        <button
          mat-mini-fab
          color="primary"
          title="Create Form"
          (click)="openAdd()"
          *ngIf="loggedUser['role'] !== 7"
        >
          <mat-icon class="col-white">add</mat-icon>
        </button>
      </div>
      <!-- <div class="icon-button-demo pull-right m-t-15">
        <button mat-mini-fab color="primary" title="Print table" (click)="downloadAttendancePdf()">
          <mat-icon class="col-white">print</mat-icon>
        </button>
      </div> -->
      <div class="icon-button-demo m-t-15">
        <button
          mat-mini-fab
          color="primary"
          title="Export"
          (click)="exportPopUp()"
        >
          <mat-icon class="col-white">file_download</mat-icon>
        </button>
      </div>
      <div class="icon-button-demo m-t-15">
        <button
          mat-mini-fab
          class="primary mat-primary"
          title="Send Email"
          (click)="emailSendPopup()"
          *ngIf="isEsi === true"
        >
          <mat-icon class="col-white">email</mat-icon>
        </button>
      </div>
      <!-- <div class="icon-button-demo pull-right m-t-15">
        <button *ngIf="loggedUser['role'] !== 7 " mat-mini-fab color="primary" title="Cancel">
          <mat-icon class="col-white">cancel</mat-icon>
        </button>
      </div> -->
      <div class="icon-button-demo m-t-15">
        <button
          *ngIf="loggedUser['role'] !== 7"
          mat-mini-fab
          color="primary"
          title="Save Attendance"
          (click)="saveAttendance()"
        >
          <mat-icon class="col-white">save</mat-icon>
        </button>
      </div>
      <div class="icon-button-demo m-t-15" *ngIf="enableSubmit">
        <button
          *ngIf="loggedUser['role'] !== 7"
          mat-mini-fab
          color="primary"
          title="Submit Attendance"
          (click)="submitAttendance()"
        >
          <mat-icon class="col-white">how_to_vote</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="body">
          <app-attendance-table-filter
            (loadTableEmitter)="loadTableEmitter($event)"
          ></app-attendance-table-filter>

          <div class="row" *ngIf="!enableEductable && !doneSubmit">
            <div class="col-sm-12 m-b-0">
              <div class="table-responsive p-r-15 p-l-15" *ngIf="!doneSubmit">
                <app-attendance-table
                  (loadTableEmitter)="loadTableEmitter()"
                  (tblCheckboxEmitter)="tblCheckboxEmitter($event)"
                  [roleId]="roleId"
                  [rolePermission]="rolePermission"
                  [tableData]="list"
                  [filters]="filtersForm.value"
                  [listOfCheckedChildIds]="listOfCheckIds"
                ></app-attendance-table>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="enableEductable && !doneSubmit">
            <div
              class="col-sm-12 m-b-0"
              *ngFor="let list of treelist | keyvalue"
            >
              <div class="table-responsive p-r-15 p-l-15">
                <h2>{{ list.key }}</h2>
                <app-attendance-table
                  (loadTableEmitter)="loadTableEmitter()"
                  (tblCheckboxEmitter)="tblCheckboxEmitter($event)"
                  [roleId]="roleId"
                  [rolePermission]="rolePermission"
                  [tableData]="list.value"
                  [listOfCheckedChildIds]="listOfCheckIds"
                ></app-attendance-table>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="doneSubmit">
            <div class="table-responsive p-r-15 p-l-15" *ngIf="doneSubmit">
              <h3 class="all-submitted">
                You have already submitted attendances for this week. To edit
                attendances, you will need to edit them on the children page in
                Accounts.
              </h3>
              <h3 class="all-submitted" style="margin-top: 20px">
                {{ distinctEducatorNames.length }} educators were processed this
                week.
              </h3>
              <div
                *ngFor="let educatorName of distinctEducatorNames"
                class="all-submitted"
                style="margin-top: 20px"
              >
                <h4 class="all-submitted">{{ educatorName }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer
  class="footer"
  style="
    background-color: #f0f3fb !important;
    color: black !important;
    left: 280px;
    text-align: left;
  "
>
  <button mat-mini-fab color="primary" title="Go Up" (click)="scrollToTop()">
    <mat-icon class="col-white">arrow_upward</mat-icon>
  </button>
  Total of children {{ totalRecords }}
</footer>
