import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropzone-doc-list',
  templateUrl: './dropzone-doc-list.component.html',
  styleUrls: ['./dropzone-doc-list.component.scss']
})
export class DropzoneDocListComponent implements OnInit {

  fileList = [];
  constructor() { }

  ngOnInit(): void {
  }


  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    // if (this.fileList[index].progress < 100) {
    //   console.log("Upload in progress.");
    //   return;
    // }
    this.fileList.splice(index, 1);
  }

  setFiles(data) {
    let fileList: FileList = data;
    console.log(data);
    for(var i = 0; i < fileList.length; i++){
      this.fileList.push(fileList[i]);
    }


  }
}
