import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropzone-recieved',
  templateUrl: './dropzone-recieved.component.html',
  styleUrls: ['./dropzone-recieved.component.css']
})
export class DropzoneRecievedComponent implements OnInit {
  @Input() items: any = [];
  @Input() showDelete: boolean = true;
  @Output() removeItemEvent = new EventEmitter<number>();
  @Input() previewType = 1;
  constructor() { }

  ngOnInit(): void {
  }

  removeItem(key: number) {
    this.removeItemEvent.emit(key);
  }
}
