import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-attendance-educator-dialog",
  templateUrl: "./attendance-educator-dialog.component.html",
  styleUrls: ["./attendance-educator-dialog.component.sass"],
})
export class AttendanceEducatorDialogComponent implements OnInit {
  selectedEducator;

  constructor(
    public dialogRef: MatDialogRef<AttendanceEducatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  onYesClick(): void {
    console.log(this.selectedEducator);
    this.dialogRef.close(this.selectedEducator);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
