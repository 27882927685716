<table
  #attendanceTable
  mat-table
  [dataSource]="tableData"
  class="w-100 mat-table-responsive t-b-1"
  *ngIf="filters.filterKey === 'A_Z'"
>
  <ng-container matColumnDef="check_box">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="toggleAllSelection($event)"></mat-checkbox>
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [ngClass]="{
        'row-error': element.validation_errors && element.hasError,
        'row-valid': element.validation_errors && !element.hasError
      }"
    >
      <mat-checkbox
        (change)="toggleSelection($event, element)"
        [(ngModel)]="element.isSelected"
      ></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="child_name">
    <th mat-header-cell *matHeaderCellDef>Child Name</th>
    <td mat-cell *matCellDef="let element; let key = index">
      <div class="d-flex align-items-center">
        <div class="mr-5 child-name">{{ element.child_full_name }}</div>
        <div
          *ngIf="
            element.validation_errors && element.validation_errors.length > 0
          "
        >
          <div
            class="alert alert-danger"
            role="alert"
            style="margin-bottom: unset"
          >
            <ul>
              <li *ngFor="let err of element.validation_errors">{{ err }}</li>
            </ul>
          </div>
        </div>
        <div
          *ngIf="
            element.validation_errors && element.validation_errors.length === 0
          "
        >
          <div
            class="alert alert-success"
            role="alert"
            style="margin-bottom: unset"
          >
            Child's Attendance Validated
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="child_start_date">
    <th mat-header-cell *matHeaderCellDef>Start Date</th>
    <td mat-cell *matCellDef="let element; let key = index">
      {{ formatDateWithoutTime(element.child_start_date) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="educator_name">
    <th mat-header-cell *matHeaderCellDef>Educator Name</th>
    <td mat-cell *matCellDef="let element; let key = index">
      {{ element.educator_name }}
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="sign_in">
      <th mat-header-cell *matHeaderCellDef> Sign In </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_in) }}</span>
        <span *ngIf="element.status == 2">Absent</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="signed_in_by">
      <th mat-header-cell *matHeaderCellDef> Signed In By </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.signed_in_by}}</span> <br />
        <span *ngIf="element.signed_in_role">({{element.signed_in_role | titlecase}})</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="sign_out">
      <th mat-header-cell *matHeaderCellDef> Sign Out </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_out) }} </span>
        <span *ngIf="element.status == 2">Absent</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="signed_out_by">
      <th mat-header-cell *matHeaderCellDef> Signed Out By </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.signed_out_by}}</span> <br />
        <span *ngIf="element.signed_out_role">({{element.signed_out_role | titlecase}})</span>
      </td>
    </ng-container> -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="p-r-61 text-align-right">
      Action
    </th>
    <td mat-cell *matCellDef="let element; let key = index" class="w-180px">
      <button
        mat-icon-button
        color="accent"
        class="btn-tbl-delete pull-right"
        title="Delete Row"
      >
        <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
      </button>
      <button
        *ngIf="rolePermission.edit"
        mat-icon-button
        color="accent"
        [disabled]="!checkEditEnabled(element.date)"
        class="btn-tbl-delete pull-right"
        (click)="confirmAbsent(element.child_attendance_id)"
        title="Mark As Absent"
      >
        <mat-icon aria-label="report absence" class="col-white"
          >report</mat-icon
        >
      </button>
      <button
        mat-icon-button
        color="accent"
        *ngIf="rolePermission.edit"
        (click)="openEdit(element)"
        class="btn-tbl-edit pull-right"
        title="Edit"
      >
        <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
      </button>
      <button
        *ngIf="rolePermission.view"
        mat-icon-button
        color="accent"
        class="btn-tbl-view pull-right"
        (click)="validateRow(element, key, false)"
        title="Validate"
      >
        <mat-icon aria-label="Validate" class="col-white">fact_check</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div *ngIf="filters.filterKey === 'educator'">
  <div *ngFor="let educator of educatorsToChild" class="mb-5">
    <h2>{{ educator.educator_name }}</h2>
    <table
      #attendanceTable
      mat-table
      [dataSource]="educator.children"
      class="w-100 mat-table-responsive t-b-1"
    >
      <ng-container matColumnDef="check_box">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="toggleAllSelectionEducator($event, educator.educator_id)"></mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'row-error': element.validation_errors && element.hasError,
            'row-valid': element.validation_errors && !element.hasError
          }"
        >
          <mat-checkbox
            (change)="toggleSelection($event, element)"
            [(ngModel)]="element.isSelected"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="child_name">
        <th mat-header-cell *matHeaderCellDef>Child Name</th>
        <td mat-cell *matCellDef="let element; let key = index">
          <div class="d-flex align-items-center">
            <div class="mr-5 child-name">{{ element.child_full_name }}</div>
            <div
              *ngIf="
                element.validation_errors &&
                element.validation_errors.length > 0
              "
            >
              <div
                class="alert alert-danger"
                role="alert"
                style="margin-bottom: unset"
              >
                <ul>
                  <li *ngFor="let err of element.validation_errors">
                    {{ err }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              *ngIf="
                element.validation_errors &&
                element.validation_errors.length === 0
              "
            >
              <div
                class="alert alert-success"
                role="alert"
                style="margin-bottom: unset"
              >
                Attendance is valid.
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="child_start_date">
        <th mat-header-cell *matHeaderCellDef>Start Date</th>
        <td mat-cell *matCellDef="let element; let key = index">
          {{ formatDateWithoutTime(element.child_start_date) }}
        </td>
      </ng-container>



      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="p-r-61 text-align-right">
          Action
        </th>
        <td mat-cell *matCellDef="let element; let key = index" class="w-180px">
          <button
            mat-icon-button
            color="accent"
            class="btn-tbl-delete pull-right"
            title="Delete Row"
          >
            <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
          </button>
          <button
            *ngIf="rolePermission.edit"
            mat-icon-button
            color="accent"
            [disabled]="!checkEditEnabled(element.date)"
            class="btn-tbl-delete pull-right"
            (click)="confirmAbsent(element.child_attendance_id)"
            title="Mark As Absent"
          >
            <mat-icon aria-label="report absence" class="col-white"
              >report</mat-icon
            >
          </button>
          <button
            mat-icon-button
            color="accent"
            *ngIf="rolePermission.edit"
            (click)="openEdit(element)"
            class="btn-tbl-edit pull-right"
            title="Edit"
          >
            <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
          </button>
          <button
            *ngIf="rolePermission.view"
            mat-icon-button
            color="accent"
            class="btn-tbl-view pull-right"
            (click)="validateRow(element, key, false)"
            title="Validate"
          >
            <mat-icon aria-label="Validate" class="col-white"
              >fact_check</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsEducators"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsEducators"></tr>
    </table>
  </div>
</div>
