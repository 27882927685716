<div class="container-fluid">

    <mat-sidenav-container class="esi-header-container" >
  
      <mat-sidenav-content>
        <nav class="menu-bar" style="height: 80px; margin-top: 10px;">
          <mat-toolbar class="container">
            <mat-toolbar-row>
                <a><img src="../../../assets/logo.png" class="h-55"></a>
                <span class="menu-spacer"></span>
                <div>
                    <p class="esi-header-d">
                        {{currentDate | date:'fullDate'}}
                    </p>
                    <p class="esi-header-t pull-right">{{currentDate | date:'shortTime'}}</p>
                    
                </div>
            </mat-toolbar-row>
          </mat-toolbar>
        </nav>
      </mat-sidenav-content>
  
    </mat-sidenav-container>
</div>

<div class="my-form-container">
    <div class="form-wrapper" [hidden]="validLogin">
        <!-- Your form elements go here -->
        <h1 style="margin-bottom: 30px !important;">Electronic Sign In</h1>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmitESignIN()">
            <mat-form-field appearance="outline">
                <mat-icon matPrefix>face</mat-icon>
                <mat-label>Username</mat-label>
                <input 
                    matInput 
                    formControlName="name"
                    placeholder="Enter your username"
                >
            </mat-form-field>       
            <mat-form-field appearance="outline">
                <mat-icon matPrefix>lock</mat-icon>
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                    <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
            </mat-form-field>
            <button 
                mat-raised-button 
                color="primary" 
                class="center-button login-esi-btn"
                [class.not-allowed]="submitted" 
                [class.spinner]="submitted" 
                type="submit"
            >
                <h3 style="margin-top: 6px !important;">LOGIN</h3>
            </button>
        </form>
    </div>

    <div class="form-wrapper" *ngIf="validLogin == true && valid_phone == false && selection_portal == ''">
        <h1 style="margin-bottom: 30px !important;">{{service_name}}</h1>
        <div class="button-container" *ngIf="!IsFDCEd">
            <button class="portal-btn parent-btn" (click)="selectionPortal('parent')">Parent</button>
            <button class="portal-btn visitor-btn" (click)="selectionPortal('visitor')">Visitor</button>
        </div>
        <div class="button-container" *ngIf="!IsFDCEd">
            <button class="portal-btn service-btn" (click)="selectionPortal('service')">Service</button>
            <button class="portal-btn staff-btn" (click)="selectionPortal('staff')">Staff</button>
        </div>
        <div class="button-container" *ngIf="IsFDCEd">
            <button class="portal-btn parent-btn" (click)="selectionPortal('parent')">Parent</button>
            <button class="portal-btn service-btn" (click)="selectionPortal('service')">Service</button>
        </div>
    </div>

    <div class="form-wrapper" *ngIf="validLogin == true && valid_phone == false && selection_portal != ''">
        <!-- Your form elements go here -->
        <h1 style="margin-bottom: 30px !important;">{{service_name}}</h1>
        
        <h6> ENTER PHONE NUMBER </h6>


        <div class="item numpad">
            <div class="row justify-content-md-center">
                <div class="col-md-12" style="display: flex; align-items: center;">
                    <input class="f-27-bold numpad-input" [value]="display" readonly style="height: 100%; margin-bottom: 0px;" >
                </div>
            </div>
            <br><br>
            <div *ngFor="let row of numpadRows" class="row">
              <button *ngFor="let number of row" (click)="appendToDisplay(number)"><h3 style="margin-bottom: 0px;">{{ number }}</h3></button>
            </div>
            <div class="row">
                <button class="backspace-btn"> </button>
                <button (click)="appendToDisplay(0)"><h3 style="margin-bottom: 0px;">0</h3></button>
                <button class="backspace-btn" (click)="deleteLastDigit()"><mat-icon>backspace</mat-icon></button>
            </div>
            <br>
        </div>
        <div class="row justify-content-md-center button-container">
            <button class="footer-btn" mat-raised-button color="warn" (click)="clearInputData()"><mat-icon>delete_forever</mat-icon></button>
            <button class="footer-btn" mat-raised-button color="primary" (click)="verifyPhone()"><mat-icon>send</mat-icon></button>
        </div>
    </div>

    <div class="form-wrapper" [hidden]="!valid_phone">
    <!-- <div class="form-wrapper" > -->
        <div class="d-flex">
            <h2 > Hi {{ verifiedUser['first_name'] }}, </h2>
            <br>

            <h6 *ngIf="pincode_exist" >PIN Code will be used as a second level of security to your information.</h6>
            <h6 *ngIf="!pincode_exist" >Kindly setup your PIN for second level security.</h6>
            <br>
            
            <h6 *ngIf="pincode_exist" >ENTER PIN CODE</h6>
            <h6 *ngIf="!pincode_exist" [hidden]="!initial_pin"> SETUP PIN CODE </h6>
            <h6 *ngIf="!pincode_exist" [hidden]="initial_pin"> CONFIRM PIN CODE </h6>
            <br>
            <br>
            <table>
                <tr>
                    <td><input style="text-align: center; vertical-align: middle;" class="f-27-bold" type="password" #first maxlength="1" required readonly placeholder="__" size="1"></td>
                    <td><input style="text-align: center; vertical-align: middle;" class="f-27-bold" type="password" #second maxlength="1" required readonly placeholder="__" size="1"></td>
                    <td><input style="text-align: center; vertical-align: middle;" class="f-27-bold" type="password" #third maxlength="1" required readonly placeholder="__" size="1"></td>
                    <td><input style="text-align: center; vertical-align: middle;" class="f-27-bold" type="password" #fourth maxlength="1" required readonly placeholder="__" size="1"></td>
                </tr>
            </table>
            <div class="item numpad">
                <br><br>
                <div *ngFor="let row of numpadRows" class="row">
                    <button *ngFor="let number of row" (click)="enter(number)"><h3 style="margin-bottom: 0px;">{{ number }}</h3></button>
                </div>
                <div class="row">
                    <button [hidden]="!initial_pin" class="backspace-btn"> </button>
                    <button [hidden]="initial_pin"  class="backspace-btn" (click)="refreshPin()"> <mat-icon>keyboard_backspace</mat-icon> </button>
                    <button (click)="enter(0)"><h3 style="margin-bottom: 0px;">0</h3></button>
                    <button class="backspace-btn" (click)="back()"><mat-icon>backspace</mat-icon></button>
                </div>
                <br>
            </div>

        </div>
        <div class="row justify-content-md-center button-container">
            <button class="footer-btn" mat-raised-button color="warn" (click)="clearPinData()"><mat-icon>delete_forever</mat-icon></button>
            <button class="footer-btn" mat-raised-button color="primary" (click)="submitPin()" [disabled]="disableSendPinBtn"><mat-icon>send</mat-icon></button>
        </div>
        <br>
        <br>
        <h6 *ngIf="pincode_exist"> <a class="txt1 pointer" (click)="fogotPin()">Forgot PIN code?</a></h6>
    </div>
</div>
