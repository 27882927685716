import { Component, OnInit } from '@angular/core';
import {LoaderService} from "../../global/loader.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loading: boolean;

  constructor(private loaderService: LoaderService,
              private ngxSpinner: NgxSpinnerService,) {

    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      if(v){
        this.ngxSpinner.show('http-loading');
      } else {
        this.ngxSpinner.hide('http-loading');
      }
    },error => {
      this.loading = false;
      this.ngxSpinner.hide('http-loading');
    });
  }

  ngOnInit(): void {
  }

}
