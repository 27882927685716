import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor{

    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>,
              next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const cloneRequest =  request.clone({
            headers: request.headers.set('X-Requested-With', 'XMLHttpRequest'),
            withCredentials: true,
        });

        return next.handle(request).pipe( tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    this.router.navigate(['login']);
                }
            }));
    }
}

export  class AppendBranchInterceptorService implements  HttpInterceptor {

    loggedUser :any = [];

    intercept(req: HttpRequest<any>,
              next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const reqCloned =  this.handleBodyIn(req, localStorage.getItem('branch_id'), 'branch_id');
        const copiedReq = reqCloned;
        return next.handle(copiedReq);
    }


    handleBodyIn(req:HttpRequest<any>, branch_id, name) {
        this.loggedUser = JSON.parse(localStorage.getItem('user'));
        if(this.loggedUser != null){
            if (this.loggedUser.role_id == 4) {
                if (req.method.toLowerCase() === 'post') {
                    if (req.body instanceof FormData) {
                        req = req.clone({
                            body: req.body.append(name, branch_id)
                        })
                    } else {
                        const foo = {};
                        foo[name] = branch_id;
                        req = req.clone({
                            body: {...req.body, ...foo}
                        })
                    }
                }
                if (req.method.toLowerCase() === 'get') {
                    req = req.clone({
                        params: req.params.append(name, branch_id)
                    });
                }

                return req;
            }else{
                return req;
            }
        }else {

            return req;
        }


    }
}
