import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: '[app-casual-day]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './casual-day.component.html',
  styleUrls: ['./casual-day.component.sass']
})
export class CasualDayComponent implements OnInit {
  @Input('group')
  public casualForm: FormGroup;
  @Input('index')
  public index: Number; 
  @Output("addCasualRow") 
  addRow: EventEmitter<any> = new EventEmitter();
  @Output("removeCasualRow") 
  removeRow: EventEmitter<any> = new EventEmitter();
  @Input('checker')
  public checker: boolean; 
  @Input('isWithName')
  public isWithName: boolean; 
  @Input('session_name')
  public session_name: string; 
  @Output("getCheckerChange") 
  getCheckerChange = new EventEmitter<any>();
  @Output("dataChange") 
  dataChange = new EventEmitter<any>();
  @Input('obj_name')
  public obj_name: String; 
  @Input('isDisabled')
  public isDisabled: boolean; 

  role_id = 0;

  constructor(public controlContainer: ControlContainer,
    private elRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.isDisabled) {
      this.casualForm.disable();
    } else {
      this.casualForm.get('session_length').disable();
      var user = JSON.parse(localStorage.getItem('user'));
      this.role_id = user['role_id'];
      if (this.role_id == 1 || this.role_id == 4) {
        this.casualForm.get('unit').enable();
        this.casualForm.get('fee').enable();
      } else {
        this.casualForm.get('unit').disable();
        this.casualForm.get('fee').disable();       
      }
    }

    this.casualForm.valueChanges.subscribe(res => {
      for (const field in res) {
        if (res[field] !== null && res[field.trim()] !== '') {
          this.checker = true;
          break;
        } else {
          this.checker = false;
        }
      }

    });
  }

  updateData($event) {
    this.getCheckerChange.emit({checker: this.checker, type: 3, index: 0});
    if (this.casualForm.valid) {
      this.dataChange.emit({form: this.casualForm.getRawValue(), index: this.index, obj_name: this.obj_name});
    }
  }

}

