import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIconFileTypes'
})
export class GetIconFileTypesPipe implements PipeTransform {

  transform(media): any {
    var icon_img = 'assets/images/file_types/pdf.png';

    if(media['type'].split('/')[0] == 'video'){
      return  'assets/images/file_types/Videos-icon.png';
    }

    switch (media.type) {
      case 'application/pdf':
        icon_img =  'assets/images/file_types/pdf.png';
        break;
      case 'text/csv':
        icon_img =  'assets/images/file_types/csv.png';
        break;
      case 'application/msword':
        icon_img =  'assets/images/file_types/doc.png';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon_img =  'assets/images/file_types/doc.png';
        break;
      case 'image/gif':
        icon_img =  media.url;
        if(media.url == undefined){
          icon_img = "assets/images/file_types/unknown.png"
        }
        break;
      case 'image/jpeg':
        icon_img =  media.url;
        // icon_img = media.previewUrl;
        if(media.url == undefined){
          icon_img = "assets/images/file_types/jpg.png"
        }
        break;
      case 'image/png':
        icon_img =  media.url;
        if(media.url == undefined){
          icon_img = "assets/images/file_types/png.png"
        }
        break;
      case 'text/plain':
        icon_img = 'assets/images/file_types/txt.png';
        break;
      case 'application/vnd.ms-excel':
        icon_img =  'assets/images/file_types/xls.png';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon_img =  'assets/images/file_types/xls.png';
        break;
      case 'application/zip':
        icon_img =  'assets/images/file_types/xls.png';
        break;
      default :
        icon_img = 'assets/images/file_types/unknown.png'

    }

    return icon_img;
  }

}
