import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/global/global.service';
import { SharedComponentService } from '../../shared-component.service';

@Component({
  selector: 'app-staff-registration-view',
  templateUrl: './staff-registration-view.component.html',
  styleUrls: ['./staff-registration-view.component.sass']
})

export class StaffRegistrationViewComponent implements OnInit {

  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  signatureLength = 0;
  currentIndex: any = -1;
  htmlBody = null;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.getNotification();
  }

  getNotification() {
    this.shardService.getStaffRegistrationForm(this.rowRef).subscribe(res => {
      this.data = res['data'];
    }, error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  modalClose() {
    this.closeModal.emit(true);
  }

}
