import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-attendance-table-filter",
  templateUrl: "./attendance-table-filter.component.html",
  styleUrls: ["./attendance-table-filter.component.scss"],
})
export class AttendanceTableFilterComponent implements OnInit {
  @Output("loadTableEmitter") loadTableEmitter: EventEmitter<any> =
    new EventEmitter();
  filterForm: FormGroup;
  options: any = [
    { value: "weekly", label: "Weekly" },
    { value: "fortnightly", label: "Fortnightly" },
  ];
  startDate: any;
  endDate: any;

  constructor(private fb: FormBuilder, private datePipe: DatePipe) {
    this.filterForm = this.fb.group({
      start_date: [new Date()],
      end_date: [new Date()],
      week_range: ["weekly"],
      filterKey: ["A_Z"],
      searchValue: [""],
      showSubmittedReports: [false],
    });
    // this.setupWeeklyFortnightlyDate(new Date());
    this.setUpDateRange(new Date(), this.filterForm.value.week_range);
  }

  ngOnInit(): void {
    this.loadTableEmitter.emit(this.filterForm);
  }

  setupWeeklyFortnightlyDate(date: Date) {
    const selectedDate = new Date(date);
    const oneWeekAgo = new Date(date);
    const twoWeekAgo = new Date(date);
    selectedDate.setDate(date.getDate());
    oneWeekAgo.setDate(date.getDate() - 6);
    twoWeekAgo.setDate(date.getDate() - 13);
    const dateSelected = this.datePipe.transform(selectedDate, "MM/dd/yyyy");
    const dateOneWeekAgo = this.datePipe.transform(oneWeekAgo, "MM/dd/yyyy");
    const dateTwoWeekAgo = this.datePipe.transform(twoWeekAgo, "MM/dd/yyyy");
    this.options = [
      {
        value: "weekly",
        label: "Weekly: " + dateOneWeekAgo + " - " + dateSelected,
      },
      {
        value: "fortnightly",
        label: "Fortnightly: " + dateTwoWeekAgo + " - " + dateSelected,
      },
    ];
  }

  formFilters($event) {
    this.setupWeeklyFortnightlyDate($event.value);
  }

  cboSelectionChange($event) {
    if ($event.value == "weekly")
      this.endDate = moment(this.startDate).endOf("isoWeek").toDate();
    else
      this.endDate = moment(this.startDate)
        .add(7, "days")
        .endOf("isoWeek")
        .toDate();
    this.filterForm.patchValue({
      end_date: this.endDate,
    });
  }

  clearFilter() {
    this.filterForm.reset();
    this.filterForm.patchValue({
      week_range: "weekly",
      filterKey: "A_Z",
      searchValue: "",
    });
    this.setUpDateRange(new Date(), this.filterForm.value.week_range);
  }

  searchFunction(form: FormGroup) {
    this.loadTableEmitter.emit(form);
  }

  getMaxDate(): Date | null {
    return new Date();
  }

  onStartChange(event: any) {
    this.setUpDateRange(event.value, this.filterForm.value.week_range);
  }

  setUpDateRange(date: Date, week_range: any = "weekly") {
    this.startDate = moment(date).startOf("isoWeek").toDate();
    if (week_range == "weekly")
      this.endDate = moment(date).endOf("isoWeek").toDate();
    else
      this.endDate = moment(this.startDate)
        .add(7, "days")
        .endOf("isoWeek")
        .toDate();
    this.filterForm.patchValue({
      start_date: this.startDate,
      end_date: this.endDate,
    });
  }
}
