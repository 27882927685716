import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //   const url: string = state.url;
  //   return this.checkUserLogin(route, url);
  // }

  // checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {

  //   if (this.authService.isLoggedIn()) {

  //     return true;
  //   }

  //   this.router.navigate(['/login']);
  //   return false;
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
        if (this.authService.isLoggedIn()) {
          var urlArray = state.url.split('?');
          // logged in so return to view validator
          this.authService.checkUrlPermission(urlArray[0])
            .subscribe(
              (data: any) => {
                res(true);
              },
              (err: any) => {
                if (err.status == 403) {
                  this.router.navigate(['**']);
                  res(false);
                }
                res(false);
              }
            );
        } else {
          let returnUrl = window.location.href;
          this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
          res(false);
        }
      }
    )
  };

}
