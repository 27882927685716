import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-esi-header',
  templateUrl: './esi-header.component.html',
  styleUrls: ['./esi-header.component.css']
})
export class EsiHeaderComponent implements OnInit {
  @Output() sendCurrentDate: EventEmitter<string> = new EventEmitter<string>();
  currentDate: Date = new Date();
  private intervalId: any;

  constructor() { }

  ngOnInit(): void {
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000); // Update every 1000 milliseconds (1 second)
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
  private updateTime() {
    this.currentDate = new Date();
    this.sendCurrentDate.emit(this.currentDate.toString());
  }

}
