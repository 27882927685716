import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import Swal from "sweetalert2";
import { AccountService } from "../../../accounts/account.service";

@Component({
  selector: 'app-grievance-complaint-notification-view',
  templateUrl: './grievance-complaint-notification-view.component.html',
  styleUrls: ['./grievance-complaint-notification-view.component.sass']
})
export class GrievanceComplaintNotificationViewComponent implements OnInit {
  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private accountService: AccountService,
  ) {
    let c_date = new Date();
    this.currentDate = new Date(c_date.getFullYear() + '/' + (c_date.getMonth() + 1) + '/' + c_date.getDate());
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      is_approval_user_id: this.loggedUser['user']['user_id'].toString(),
      signature_date: [this.currentDate],
      grievance_form_ref: [''],
      notification_ref: ['']
    });
  }

  ngOnInit(): void {
    this.getNotification();
    this.getAllMembers();
  }

  formSubmit(form: FormGroup, type) {
    if (!form.valid || this.submitted) {
      this.globeService.scrollToError();
      return false;
    }
    this.sectionForm.patchValue({
      'signature_date': this.globeService.formatDatetoServerSide(this.sectionForm.value.signature_date)
    });
    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    if (type == 1) {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to approve this notification?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approved',
        cancelButtonText: 'No, will approve later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    } else {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to Ignore this notification',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Ignore',
        cancelButtonText: 'No, Will Ignore later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    }

  }

  formSave(type) {
    const xForm = new FormData();
    xForm.append('form', JSON.stringify(this.sectionForm.value));
    xForm.append('signature', this.signatureImg);
    xForm.append('is_approval', type);
    this.shardService.updateGrievanceNotification(xForm).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.closeModal.emit(true);
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification(this.error);
      this.submitted = false;
    }
    );
  }

  removeSignature() {
    this.isNewSignature = true;
    this.signatureImg = '';
  }
  signatureImgOutput($event) { this.signatureImg = $event; }

  getNotification() {
    this.shardService.getGrievanceForm(this.rowRef).subscribe((res) => {
      this.data = res['data'];
      if (this.data['is_approval'] == 0) {
        this.sectionForm.patchValue({
          signature_date: this.currentDate,
          grievance_form_ref: this.data['grievance_form_ref'],
          notification_ref: this.notificationRef
        });
      }
    }, (error) => {
    }
    );
  }

  getAllMembers() {
    this.accountService.getAllMembers().subscribe((res) => {
      this.allMembers = res['members'];
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modelClose() {

  }
}
