<div class="container-fluid">

  <mat-sidenav-container class="example-container bg-primary-green"  (backdropClick)="close('backdrop')"  >
    <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>
      <mat-nav-list>
        <a (click)="close('toggle button')"  mat-list-item>Close</a>
        <!-- <a routerLinkActive="active" [routerLink]="'feature'" mat-list-item>Feature</a> -->
<!--        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'/'" mat-list-item>Home</a>-->
        <!-- <a routerLinkActive="active" [routerLink]="'/about-us'" mat-list-item>About Us</a> -->
        <!-- <a routerLinkActive="active" [routerLink]="'/resources'" mat-list-item>Resources</a> -->
        <!-- <a routerLinkActive="active" [routerLink]="'/resources'" mat-list-item>Request a Demo</a> -->
        <a routerLinkActive="active" [routerLink]="'/login'" mat-list-item>Login</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content >
      <nav  class="bg-primary-green menu-bar">
        <mat-toolbar class="container">
          <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.open()" fxShow="true" fxHide.gt-sm>
              <mat-icon>menu</mat-icon>
            </button>
           <a  [routerLink]="'/home'"><img src="../../../assets/logo.png" class="h-55"></a>
            <span class="menu-spacer"></span>
            <div fxShow="true" fxHide.lt-md class="menu-item">
              <!-- The following menu items will be hidden on both SM and XS screen sizes -->
              <!-- <a routerLinkActive="active" [routerLink]="'/feature'">Feature</a> -->
<!--              <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'/'">Home</a>-->
              <!-- <a routerLinkActive="active" [routerLink]="'/about-us'">About Us</a> -->
              <!-- <a routerLinkActive="active" [routerLink]="'/resources'">Resources</a> -->
              <!-- <a routerLinkActive="active" [routerLink]="'/resources'">Request a Demo</a> -->
              <a routerLinkActive="active" [routerLink]="'/login'">Login</a>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </nav>

      <router-outlet></router-outlet>

    </mat-sidenav-content>

  </mat-sidenav-container>
</div>
