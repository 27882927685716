import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'signature'
})
export class SignaturePipe implements PipeTransform {

  transform(imgData: string, type: string): any {
    var src = imgData;
    try {
      if (imgData.length < 15){
        src = 'assets/images/signature-dot.png';
      }
      return src;
    } catch (error) {
      return imgData;
    }
  }
}
