import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { GlobalService } from 'src/app/global/global.service';
import { CustomvalidationService } from "src/app/services/customvalidation.service";

@Component({
  selector: 'app-children-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './children-schedule.component.html',
  styleUrls: ['./children-schedule.component.scss']
})
export class ChildrenScheduleComponent implements OnInit, AfterViewChecked  {

  @Input('isEdit')
  public isEdit: boolean;

  @Input('isDisabled')
  public disableSchedule: boolean;

  @Input('routineSession')
  public routineSession;

  @Input('casualSession')
  public casualSession;

  @Input('startDate')
  public startDate;

  isScheduleStartValid = false;
  @Output("updateForm") formUpdated: EventEmitter<any> = new EventEmitter();

  scheduleChecker = [false, false, false, false, false, false, false];
  schedCheck = false;
  scheduleChecker2 = [false, false, false, false, false, false, false];
  schedCheck2 = false;
  scheduleChecker3 = [false, false, false, false, false, false, false];
  schedCheck3 = false;
  casualSchedChecker = false;
  casualDatedChecker = false;
  public scheduleForm: FormGroup;
  editFlag = false;

  dayArrayNames = ['mon_sched', 'tue_sched', 'wed_sched', 'thu_sched', 'fri_sched', 'sat_sched', 'sun_sched'];
  dayArrayNames2 = ['mon_sched_2', 'tue_sched_2', 'wed_sched_2', 'thu_sched_2', 'fri_sched_2', 'sat_sched_2', 'sun_sched_2'];
  casDayArrayNames = ['cas_mon_sched', 'cas_tue_sched', 'cas_wed_sched', 'cas_thu_sched', 'cas_fri_sched', 'cas_sat_sched', 'cas_sun_sched'];
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  role_id = 0;

  constructor(private fb: FormBuilder, private controlContainer: ControlContainer,
    private globeService: GlobalService, private validatorService: CustomvalidationService,
    private readonly changeDetectorRef: ChangeDetectorRef) {
   }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

   checkerUpdated(event) {
    switch(event.type) {
      case 1: this.scheduleChecker[event.i] = event.checker;
        if (this.scheduleChecker.includes(true) || 
        (this.scheduleForm.controls[event.obj_name] && 
          (<FormArray> this.scheduleForm.controls[event.obj_name]).length > 1)) {
          this.scheduleForm.controls['mon_sched'].setErrors(null);
          this.schedCheck = true;
        } else {
          this.schedCheck = false;
          this.scheduleForm.controls['mon_sched'].setErrors({"requireAtLeastOneSession": true});
        }
        break;
      case 2: this.scheduleChecker2[event.i] = event.checker;
        if (this.scheduleChecker2.includes(true) || 
        (this.scheduleForm.controls[event.obj_name] && 
          <FormArray> this.scheduleForm.controls[event.obj_name]).length > 1) {
          this.scheduleForm.controls['mon_sched_2'].setErrors(null);
          this.schedCheck2 = true;
        } else {
          this.schedCheck2 = false;
          this.scheduleForm.controls['mon_sched_2'].setErrors({"requireAtLeastOneSession": true});
        }
        break;
      case 3:
        this.casualSchedChecker = event.checker;
        if (!event.checker) {
          this.scheduleForm.controls['cas_sched'].setErrors({"requireAtLeastOneSession": true});
        } else {
          this.scheduleForm.controls['cas_sched'].setErrors(null);
        }
        break;
      case 4: this.scheduleChecker3[event.i] = event.checker;
        if (this.scheduleChecker3.includes(true) || 
        (this.scheduleForm.controls[event.obj_name] && 
          <FormArray> this.scheduleForm.controls[event.obj_name]).length > 1) {
          this.scheduleForm.controls['cas_mon_sched'].setErrors(null);
          this.schedCheck3 = true;
        } else {
          this.schedCheck3 = false;
          this.scheduleForm.controls['cas_mon_sched'].setErrors({"requireAtLeastOneSession": true});
        }
        break;
      case 5: 
      this.casualDatedChecker = event.checker;
      if (!event.checker) {
        this.scheduleForm.controls['dated_cas_sched'].setErrors({"requireAtLeastOneSession": true});
      } else {
        this.scheduleForm.controls['dated_cas_sched'].setErrors(null);
      }
      break;
    }

      this.scheduleForm.updateValueAndValidity({emitEvent: false, onlySelf: true});
      this.formUpdated.emit({form: this.scheduleForm});
   }

  dataChange(event) {
    var temp =  this.scheduleForm.controls[event.obj_name] as FormArray
    if (temp && (event.index > temp.length)) {
      (<FormArray>this.scheduleForm.controls[event.obj_name]).push(this.fb.group(event.form));
    } else {
      var idx = event.index;
      if((<FormArray>this.scheduleForm.get(event.obj_name))) {
        (<FormArray>this.scheduleForm.get(event.obj_name)).at(idx).patchValue(event.form, {emitEvent: false});
      }
    }
    this.scheduleForm.updateValueAndValidity({emitEvent: false, onlySelf: true});
    this.formUpdated.emit({form: this.scheduleForm});
  }

  toggleCasualForm(isEnable) {
    if (isEnable) {
      (<FormArray>this.scheduleForm.get('cas_sched')).controls.forEach(control => {
        if (this.role_id == 1 || this.role_id == 4) {
          control.get('fee').enable();
          control.get('unit').enable();
        }
      });
    } else {
      (<FormArray>this.scheduleForm.get('cas_sched')).controls.forEach(control => {
        if (this.role_id != 1 && this.role_id != 4) {
          control.get('fee').disable();
          control.get('unit').disable();
        }
        control.get('session_length').disable();
      });
    }
  }

  toggleDisabledFields(arrayName, isEnable) {
    if (isEnable) {
      arrayName.forEach(dayName => {
        (<FormArray>this.scheduleForm.get(dayName)).controls.forEach(control => {
          control.get('fee').enable();
          control.get('unit').enable();
        });
      })
    } else {
      arrayName.forEach(dayName => {
        (<FormArray>this.scheduleForm.get(dayName)).controls.forEach(control => {
          control.get('fee').disable();
          control.get('unit').disable();
        });
      })
    }
  }

  toggleDayForm(arrayName, isEnable) {
    if (isEnable) {
      arrayName.forEach(dayName => {
        (<FormArray>this.scheduleForm.get(dayName)).controls.forEach(control => {
          control.get('start_time').enable();
          control.get('end_time').enable();
          if (this.role_id == 1 || this.role_id == 4) {
            control.get('fee').enable();
            control.get('unit').enable();
          }
        });
      })
    } else {
      arrayName.forEach(dayName => {
        (<FormArray>this.scheduleForm.get(dayName)).controls.forEach(control => {
          control.get('start_time').disable();
          control.get('end_time').disable();
          if (this.role_id != 1 && this.role_id != 4) {
            control.get('fee').disable();
            control.get('unit').disable();
          }
        });
      })
    }
  }


  initializeForm() {
    this.scheduleForm = <FormGroup>this.controlContainer.control;
    this.scheduleForm = this.fb.group({
      session_type: ['0', [Validators.required]],
      session_cycle: [null],
      is_session_weekly: ['0'],
      with_day: [false],
      with_multiple_day: [false],
      without_day: [false],
      cas_start: [''],
      cas_end: [''],
      start_week: [this.startDate],
      mon_sched: this.fb.array([this.createRoutineSession()]),
      tue_sched: this.fb.array([this.createRoutineSession()]),
      wed_sched: this.fb.array([this.createRoutineSession()]),
      thu_sched: this.fb.array([this.createRoutineSession()]),
      fri_sched: this.fb.array([this.createRoutineSession()]),
      sat_sched: this.fb.array([this.createRoutineSession()]),
      sun_sched: this.fb.array([this.createRoutineSession()]),
      mon_sched_2: this.fb.array([this.createRoutineSession()]),
      tue_sched_2: this.fb.array([this.createRoutineSession()]),
      wed_sched_2: this.fb.array([this.createRoutineSession()]),
      thu_sched_2: this.fb.array([this.createRoutineSession()]),
      fri_sched_2: this.fb.array([this.createRoutineSession()]),
      sat_sched_2: this.fb.array([this.createRoutineSession()]),
      sun_sched_2: this.fb.array([this.createRoutineSession()]),
      cas_mon_sched: this.fb.array([this.createRoutineSession()]),
      cas_tue_sched: this.fb.array([this.createRoutineSession()]),
      cas_wed_sched: this.fb.array([this.createRoutineSession()]),
      cas_thu_sched: this.fb.array([this.createRoutineSession()]),
      cas_fri_sched: this.fb.array([this.createRoutineSession()]),
      cas_sat_sched: this.fb.array([this.createRoutineSession()]),
      cas_sun_sched: this.fb.array([this.createRoutineSession()]),
      cas_sched: this.fb.array([this.createCasualSession()]),
      dated_cas_sched: this.fb.array([this.createRoutineSession(null, true)]),
    }, 
    {
      validators: [
        this.validatorService.conditionallyRequiredValidator('cas_end', 'with_multiple_day', true),
        this.validatorService.conditionallyRequiredValidator('cas_start', 'with_multiple_day', true),
        this.validatorService.conditionallyRequiredValidator('session_cycle', 'session_type', '1'),
        this.validatorService.conditionallyRequiredValidator('session_cycle', 'session_type', '2'),
        this.validatorService.conditionallyRequiredValidator('start_week', 'session_cycle', '2'),
        this.validatorService.conditionallyRequiredValidator('is_session_weekly', 'session_cycle', '2'),
    ]
    });
  }

  ngOnInit(): void {
    var user = JSON.parse(localStorage.getItem('user'));
    this.role_id = user['role_id'];
    if (this.scheduleForm == null || this.scheduleForm.get('session_type') == null) {
      this.initializeForm();
    }
    this.detectChanges();
    if ((this.routineSession != null && Object.keys(this.routineSession).length > 0)) {
      this.isScheduleStartValid = true;
      this.addEditData();
    }
    if (this.disableSchedule && this.scheduleForm != null) {
      this.disableForm();
    }

    this.formUpdated.emit({form: this.scheduleForm});
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.routineSession && this.routineSession != null && Object.keys(this.routineSession).length > 0 )|| 
    (changes.casualSession && this.casualSession != null && this.routineSession != null && Object.keys(this.casualSession).length > 0)
    ) {
        this.isScheduleStartValid = true;
        if (this.scheduleForm == null || this.scheduleForm.get('session_type') == null) {
          this.initializeForm();
        }
        this.addEditData();
        this.disableForm();
    }
  }

  disableForm() {
    if (this.disableSchedule) {
      this.scheduleForm.disable();
    } else {
      this.scheduleForm.enable();
    }
  }

  addScheduledDataToForm(arr, type) {
    let propertyArray: any = [];
    let sessions = [];
    if (type == 1){
      sessions = this.routineSession;
    } else if (type == 2 || type == 3) {
      sessions = this.casualSession;
    } 

    if (type == 1 || type == 2) {
      arr.forEach(dayName => {
        propertyArray = [];
        if (sessions && sessions[dayName]) {
          for(let item of sessions[dayName]) {
            propertyArray.push(this.createRoutineSession(item));
          }
          if (propertyArray.length == 0) {
            propertyArray.push(this.createRoutineSession());
          }
          if (type == 2) {
            this.scheduleForm.controls['cas_' + dayName] = this.fb.array(propertyArray);
          } else {
            this.scheduleForm.controls[dayName] = this.fb.array(propertyArray);
          }
        }
      })
    } else if (type == 3) {
      if (sessions && sessions['dated_data']) {
        propertyArray = [];
        for (let item of sessions['dated_data']) {
          propertyArray.push(this.createRoutineSession(item, true));
        } 
        if (propertyArray.length == 0) {
          propertyArray.push(this.createRoutineSession(null, true));
        }
        this.scheduleForm.controls['dated_cas_sched'] = this.fb.array(propertyArray);
      }
    }
  }

  updateScheduledCheckers(arr, type) {
    let id = 0;
    let sessions = [];
    if (type == 1 || type == 2) {
       sessions = this.routineSession;
    } else if (type == 3) {
      sessions = this.casualSession;
    }

    arr.forEach(dayName => {
      if (sessions && sessions[dayName]) {
        for(let item of sessions[dayName]) {
          if (item['start_time'] && item['end_time']) {
            switch(type) {
              case 1: this.schedCheck = true;
                this.scheduleChecker[id] = true; 
                break;
              case 2: this.schedCheck2 = true;
              this.scheduleChecker2[id] = true; 
                break;
              case 3: this.schedCheck3 = true;
                this.scheduleChecker3[id] = true; 
                break;
            }
          }       
        }
      }
        id++;
      });
      switch(type) {
      case 1:     
        if (this.scheduleChecker.includes(true)) {
          this.scheduleForm.controls['mon_sched'].setErrors(null);
          this.schedCheck = true;
        } else {
          this.scheduleForm.controls['mon_sched'].setErrors({"requireAtLeastOneSession": true});
          this.schedCheck = false;
        }
        break;
      case 2: 
        if (this.scheduleChecker2.includes(true)) {
          this.scheduleForm.controls['mon_sched_2'].setErrors(null);
          this.schedCheck2 = true;
        } else {
          this.scheduleForm.controls['mon_sched_2'].setErrors({"requireAtLeastOneSession": true});
          this.schedCheck2 = false;
        }
        break;
      case 3: 
        if (this.scheduleChecker3.includes(true)) {
          this.scheduleForm.controls['cas_mon_sched'].setErrors(null);
          this.schedCheck3 = true;
        } else {
          this.scheduleForm.controls['cas_mon_sched'].setErrors({"requireAtLeastOneSession": true});
          this.schedCheck3 = false;
        }
        break;
    }
  }

  cycleChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.scheduleForm.patchValue({
        start_week: this.globeService.formatDatetoObject(new Date(this.startDate))
      })
    } else if (event.value == 2) {
      this.scheduleForm.patchValue({
        start_week: this.globeService.formatDatetoObject(this.routineSession.start_week != null ? new Date(this.routineSession.start_week) : ''),
      })
    }
}

  addEditData() {
    if (!this.editFlag) {
      if (this.casualSession) {
        if (this.casualSession['no_date_data'] && this.casualSession['no_date_data'].length > 0) {
          this.casualSchedChecker = true;
        }
        if (this.casualSession['dated_data'] && this.casualSession['dated_data'].length > 0) {
          this.casualDatedChecker = true;
        }
        this.scheduleForm.patchValue({
          with_multiple_day: this.casualSession.is_casual_multiple_days != null ? this.casualSession.is_casual_multiple_days : false,
          with_day: this.casualSession.is_casual_one_day !== null ? this.casualSession.is_casual_one_day : false,
          without_day: this.casualSession.is_casual_no_days !== null ? this.casualSession.is_casual_no_days : false,
          cas_start: this.casualSession.casual_start_date != null ? this.casualSession.casual_start_date : false,
          cas_end: this.casualSession.casual_end_date != null ? this.casualSession.casual_end_date : false,
        },{emitEvent: false});
        }
      }
      this.updateScheduledCheckers(this.dayArrayNames, 1);
      this.updateScheduledCheckers(this.dayArrayNames2, 2);
      this.updateScheduledCheckers(this.dayArrayNames, 3);
    

    this.scheduleForm.patchValue({
      session_type: this.routineSession.session_type != null ? this.routineSession.session_type.toString() : '0',
      session_cycle: this.routineSession.session_cycle != null ? this.routineSession.session_cycle.toString() : null,
      is_session_weekly: this.routineSession.is_two_week_schedule != null ? this.routineSession.is_two_week_schedule.toString() : '0',
      start_week: this.globeService.formatDatetoObject(this.routineSession.start_week != null ? new Date(this.routineSession.start_week) : ''),
    },{emitEvent: false});

    this.addScheduledDataToForm(this.dayArrayNames, 1);
    this.addScheduledDataToForm(this.dayArrayNames2, 1);
    this.addScheduledDataToForm(this.dayArrayNames, 2);

    let propertyArray = [];
    if ((this.routineSession.session_type == 1 || this.routineSession.session_type == 3 ) && this.casualSession && this.casualSession['no_date_data']) {
      for(let item of this.casualSession['no_date_data']) {
        propertyArray.push(this.createCasualSession(item));
      }
      if (propertyArray.length == 0) {
        propertyArray.push(this.createCasualSession());
      }
      this.scheduleForm.controls['cas_sched'] = this.fb.array(propertyArray);
    }

    propertyArray = [];
    if ((this.routineSession.session_type == 1 || this.routineSession.session_type == 3 ) && this.casualSession && this.casualSession['dated_data']) {
      for(let item of this.casualSession['dated_data']) {
        propertyArray.push(this.createRoutineSession(item, true));
      }
      if (propertyArray.length == 0) {
        propertyArray.push(this.createRoutineSession(null, true));
      }
      this.scheduleForm.controls['dated_cas_sched'] = this.fb.array(propertyArray);
    }
    this.editFlag = true;
  }

  detectChanges() {
    this.scheduleForm.valueChanges.subscribe( () => {
      this.formUpdated.emit({form: this.scheduleForm});
    });

    this.scheduleForm.get('session_type').valueChanges
    .subscribe(value => {
      switch (value) {
        case '1':
          this.toggleCasualForm(true);
          this.toggleDayForm(this.dayArrayNames, true);
          this.toggleDayForm(this.dayArrayNames2, true);
          break;
        case '2':
          this.toggleDayForm(this.dayArrayNames, true);
          this.toggleDayForm(this.dayArrayNames2, true);
          this.toggleCasualForm(false);
          break;
        case '3': 
          this.toggleCasualForm(true);
          this.toggleDayForm(this.casDayArrayNames, true);
          this.toggleDayForm(this.dayArrayNames, false);
          this.toggleDayForm(this.dayArrayNames2, false);
        break;
      }
    });

    this.scheduleForm.get('session_cycle').valueChanges
    .subscribe(value => {
      if (value == '1') {
        this.toggleDayForm(this.dayArrayNames2, false);
      } else if (value == '2') {
        this.toggleCasualForm(false);
        this.toggleDayForm(this.dayArrayNames2, false);
      }
    });
    
    this.scheduleForm.get('is_session_weekly').valueChanges
    .subscribe(value => {
        if (value == '1') {
          this.toggleDayForm(this.dayArrayNames2, true);
        } else if (value == '2' && this.scheduleForm.get('session_type').value != '2') {
          this.toggleCasualForm(true);
        }
    })

    this.scheduleForm.get('with_multiple_day').valueChanges
    .subscribe(value => {
        if (value) {
          this.toggleDayForm(this.casDayArrayNames, true);
        }
    })
    this.scheduleForm.get('without_day').valueChanges
    .subscribe(value => {
        if (value) {
          this.toggleCasualForm(true);
        }
    })

    this.scheduleForm.get('with_day').valueChanges
    .subscribe(value => {
        if (value) {
          this.toggleDayForm(['dated_cas_sched'], true);
        }
    })
  }
  
  createRoutineSession(data = null, isWithDate = false) {
    var form = this.fb.group({
      start_time: [(!data || !data.start_time ? '' : data.start_time), []],
      end_time: [(!data || !data.end_time ? '' : data.end_time), []],
      fee: [(!data || !data.fee ? '' : (data.fee)), [Validators.pattern("^$|([0-9]{2,}).([0-9]{2})")]],
      unit: [(!data || !data.unit ? '' : data.unit)],
    },
    {
      validators: [
        this.validatorService.timeRangeValidator('start_time', 'end_time'),
        this.validatorService.requiredFieldValidator('start_time', 'end_time'),
        this.validatorService.requiredFieldValidator('start_time', 'fee'),
        this.validatorService.requiredFieldValidator('end_time', 'fee'),
        this.validatorService.requiredFieldValidator('start_time', 'unit'),
        this.validatorService.requiredFieldValidator('end_time', 'unit')
      ]
    }); 

    if (isWithDate) {
      let control = new FormControl((!data || !data.casual_date ? '' : data.casual_date));
      form.addControl('casual_date', control);  
    }

    if (this.role_id == 1 || this.role_id == 4) {
      form.get('fee').enable();
      form.get('unit').enable();
    } else {
      form.get('fee').disable();
      form.get('unit').disable();
    }
    return form;
  }

  createCasualSession(data = null) {
    var form = this.fb.group({
      description: [(!data || !data.description ? '' : (data.description).toString())],
      fee: [(!data || !data.fee ? '' : (data.fee).toString()), [Validators.pattern("^$|([0-9]{2,}).([0-9]{2})")]],
      unit: [(!data || !data.unit ? '' : (data.unit).toString()), []],
      session_length: [(!data || !data.session_length ? '' : (data.session_length).toString()), [Validators.pattern("^([01]?[0-9]|2[0-3]):[0-5][0-9]$")]]
    });

    if (this.role_id == 1 || this.role_id == 4) {
      form.get('fee').enable();
      form.get('unit').enable();
    } else {
      form.get('fee').disable();
      form.get('unit').disable();
    }

    form.get('session_length').disable();

    return form;
  }

  
  validateDate () {
    let isValidDate = Date.parse(this.scheduleForm.get('start_week').value);
    if (isNaN(isValidDate)) {
      this.isScheduleStartValid = false;
    } else {
      this.isScheduleStartValid = true;
    }
    return this.isScheduleStartValid;
  } 

  resetScheduleData($event) {
    let val = Number($event.value);

    if (this.isEdit && this.routineSession != null) {
      if (this.routineSession.session_type != 0 && this.routineSession.session_type) {
        this.addEditData();
      }
      if (val == 2) {
        this.scheduleForm.patchValue({
          with_day: false,
          with_multiple_day: false,
          without_day: false,
          cas_start: '',
          cas_end: '',
          session_type: $event.value
        })

        this.casDayArrayNames.forEach(element => {
          this.scheduleForm.setControl(element, this.fb.array([this.createRoutineSession()]));
        });

        this.scheduleForm.setControl('cas_sched', this.fb.array([this.createCasualSession()]));
        this.scheduleForm.setControl('dated_cas_sched', this.fb.array([this.createRoutineSession(null, true)]));

      } else if (val == 3) {
        this.scheduleForm.patchValue({
          session_cycle: null,
          is_session_weekly: '0',
          start_week: this.startDate,
          session_type: $event.value
        })

        this.dayArrayNames.forEach(element => {
          this.scheduleForm.setControl(element, this.fb.array([this.createRoutineSession()]));
        });

        this.dayArrayNames2.forEach(element => {
          this.scheduleForm.setControl(element, this.fb.array([this.createRoutineSession()]));
        });
      }

      this.scheduleForm.markAsUntouched();

      if (this.role_id == 1 || this.role_id == 4) {
        this.toggleDisabledFields(this.dayArrayNames, true);
        this.toggleDisabledFields(this.dayArrayNames2, true);
        this.toggleDisabledFields(this.casDayArrayNames, true);
      } else {
        this.toggleDisabledFields(this.dayArrayNames, false);
        this.toggleDisabledFields(this.dayArrayNames2, false);
        this.toggleDisabledFields(this.casDayArrayNames, false);
      }
    }

    this.scheduleForm.updateValueAndValidity({emitEvent: false, onlySelf: true});
    this.formUpdated.emit({form: this.scheduleForm});
  }
  
  addRoutineRow(varName) {
    const control = <FormArray>this.scheduleForm.controls[varName];
    control.push(this.createRoutineSession());
  }

  addCasualDatedRow() {
    const control = <FormArray>this.scheduleForm.controls['dated_cas_sched'];
    control.push(this.createRoutineSession(null, true));
  }

  addCasualRow() {
    const control = <FormArray>this.scheduleForm.controls['cas_sched'];
    control.push(this.createCasualSession());
  }

  removeRoutineRow (varName, index) {
    const control = <FormArray>this.scheduleForm.controls[varName];
    control.removeAt(index);
  }

  removeCasualDatedRow(index) {
    const control = <FormArray>this.scheduleForm.controls['dated_cas_sched'];
    control.removeAt(index);
  }

  removeCasualRow (index) {
    const control = <FormArray>this.scheduleForm.controls['cas_sched'];
    control.removeAt(index);
  } 
}

  
  