import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { GlobalService } from "../../global/global.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EsiService } from "src/app/esi/esi.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { MatTable } from "@angular/material/table";
import { ChildAttendanceAddComponent } from "../child-attendance-add/child-attendance-add.component";
import {
  ConfirmDialogModel,
  ConfirmationModalComponent,
} from "../../shared-component/confirmation-modal/confirmation-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { AttendanceExportModalComponent } from "../attendance-export-modal/attendance-export-modal.component";
import { ExcelService } from "src/app/shared/excel.service";
import { DateAdapter } from "@angular/material/core";

import Swal from "sweetalert2";
import { ChildAttendanceUtilService } from "src/app/attendance-page/services/child-attendance-util.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-child-attendance",
  templateUrl: "./child-attendance.component.html",
  styleUrls: ["./child-attendance.component.sass"],
})
export class ChildAttendanceComponent implements OnInit {
  @ViewChild(MatTable) attendanceTable!: MatTable<any>;
  @ViewChild("print") print!: ElementRef;

  @Input() userAttendanceType: String;
  @Input() is_fdc: Boolean;

  list: any = [];
  listOfCheckIds: any = [];
  treelist: any = [];
  modalRef: NgbModalRef;
  rolePermission: any = [];
  loggedUser: any = [];
  displayedColumns: string[] = [
    "check_box",
    "child_name",
    "sign_in",
    "signed_in_by",
    "sign_out",
    "signed_out_by",
    "action",
  ];
  filtersForm: FormGroup;
  error = "";
  verifiedUser: any = [];
  roleId: number;
  userId: number;
  totalRecords = 0;
  currentPage = 1;
  pageSize = 3;
  submittedFilter = false;
  isEsi = false;
  isEditEnabled = false;
  view_html = null;
  center_type = 0;
  enableEductable = false;
  enableSubmit = false;

  uncheckIds = [];
  checkIds = [];

  doneSubmit = false;
  distinctEducatorNames: any = [];

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private globeService: GlobalService,
    private esiService: EsiService,
    private router: Router,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private dateAdapter: DateAdapter<Date>,
    private childAttendanceUtilService: ChildAttendanceUtilService
  ) {
    this.dateAdapter.setLocale("en-GB");
    this.isEsi = localStorage.getItem("esi_mode")
      ? JSON.parse(localStorage.getItem("esi_mode"))
      : false;
    this.verifiedUser = JSON.parse(localStorage.getItem("user"));
    if (this.isEsi) {
      this.userId = this.verifiedUser["account_details"].user_id;
      this.center_type = this.verifiedUser["account_details"].center_type_id;
    } else {
      this.userId = this.verifiedUser["user"].user_id;
      this.center_type = this.verifiedUser.user.center_type_id;
    }
    this.roleId = this.verifiedUser.role_id;
    if (this.verifiedUser == null) {
      this.router.navigate(["/login"]);
    }
    this.filtersForm = this.fb.group({
      start_date: [new Date()],
      end_date: [new Date()],
      week_range: ["weekly"],
      filterKey: ["A_Z"],
      searchValue: [""],
    });
    this.pageSize = environment.pageSize;
  }

  ngOnInit(): void {
    this.getDayToday();
    this.globeService
      .getFormPermission(this.userAttendanceType, this.center_type)
      .subscribe(
        (res) => {
          this.rolePermission = res["data"];
          // this.getAttendanceList();
        },
        (error) => {
          this.error = error.error.error;
          this.globeService.showErrorNotification("Access Denied");
          this.router.navigate(["**"]);
        }
      );
  }

  loadTableEmitter(filters: FormGroup = null) {
    if (filters) this.filtersForm = filters;
    this.getAttendanceList();
  }

  tblCheckboxEmitter(data: any, mode) {
    if (!data.event.checked) {
      this.handleUncheck(data.row);
    } else {
      this.handleCheck(data.row);
    }
  }
  handleCheck(row: any) {
    const chidlId = this.listOfCheckIds.find((id) => id === row.child_id);
    if (!chidlId) {
      this.listOfCheckIds.push(row.child_id);
    }

    // this.uncheckIds = this.uncheckIds.filter(
    //   (element) => element !== row.all_child_attendance_ids
    // );
  }

  handleUncheck(row: any) {
    this.listOfCheckIds = this.listOfCheckIds.filter(
      (id) => id !== row.child_id
    );

    // const filteredData = this.listOfCheckIds.filter(
    //   (item) => item.all_child_attendance_ids === row.all_child_attendance_ids
    // );

    // if (filteredData.length > 0) {
    //   this.uncheckIds.push(filteredData[0].all_child_attendance_ids);
    // }
  }

  getDayToday() {
    const today = new Date();
    if (today.getDay() > 3) this.enableSubmit = true;
  }
  emailSendPopup() {
    const message = `Are you sure you want to send the Attendance Report?`;

    const dialogData = new ConfirmDialogModel("Confirmation", message);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      maxWidth: "400px",
      panelClass: "confirm-dialog",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.sendEmail();
      }
    });
  }

  sendEmail() {
    let data = this.filtersForm.value;
    data["user_id"] = this.userId;
    data["role_id"] = this.verifiedUser["role_id"];

    this.esiService.sendReportToEmail(data).subscribe(
      (res) => {
        this.globeService.showSuccessNotification(
          "Attendance Report was successfully sent!"
        );
        this.getAttendanceList();
      },
      (error) => {
        this.globeService.showErrorNotification("Something went wrong!");
      }
    );
  }

  openAdd() {
    this.modalRef = this.modalService.open(ChildAttendanceAddComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "sm",
      centered: true,
    });
    this.modalRef.result.then((result) => {
      if (result) this.getAttendanceList();
    });
  }

  downloadAttendancePdf() {
    let data = this.filtersForm.value;
    data["user_id"] = this.userId;
    data["role_id"] = this.verifiedUser["role_id"];
    this.esiService.downloadAttendance(data).subscribe((res) => {
      window.open(res["url"]);
    });
  }

  checkEditEnabled(date) {
    let dateLimit = new Date(date);
    dateLimit.setDate(dateLimit.getDate() + 14);
    let today = new Date();
    let isEditEnabled = false;
    switch (this.roleId) {
      case 4:
        if (today.getTime() < dateLimit.getTime()) {
          isEditEnabled = true;
        } else {
          isEditEnabled = false;
        }
        break;
      case 7:
        dateLimit = new Date(date);
        if (
          today.getMonth() == dateLimit.getMonth() &&
          today.getDate() == dateLimit.getDate() &&
          today.getFullYear() == dateLimit.getFullYear()
        ) {
          isEditEnabled = true;
        } else {
          isEditEnabled = false;
        }
        break;
    }

    return isEditEnabled;
  }
  filterIsSubmitted(data: any[]): any[] {
    return data.filter((item: any) => item.isSubmitted === 0);
  }

  getAttendanceList() {
    if (this.isEsi) {
      this.userId = this.verifiedUser["account_details"].user_id;
    } else {
      this.userId = this.verifiedUser["user"].user_id;
    }
    this.esiService
      .getAttendanceTableList(
        this.userId,
        this.verifiedUser["role_id"],
        this.filtersForm.get("start_date").value
          ? this.globeService.formatDatetoServerSide(
              this.filtersForm.get("start_date").value
            )
          : this.globeService.formatDatetoServerSide(new Date()),
        this.filtersForm.get("end_date").value
          ? this.globeService.formatDatetoServerSide(
              this.filtersForm.get("end_date").value
            )
          : this.globeService.formatDatetoServerSide(new Date()),
        this.filtersForm.get("week_range").value,
        this.filtersForm.get("filterKey").value,
        this.filtersForm.get("searchValue").value
      )
      .subscribe(
        (res) => {
          this.listOfCheckIds = [];
          let filteredData = [];
          if (this.filtersForm.get("filterKey").value == "educator") {
            this.enableEductable = true;
            this.treelist = res["data"];
            let rawIds = [];
            for (const key in this.treelist) {
              if (this.treelist.hasOwnProperty(key)) {
                const filteredRow = this.treelist[key].filter(
                  (item) => item.isSubmitted === 1
                );
                filteredData.push(...filteredRow);

                const array = this.treelist[key];

                rawIds = array.filter((item) => item.isSelected === 1);
                this.listOfCheckIds.push(...rawIds);
                this.treelist[key].filter((item) => item.isSubmitted === 0);
              }
            }
            for (const educatorName in this.treelist) {
              const educatorData = this.treelist[educatorName];
              this.treelist[educatorName] =
                this.filterIsSubmitted(educatorData);
            }
          } else {
            filteredData = res["data"].filter((item) => item.isSubmitted === 1);
            this.enableEductable = false;
            this.list = res["data"].filter((item) => item.isSubmitted === 0);
            this.listOfCheckIds = res["data"].filter(
              (item) => item.isSelected === 1
            );
          }
          if (res["total_records"] == filteredData.length) {
            this.doneSubmit = true;
            if (this.filtersForm.get("filterKey").value == "A_Z") {
              const uniqueEducatorNamesSet = new Set<string>();

              res["data"].forEach((item) => {
                uniqueEducatorNamesSet.add(item.educator_name);
              });

              this.distinctEducatorNames = Array.from(uniqueEducatorNamesSet);
            } else {
              const uniqueEducatorNamesSet = new Set<string>();
              for (const educatorName in res["data"])
                uniqueEducatorNamesSet.add(educatorName);

              this.distinctEducatorNames = Array.from(uniqueEducatorNamesSet);
            }
          } else this.doneSubmit = false;

          this.totalRecords = res["total_records"] - filteredData.length;
          this.submittedFilter = false;
          this.uncheckIds = [];
          // this.attendanceTable.renderRows();
        },
        (error) => {
          this.error = error.error.error;
          this.submittedFilter = false;
          this.globeService.showErrorNotification("Access Denied");
        }
      );
  }

  onPageChange = (pageNumber) => {
    this.currentPage = pageNumber;
    this.getAttendanceList();
  };

  formFilters($event) {
    this.currentPage = 1;
    this.submittedFilter = true;
    this.getAttendanceList();
  }
  // Function to select/deselect all rows
  toggleAllSelection(event: any) {
    this.list.forEach((item) => (item.isSelected = event.checked));
  }

  submitAttendance() {
    let selectedIds = [];
    if (this.filtersForm.get("filterKey").value == "educator") {
      let rawIds = [];
      for (const key in this.treelist) {
        if (this.treelist.hasOwnProperty(key)) {
          const array = this.treelist[key];
          rawIds = array
            .filter((item) => item.isSelected)
            .map((item) => item.all_child_attendance_ids);
          selectedIds.push(...rawIds);
        }
      }
    } else
      selectedIds = this.list
        .filter((item) => item.isSelected)
        .map((item) => item.all_child_attendance_ids);

    if (selectedIds.length == 0) {
      this.globeService.showWarningNotification("No attendance selected.");
      return;
    }
    const xForm = new FormData();
    xForm.append("child_attendance_ids", selectedIds.join(","));
    xForm.append("mode", "SUBMIT");
    xForm.append("user_id", this.verifiedUser.user.user_id);

    this.esiService.batchAction(xForm).subscribe(
      (res) => {
        this.globeService.showSuccessNotification(
          selectedIds.length + " attendance submitted."
        );
        this.getAttendanceList();
      },
      (errorMSG) => {
        console.log(errorMSG);
      }
    );
  }
  saveAttendance() {
    this.childAttendanceUtilService.setCheckedChildIds(this.listOfCheckIds);
    this.childAttendanceUtilService.setTriggerSave(true);

    // let selectedIds = [];
    // if (this.filtersForm.get("filterKey").value == "educator") {
    //   let rawIds = [];
    //   for (const key in this.treelist) {
    //     if (this.treelist.hasOwnProperty(key)) {
    //       const array = this.treelist[key];

    //       rawIds = array
    //         .filter((item) => item.isSelected)
    //         .map((item) => item.all_child_attendance_ids);
    //       selectedIds.push(...rawIds);
    //     }
    //   }
    // } else
    //   selectedIds = this.list
    //     .filter((item) => item.isSelected)
    //     .map((item) => item.all_child_attendance_ids);

    // if (selectedIds.length == 0 && this.uncheckIds.length == 0) {
    //   this.globeService.showWarningNotification("No attendance selected.");
    //   return;
    // }

    // const xForm = new FormData();
    // xForm.append("child_attendance_ids", selectedIds.join(","));
    // xForm.append("uncheck_child_attendance_ids", this.uncheckIds.join(","));
    // xForm.append("mode", "SAVE");
    // xForm.append("user_id", this.verifiedUser.user.user_id);

    // this.esiService.batchAction(xForm).subscribe(
    //   (res) => {
    //     this.globeService.showSuccessNotification(
    //       selectedIds.length + this.uncheckIds.length + " attendance updated."
    //     );

    //     this.getAttendanceList();
    //   },
    //   (errorMSG) => {
    //     console.log(errorMSG);
    //   }
    // );
  }

  scrollToTop() {
    this.scrollStep();
  }

  scrollStep() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop > 0) {
      // Calculate how far to scroll for this frame
      const scrollStep = scrollTop / 20; // You can adjust the divisor for desired scrolling speed

      // Use requestAnimationFrame to create smooth animation
      window.requestAnimationFrame(() => {
        if (document.documentElement.scrollTop > 0) {
          // Scroll by scrollStep amount
          document.documentElement.scrollTop -= scrollStep;
          // Recursively call scrollStep until scrollTop is 0
          this.scrollStep();
        }
      });
    }
  }

  exportPopUp() {
    this.modalRef = this.modalService.open(AttendanceExportModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "sm",
      centered: true,
    });
    this.modalRef.result.then((result) => {
      if (result == "csv") {
        if (this.list.length > 0)
          this.excelService.exportAsExcelFile(this.list, "child_attendance");
        else
          this.globeService.showWarningNotification("No data to generate CSV.");
      }
      if (result == "pdf") this.downloadAttendancePdf();
    });
  }

  async submitReport() {
    this.childAttendanceUtilService.triggerValidate
      .pipe(take(1))
      .subscribe(async (triggerValidate) => {
        console.log(triggerValidate);

        if (!triggerValidate) {
          const response = await Swal.fire({
            title: "Validation Required",
            icon: "warning",
            html: "Attendance Report Failed. There were error that requires validation. Please click Validate to see the details.",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Validate",
            cancelButtonText: "Cancel",
          });

          if (response.isConfirmed) {
            console.log("confirmed: ", response);
            this.validate();
          } else {
            console.log("not confirmed: ", response);
          }
        } else {
          this.childAttendanceUtilService.validation_errors
            .pipe(take(1))
            .subscribe(async (validationErrors) => {
              if (validationErrors.length > 0) {
                const response = await Swal.fire({
                  title: "Validation Required",
                  icon: "warning",
                  html: "Attendance Report has validation errors. Please fix the issues listed.",
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: true,
                  confirmButtonText: "Ok",
                });
              } else {
                this.childAttendanceUtilService.setCheckedChildIds(
                  this.listOfCheckIds
                );
                this.childAttendanceUtilService.setTriggerReport(true);

                setTimeout(() => {
                  Swal.fire({
                    title: "Report Submission",
                    icon: "success",
                    html: "Attendance / Care Session Report submitted successfully",
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText: "Ok",
                  });
                }, 2000);
              }
            });
        }
      });
  }

  validate() {
    this.childAttendanceUtilService.setCheckedChildIds(this.listOfCheckIds);
    this.childAttendanceUtilService.setTriggerValidate(true);
  }
}
