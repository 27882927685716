
<div class="modal-header m-t-15">
    <div class="modalHeader clearfix">
        <div class="modal-about contact-header">
            <div class="contact-details-heading">
                <div class="contact-details-name mb-2 w-100-p">Update Attendance Record</div>
                <div class="badge col-green">{{ data.date }} {{data.day}}</div>
            </div>
        </div>
    </div>
    <button mat-icon-button (click)="cancelUpdates()" class="bg-light-gray m-r-14" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body p-b-30 p-t-5 p-l-5">
    <mat-card-content class="mb-2">
        <form class="register-form filter-form" [formGroup]="attendanceForm" (ngSubmit)="submitUpdates()">
            <div class="row m-b-10">
                <mat-form-field class="example-full-width m-l-15" appearance="outline">
                    <mat-label>Sign in</mat-label>
                    <input type="time" matInput placeholder="HH:mm" formControlName="start_time">
                    <mat-error *ngIf="attendanceForm.get('start_time').touched && attendanceForm.get('start_time').hasError('required')">
                        Sign in time is not valid.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row m-b-10">
                <mat-form-field class="example-full-width m-l-15" appearance="outline">
                    <mat-label>Sign out</mat-label>
                    <input type="time" matInput placeholder="HH:mm" formControlName="end_time">
                </mat-form-field>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0">
                <button mat-flat-button color="primary" type="submit" [disabled]="!attendanceForm.valid" class="pull-right text-white m-b-10">
                    Submit
                </button>
                <button class="pull-right cancel-btn text-white mr-3 bg-light-red m-b-10" type="button" (click)="cancelUpdates()"
                    mat-raised-button>Close</button>
            </div>
        </form>
    </mat-card-content>
</div>
