import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-relief-educator-notification-view',
  templateUrl: './relief-educator-notification-view.component.html',
  styleUrls: ['./relief-educator-notification-view.component.sass']
})
export class ReliefEducatorNotificationViewComponent implements OnInit {
  @Input() rowRef: string;
  @Input() notificationRef: string = '';
  @Input() headerHide: number = 0;
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;


  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
  ) {
    let c_date = new Date();
    this.currentDate = new Date(c_date.getFullYear() + '/' + (c_date.getMonth() + 1) + '/' + c_date.getDate());
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
      bsacs_unit: [''],
      manager_date: [''],
      signature: [''],
      request_type: [''],
      is_approval: [''],
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'relief_educator',
      notification_ref: this.notificationRef,
      request_type: 'own-table'
    });
    this.getReliefNotification();
  }

  formSubmit(form: FormGroup, type) {
    if (!form.valid || this.submitted) {
      this.globeService.scrollToError();
      return false;
    }
    this.sectionForm.patchValue({
      'signature_date': this.globeService.formatDatetoServerSide(this.sectionForm.value.signature_date)
    });
    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    if (type == 1) {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to approve this notification?',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approved',
        cancelButtonText: 'No, will approve later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    } else {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to decline this notification',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, decline',
        cancelButtonText: 'No, will decline later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    }

  }

  formSave(type) {

    this.sectionForm.patchValue({
      signature: this.signatureImg,
      manager_date: this.globeService.formatDatetoServerSide(this.sectionForm.value.manager_date),
      is_approval: type
    });

    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    // const xForm = new FormData();
    // xForm.append('form', JSON.stringify(this.sectionForm.value));
    // xForm.append('signature', this.signatureImg);
    // xForm.append('is_approval', type);
    this.shardService.updateReliefNotification(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.closeModal.emit(true);
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification(this.error);
      this.submitted = false;
    }
    );
  }

  removeSignature() {
    this.isNewSignature = true;
    this.signatureImg = '';
  }
  signatureImgOutput($event) { this.signatureImg = $event; }

  getReliefNotification() {
    this.shardService.getRelief(this.rowRef).subscribe((res) => {
      this.data = res['data'];
      if (this.data['is_approval'] == 0) {
        this.sectionForm.patchValue({
          signature_date: this.currentDate,
          relief_educator_ref: this.data['relief_educator_ref'],
          notification_ref: this.notificationRef
        });
      }
    }, (error) => {
    }
    );
  }

  modelClose() {

  }
}
