<form class="register-form" [formGroup]="scheduleForm">
    <div class="col-md-12 col-sm-12 mb-2 m-t-15">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-select formControlName="session_type" (selectionChange)="resetScheduleData($event)">
          <mat-option value="0" disabled="true">Select Session Type</mat-option>
          <mat-option value="1">Routine Session (CASUAL PERMITTED)</mat-option>
          <mat-option value="2">Routine Session</mat-option>
          <mat-option value="3">Casual Session</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-12 col-sm-12 mb-2 m-t-15" *ngIf="scheduleForm.get('session_type').value == '1' || scheduleForm.get('session_type').value == '2'">
        <h4>Routine Session</h4>
        <p class="schedule_description">A carer's hours of Child Care Subsidy are allocated per fortnight, you can define routine sessions using either a weekly or fortnightly cycle.</p>
        <ul class=“bullet-list”>
          <li>A <b>weekly</b> cycle means a child is booked to attend the same sessions every week.</li>
          <li>A <b>fortnightly</b> cycle means a child is booked to attend different sessions on alternating weeks.</li>
        </ul>
        <div class="row m-t-20">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <p class="schedule_description">Are the routine sessions for the new arrangement on a weekly or fortnightly cycle?</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <mat-radio-group formControlName="session_cycle">
              <mat-radio-button class="border-radio" value="1" (change)="cycleChange($event)">Weekly</mat-radio-button>
              <mat-radio-button class="border-radio" value="2" (change)="cycleChange($event)">Fortnightly</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row m-t-20" *ngIf="scheduleForm.get('session_cycle').value == '2'">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <p class="schedule_description">Is there at least one routine session in each week of a fortnight?</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <mat-radio-group formControlName="is_session_weekly">
              <mat-radio-button class="border-radio" value="1">Yes</mat-radio-button>
              <mat-radio-button class="border-radio" value="2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row m-t-20" *ngIf="(scheduleForm.get('is_session_weekly').value == '1' || 
        scheduleForm.get('is_session_weekly').value == '2') && scheduleForm.get('session_cycle').value == '2'">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
            <p class="schedule_description">Select the week from which the new arrangements will take effect?</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-4 mb-2">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 94%;">
              <input matInput placeholder="dd/mm/yyyy" formControlName="start_week" [matDatepicker]="child_start" (click)="child_start.open()">
              <mat-datepicker-toggle matSuffix [for]="child_start"></mat-datepicker-toggle>
              <mat-datepicker #child_start></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2">
            <button mat-raised-button class="text-white m-l-3 search-btn pull-left" (click) = validateDate()>Search</button>
          </div> -->
      </div>

      <div class="row" *ngIf="scheduleForm.get('session_cycle').value == '1' || (scheduleForm.get('session_cycle').value == '2' && validateDate())">
        <div class="col-md-12 col-sm-12 mb-2 m-t-15">
          <p class="schedule_description">For the Routine Session the following is mandatory: Start time, End time, Fee $ and Fee Unit</p>
          <p class="p-warn" *ngIf="!disableSchedule && !schedCheck">Session must have at least one entry</p>
          <h5 *ngIf="scheduleForm.get('session_cycle').value == '1'">Record the weekly routine sessions details for the new arrangement below:</h5>
          <h5 *ngIf="scheduleForm.get('session_cycle').value == '2'">Record the new arrangement for the selected week of the fortnightly cycle below:</h5>
          <div class="col-md-12 table-responsive table-no-padding">
            <table class="table remove-padding-bottom w-100 mat-table-responsive t-b-1">
              <thead>
                <tr class="mat-header-row cdk-header-row ng-star-inserted">
                  <th class="header-cell">Routine</th>
                  <th class="header-cell">Start time</th>
                  <th class="header-cell">End time</th>
                  <th class="header-cell">Fee $</th>
                  <th class="header-cell">Fee Unit</th>
                  <th class="header-cell"></th>
                </tr>
              </thead>
              <ng-container *ngFor="let dayName of dayArrayNames; index as j">
                <tbody [formArrayName]="dayName" *ngFor="let data of scheduleForm.controls[dayName].controls; index as i">
                  <tr app-routine-day [day]="days[j]" [isCasualWithDate]= "false" [group]="scheduleForm.controls[dayName].controls[i]" [index]="i" [checker]="scheduleChecker[j]"
                  class="{{(scheduleForm.controls[dayName].controls[i].touched && scheduleForm.controls[dayName].controls[i].invalid) 
                    ? 'error-row' : '' }}" (addRoutineRow)="addRoutineRow(dayName)" [type] = "1" (dataChange)="dataChange($event)" [obj_name]="dayName"
                    (removeRoutineRow)="removeRoutineRow(dayName, i)"  (getCheckerChange)="checkerUpdated($event)" [dayIdx]="j" [isDisabled]="this.disableSchedule"></tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 mb-2 m-t-15" *ngIf="scheduleForm.get('session_cycle').value != '1' && scheduleForm.get('is_session_weekly').value == '1'">
          <p class="p-warn" *ngIf="!disableSchedule && !schedCheck2">Session must have at least one entry</p>
          <h5 *ngIf="scheduleForm.get('session_cycle').value == '1'">Record the weekly routine sessions details for the new arrangement below:</h5>
          <h5 *ngIf="scheduleForm.get('session_cycle').value == '2'">Record the new arrangement for the selected week of the fortnightly cycle below:</h5>
          <div class="col-md-12 table-responsive table-no-padding">
            <table class="table remove-padding-bottom w-100 mat-table-responsive t-b-1">
              <thead>
                <tr class="mat-header-row cdk-header-row ng-star-inserted">
                  <th class="header-cell">Routine</th>
                  <th class="header-cell">Start time</th>
                  <th class="header-cell">End time</th>
                  <th class="header-cell">Fee $</th>
                  <th class="header-cell">Fee Unit</th>
                  <th class="header-cell"></th>
                </tr>
              </thead>
              <ng-container *ngFor="let dayName of dayArrayNames2; index as j">
                <tbody [formArrayName]="dayName" *ngFor="let data of scheduleForm.controls[dayName].controls; index as i">
                  <tr app-routine-day [day]="days[j]" [group]="scheduleForm.controls[dayName].controls[i]" [index]="i" [checker]="scheduleChecker2[j]"
                  class="{{(scheduleForm.controls[dayName].controls[i].touched && scheduleForm.controls[dayName].controls[i].invalid) 
                    ? 'error-row' : '' }}" (addRoutineRow)="addRoutineRow(dayName)" [type] = "2" (dataChange)="dataChange($event)" [obj_name]="dayName"
                    (removeRoutineRow)="removeRoutineRow(dayName, i)"  (getCheckerChange)="checkerUpdated($event)" [dayIdx]="j" [isDisabled]="this.disableSchedule"></tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 mb-2 m-t-15" *ngIf="scheduleForm.get('session_type').value == '3' || scheduleForm.get('session_type').value == '1'">
      <h4>Casual Session</h4>
      <div class="row m-t-20">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <p class="schedule_description">Choose the type of casual sessions</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <mat-checkbox formControlName="with_day" class="border-radio custom-checkbox width-30">Daily-based</mat-checkbox>
          <mat-checkbox formControlName="with_multiple_day" class="border-radio custom-checkbox width-30">Weekly-based</mat-checkbox>
          <mat-checkbox formControlName="without_day" class="border-radio custom-checkbox width-30">Description-based</mat-checkbox>
          <!-- <mat-error *ngIf="scheduleForm.get('with_multiple_day').hasError('required') || scheduleForm.get('without_day').hasError('required') || scheduleForm.get('with_day').hasError('required')">
            Pick at least one casual session type.
          </mat-error> -->
        </div>
      </div>
      <div class="row m-t-20" *ngIf="scheduleForm.get('with_multiple_day').value">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <p class="schedule_description">Choose the date range casual sessions will be applied to</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="custom-fit-field" appearance="outline">
            <mat-date-range-input [rangePicker]="picker" [dateFilter]="globeService.weekStartDatesFilter">
              <input matStartDate placeholder="Start date" formControlName="cas_start"  (click)="picker.open()">
              <input matEndDate placeholder="End date" formControlName="cas_end" (click)="picker.open()">
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="scheduleForm.get('cas_start').hasError('required') || scheduleForm.get('cas_end').hasError('required')">
              Date Range is not valid
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <p class="p-warn" *ngIf="!disableSchedule && (!casualSchedChecker && scheduleForm.get('without_day').value) || (!schedCheck3 && scheduleForm.get('with_multiple_day').value) || (!casualDatedChecker && scheduleForm.get('with_day').value)">Session must have at least one entry</p>
      <h5 *ngIf="scheduleForm.get('without_day').value || scheduleForm.get('with_multiple_day').value || scheduleForm.get('with_day').value">Record the casual sessions details for the new arrangement below:</h5>
      
      
      <div class="col-md-12 table-responsive table-no-padding" *ngIf="scheduleForm.get('without_day').value">
        <table class="table remove-padding-bottom w-100 mat-table-responsive t-b-1">
          <thead>
            <tr class="mat-header-row cdk-header-frow ng-star-inserted">
              <th class="header-cell">Description</th>
              <th class="header-cell">Fee $</th>
              <th class="header-cell">Fee Unit</th>
              <th class="header-cell">Session Length</th>
              <th class="header-cell"></th>
            </tr>
          </thead>
          <tbody formArrayName="cas_sched" *ngFor="let data of scheduleForm.controls.cas_sched.controls; index as i">
            <tr app-casual-day [group]="scheduleForm.controls.cas_sched.controls[i]" (addCasualRow)="addCasualRow()" (dataChange)="dataChange($event)" obj_name="cas_sched"
            (removeCasualRow)="removeCasualRow(i)" class="{{(scheduleForm.controls.cas_sched.controls[i].touched && scheduleForm.controls.cas_sched.controls[i].invalid) ? 'error-row' : '' }}"
            [checker]="casualSchedChecker" (getCheckerChange)="checkerUpdated($event)" [index]="i" [isWithName]="false" [isDisabled]="this.disableSchedule"></tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 table-responsive table-no-padding" *ngIf="scheduleForm.get('with_multiple_day').value">
        <table class="table remove-padding-bottom w-100 mat-table-responsive t-b-1">
          <thead>
            <tr class="mat-header-row cdk-header-row ng-star-inserted">
              <th class="header-cell">Casual</th>
              <th class="header-cell">Start time</th>
              <th class="header-cell">End time</th>
              <th class="header-cell">Fee $</th>
              <th class="header-cell">Fee Unit</th>
              <th class="header-cell"></th>
            </tr>
          </thead>
          <ng-container *ngFor="let dayName of casDayArrayNames; index as j">
            <tbody [formArrayName]="dayName" *ngFor="let data of scheduleForm.controls[dayName].controls; index as i">
              <tr app-routine-day [day]="days[j]" [isCasualWithDate]= "false" [group]="scheduleForm.controls[dayName].controls[i]" [index]="i" [checker]="scheduleChecker3[j]"
              class="{{(scheduleForm.controls[dayName].controls[i].touched && scheduleForm.controls[dayName].controls[i].invalid) 
                ? 'error-row' : '' }}" (addRoutineRow)="addRoutineRow(dayName)" [type] = "4" (dataChange)="dataChange($event)" [obj_name]="dayName"
                (removeRoutineRow)="removeRoutineRow(dayName, i)"  (getCheckerChange)="checkerUpdated($event)" [dayIdx]="j" [isDisabled]="this.disableSchedule"></tr>
            </tbody>
          </ng-container>
        </table>
      </div>
      <div class="col-md-12 table-responsive table-no-padding" *ngIf="scheduleForm.get('with_day').value">
        <table class="table remove-padding-bottom w-100 mat-table-responsive t-b-1">
          <thead>
            <tr class="mat-header-row cdk-header-row ng-star-inserted">
              <th class="header-cell"></th>
              <th class="header-cell">Date</th>
              <th class="header-cell">Start time</th>
              <th class="header-cell">End time</th>
              <th class="header-cell">Fee $</th>
              <th class="header-cell">Fee Unit</th>
              <th class="header-cell"></th>
            </tr>
          </thead>

          <tbody formArrayName="dated_cas_sched" *ngFor="let data of scheduleForm.controls.dated_cas_sched.controls; index as i">
            <tr app-routine-day day="" [group]="scheduleForm.controls.dated_cas_sched.controls[i]" [index]="i" [checker]="casualDatedChecker"
            class="{{(scheduleForm.controls.dated_cas_sched.controls[i].touched && scheduleForm.controls.dated_cas_sched.controls[i].invalid) 
              ? 'error-row' : '' }}" (addRoutineRow)="addCasualDatedRow()" [type] = "5" (dataChange)="dataChange($event)" [obj_name]="dayName"
              (removeRoutineRow)="removeCasualDatedRow(i)" [isCasualWithDate]="true"  (getCheckerChange)="checkerUpdated($event)" [dayIdx]="0" [isDisabled]="this.disableSchedule">
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</form> 