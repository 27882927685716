import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import { FamiliesCommunitiesFormService } from '../../../families-communities-form/families-communities-form.service';
import { AccountService } from "../../../accounts/account.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-change-booking-view',
  templateUrl: './change-booking-view.component.html',
  styleUrls: ['./change-booking-view.component.sass']
})
export class ChangeBookingViewComponent implements OnInit {

  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  declineRequestModalRef: NgbModalRef;
  loggedUser: any = [];
  signatureImg = null;
  signatureImg2 = null;
  signatureImg3 = null;
  currentDate = null;
  isNewSignature = true;
  isNewSignature2 = true;
  isNewSignature3 = false;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  currentIndex: any = -1;
  htmlBody = null;
  isFdc = false;
  mediumModalConfig = { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, backdrop: false };
  parents: any = [];

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private familyService: FamiliesCommunitiesFormService,
    private accountService: AccountService,
    private modalService: NgbModal,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      educator_id: [''],
      notification_ref: [''],
      signature1: [''],
      signature2: [''],
      signature3: [''],
      request_type: [''],
      p_sign_date: [''],
      e_sign_date: [''],
      received_by_user_id: [''],
      received_by_user_name: [''],
      received_by_role: [''],
      approved_sign_date: [''],
      approved_by_user_id: [''],
      approved_by_user_name: [''],
      approved_by_role: [''],
      result_type: [0],
      decline_reason: [''],
      isFdc: [false]
    });
  }

  ngOnInit(): void {
    this.isFdc = this.loggedUser['user'].center_type_id == 2 ? true : false;
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'change_booking_form',
      notification_ref: this.notificationRef,
      request_type: 'own-table'
    });

    this.getNotification();
    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
      this.getParents(child['child_id']);
    }
  }

  
  getParents(childId) {
    this.accountService.getParentByChildId(childId).subscribe((res) => {
      this.parents = res['parents'];
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }


  getNotification() {
    this.htmlBody = null;
    var data = { change_booking_form_ref: this.rowRef, type: 2, };

    this.familyService.getViewForF7ChangeOfBooking(data).subscribe(res => {
      this.htmlBody = res['view_html'];
      this.isNewSignature = !res['is_parent_signed'];
      this.isNewSignature2 = !res['is_received_signed'];
      this.isNewSignature3 = !res['is_approved_signed'];

      if (this.loggedUser.role_id == 6) {
        this.sectionForm.patchValue({
          received_by_user_id: this.loggedUser.user.user_id,
        })
      }
      if ((this.loggedUser.role_id == 7 && this.isNewSignature2) || ((this.loggedUser.role_id == 4 || this.loggedUser.role_id == 7 || this.loggedUser.role_id == 9) && this.isNewSignature2) && this.isFdc) {
        this.sectionForm.patchValue({
          received_by_user_name : (this.loggedUser.first_name ? this.loggedUser.first_name : '') + ' ' + (this.loggedUser.last_name ? this.loggedUser.last_name : ''),
          received_by_user_id: this.loggedUser.user.user_id,
          received_by_role: this.loggedUser.role
        })
      }

      if ((this.loggedUser.role_id == 4 || this.loggedUser.role_id == 9) && this.isNewSignature3) {
        this.sectionForm.patchValue({
          approved_by_user_name : (this.loggedUser.first_name ? this.loggedUser.first_name : '') + ' ' + (this.loggedUser.last_name ? this.loggedUser.last_name : ''),
          approved_by_user_id: this.loggedUser.user.user_id,
          approved_by_role: this.loggedUser.role
        })
      }

    }, error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  declinePopUp(content) {
    this.declineRequestModalRef = this.modalService.open(content, this.mediumModalConfig);
  }

  submitDecline() {
    this.formSubmit(2);
    this.modalService.dismissAll();
  }

  formSubmit(type = 0) {    
    if (!this.sectionForm.valid || this.submitted) {
      this.globeService.scrollToError();
      return;
    }

    if (this.loggedUser.role_id == 6) {
      this.sectionForm.patchValue({
        p_sign_date: this.globeService.formatDatetoServerSide(this.sectionForm.value.p_sign_date),
        signature1: this.signatureImg,
      });
      type = 0;
    } 
    
    if ((this.loggedUser.role_id == 7 && this.isNewSignature2) || ((this.loggedUser.role_id == 4 || this.loggedUser.role_id == 7 || this.loggedUser.role_id == 9) && this.isNewSignature2) && this.isFdc) {
      this.sectionForm.patchValue({
        e_sign_date: this.globeService.formatDatetoServerSide(this.sectionForm.value.e_sign_date),
        signature2: this.signatureImg2,
      });
      type = type != 2 ? 1: type;
    } 

    if ((this.loggedUser.role_id == 4 || this.loggedUser.role_id == 9) && this.isNewSignature3) {
      this.sectionForm.patchValue({
        approved_sign_date: this.globeService.formatDatetoServerSide(this.sectionForm.value.approved_sign_date),
        signature3: this.signatureImg3,
      });
      type = type != 2 ? 3: type;
    }


    this.sectionForm.patchValue({
      result_type: type,
      isFdc: this.isFdc
    })

    this.shardService.updateParentSignature(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.modalClose();
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

  signatureImgOutput1($event) { this.signatureImg = $event; }


  signatureImgOutput2($event) { this.signatureImg2 = $event; }

  signatureImgOutput3($event) { this.signatureImg3 = $event; }

}
