import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalService } from "src/app/global/global.service";
import { DayCoverage, WeekCoverage } from "../models/coverage";

@Injectable({
  providedIn: "root",
})
export class ChildAttendanceService {
  constructor(private http: HttpClient, private globeService: GlobalService) {}

  getAttendance(childId: number) {
    return this.http.get(
      `${this.globeService.baseUrl}child-attendance/${childId}`,
      this.globeService.getHttpOptionsAuth()
    );
  }

  saveAttendance(weekCoverage: WeekCoverage) {
    return this.http.post(
      `${this.globeService.baseUrl}child-attendance`,
      weekCoverage,
      this.globeService.getHttpOptionsAuth()
    );
  }

  saveAttendanceList(weekCoverageList: WeekCoverage[]) {
    return this.http.post(
      `${this.globeService.baseUrl}child-attendance/bulk-save`,
      weekCoverageList,
      this.globeService.getHttpOptionsAuth()
    );
  }

  reportAttendance(session: DayCoverage) {
    const httpOptions = this.globeService.getHttpOptionsAuth();
    httpOptions.headers.append('_method', 'PATCH');
    return this.http.patch(
      `${this.globeService.baseUrl}child-attendance/report/${session.child_attendance_id}`,
      session,
      httpOptions
    );
  }
}
