import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { WeekCoverage } from "../models/coverage";

@Component({
  selector: "app-attendance-weekly-computation-form",
  templateUrl: "./attendance-weekly-computation-form.component.html",
  styleUrls: ["./attendance-weekly-computation-form.component.sass"],
})
export class AttendanceWeeklyComputationFormComponent
  implements OnInit, OnChanges
{
  @Input() weekCoverage: WeekCoverage;
  @Input() index: number;

  @Output() addNewSession = new EventEmitter();
  @Output() removeSession = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const weekCoverageChanges = changes.weekCoverage;

    if (weekCoverageChanges && weekCoverageChanges.currentValue) {
      this.initializeForm();
      this.populateForm(weekCoverageChanges.currentValue);
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    if (!this.form) {
      this.form = this.fb.group({
        id: null,
        increase_entitlement: ["not_eligible"],
        hours_to_claim: ["not_eligible"],
        std_hours: [0],
        std_amount: [0],
        non_std_hours: [0],
        non_std_amount: [0],
        std_total: [0],
        hours_to_claim_total: [0],
        child_count: [0],
        levy: [0],
        non_eligible: [0],
      });
    }
  }

  populateForm(weekCoverage: WeekCoverage) {
    const foodMealFee = weekCoverage.totalFoodMealFee
      ? weekCoverage.totalFoodMealFee
      : 0;
    const travelFee = weekCoverage.totalTravelFee
      ? weekCoverage.totalTravelFee
      : 0;

    this.form.patchValue({
      levy: weekCoverage.totalAdminLevy,
      std_total:
        weekCoverage.totalAdminLevy +
        weekCoverage.totalEducatorFee +
        foodMealFee +
        travelFee,
      std_hours: weekCoverage.totalHours,
      child_count: 1,
    });

    this.form.updateValueAndValidity();
  }

  addNewBookingSession() {
    // console.log(this.weekCoverage);

    this.addNewSession.emit({
      index: this.index,
      start_date: this.weekCoverage.startDate,
      end_date: this.weekCoverage.endDate,
    });
  }

  removeBookingSession() {
    this.removeSession.emit({
      index: this.index,
    });
  }
}
