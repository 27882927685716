import { Component, OnInit } from '@angular/core';
import { EsiService } from "./../esi.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-esi-dashboard',
  templateUrl: './esi-dashboard.component.html',
  styleUrls: ['./esi-dashboard.component.css']
})
export class EsiDashboardComponent implements OnInit {
  currentDate: Date = new Date();
  private intervalId: any;

  attendance_list = [];
  actions: string[] = [
    'RESET',
    'SIGN IN',
    'SIGN OUT',
    'ABSENT'
  ];
  child_attendance_ids: number[];
  selectedOption: string;
  verifiedUser: any = [];
  
  constructor(
    private router: Router,
    private esiService: EsiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    // localStorage.clear();

    this.verifiedUser = JSON.parse(localStorage.getItem('verified_phone_user'));
    if(this.verifiedUser == null)
      this.router.navigate(['/esi/login']);

    this.child_attendance_ids = [];
  }

  ngOnInit(): void {
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000); // Update every 1000 milliseconds (1 second)

    this.getAttendanceList();
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
  private updateTime() {
    this.currentDate = new Date();
  }
  initial = true;

  getAttendanceList() {
    
    this.esiService.getAttendanceList(this.verifiedUser.user_id, this.verifiedUser.role_id).subscribe((res) => {
      this.attendance_list = res['data'];
      this.attendance_list.sort((a, b) => a.child_full_name.localeCompare(b.child_full_name));
      if(res['role_id'] == '6') {
        this.addAddtionalSignInForParents();
      }
      if(this.initial) {
        this.showValidationPopUpForPastAttendance(res['past_data']);
      }
      this.initial = false;
      this.cdr.detectChanges();
    }, (errorMSG) => {
      //console.log(errorMSG);
    });
  }
  signIn(number: string, child: string) {
    const xForm = new FormData();
    xForm.append('child_attendance_id', number);
    xForm.append('child_id', child);
    xForm.append('user_id', this.verifiedUser.user_id);
    this.esiService.signIn(xForm).subscribe((res) => {
      this.getAttendanceList();
    }, (errorMSG) => {
      //console.log(errorMSG);
    });
  }
  parentValidatePastAttendance(child_attendance_id: string) {
    const xForm = new FormData();
    xForm.append('child_attendance_id', child_attendance_id);
    xForm.append('user_id', this.verifiedUser.user_id);
    this.esiService.parentValidatePastAttendance(xForm).subscribe((res) => {
      console.log(res);
    }, (errorMSG) => {
      console.log(errorMSG);
    });
  }
  showValidationPopUpForPastAttendance(data: any) {
    this.showAlertRecursively(data);
  }
  
  private async showAlertRecursively(data: any) {
    for (const row of data) {
      await this.showAlert(row);
    }
  }
  async showAlert(row) {
    const dateToConvert = new Date(row?.date);
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(dateToConvert, 'MMMM d, yyyy');
    let msg = '';
    if(row.status == 1)
      msg = row?.child_name + ' was signed in ' + this.datePipe.transform(row?.sign_in, 'shortTime') + ' and was signed out ' + this.datePipe.transform(row?.sign_out, 'shortTime') + ' by ' + row?.uesr_updated_by + ' on ' + formattedDate;
    if(row.status == 2)
      msg = row?.child_name + ' was marked absent by ' + row?.uesr_updated_by + ' on ' + formattedDate;

    return new Promise<void>(async (resolve) => {
      try {
        const result = await Swal.fire({
          title: 'Validation Required',
          showCancelButton: true,
          text: msg,
          icon: 'question',
          confirmButtonText: 'Confirm',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Dispute'
        });
  
        if (result.isConfirmed) {
          this.parentValidatePastAttendance(row?.child_attendance_id);
        } else {
          console.log('dispute');
        }
        resolve();
      } catch (error) {
        console.error('An error occurred:', error);
        resolve();
      }
    });
  }

  signOut(number: string, sign_in: any, child: string) {
    //console.log(sign_in);
    if (sign_in == null) {
      this.popUpErrorMsg("Sign In is required");
      return;
    }
    const xForm = new FormData();
    xForm.append('child_attendance_id', number);
    xForm.append('user_id', this.verifiedUser.user_id);
    xForm.append('child_id', child);
    this.esiService.signOut(xForm).subscribe((res) => {
      //console.log(res);
      this.getAttendanceList();
    }, (errorMSG) => {
      //console.log(errorMSG);
    });
  }

  triggerBatchAction($event: Event) {
    //console.log($event['value'].toString());
    this.getChildAttendanceIdsBasedOnMode($event['value'].toString())
    //console.log(this.child_attendance_ids);
    //console.log(this.selectedOption);
    if (this.child_attendance_ids.length == 0)
    {
      this.clearSelection();
      this.popUpErrorMsg("No valid schedule to be tagged as " + $event['value'].toString());
      const resetIndex = this.actions.indexOf($event['value'].toString());

      // Check if "RESET" action is found and remove it
      if (resetIndex !== -1) {
        this.actions.splice(resetIndex, 1);
      }
      return;
    }
    this.actions = [
      'RESET',
      'SIGN IN',
      'SIGN OUT',
      'ABSENT'
    ];
    const xForm = new FormData();
    xForm.append('child_attendance_ids', this.child_attendance_ids.join(','));
    xForm.append('mode', $event['value'].toString());
    xForm.append('user_id', this.verifiedUser.user_id);
    this.esiService.batchAction(xForm).subscribe((res) => {
      //console.log(res);
      this.getAttendanceList();
      this.clearSelection();
    }, (errorMSG) => {
      //console.log(errorMSG);
    });
  }

  getChildAttendanceIdsBasedOnMode(mode: string) {
    this.child_attendance_ids = [];
    this.attendance_list.forEach(element => {
      switch (mode) {
        case 'RESET':
          if (element.sign_in != null || element.status == 2)
            this.child_attendance_ids.push(element.child_attendance_id);
          break;
        case 'SIGN IN':
          if (element.sign_in == null && element.status != 2)
            this.child_attendance_ids.push(element.child_attendance_id);
          break;
        case 'SIGN OUT':
          if (element.sign_in != null && element.sign_out == null && element.status != 2)
            this.child_attendance_ids.push(element.child_attendance_id);
          break;
        case 'ABSENT':
          if (element.sign_in == null && element.sign_out == null && element.status != 2)
            this.child_attendance_ids.push(element.child_attendance_id);
          break;
        default:
          break;
      }
    });
  }
  clearSelection() {
    this.selectedOption = "";
  }
  receivedDate: string;
  cnt = 0;
  receiveCurrentDate(curTime: string) {
    this.receivedDate = curTime;
    this.addAddtionalSignInForParents();
  }
  
  addAddtionalSignInForParents()
  {
    this.attendance_list.forEach(item => item.showAdditionalEntry = false);
    let prev_data = null;
    this.attendance_list.forEach((element) => {
      let showAdditionalEntry = true;
      if(!this.isTimeGreaterThanOrEqual(element?.sched_end))
      {
        if(prev_data)
        {
          if(prev_data.child_id == element.child_id)
          {
            if((this.attendance_list.filter(item => item.child_id === element.child_id && item.showAdditionalEntry)).length > 0)
              showAdditionalEntry = false;
          }
        }
      }
      else
        showAdditionalEntry = false;
      element.showAdditionalEntry = showAdditionalEntry;
      prev_data = element;
    });
  }
  
  isTimeGreaterThanOrEqual(targetTime: string): boolean {
    if(!targetTime)
      return true;
    const [targetHour, targetMinute] = targetTime.split(':').map(Number);

    const targetDate: Date = new Date();
    const currentTime: Date = new Date(this.receivedDate);

    targetDate.setHours(targetHour, targetMinute, 0, 0);
    return currentTime >= targetDate;
  }
  // global error message
  /**
   * error message 
   * Global
   */
  popUpErrorMsg(error: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      text: error,
      icon: 'error'
    })
  }
}
