import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-esi-past-attendance',
  templateUrl: './esi-past-attendance.component.html',
  styleUrls: ['./esi-past-attendance.component.scss']
})
export class EsiPastAttendanceComponent implements OnInit {

  userName: string;
  verifiedUser: any;

  constructor() { }

  ngOnInit(): void {
    this.verifiedUser = JSON.parse(localStorage.getItem('verified_phone_user'));
    this.userName = (this.verifiedUser.first_name ? this.verifiedUser.first_name : "") + " " 
    + (this.verifiedUser.last_name ? this.verifiedUser.last_name : "");
  }

}
