<div>
  <!-- Left Sidebar -->
  <aside
    id="leftsidebar"
    class="sidebar"
    (mouseenter)="mouseHover()"
    (mouseleave)="mouseOut()"
  >
    <!-- Menu -->
    <div class="menu">
      <ul
        class="list"
        [ngStyle]="{
          position: 'relative',
          'max-height': listMaxHeight + 'px',
          'max-width': listMaxWidth + 'px'
        }"
        [perfectScrollbar]
      >
        <li class="sidebar-user-panel" *ngIf="userData">
          <div class="user-panel">
            <div class="image pointer" (click)="goDashboard()">
              <img
                *ngIf="userData.center_logo !== null"
                class="img-circle user-img-circle profile-sidebar-img"
                src="{{ userData.center_logo }}"
              />
              <img
                *ngIf="userData.center_logo === null"
                class="img-circle user-img-circle profile-sidebar-img"
                src="assets/images/users.jpg"
              />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name text-capitalize">
              {{ userData.first_name }} {{ userData.last_name }}
            </div>
            <!--            <div class="profile-usertitle-job ">{{userData.role | uppercase}} </div>-->
          </div>
          <div class="sidebar-userpic-btn">
            <ng-container
              *ngIf="userData.is_temp_user != 1 && userData.role_id != 1"
            >
              <a
                mat-icon-button
                (click)="navigateProfile()"
                matTooltip="Profile"
                [matTooltipPosition]="'above'"
              >
                <i class="material-icons f-23 color-green">account_circle</i>
              </a>

              <!--              <a mat-icon-button disabled routerLink="email/inbox" matTooltip="Email" [matTooltipPosition]="'above'">-->
              <!--                <i class="material-icons-two-tone">mark_email_unread</i>-->
              <!--              </a>-->

              <a
                mat-icon-button
                disabled
                matTooltip="Email"
                [matTooltipPosition]="'above'"
                (click)="navigateToEmail()"
              >
                <i
                  class="material-icons f-23 color-purple"
                  (click)="navigateToEmail()"
                  >mark_email_unread</i
                >
              </a>

              <!-- <a mat-icon-button disabled routerLink="calendar" matTooltip="Calendar" [matTooltipPosition]="'above'">
                <i class="material-icons f-23 color-red">event</i>
              </a> -->
            </ng-container>

            <a
              mat-icon-button
              disabled
              matTooltip="Lock"
              (click)="navigateLock()"
              [matTooltipPosition]="'above'"
            >
              <i class="material-icons f-23 color-orange">lock</i>
            </a>
            <ng-container
              *ngIf="userData.is_temp_user == 0 && userData.role_id == 1"
            >
              <a
                mat-icon-button
                disabled
                matTooltip="Email"
                [matTooltipPosition]="'above'"
                (click)="navigateToEmailAdmin()"
              >
                <i
                  class="material-icons f-23 color-purple"
                  (click)="navigateToEmail()"
                  >mark_email_unread</i
                >
              </a>
            </ng-container>
          </div>
        </li>

        <!--        <li [class.active]="showMenu === sidebarItem.moduleName && sidebarItem.submenu.length != 0" *ngFor="let sidebarItem of menus" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">-->
        <!--          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>-->
        <!--          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]" (click)="callMenuToggle($event, sidebarItem.moduleName)" class="menu-top">-->
        <!--            <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>-->
        <!--            <span class="hide-menu">{{sidebarItem.title}}-->
        <!--            </span>-->
        <!--            <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>-->
        <!--          </a>-->
        <!--          &lt;!&ndash; First level menu &ndash;&gt;-->
        <!--          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">-->
        <!--            <li [ngClass]="showSubMenu === sidebarSubItem.moduleName? 'activeSub':''" *ngFor="let sidebarSubItem of sidebarItem.submenu" [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">-->
        <!--              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]" (click)="callSubMenuToggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">-->
        <!--                {{sidebarSubItem.title}}-->
        <!--              </a>-->
        <!--              &lt;!&ndash; Second level menu &ndash;&gt;-->
        <!--              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">-->
        <!--                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu" [ngClass]="showSubSubMenu === sidebarSubsubItem.moduleName? 'activeSubSub':''" [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">-->
        <!--                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]" [ngClass]="[sidebarSubsubItem.class]">-->
        <!--                    {{sidebarSubsubItem.title}}-->
        <!--                  </a>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </li>-->

        <!--        <li>-->
        <!--          <div class="header">My Child eLearning</div>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">event_note</i><span class="hide-menu">News</span></a>-->
        <!--          <a class="menu-top menu-toggle"><i class="material-icons-two-tone">spellcheck</i><span class="hide-menu">Education</span></a>-->
        <!--          <ul class="ml-menu">-->
        <!--            <li>-->
        <!--              <a>Weekly Programming</a>-->
        <!--            </li>-->
        <!--          </ul>-->

        <!--        <a class="menu-top"><i class="material-icons-two-tone">forum</i><span class="hide-menu">Forms</span></a>-->
        <!--        <a class="menu-top"><i class="material-icons-two-tone">bug_report</i><span class="hide-menu">Reports</span></a>-->
        <!--        <a class="menu-top"><i class="material-icons-two-tone">message</i><span class="hide-menu">Messages</span></a>-->
        <!--        </li>-->

        <!--        <li  class="ng-star-inserted ">-->
        <!--          <a  class="menu-top menu-toggle ng-star-inserted" [routerLink]="null" (click)="callMenuToggle($event, 'test-apps')" >-->
        <!--          <i class="material-icons-two-tone">stars</i><span  class="hide-menu">More Apps </span>-->
        <!--          </a>-->
        <!--          <ul  class="ml-menu ng-star-inserted">-->
        <!--            <li  class="ng-star-inserted"><a class="ml-menu" href="/apps/chat"> Chat </a></li>-->
        <!--            <li   class="ng-star-inserted"><a  class="ml-menu" href="/apps/dragdrop"> Drag &amp; Drop </a></li>-->
        <!--            <li class="ng-star-inserted"><a  class="ml-menu" href="/apps/contact-grid"> Contact Grid </a></li>-->
        <!--            <li  class="ng-star-inserted"><a  class="ml-menu" href="/apps/support"> Support </a></li>-->
        <!--          </ul>-->
        <!--        </li>-->

        <!--        <li>-->
        <!--          <div class="header">Management eForms</div>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">event_note</i><span class="hide-menu">News</span></a>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">spellcheck</i><span class="hide-menu">Education</span></a>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">forum</i><span class="hide-menu">Forms</span></a>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">bug_report</i><span class="hide-menu">Reports</span></a>-->
        <!--          <a class="menu-top"><i class="material-icons-two-tone">message</i><span class="hide-menu">Messages</span></a>-->
        <!--        </li>-->

        <!-- Top Most level menu -->
        <ng-container *ngIf="userData.is_temp_user != 1">
          <li
            [class.active]="
              showMenu === sidebarItem.moduleName &&
              sidebarItem.submenu.length != 0
            "
            *ngFor="let sidebarItem of menus"
            [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
          >
            <div class="header" *ngIf="sidebarItem.groupTitle === true">
              {{ sidebarItem.title }}
            </div>
            <!-- Temporary remove educator registration & Inclusion & Diversities in menu -->
            <ng-container
              *ngIf="
                sidebarItem.title !== 'Educator Registration' &&
                sidebarItem.title !== 'Inclusion & Diversities' &&
                sidebarItem.title !== 'Staff Registration'
              "
            >
              <a
                [routerLink]="
                  sidebarItem.path != '' ? [sidebarItem.path] : null
                "
                *ngIf="!sidebarItem.groupTitle"
                [ngClass]="[sidebarItem.class]"
                (click)="callMenuToggle($event, sidebarItem.moduleName)"
                class="menu-top"
              >
                <i [ngClass]="[sidebarItem.iconType]">{{ sidebarItem.icon }}</i>
                <span class="hide-menu">
                  {{ sidebarItem.title }}
                </span>
                <span
                  *ngIf="sidebarItem.badge != ''"
                  [ngClass]="[sidebarItem.badgeClass]"
                  >{{ sidebarItem.badge }}</span
                >
                <span
                  *ngIf="sidebarItem.title === 'Dashboard' && hasNotifications"
                >
                  <mat-icon class="mat-icon-warning alert-icon mr-2 ml-2 pull-right"
                    >warning</mat-icon
                  >
                </span>
              </a>
            </ng-container>
            <ng-container *ngIf="sidebarItem.title === 'Educator Registration'">
              <!-- <ng-container *ngIf="userData.role_id !== 9 && userData.user.center_type_id !== 1"> -->
              <ng-container>
                <a
                  [routerLink]="
                    sidebarItem.path != '' ? [sidebarItem.path] : null
                  "
                  *ngIf="!sidebarItem.groupTitle"
                  [ngClass]="[sidebarItem.class]"
                  (click)="callMenuToggle($event, sidebarItem.moduleName)"
                  class="menu-top"
                >
                  <i [ngClass]="[sidebarItem.iconType]">{{
                    sidebarItem.icon
                  }}</i>
                  <span class="hide-menu">{{ sidebarItem.title }} </span>
                  <span
                    *ngIf="sidebarItem.badge != ''"
                    [ngClass]="[sidebarItem.badgeClass]"
                    >{{ sidebarItem.badge }}</span
                  >
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="sidebarItem.title === 'Staff Registration'">
              <ng-container *ngIf="userData.user.center_type_id !== 1">
                <a
                  [routerLink]="
                    sidebarItem.path != '' ? [sidebarItem.path] : null
                  "
                  *ngIf="!sidebarItem.groupTitle"
                  [ngClass]="[sidebarItem.class]"
                  (click)="callMenuToggle($event, sidebarItem.moduleName)"
                  class="menu-top"
                >
                  <i [ngClass]="[sidebarItem.iconType]">{{
                    sidebarItem.icon
                  }}</i>
                  <span class="hide-menu">{{ sidebarItem.title }} </span>
                  <span
                    *ngIf="sidebarItem.badge != ''"
                    [ngClass]="[sidebarItem.badgeClass]"
                    >{{ sidebarItem.badge }}</span
                  >
                </a>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="sidebarItem.title === 'Inclusion & Diversities'"
            >
              <!-- <ng-container *ngIf="userData.role_id !== 6"> -->
              <a
                [routerLink]="
                  sidebarItem.path != '' ? [sidebarItem.path] : null
                "
                *ngIf="!sidebarItem.groupTitle"
                [ngClass]="[sidebarItem.class]"
                (click)="callMenuToggle($event, sidebarItem.moduleName)"
                class="menu-top"
              >
                <i [ngClass]="[sidebarItem.iconType]">{{ sidebarItem.icon }}</i>
                <span class="hide-menu">{{ sidebarItem.title }} </span>
                <span
                  *ngIf="sidebarItem.badge != ''"
                  [ngClass]="[sidebarItem.badgeClass]"
                  >{{ sidebarItem.badge }}</span
                >
              </a>
              <!-- </ng-container> -->
            </ng-container>
            <!-- End of Temporary remove educator registration & Inclusion & Diversities in menu -->

            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li
                [ngClass]="
                  showSubMenu === sidebarSubItem.moduleName ? 'activeSub' : ''
                "
                *ngFor="let sidebarSubItem of sidebarItem.submenu"
                [routerLinkActive]="
                  sidebarSubItem.submenu.length > 0 ? '' : 'active'
                "
              >
                <!-- <ng-container *ngIf="sidebarSubItem.title !== 'Risk Management Plan' "> --><!-- Enable this once backend is fixed -->
                <a
                  *ngIf="sidebarSubItem.is_link == 0"
                  [routerLink]="
                    sidebarSubItem.submenu.length > 0
                      ? null
                      : [sidebarSubItem.path]
                  "
                  (click)="callSubMenuToggle($event, sidebarSubItem.moduleName)"
                  [ngClass]="[sidebarSubItem.class]"
                >
                  <div *ngIf="sidebarSubItem.title == 'Daily Blog/Reflection'">
                    Daily Blog and Reflection
                  </div>
                  <div *ngIf="sidebarSubItem.title !== 'Daily Blog/Reflection'">
                    {{ sidebarSubItem.title }}
                  </div>
                </a>
                <a
                  *ngIf="sidebarSubItem.is_link == 1"
                  [href]="
                    sidebarSubItem.submenu.length > 0
                      ? null
                      : [sidebarSubItem.path]
                  "
                  target="_blank"
                  (click)="callSubMenuToggle($event, sidebarSubItem.moduleName)"
                  [ngClass]="[sidebarSubItem.class]"
                >
                  {{ sidebarSubItem.title }}
                </a>
                <!-- Second level menu -->
                <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                  <li
                    *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                    [ngClass]="
                      showSubSubMenu === sidebarSubsubItem.moduleName
                        ? 'activeSubSub'
                        : ''
                    "
                    [routerLinkActive]="
                      sidebarSubsubItem.submenu.length > 0 ? '' : 'active'
                    "
                  >
                    <a
                      *ngIf="sidebarSubsubItem.is_link == 0"
                      [routerLink]="
                        sidebarSubsubItem.submenu.length > 0
                          ? null
                          : [sidebarSubsubItem.path]
                      "
                      [ngClass]="[sidebarSubsubItem.class]"
                    >
                      {{ sidebarSubsubItem.title }}
                    </a>
                    <a
                      *ngIf="sidebarSubsubItem.is_link == 1"
                      [href]="
                        sidebarSubsubItem.submenu.length > 0
                          ? null
                          : [sidebarSubsubItem.path]
                      "
                      target="_blank"
                      [ngClass]="[sidebarSubsubItem.class]"
                    >
                      {{ sidebarSubsubItem.title }}
                    </a>
                  </li>
                </ul>
                <!-- </ng-container> -->
              </li>
            </ul>
          </li>
        </ng-container>
        <li class="" style="height: 50px"></li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
