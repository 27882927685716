import { Component } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, RoutesRecognized } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlatformLocation } from '@angular/common';
import { BnNgIdleService } from "bn-ng-idle";
import { filter, pairwise } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl: string;
  private config: { version: string };

  constructor(
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private bnIdle: BnNgIdleService,
    private httpClient: HttpClient,
    config: NgbModalConfig,
    private modalService: NgbModal

  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });

    this.bnIdle.startWatching(3000).subscribe((res) => {
      if (res) {
        const isLogin = localStorage.getItem('STATE');
        if (isLogin === 'true') {
          localStorage.setItem('STATE', 'false');
          localStorage.setItem('access_token', '');
          this._router.navigate(['/authentication/locked']);
        }
      }
    });
  }

  ngOnInit() {
    // this.config = require('./../assets/config.json');
    // const headers = new HttpHeaders()
    //   .set('Cache-Control', 'no-cache')
    //   .set('Pragma', 'no-cache');

    // this.httpClient.get<{ version: string }>('/assets/config.json', { headers })
    //   .subscribe(config => {
    //     if (config.version !== this.config.version) {
    //       location.reload();
    //     }
    //   });
  }
}
