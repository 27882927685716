import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GlobalService} from "../global/global.service";

@Injectable({
  providedIn: 'root'
})
export class WebPagesService {

  constructor(
    private http: HttpClient,
    private globalService : GlobalService,
  ) { }


  getKeyPoints (FormId) {
    return this.http.get(this.globalService.baseUrl + "child-enrollment/get-key-points?form_id=" +FormId ,this.globalService.getHttpOptions());
  }

  childEnrollmentFormSubmit(data){

    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form" ,data ,this.globalService.getHttpOptionsAuthWithOutContentType());
  }

  checkEnrollmentForm(formRef) {
    return this.http.get(this.globalService.baseUrl + "child-enrollment/check-enrollment-list?form_ref=" +formRef ,this.globalService.getHttpOptions());

  }

  uploadFiles(files) {
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    };
    return this.http.post(this.globalService.baseUrl + 'child-enrollment/upload-files' , files, httpOption);
  }

  childEnrollmentFormEmergencySubmit(data) {


    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form-emergency" ,data ,this.globalService.getHttpOptionsWithFile());

  }
  childEnrollmentFormMedicalSubmit(data) {
    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form-medical" ,data ,this.globalService.getHttpOptionsWithFile());
  }

  childEnrollmentFormScheduleSubmit(data) {
    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form-schedule" ,data ,this.globalService.getHttpOptionsWithFile());
  }

  childEnrollmentFormDocumentSubmit(data) {


    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form-document" ,data ,this.globalService.getHttpOptionsAuthWithOutContentType());

  }


  childEnrollmentFormAgreementSubmit(data) {
    return this.http.post(this.globalService.baseUrl + "child-enrollment/submit-form-agreement" ,data ,this.globalService.getHttpOptionsAuthWithOutContentType());
  }
  downloadDocument(data) {
    return this.http.post(this.globalService.baseUrl + 'child-enrollment/download-enrollment-form', data, this.globalService.getHttpOptionsAuth());
  }

}
