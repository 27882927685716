
<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <ng-container *ngIf="headerHide != 1">
    <h3>Report Incident, Injury, Trauma and Illness</h3>
    <h6>{{ data.created_at | date: 'longDate' }}</h6>
    </ng-container>
    <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.is_approval == 2">Declined - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <span class="label l-bg-green shadow-style m-b-14" *ngIf="data.is_approval == 1">Approved - {{ data.parent_signature_date | date: 'longDate'}}</span>
    <span class="label l-bg-orange shadow-style m-b-14" *ngIf="data.is_approval == 0">Pending </span>
    <br/>

    <h5 class="form-group-heading"> Child Details</h5>
    <table class="table table-sm border-0">
      <tr><td style="width: 20%">Surname</td><td>: {{ data['child_surname'] }}</td></tr>
      <tr><td>Given Names</td><td>: {{ data['child_givenname'] }}</td></tr>
      <tr><td>Date of birth</td><td>: {{ data['child_dob'] | date: 'longDate'}}</td></tr>
    </table>

    <h5 class="form-group-heading">Incident/injury/trauma/illness details </h5>
    <p><strong>Type : </strong>{{ data['type'] }}</p>
    <p><strong>Circumstances leading to the incident/injury/trauma : </strong> <br/>{{ data['circumstances'] }}</p>
    <p><strong>Products or structures involved : </strong> <br/>{{ data['involved_description'] }}</p>
    <p><strong>Location : </strong>{{ data['location'] }}</p>
    <p><strong>Date and Time : </strong>{{ data['incident_date'] | date: 'medium'}}</p>
    <p><strong>Name of witness : </strong>{{ data['name_witness'] }}</p>
    <img src="{{ data['signature'] }}">
    <br/>
    <em>{{ data['name_witness'] }} - {{ data['signature_date'] | date: 'longDate'}}</em>

    <br>
    <br>
    <h5 class="form-group-heading">Nature of injury sustained:</h5>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
        <img src="assets/images/forms/healthandsafetyform.JPG" class="full-width">
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
        <mat-icon class="f-11"  *ngIf="data['abrasion_scrape'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['abrasion_scrape'] == 0">close</mat-icon>
        Abrasion, scrape<br/>
        <mat-icon class="f-11"  *ngIf="data['broken_bone'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['broken_bone'] == 0">close</mat-icon>
        Broken bone/ fracture<br/>
        <mat-icon class="f-11"  *ngIf="data['bruise'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['bruise'] == 0">close</mat-icon>
        Bruise<br/>
        <mat-icon class="f-11"  *ngIf="data['burn'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['burn'] == 0">close</mat-icon>
        Burn<br/>
        <mat-icon class="f-11"  *ngIf="data['concussion'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['concussion'] == 0">close</mat-icon>
        Concussion<br/>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
        <mat-icon class="f-11"  *ngIf="data['cut'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['cut'] == 0">close</mat-icon>
        Cut<br/>
        <mat-icon class="f-11"  *ngIf="data['rash'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['rash'] == 0">close</mat-icon>
        Rash<br/>
        <mat-icon class="f-11"  *ngIf="data['sprain'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['sprain'] == 0">close</mat-icon>
        Sprain<br/>
        <mat-icon class="f-11"  *ngIf="data['swelling'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['swelling'] == 0">close</mat-icon>
        Swelling<br/>
        <mat-icon class="f-11"  *ngIf="data['other'] == 1">check</mat-icon>
        <mat-icon class="f-11"  *ngIf="data['other'] == 0">close</mat-icon>
        Other (please specify)<br/>
        <p *ngIf="data['other'] == 1"><strong>Description : </strong> {{ data['other_description'] }}</p>
      </div>
    </div>

    <br/>
    <h5 class="form-group-heading"> Illness </h5>
    <p><strong>Circumstances surrounding child becoming ill, including apparent symptoms : </strong> <br/>{{ data['illness_symptoms'] }}</p>
    <p><strong>Date and Time : </strong>{{ data['illness_date'] | date: 'medium'}}</p>

    <br/>
    <h5 class="form-group-heading">Action Taken</h5>
    <p><strong>Details of action taken, including first aid administration of medication : </strong> <br/>{{ data['action_taken'] }}</p>
    <p><strong>Medical personnel contacted : </strong>
      <span *ngIf="data['is_contact_medical_person'] == 1">Yes</span>
      <span *ngIf="data['is_contact_medical_person'] == 0">No</span>
    </p>
    <p *ngIf="data['is_contact_medical_person'] == 1"><strong>Medical Details : </strong> <br/>{{ data['medical_person_details'] }}</p>


    <br/>
    <h5 class="form-group-heading">Details of person completing this record</h5>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
      <img src="{{ data['recorder_signature'] }}">
      <br/>
      <em>{{ data['recorder_name'] }} - {{ data['recorder_date'] | date: 'medium'}}</em>
    </div>

    <br/>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13" *ngIf="data.is_approval != 0">
      <h5 class="form-group-heading">Parent Acknowledgement</h5>
      <p>I {{ data['sign_parent_name'] }} have been notified of my child's incident/injury/trauma/illness.</p>
      <br/>
      <img src="{{ data['parent_signature'] }}">
      <br/>
      <em>{{ data['sign_parent_name'] }} (Parent) -  {{ data.parent_signature_date | date: 'longDate'}}</em>
    </div>

<!--    <ng-container *ngIf="data.is_approval == 0 && loggedUser['role'] == 'parent'">-->
<!--      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">-->
<!--        <h5 class="form-group-heading">Parent Details For Acknowledgement</h5>-->
<!--        <form class="register-form filter-form" [formGroup]="sectionForm" >-->
<!--          <div class="row">-->
<!--            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">-->

<!--              <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">-->
<!--                <mat-label>Signature Date</mat-label>-->
<!--                <input matInput formControlName="signature_date" [matDatepicker]="picker">-->
<!--                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--                <mat-datepicker #picker></mat-datepicker>-->
<!--              </mat-form-field>-->

<!--              <label class="f-12">Signature of Parent</label>-->
<!--              <div *ngIf="!isNewSignature">-->
<!--                <span *ngIf="isEdit"  (click)="removeSignature()" class="badge badge-danger remove-signature pointer b-r-15">x</span>-->
<!--                <img src="{{ signatureImg }}">-->
<!--              </div>-->
<!--              <ng-container *ngIf="isNewSignature">-->
<!--                <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>-->
<!--              </ng-container>-->
<!--              <br/>-->
<!--              <span class="text-danger"  *ngIf="sigError">Signature is required</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <input type="hidden" formControlName="f1_incident_form_ref">-->
<!--          <input type="hidden" formControlName="parent_id">-->

<!--          <br/>-->
<!--          <br/>-->
<!--          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">-->
<!--            <button mat-flat-button color="primary"  (click)="formSubmit(sectionForm, 1)"  [class.spinner]="submitted" class="pull-right  text-white" type="submit">-->
<!--              Approved-->
<!--            </button>-->
<!--            <button (click)="formSubmit(sectionForm, 2)"  class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" mat-raised-button data-dismiss="modal">Decline</button>-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
<!--    </ng-container>-->
  </div>
</div>
