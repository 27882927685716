import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { GlobalService } from "src/app/global/global.service";
import { EsiService } from "src/app/esi/esi.service";
import {
  ConfirmDialogModel,
  ConfirmationModalComponent,
} from "src/app/shared-component/confirmation-modal/confirmation-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { ChildAttendanceViewModalComponent } from "../child-attendance-view-modal/child-attendance-view-modal.component";
import { AttendanceFormComponent } from "src/app/attendance-page/attendance-form/attendance-form.component";
import {
  AttendanceRow,
  ChildAttendance,
  ChildCasualSchedule,
  ChildSchedule,
} from "src/app/attendance-page/models/attendance-row";
import { ChildAttendanceUtilService } from "src/app/attendance-page/services/child-attendance-util.service";
import { Subscription, forkJoin } from "rxjs";
import { ChildScheduleService } from "src/app/attendance-page/services/child-schedule.service";
import { ChildAttendanceService } from "src/app/attendance-page/services/child-attendance.service";
import { EducatorsService } from "src/app/accounts/educators.service";
import { EducatorModel } from "src/app/accounts/fhc-ihc-educators/educator.model";
import { WeekCoverage } from "src/app/attendance-page/models/coverage";

import { formatDate } from "@angular/common";
import { take } from "rxjs/operators";

interface ChildAttendanceRecord {
  child_id: number;
  child_full_name: string;
  child_start_date: string; // or Date if you convert it
  educator_id: number;
  educator_name: string;
  all_child_attendance_ids: number[]; // After parsing from a string
  all_is_saves: number[]; // After parsing from a string
  isSelected: boolean;
  isSubmitted: boolean;
  hasError: boolean;
  validation_errors: string[];
}

@Component({
  selector: "app-attendance-table",
  templateUrl: "./attendance-table.component.html",
  styleUrls: ["./attendance-table.component.scss"],
})
export class AttendanceTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tableData: any[] = [];
  @Input() rolePermission: any[] = [];
  @Input() roleId: number;

  @Input() filters: any;
  @Input() listOfCheckedChildIds = [];

  @Output("loadTableEmitter") loadTableEmitter: EventEmitter<any> =
    new EventEmitter();
  @Output("tblCheckboxEmitter") tblCheckboxEmitter: EventEmitter<any> =
    new EventEmitter();
  error = "";
  displayedColumns: string[] = [
    "check_box",
    "child_name",
    "child_start_date",
    "educator_name",
    "action",
  ];

  displayedColumnsEducators: string[] = [
    "check_box",
    "child_name",
    "child_start_date",
    "action",
  ];
  modalRef: NgbModalRef;

  triggerValidateSub: Subscription;
  triggerReportSub: Subscription;

  educatorsToChild: {
    educator_id: number;
    educator_name: string;
    children: any[];
  }[] = [];

  constructor(
    private modalService: NgbModal,
    private globeService: GlobalService,
    private esiService: EsiService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private childAttendanceUtilService: ChildAttendanceUtilService,
    private childScheduleService: ChildScheduleService,
    private childAttendanceService: ChildAttendanceService,
    private educatorService: EducatorsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const tableDataChanges = changes.tableData;

    if (tableDataChanges && tableDataChanges.currentValue) {
      this.educatorsToChild = [];

      this.tableData.forEach((item) => {
        // check if educator exists
        const educator = this.educatorsToChild.find(
          (e) => e.educator_id === item.educator_id
        );
        if (educator) {
          educator.children.push(item);
        } else {
          this.educatorsToChild.push({
            educator_id: item.educator_id,
            educator_name: item.educator_name,
            children: [item],
          });
        }
      });

      console.log(this.educatorsToChild);
    }
  }

  ngOnInit(): void {

    this.triggerValidateSub =
      this.childAttendanceUtilService.triggerValidate.subscribe(
        (triggerValidate) => {
          if (triggerValidate) {
            // this.validateAttendanceForm();
            this.validateChildAttendance();
          }
        }
      );

    this.triggerReportSub =
      this.childAttendanceUtilService.triggerReport.subscribe(
        (triggerReport) => {
          if (triggerReport) {
            this.sendReport();
          }
        }
      );

    console.log(this.tableData);
  }

  openView(data) {
    this.modalRef = this.modalService.open(ChildAttendanceViewModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      centered: true,
    });
    this.modalRef.componentInstance.data = data;
    this.modalRef.componentInstance.rolePermission = this.rolePermission;
    this.modalRef.componentInstance.editMode = false;
  }

  openEdit(data) {
    this.modalRef = this.modalService.open(AttendanceFormComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      centered: true,
    });

    const rowData: AttendanceRow = {
      ...data,
      start_date: this.filters.start_date,
      end_date: this.filters.end_date,
      week_range: this.filters.week_range,
    };

    this.modalRef.componentInstance.attendanceRow = rowData;

    // this.modalRef.result.then(result => {
    //   console.log('result: ', result);
    //   this.validateChildAttendance();
    // });

    // this.modalRef = this.modalService.open(ChildAttendanceEditComponent);
    // this.modalRef.componentInstance.data = data;
    // this.modalRef.result.then((result) => {
    //   if (result) {
    //     this.esiService.editAttendance(result).subscribe((res) => {
    //       if (res) {
    //         // this.getAttendanceList();
    //         this.loadTableEmitter.emit();
    //       }
    //     }, (error) => {
    //       this.error = error.error.error;
    //       this.globeService.showErrorNotification('Update Failed');
    //     });
    //   }
    // });
  }
  confirmAbsent(id) {
    const message = `Are you sure you want to mark child as absent?`;

    const dialogData = new ConfirmDialogModel("Confirmation", message);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      maxWidth: "400px",
      panelClass: "confirm-dialog",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const xForm = new FormData();
        xForm.append("child_attendance_id", id);
        this.esiService.markAsAbsent(xForm).subscribe(
          (res) => {
            this.loadTableEmitter.emit();
          },
          (errorMSG) => {
            console.log(errorMSG);
          }
        );
      }
    });
  }

  toggleAllSelection(event: any) {
    this.tableData.forEach((element) => {
      if (element.isSelected == 1) {
        this.toggleSelection(event, element);
      }
      element.isSelected = event.checked;
    });
  }

  toggleAllSelectionEducator(event: any, educator_id: number) {
    const educator = this.educatorsToChild.find(
      (e) => e.educator_id === educator_id
    );

    if (educator) {
      educator.children.forEach((element) => {
        element.isSelected = event.checked;
        this.toggleSelection(event, element);
      });
    }
  }

  // Function to toggle individual row selection
  toggleSelection(event: any, row) {
    this.tblCheckboxEmitter.emit({ row, event });
  }

  checkEditEnabled(date) {
    let dateLimit = new Date(date);
    dateLimit.setDate(dateLimit.getDate() + 14);
    let today = new Date();
    let isEditEnabled = false;
    switch (this.roleId) {
      case 4:
        if (today.getTime() < dateLimit.getTime()) {
          isEditEnabled = true;
        } else {
          isEditEnabled = false;
        }
        break;
      case 7:
        dateLimit = new Date(date);
        if (
          today.getMonth() == dateLimit.getMonth() &&
          today.getDate() == dateLimit.getDate() &&
          today.getFullYear() == dateLimit.getFullYear()
        ) {
          isEditEnabled = true;
        } else {
          isEditEnabled = false;
        }
        break;
    }

    return isEditEnabled;
  }

  formatDateWithoutTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return this.datePipe.transform(date, "dd-MM-yyyy");
  }

  validateRow(
    data: ChildAttendanceRecord,
    index: number,
    updateService: boolean
  ) {
    if (!data.validation_errors) {
      data.validation_errors = [];
    }

    data.hasError = false;
    data.validation_errors = [];

    const childSchedule$ = this.childScheduleService.getChildSchedule(
      data.child_id
    );
    const childCasualSchedule$ =
      this.childScheduleService.getChildCasualSchedule(data.child_id);
    const childAttendance$ = this.childAttendanceService.getAttendance(
      data.child_id
    );
    const educator$ = this.educatorService.getEducator(data.educator_id);

    forkJoin([
      childSchedule$,
      childAttendance$,
      educator$,
      childCasualSchedule$,
    ]).subscribe({
      next: ([
        childScheduleResp,
        childAttendanceResp,
        educatorResp,
        childCasualScheduleResp,
      ]) => {
        const childSchedule = childScheduleResp as ChildSchedule;
        const childCasualSchedule =
          childCasualScheduleResp as ChildCasualSchedule;
        const childAttendance = childAttendanceResp as ChildAttendance[];
        const educator = educatorResp as EducatorModel;

        const response: any =
          this.childAttendanceUtilService.setChildDailyAttendanceData(
            data.child_id,
            data.educator_id,
            this.filters.week_range,
            this.filters.start_date,
            this.filters.end_date,
            data.child_full_name,
            childSchedule,
            childCasualSchedule,
            childAttendance,
            educator
          );

        // this.childAttendanceUtilService.setValidationErrors([]);

        response.forEach((week: WeekCoverage) => {
          week.days.forEach((day) => {
            day.forEach((session, index) => {
              if (session.has_booked_session) {
                const attendanceDate = formatDate(
                  session.attendance_date,
                  "dd/MM/yyyy",
                  "en-AU"
                );

                let missingSignInsError =
                  this.childAttendanceUtilService.validateMissingSignIns(
                    session.booked_times
                  );
                if (missingSignInsError) {
                  missingSignInsError += ` Session ${
                    index + 1
                  } ${attendanceDate}`;
                  data.hasError = true;
                  data.validation_errors.push(missingSignInsError);

                  if (updateService) {
                    this.childAttendanceUtilService.addToValidationErrors(
                      missingSignInsError
                    );
                  }
                }

                let brokenSignInsError =
                  this.childAttendanceUtilService.validateBrokenSignIns(
                    session.booked_times
                  );
                if (brokenSignInsError) {
                  brokenSignInsError += ` Session ${
                    index + 1
                  } ${attendanceDate}`;
                  data.hasError = true;
                  data.validation_errors.push(brokenSignInsError);
                  if (updateService) {
                    this.childAttendanceUtilService.addToValidationErrors(
                      brokenSignInsError
                    );
                  }
                }

                let signInsNotWithinBookedTimeError =
                  this.childAttendanceUtilService.validateSignInWithinBookedTime(
                    session.booked_times,
                    session.booked_start_time,
                    session.booked_end_time
                  );
                if (signInsNotWithinBookedTimeError) {
                  signInsNotWithinBookedTimeError += ` Session ${
                    index + 1
                  } ${attendanceDate}`;
                  data.hasError = true;
                  data.validation_errors.push(signInsNotWithinBookedTimeError);
                  if (updateService) {
                    this.childAttendanceUtilService.addToValidationErrors(
                      signInsNotWithinBookedTimeError
                    );
                  }
                }

                let startTimeAndEndTimeNotValidError =
                  this.childAttendanceUtilService.validateStartAndEndTime(
                    session.booked_times
                  );
                if (startTimeAndEndTimeNotValidError) {
                  startTimeAndEndTimeNotValidError += ` Session ${
                    index + 1
                  } ${attendanceDate}`;
                  data.hasError = true;
                  data.validation_errors.push(startTimeAndEndTimeNotValidError);
                  if (updateService) {
                    this.childAttendanceUtilService.addToValidationErrors(
                      startTimeAndEndTimeNotValidError
                    );
                  }
                }

                let signInOverlapsError =
                  this.childAttendanceUtilService.validateSignInOverlaps(
                    session.booked_times
                  );
                if (signInOverlapsError) {
                  signInOverlapsError += ` Session ${
                    index + 1
                  } ${attendanceDate}`;
                  data.hasError = true;
                  data.validation_errors.push(signInOverlapsError);
                  if (updateService) {
                    this.childAttendanceUtilService.addToValidationErrors(
                      signInOverlapsError
                    );
                  }
                }
              }
            });
          });
        });
      },
      error: (error) => {},
      complete: () => {
        const updatedTableData = [...this.tableData];
        updatedTableData.splice(index, 1, data);
        this.tableData = updatedTableData;
      },
    });
  }

  validateChildAttendance() {
    this.childAttendanceUtilService.checkedChildIds
      .pipe(take(1))
      .subscribe((childIds) => {
        if (childIds.length == 0) {
          this.tableData.forEach((data: ChildAttendanceRecord, index) => {
            this.validateRow(data, index, true);
          });
        } else {
          this.tableData.forEach((data: ChildAttendanceRecord, index) => {
            if (childIds.includes(data.child_id)) {
              this.validateRow(data, index, true);
            }
          });
        }
      });
  }

  sendReport() {
    this.childAttendanceUtilService.checkedChildIds
      .pipe(take(1))
      .subscribe((childIds) => {
        if (childIds.length == 0) {
          this.tableData.forEach((data: ChildAttendanceRecord, index) => {
            this.submitReportRow(data, index);
          });
        } else {
          this.tableData.forEach((data: ChildAttendanceRecord, index) => {
            if (childIds.includes(data.child_id)) {
              this.submitReportRow(data, index);
            }
          });
        }
      });
  }

  submitReportRow(data: ChildAttendanceRecord, index: number) {
    if (data.validation_errors && !data.hasError) {
      const childSchedule$ = this.childScheduleService.getChildSchedule(
        data.child_id
      );
      const childCasualSchedule$ =
        this.childScheduleService.getChildCasualSchedule(data.child_id);
      const childAttendance$ = this.childAttendanceService.getAttendance(
        data.child_id
      );
      const educator$ = this.educatorService.getEducator(data.educator_id);

      forkJoin([
        childSchedule$,
        childAttendance$,
        educator$,
        childCasualSchedule$,
      ]).subscribe({
        next: ([
          childScheduleResp,
          childAttendanceResp,
          educatorResp,
          childCasualScheduleResp,
        ]) => {
          const childSchedule = childScheduleResp as ChildSchedule;
          const childCasualSchedule =
            childCasualScheduleResp as ChildCasualSchedule;
          const childAttendance = childAttendanceResp as ChildAttendance[];
          const educator = educatorResp as EducatorModel;

          const response: any =
            this.childAttendanceUtilService.setChildDailyAttendanceData(
              data.child_id,
              data.educator_id,
              this.filters.week_range,
              this.filters.start_date,
              this.filters.end_date,
              data.child_full_name,
              childSchedule,
              childCasualSchedule,
              childAttendance,
              educator
            );

          response.forEach((week: WeekCoverage) => {
            week.days.forEach((day) => {
              day.forEach((session, index) => {
                console.log(session.child_attendance_id);

                this.childAttendanceService
                  .reportAttendance(session)
                  .subscribe();
              });
            });
          });
        },
      });
    }
  }

  saveReportRow(data: ChildAttendanceRecord, index: number) {}

  ngOnDestroy(): void {
    if (this.triggerValidateSub) {
      this.triggerValidateSub.unsubscribe();
    }

    if (this.triggerReportSub) {
      this.triggerReportSub.unsubscribe();
    }
  }
}
