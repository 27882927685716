import { Component, Input, OnInit } from "@angular/core";

import { DayCoverage, WeekCoverage } from "../models/coverage";
import {
  AttendanceRow,
  ChildAttendance,
  ChildAttendanceOtherDetails,
  ChildCasualSchedule,
  ChildSchedule,
} from "../models/attendance-row";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ChildScheduleService } from "../services/child-schedule.service";
import { ChildAttendanceService } from "../services/child-attendance.service";
import { ChildAttendanceUtilService } from "../services/child-attendance-util.service";
import { ChildAttendanceOtherDetailsService } from "../services/child-attendance-other-details.service";

import { GlobalService } from "src/app/global/global.service";
import { EducatorsService } from "src/app/accounts/educators.service";
import { forkJoin } from "rxjs";
import { EducatorModel } from "src/app/accounts/fhc-ihc-educators/educator.model";

import * as moment from "moment";

import * as _ from "lodash";

@Component({
  selector: "app-attendance-form",
  templateUrl: "./attendance-form.component.html",
  styleUrls: ["./attendance-form.component.sass"],
})
export class AttendanceFormComponent implements OnInit {
  @Input() public attendanceRow: AttendanceRow;
  @Input() childId: number;

  isLoading = false;

  displayedWeeks: Date[] = [];
  weeksCovered: WeekCoverage[] = [];
  educatorsList: EducatorModel[] = [];
  center_id: number;

  childSchedule: ChildSchedule;
  childCasualSchedule: ChildCasualSchedule;
  childAttendance: ChildAttendance[];
  educator: EducatorModel;

  constructor(
    public modal: NgbActiveModal,
    private childScheduleService: ChildScheduleService,
    private childAttendanceService: ChildAttendanceService,
    private educatorService: EducatorsService,
    private globalService: GlobalService,
    private childAttendanceUtilService: ChildAttendanceUtilService,
    private childAttendanceOtherDetailsService: ChildAttendanceOtherDetailsService
  ) {}

  ngOnInit(): void {
    this.center_id = this.globalService.getCurrentUser().center_id;

    if (this.attendanceRow) {
      this.getEducators(this.center_id);
      const { child_id, educator_id, all_child_attendance_ids } = this.attendanceRow;
      this.setChildDailyAttendanceData(child_id, educator_id, all_child_attendance_ids);
    }
  }

  setChildDailyAttendanceData(childId: number, educatorId: number, childAttendanceIds: number[]) {
    this.isLoading = true;

    const childSchedule$ = this.childScheduleService.getChildSchedule(childId);
    const childCasualSchedule$ =
      this.childScheduleService.getChildCasualSchedule(childId);
    const childAttendance$ = this.childAttendanceService.getAttendance(childId);
    const educator$ = this.educatorService.getEducator(educatorId);

    forkJoin([
      childSchedule$,
      childAttendance$,
      educator$,
      childCasualSchedule$,
    ]).subscribe({
      next: ([
        childScheduleResp,
        childAttendanceResp,
        educatorResp,
        childCasualScheduleResp,
      ]) => {
        this.childSchedule = childScheduleResp as ChildSchedule;
        this.childCasualSchedule =
          childCasualScheduleResp as ChildCasualSchedule;
        this.childAttendance = childAttendanceResp as ChildAttendance[];
        console.log(this.childAttendance);
        this.childAttendance = this.childAttendance.filter(ca => childAttendanceIds.includes(ca.child_attendance_id));
        console.log(this.childAttendance);
        this.educator = educatorResp as EducatorModel;

        let weeks = [];

        if (this.attendanceRow.week_range === "fortnightly") {
          weeks = this.childAttendanceUtilService.splitIntoWeeks(
            this.attendanceRow.start_date,
            this.attendanceRow.end_date
          );
        } else {
          weeks = [
            {
              start_date: this.attendanceRow.start_date,
              end_date: this.attendanceRow.end_date,
            },
          ];
        }

        const weeklyChildAttendance = weeks.map((week) => {
          const days2D = this.childAttendanceUtilService.setWeekChildAttendances(
            week.start_date,
            week.end_date,
            this.childSchedule,
            this.educator,
            this.childAttendance,
            this.childCasualSchedule,
            childId,
            this.attendanceRow.child_full_name,
            [this.educator.educator_id]
          );

          const { totalAdminLevy, totalEducatorFee, totalHours, totalFoodFee, totalTravelFee } =
            this.childAttendanceUtilService.computeWeeklyValues(days2D);

          return {
            startDate: week.start_date,
            endDate: week.end_date,
            year: this.attendanceRow.start_date.getFullYear(),
            days: days2D,
            totalEducatorFee: totalEducatorFee,
            totalHours: totalHours,
            totalAdminLevy: totalAdminLevy,
            totalFoodMealFee: totalFoodFee,
            totalTravelFee: totalTravelFee,
          };
        });

        this.weeksCovered = weeklyChildAttendance;

        console.log(this.weeksCovered);
      },
      error: (error) => {
        this.globalService.showErrorNotification(
          "Acess cannot get data at the moment. Please try again later."
        );
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getEducators(centerId: number) {
    this.educatorService
      .getEducators({ center_id: centerId, status: 1 })
      .subscribe((educators) => {
        this.educatorsList = educators;
      });
  }

  handleUpdateDayCoverage(event) {
    const cloneWeeksCovered = _.cloneDeep(this.weeksCovered);

    if (!cloneWeeksCovered || cloneWeeksCovered.length === 0) {
      return;
    }

    console.log(event);

    const week = cloneWeeksCovered[event.index[0]];
    const day = week.days[event.index[1]];
    const session:DayCoverage = day[event.index[2]];

    session.booked_start_time = event.day.booked_start_time;
    session.booked_end_time = event.day.booked_end_time;
    session.booked_times = [...event.day.booked_times];
    session.educator_ids = [...event.day.educator_ids];
    session.food_meal_fee = event.day.food_meal_fee;
    session.travel_fee = event.day.travel_fee;
    session.absence_document_held = event.day.absence_document_held;
    session.fees_session = event.day.fees_session;
    session.location = event.day.location;
    session.notes = event.day.notes;
    session.is_absent = event.day.is_absent;
    session.has_booked_session = event.day.has_booked_session;
    session.absence_reason = event.day.absence_reason;
    session.educator_fee = event.day.educator_fee;


    const { totalAdminLevy, totalEducatorFee, totalHours, totalFoodFee, totalTravelFee } =
      this.childAttendanceUtilService.computeWeeklyValues(week.days);

    week.totalAdminLevy = totalAdminLevy;
    week.totalEducatorFee = totalEducatorFee;
    week.totalFoodMealFee = totalFoodFee;
    week.totalTravelFee = totalTravelFee;
    week.totalHours = totalHours;

    this.weeksCovered = _.cloneDeep(cloneWeeksCovered);

    console.log(this.weeksCovered);

  }

  handleAddNewSession(event) {
    const { index, start_date, end_date } = event;

    const clonedChildAttedance = _.cloneDeep(this.childAttendance);

    clonedChildAttedance.forEach((attendance) => {
      attendance.sign_in = null;
      attendance.sign_out = null;
    });

    const days2D = this.childAttendanceUtilService.setWeekChildAttendances(
      start_date,
      end_date,
      this.childSchedule,
      this.educator,
      clonedChildAttedance,
      this.childCasualSchedule,
      this.attendanceRow.child_id,
      this.attendanceRow.child_full_name,
      [this.educator.educator_id]
    );

    const { totalAdminLevy, totalEducatorFee, totalHours, totalFoodFee, totalTravelFee } =
      this.childAttendanceUtilService.computeWeeklyValues(days2D);

    const newWeek = {
      startDate: start_date,
      endDate: end_date,
      year: this.attendanceRow.start_date.getFullYear(),
      days: days2D,
      totalEducatorFee: totalEducatorFee,
      totalHours: totalHours,
      totalAdminLevy: totalAdminLevy,
      totalFoodMealFee: totalFoodFee,
      totalTravelFee: totalTravelFee,
    };
    // this.weeksCovered.splice(index + 1, 0, newWeek);

    const clonedWeeks = _.cloneDeep(this.weeksCovered);

    clonedWeeks.splice(index + 1, 0, newWeek);

    console.log(clonedWeeks);

    this.weeksCovered = clonedWeeks;


  }

  handleRemoveSession(event) {
    const { index } = event;
    this.weeksCovered.splice(index, 1);
  }

  saveAttendance() {
    this.isLoading = true;

    this.weeksCovered = this.removeEmptySignIns(this.weeksCovered);

    this.childAttendanceService
      .saveAttendanceList(this.weeksCovered)
      .subscribe({
        next: (response) => {
          this.globalService.showSuccessNotification(
            "Attendance saved successfully"
          );
        },
        error: (error) => {
          console.log(error);
          this.globalService.showErrorNotification(
            "Cannot save attendance at this time. Please try again later."
          );
        },
        complete: () => {
          this.isLoading = false;
        },
      });


    this.weeksCovered.forEach((week) => {
      week.days.forEach((day) => {
        const firstSession = day[0] ? day[0] : null;

        if (firstSession && firstSession.child_attendance_id) {
          const childAttendanceOtherDetails: ChildAttendanceOtherDetails = {
            child_id: firstSession.child_id,
            child_attendance_id: firstSession.child_attendance_id,
            absence_reason: firstSession.absence_reason,
            absence_document_held: firstSession.absence_document_held,
            educator_id: firstSession.educator_ids.join(","),
            fee_session: firstSession.fees_session,
            is_absent: firstSession.is_absent,
            food_fee: firstSession.food_meal_fee,
            location: firstSession.location,
            notes: firstSession.notes,
            travel_fee: firstSession.travel_fee,
          };
          this.childAttendanceOtherDetailsService
            .createOrUpdate(childAttendanceOtherDetails)
            .subscribe();
        }
      });
    });
  }

  removeEmptySignIns(weekCoverage: WeekCoverage[]) {
    const clonedWeekCovered = _.cloneDeep(weekCoverage);
    clonedWeekCovered.forEach((week) => {
      week.days.forEach((day) => {
        day.forEach((session) => {
          const bookedTimes = _.cloneDeep(session.booked_times);
          let len = bookedTimes.length;
          while (len--) {
            if (!bookedTimes[len].start_time && !bookedTimes[len].end_time) {
              bookedTimes.splice(len, 1);
            }
          }

          session.booked_times = [...bookedTimes];
        });
      });

    });

    console.log('clonedWeekCovered: ', clonedWeekCovered);
    return clonedWeekCovered
  }
}
