<mat-card
  class="attendance-card d-flex flex-column day-content"
  [ngClass]="{
    'attendance-absent': form.controls.is_absent.value,
    'border border-danger':
      validation_errors.length > 0 &&
      dayCoverage.has_booked_session &&
      !this.form.controls.is_absent.value,
    'no-session': !dayCoverage.has_booked_session
  }"
>
  <mat-card-header class="w-100 justify-content-between">
    <mat-card-title>
      <div>{{ dayCoverage?.attendance_date | date : "E d" }}</div>
    </mat-card-title>
    <div class="pull-right clickable">
      <mat-icon (click)="removeSession()">close</mat-icon>
    </div>
  </mat-card-header>
  <mat-card-content
    *ngIf="!dayCoverage.has_booked_session"
    class="empty-card"
    (click)="activateDay()"
    [class.clickable]="!dayCoverage.has_booked_session"
  >
    <h4 class="text-center">No Care</h4>
  </mat-card-content>
  <mat-card-content *ngIf="dayCoverage.has_booked_session">
    <div *ngIf="validation_errors.length > 0">
      <div class="alert alert-danger" role="alert">
        <ul>
          <li *ngFor="let error of validation_errors">* {{ error }}</li>
        </ul>
      </div>
    </div>
    <div *ngIf="outsideSignIns.length > 0">
      <div class="alert alert-warning" role="alert">
        * There are attendance outside booked times.
        <ul>
          <li *ngFor="let attendance of outsideSignIns">
            {{ attendance.start_time }} - {{ attendance.end_time }}
          </li>
        </ul>
      </div>
    </div>
    <div class="mb-3 text-center clickable" (click)="addEducator()">
      <mat-icon *ngFor="let educator of this.form.controls.educator_ids.value"
        >person</mat-icon
      >
    </div>
    <form [formGroup]="form" class="d-flex flex-column justify-content-center">
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Booked Start Time</mat-label>
        <input matInput type="time" formControlName="booked_start_time" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Booked End Time</mat-label>
        <input matInput type="time" formControlName="booked_end_time" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Educator Fee</mat-label>
        <input matInput type="number" formControlName="educator_fee" />
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>
      <div
        formArrayName="booked_times"
        class="d-flex flex-column justify-content-center shadow rounded p-3 mb-5"
        *ngFor="let bookedTimesCtrl of booked_times.controls; let i = index"
      >
        <div [formGroup]="bookedTimesCtrl">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Start Time</mat-label>
            <input matInput type="time" formControlName="start_time" />
            <mat-icon
              matPrefix
              class="clickable"
              (click)="setChildAttendancePresent()"
              >arrow_forward</mat-icon
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>End Time</mat-label>
            <input matInput type="time" formControlName="end_time" />
            <mat-icon
              matPrefix
              class="clickable"
              (click)="setChildAttendanceAbsent()"
              >arrow_back</mat-icon
            >
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Food Fee</mat-label>
        <input matInput type="number" formControlName="food_meal_fee"  step="0.01"/>
        <mat-icon matSuffix>restaurant</mat-icon>
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Travel Fee</mat-label>
        <input matInput type="number" formControlName="travel_fee" step="0.01"/>
        <mat-icon matSuffix>airport_shuttle</mat-icon>
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Fee Session</mat-label>
        <mat-select formControlName="fees_session">
          <mat-option value="standard">Standard</mat-option>
          <mat-option value="zero">Zero</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Location</mat-label>
        <mat-select formControlName="location">
          <mat-option value="no">No</mat-option>
          <mat-option value="yes_in_house">Yes, (In House)</mat-option>
          <mat-option value="yes_off_site">Yes, (Off Site)</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Additional Notes</mat-label>
        <textarea matInput formControlName="notes"></textarea>
      </mat-form-field>
      <section *ngIf="form.controls.is_absent.value">
        <mat-checkbox formControlName="absence_document_held"
          >Absence Document Held</mat-checkbox
        >
      </section>
      <mat-form-field
        appearance="outline"
        *ngIf="form.controls.is_absent.value"
      >
        <mat-select formControlName="absence_reason">
          <mat-option
            *ngFor="let reason of childAbsenseReasons"
            [value]="reason.value"
            >{{ reason.text }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
