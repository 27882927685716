<div class="bg-white px-3 py-3 rounded text-dark">
  <h3 class="mb-2">Select Educator</h3>
  <div class="form-group">
    <label>Educators</label>
    <select class="form-control" [(ngModel)]="selectedEducator">
      <option
        *ngFor="let educator of data.educators"
        [value]="educator.educator_id"
      >
        {{ educator.educator_name }}
      </option>
    </select>
  </div>
  <div class="d-flex justify-content-end">
    <button type="submit" class="btn btn-primary mr-2" (click)="onYesClick()">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="onNoClick()">
      Cancel
    </button>
  </div>
</div>
