import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomvalidationService {

  constructor() { }

  requiredFieldValidator(requiredField: string, dependentField: string) {
    return (formGroup: FormGroup) => {
      const requiredControl = formGroup.controls[requiredField];
      const dependentControl = formGroup.controls[dependentField];
      
      if (!dependentControl || dependentControl.value === '' || dependentControl.value === 0) {
        if (requiredControl.errors && requiredControl.errors.required) {
          delete requiredControl.errors.required;
          if (Object.keys(requiredControl.errors).length === 0) {
            requiredControl.setErrors(null);
          }
        }
        return null;
      }
  
      if (requiredControl.errors && !requiredControl.errors.required) {
        return null;
      }
  
      if (!requiredControl.value && dependentControl.value) {
        requiredControl.setErrors({required: true});
        requiredControl.markAsTouched();
      }
    }
}

  conditionallyRequiredValidator(field: string, conditionalField: string, value: any) {
    return (formGroup: FormGroup) => {
      const requiredControl = formGroup.controls[field];
      const conditional = formGroup.controls[conditionalField];
      
      if (conditional.value != value ) {
        delete requiredControl.errors?.required;
        if (requiredControl.errors && Object.keys(requiredControl.errors).length === 0) {
          requiredControl.setErrors(null);
        }
        return null;
      }

      if (requiredControl.value || (requiredControl.errors && !requiredControl.errors.required)) {
        return null;
      }
  
      if (conditional.value == value && (!requiredControl.value || requiredControl.value === '')) {
        requiredControl.setErrors({required: true});
        requiredControl.markAsTouched();
      }
    }
  }

timeRangeValidator(start: string, end: string) {
    return (formGroup: FormGroup) => {
      const startControl = formGroup.controls[start];
      const endControl = formGroup.controls[end];
      
      if (((!startControl && !endControl) || (startControl && !endControl))) {
        if (startControl.errors && startControl.errors.required) {
          delete startControl.errors?.required;
          if (Object.keys(startControl.errors).length === 0) {
            startControl.setErrors(null);
          }
        }
        if (endControl.errors && endControl.errors.required) {
          delete endControl.errors?.required;
          if (Object.keys(endControl.errors).length === 0) {
            endControl.setErrors(null);
          }
        }
        return null;
      }
  
      if (startControl.errors && endControl.errors) {
        return null;
      }

      
      var startSeconds = this.convertTimeToSeconds(startControl.value);
      var endSeconds = this.convertTimeToSeconds(endControl.value);
      var startHours = Math.floor(startSeconds / 3600);
      var endHours = Math.floor(endSeconds / 3600);

      if (!isNaN(endSeconds) && !isNaN(startSeconds) && endSeconds < startSeconds) {
        startControl.setErrors({invalidTimeRange:true})
        startControl.markAsTouched();
        endControl.setErrors({invalidTimeRange:true})
        endControl.markAsTouched();
      } else if (!isNaN(endSeconds) && !isNaN(startSeconds) && ((endHours - startHours) > 12)) {
        endControl.setErrors({overTimeLimit:true})
        endControl.markAsTouched();
      } else {
        if (!endControl.errors?.required) {
          delete endControl?.errors?.required;
          endControl.setErrors(null)
        } 
        if (!startControl.errors?.required) {
          delete startControl?.errors?.required;
          startControl.setErrors(null)
        }        
      }
    }
}

  convertTimeToSeconds(timeString) {
    if (timeString && timeString.split(':')) {
      var a = timeString.split(':')
      var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
      return seconds;
    } 
    return NaN;
  }

  conditionalCheckboxesValidator (checkboxes: Array<string>, conditionalField: string, values: Array<any>) {
    return (formGroup: FormGroup) => {
      const conditional = formGroup.get(conditionalField)?.value;
      let checker = false;


      if (values.includes(conditional)) {
        checkboxes.forEach((key) => {
          if (formGroup.get(key).value) {
            checker = true;
          }
        })

        if (checker) {
          return null;
        } else {
          return { atLeastOneCheckboxRequired: true };
        }
      }
      return null;
    }
  }

  mondayValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      // Assuming control.value is in a format that can be parsed by Date, e.g., 'YYYY-MM-DD'
      const selectedDate = new Date(control.value);
      if (selectedDate.getDay() !== 1) {  // In JavaScript and TypeScript, 1 represents Monday
        return { 'notMonday': true };
      } 
    }
    return null;
  }

}
