
<div class="files-list">
    <div class="single-file" *ngFor="let file of fileList; let i = index">
        <img src="../../../assets/images/dnd/ic-file.svg" width="45px" alt="file">
        <div class="info">
            <h4 class="name">
                {{ file?.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.size) }}
            </p>
        </div>
        <img src="../../../assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
    </div>
</div>
