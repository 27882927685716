import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EsiService } from 'src/app/esi/esi.service';
import { GlobalService } from 'src/app/global/global.service';

@Component({
  selector: 'app-child-attendance-view-modal',
  templateUrl: './child-attendance-view-modal.component.html',
  styleUrls: ['./child-attendance-view-modal.component.sass']
})
export class ChildAttendanceViewModalComponent implements OnInit {
  @Input() public data: any;
  @Input() rolePermission: any[] = [];
  displayedColumns: string[] = ['check_box', 'day', 'sched', 'sign_in', 'signed_in_by', 'sign_out', 'signed_out_by'];
  modalRef: NgbModalRef;
  tableData: any[] = [];
  totalRecords: any;
  editMode = false;
  constructor(public modal: NgbActiveModal,
    private modalService: NgbModal,
    private globeService: GlobalService,
    private esiService: EsiService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.rolePermission);
    console.log('editMode');
    console.log(this.editMode);
    if(this.editMode)
      this.displayedColumns = ['check_box', 'day', 'sched', 'sign_in', 'signed_in_by', 'sign_out', 'signed_out_by', 'action'];

    this.esiService.getAttendanceTablePerChild(
      this.data.all_child_attendance_ids
    ).subscribe((res) => {
      console.log(res);
      this.tableData = res['data'];
      this.totalRecords = res['total_records'];
    }, (error) => {
      console.log(error);
    });
  }
  openView(data) {
      console.log(data);
      console.log('For enhancement part II');
  }
  openEdit(data) {
      console.log(data);
      console.log('For enhancement part II');
  }
  confirmAbsent(id) {
    console.log('For enhancement part II');
  }
  toggleAllSelection(event: any) {
    console.log(this.tableData);
    console.log(event);
    this.tableData.forEach(element => {
      element.isSelected = event.checked
    });
  }

  checkEditEnabled(date) {
    let dateLimit = new Date(date);
    dateLimit.setDate(dateLimit.getDate() + 14);
    let today = new Date();
    let isEditEnabled = false;
    return isEditEnabled;
  }
  formatDateWithoutTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  formatTime(time: string): string {
    // Construct a valid date by using a placeholder date (e.g., 1970-01-01)
    const fullDateTime = `1970-01-01 ${time}`;
    
    // Use DatePipe to format the time
    const formattedTime = this.datePipe.transform(fullDateTime, 'h:mm a');
    
    return formattedTime || ''; // Handle invalid times gracefully
  }
  formatDateToDay(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // Get the day of the week and convert it to the corresponding string
    return daysOfWeek[date.getDay()];
  }
}
