import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedComponentService } from '../../shared-component.service';
import { DatePipe, UpperCasePipe } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { GlobalService } from '../../../global/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-authorisation-rmp-notification-view',
  templateUrl: './authorisation-rmp-notification-view.component.html',
  styleUrls: ['./authorisation-rmp-notification-view.component.sass'],
})
export class AuthorisationRmpNotificationViewComponent implements OnInit {
  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Input() proposedRouteDropZoneFiles = [];
  @Input() venueSafetyDropZoneFiles = [];
  @Input() vehicleSafetyDropZoneFiles = [];

  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  destinations: any = [];
  risks: any = [];
  consulation_table: any = [];

  loggedUser: any = [];
  sectionForm: FormGroup;
  currentDate = null;
  signatureImg = null;
  isNewSignature = true;
  isEdit = false;
  submitted = false;
  error = '';
  sigError = false;

  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  parentsSignatures: any = [];

  submitBtnClick = false;

  constructor(
    private shardService: SharedComponentService,
    private datePipe: DatePipe,
    private uppercasePipe: UpperCasePipe,
    private fb: FormBuilder,
    private globeService: GlobalService
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));

    this.sectionForm = this.fb.group({
      parent_id: [''],
      child_id: [''],
      date: [''],
      ref: [''],
      notification_ref: [''],
      table: [''],
      signature: [''],
      parent_signature_date: ['', [Validators.required]],
      is_approval: [''],
      parent_authorisation_fullname: ['', [Validators.required]],
      parent_authorisation_checkbox_1: [
        '',
        [Validators.required, customValidateCheckbox1()],
      ],
      parent_authorisation_checkbox_2: [
        '',
        [Validators.required, customValidateCheckbox2()],
      ],
      parent_authorisation_checkbox_3: [
        '',
        [Validators.required, customValidateCheckbox3()],
      ],
      parent_authorisation_checkbox_4: [
        '',
        [Validators.required, customValidateCheckbox4()],
      ],
      parent_authorisation_checkbox_5: [
        '',
        [Validators.required, customValidateCheckbox5()],
      ],
      parent_authorisation_checkbox_6: [
        '',
        [Validators.required, customValidateCheckbox6()],
      ],
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      parent_id: this.loggedUser['user']['id'],
      date: new Date(),
      ref: this.rowRef,
      notification_ref: this.notificationRef,
      table: 'authorisation_rmp_form',
    });

    this.getNotification();

    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id'],
      });
    }
  }

  getNotification() {
    this.shardService.getAuthorisation(this.rowRef).subscribe(
      (res) => {
        this.data = res['data'];
        this.destinations = res['data']['destinations'];
        this.risks = res['data']['risks'];
        this.consulation_table = res['data']['consulation_table'];

        this.children = res['children'];
        this.parentsSignatures = res['parents_signatures'];

        if (this.loggedUser['role_id'] != 6) {
          this.data['child_ids'].forEach((obj, key) => {
            this.selectedChildren.push(
              this.children.filter((element) => {
                return element.child_id == obj;
              })[0]
            );
          });
        }
      },
      (error) => {}
    );
  }

  removeSignature() {
    this.isNewSignature = true;
  }

  signatureImgOutput($event) {
    this.signatureImg = $event;
  }

  formSubmit(form: FormGroup, type) {
    if (type == 1) {
      this.sectionForm
        .get('parent_authorisation_checkbox_1')
        .setValidators([Validators.required, customValidateCheckbox1()]);
      this.sectionForm
        .get('parent_authorisation_checkbox_2')
        .setValidators([Validators.required, customValidateCheckbox2()]);
      this.sectionForm
        .get('parent_authorisation_checkbox_3')
        .setValidators([Validators.required, customValidateCheckbox3()]);
      this.sectionForm
        .get('parent_authorisation_checkbox_4')
        .setValidators([Validators.required, customValidateCheckbox4()]);
      this.sectionForm
        .get('parent_authorisation_checkbox_5')
        .setValidators([Validators.required, customValidateCheckbox5()]);
      this.sectionForm
        .get('parent_authorisation_checkbox_6')
        .setValidators([Validators.required, customValidateCheckbox6()]);
      this.sectionForm
        .get('parent_signature_date')
        .setValidators([Validators.required]);
    } else {
      this.sectionForm.get('parent_authorisation_checkbox_1').clearValidators();
      this.sectionForm.get('parent_authorisation_checkbox_2').clearValidators();
      this.sectionForm.get('parent_authorisation_checkbox_3').clearValidators();
      this.sectionForm.get('parent_authorisation_checkbox_4').clearValidators();
      this.sectionForm.get('parent_authorisation_checkbox_5').clearValidators();
      this.sectionForm.get('parent_authorisation_checkbox_6').clearValidators();
      this.sectionForm.get('parent_signature_date').clearValidators();
    }
    this.sectionForm
      .get('parent_authorisation_checkbox_1')
      .updateValueAndValidity();
    this.sectionForm
      .get('parent_authorisation_checkbox_2')
      .updateValueAndValidity();
    this.sectionForm
      .get('parent_authorisation_checkbox_3')
      .updateValueAndValidity();
    this.sectionForm
      .get('parent_authorisation_checkbox_4')
      .updateValueAndValidity();
    this.sectionForm
      .get('parent_authorisation_checkbox_5')
      .updateValueAndValidity();
    this.sectionForm
      .get('parent_authorisation_checkbox_6')
      .updateValueAndValidity();
    this.sectionForm.get('parent_signature_date').updateValueAndValidity();

    this.sigError = false;
    if (this.signatureImg == null && type == 1) {
      this.sigError = true;
    }
    this.submitBtnClick = true;
    if (!form.valid || this.submitted) {
      this.globeService.scrollToError();
      return false;
    }
    if (this.signatureImg == null && type == 1) return false;
    this.sectionForm.patchValue({
      parent_signature_date: this.globeService.formatDatetoServerSide(
        this.sectionForm.value.parent_signature_date
      ),
    });

    if (type == 1) {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to approve this notification?',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approved',
        cancelButtonText: 'No, will approve later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    } else {
      Swal.fire(<any>{
        title: 'Are you sure?',
        text: 'Do you want to decline this notification',
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, decline',
        cancelButtonText: 'No, will decline later',
      }).then((result) => {
        if (result.value) {
          this.formSave(type);
        } else {
          this.closeModal.emit(true);
        }
      });
    }
  }

  formSave(type) {
    this.sectionForm.patchValue({
      signature: this.signatureImg,
      is_approval: type,
    });

    this.shardService.updateParentSignature(this.sectionForm.value).subscribe(
      (res) => {
        this.globeService.showSuccessNotification('Successfully updated');
        this.closeModal.emit(true);
      },
      (error) => {
        this.error = error.error.error;
        this.globeService.showErrorNotification(this.error);
        this.submitted = false;
      }
    );
  }

  modelClose() {
    this.closeModal.emit(true);
  }
}

export function customValidateCheckbox1(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
export function customValidateCheckbox2(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
export function customValidateCheckbox3(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
export function customValidateCheckbox4(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
export function customValidateCheckbox5(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
export function customValidateCheckbox6(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { parent_authorisation_checkbox_1: true };
    }
    return null;
  };
}
