<div class="row p-15">
    <div class="clearfix">
        <div class="contact-header">
            <div class="contact-details-heading">
                <div class="contact-details-name mb-2 w-100-p"> Request for New Schedule</div>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
        <div class="m-t-15" [innerHTML]="htmlBody"></div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
        <form class="register-form" [formGroup]="sectionForm" (ngSubmit)="formSubmit()">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="isNewSignature && loggedUser['role_id'] == 6">
                    <h5 class="form-group-heading">Parent/Guardian</h5>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select formControlName="parent_id">
                            <mat-option value="{{ parent.parent_id }}" *ngFor="let parent of parents">{{ parent.parent_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                        <mat-label>Parent/Guardian Signature Date</mat-label>
                        <input matInput formControlName="p_sign_date" [matDatepicker]="picker1"
                            (click)="picker1.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>

                    <label class="f-12 m-t-15">Parent/Guardian Signature</label>
                    <ng-container>
                        <app-signature-pads (signaturePadEmit)="signatureImgOutput1($event)"></app-signature-pads>
                    </ng-container>
                    <br />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="!isNewSignature && isNewSignature2 && isFdc && 
                ((loggedUser['role_id'] == 7) || loggedUser['role_id'] == 4 || loggedUser['role_id'] == 9)">
                    <h5 class="form-group-heading">Received by:</h5>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" formControlName="received_by_user_name" matInput readonly>
                    </mat-form-field>
                    <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                        <mat-label>Position / Role:</mat-label>
                        <input type="text" matInput formControlName="received_by_role" readonly>
                    </mat-form-field>
                    <input type="hidden" formControlName="received_by_user_id">

                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                        <mat-label>Signature Date</mat-label>
                        <input matInput formControlName="e_sign_date" [matDatepicker]="picker2"
                            (click)="picker2.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <label class="f-12 m-t-15">Received by Signature</label>
                    <app-signature-pads (signaturePadEmit)="signatureImgOutput2($event)"></app-signature-pads>
                    <br />
                </div>


                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="!isNewSignature && isNewSignature3 && (loggedUser['role_id'] == 4 || loggedUser['role_id'] == 9)">
                    <h5 class="form-group-heading">Approved by:</h5>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" formControlName="approved_by_user_name" matInput readonly>
                    </mat-form-field>
                    <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                        <mat-label>Position / Role:</mat-label>
                        <input type="text" matInput formControlName="approved_by_role" readonly>
                    </mat-form-field>
                    <input type="hidden" formControlName="approved_by_user_id">
                    
                    <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
                        <mat-label>Signature Date</mat-label>
                        <input matInput formControlName="approved_sign_date" [matDatepicker]="picker" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <label class="f-12 m-t-15">Approved by Signature</label>
                    <app-signature-pads (signaturePadEmit)="signatureImgOutput3($event)"></app-signature-pads>
                    <br />
                    <span class="text-danger" *ngIf="sigError">Signature is required</span>
                </div>
            </div>
            <br />
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0" *ngIf="loggedUser['role_id'] == 6 && isNewSignature">
                <button mat-flat-button color="primary" class="pull-right  text-white"
                    type="submit">
                    Submit
                </button>
                <button class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" (click)="modalClose()"
                    mat-raised-button data-dismiss="modal">Close</button>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0" *ngIf="!isNewSignature && ((loggedUser['role_id'] == 7 && isFdc && isNewSignature2) || ((loggedUser['role_id'] == 4 || loggedUser['role_id'] == 9) && (isNewSignature2 || isNewSignature3)))">
                <button mat-flat-button color="primary" class="pull-right  text-white"
                    type="submit">
                    Approve
                </button>
                <button (click)="declinePopUp(declineRequest)" class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button"
                mat-raised-button data-dismiss="modal">Decline</button>
            </div>
        </form>
    </div>
</div>


<ng-template #declineRequest let-modal>
    <div class="modal-header editRowModal">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button mat-icon-button (click)="modal.dismiss()" class="bg-light-gray m-r-14" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <form class="register-form" [formGroup]="sectionForm" (keydown.enter)="$event.preventDefault()">
      <div class="modal-body">
        <div style="margin: auto; display: flex; align-items: center; align-self: center; justify-content: center; justify-self: center; width: 80%;">
          <h2 style="font-weight: bold">Are you sure?</h2>
        </div>
        <div style="margin: auto; display: flex; align-items: center; align-self: center; justify-content: center; justify-self: center; width: 80%;">
          <p>Do you want to decline the request for New Schedule?</p>
        </div>
        <div style="margin: auto; display: flex; align-items: center; align-self: center; justify-content: center; justify-self: center; width: 80%;">
          <mat-form-field class="example-full-width" appearance="outline" >
            <mat-label>Please provide details</mat-label>
            <input matInput formControlName="decline_reason">
          </mat-form-field>
        </div>
  
      </div>
      <div class="modal-footer" style="justify-content: center;">
        <button mat-raised-button type="button" (click)="submitDecline()" class="text-white mr-3" color="primary" [class.spinner]="submitted">SUBMIT</button>
        <button mat-raised-button type="button" class="cancel-btn text-white mr-3  bg-light-red" data-dismiss="modal" (click)="modal.dismiss()">CANCEL</button>
      </div>
    </form>
  </ng-template>
