import { Component, OnInit, Output, EventEmitter, Input, ViewChild, TemplateRef, ElementRef} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { GlobalService } from "../../global/global.service";
import { WebPagesService } from "../../web-pages/web-pages.service";
import { error } from "selenium-webdriver";
import { ChildEnrollmentService } from "../../child-enrollment/child-enrollment.service";
import { MatStepper } from "@angular/material/stepper";
import Swal from "sweetalert2";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { SignaturePad } from "angular2-signaturepad";
import { CustomDropzoneComponent } from "../custom-dropzone/custom-dropzone.component";
import { DropzoneDocListComponent } from "../dropzone-doc-list/dropzone-doc-list.component";
import { async } from "q";
import { saveAs as importedSaveAs } from "file-saver";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { AccountService } from "../../accounts/account.service";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ExcelService } from '../../shared/excel.service';

export interface Country {
  country_id: string;
  country_code: string;
  country_name: string;
  status: string;
}

@Component({
  selector: 'app-enrollment-form-shared',
  templateUrl: './enrollment-form.component.html',
  styleUrls: ['./enrollment-form.component.css']
})

export class EnrollmentFormComponent implements OnInit {
  @Output() public getStatus = new EventEmitter<string>();
  @Input() enrollmentFormRef: any;
  @Output() CloseForm = new EventEmitter<any>();

  @ViewChild('stepper') private wizardStepper: MatStepper;
  @ViewChild('showDuplicateChild') templateRefDuplicateChild: TemplateRef<any>;
  @ViewChild('showViewMore') templateRefViewMore: TemplateRef<any>;
  @ViewChild('SignaturePad') signaturePad: SignaturePad;
  @ViewChild('dropzoneList') dropzoneList: DropzoneDocListComponent;

  @ViewChild('Chepatitis_b0') Chepatitis_b0;
  @ViewChild('Chepatitis_b2') Chepatitis_b2;
  @ViewChild('Chepatitis_b4') Chepatitis_b4; 
  @ViewChild('Chepatitis_b6') Chepatitis_b6;
  @ViewChild('Cdiphtheria2') Cdiphtheria2;
  @ViewChild('Cdiphtheria4') Cdiphtheria4;
  @ViewChild('Cdiphtheria6') Cdiphtheria6;
  @ViewChild('Cdiphtheria18') Cdiphtheria18;
  @ViewChild('Cdiphtheria48') Cdiphtheria48;
  @ViewChild('Ctetanus2') Ctetanus2;
  @ViewChild('Ctetanus4') Ctetanus4;
  @ViewChild('Ctetanus6') Ctetanus6;
  @ViewChild('Ctetanus18') Ctetanus18;
  @ViewChild('Ctetanus48') Ctetanus48;
  @ViewChild('Cpertusis2') Cpertusis2;
  @ViewChild('Cpertusis4') Cpertusis4;
  @ViewChild('Cpertusis6') Cpertusis6;
  @ViewChild('Cpertusis18') Cpertusis18;
  @ViewChild('Cpertusis48') Cpertusis48;
  @ViewChild('Cpolio2') Cpolio2;
  @ViewChild('Cpolio4') Cpolio4;
  @ViewChild('Cpolio6') Cpolio6;
  @ViewChild('Cpolio48') Cpolio48;
  @ViewChild('Chib2') Chib2;
  @ViewChild('Chib4') Chib4;
  @ViewChild('Chib6') Chib6;
  @ViewChild('Chib18') Chib18;
  @ViewChild('Cpneumococcal2') Cpneumococcal2;
  @ViewChild('Cpneumococcal4') Cpneumococcal4;
  @ViewChild('Cpneumococcal6') Cpneumococcal6;
  @ViewChild('Cpneumococcal12') Cpneumococcal12;
  @ViewChild('Cpneumococcal48') Cpneumococcal48;
  @ViewChild('Crotavirus2') Crotavirus2;
  @ViewChild('Crotavirus4') Crotavirus4;
  @ViewChild('Cmeasles12') Cmeasles12;
  @ViewChild('Cmeasles18') Cmeasles18;
  @ViewChild('Cmumps12') Cmumps12;
  @ViewChild('Cmumps18') Cmumps18;
  @ViewChild('Crubella12') Crubella12;
  @ViewChild('Crubella18') Crubella18;
  @ViewChild('Cmeningococcal_c12') Cmeningococcal_c12;
  @ViewChild('Cvaricella18') Cvaricella18;
  @ViewChild('Chepatitis_a12') Chepatitis_a12;
  @ViewChild('Chepatitis_a18') Chepatitis_a18;
  modalConfig = { ariaLabelledBy: 'modal-basic-title', size: 'lg' };
  childModalRef: NgbModalRef;
  viewMoreModalRef: NgbModalRef;
  displayedColumns: string[] = ['#','Institute / Occupation', 'Address', 'Phone'];
  labelPosition: 'before' | 'after' = 'after';
  dataSource = [
  ];
  dataSource2 = [
  ];
  ParentAgreementSection = true;
  isLinear = true;
  HFormGroup1: FormGroup;
  HFormGroup2: FormGroup;
  HFormGroup3: FormGroup;
  HFormGroup4: FormGroup;
  HFormGroup5: FormGroup;
  HFormGroup6: FormGroup;
  HFormGroup7: FormGroup;
  routineSession = {};
  casualSession = {};
  startDate = '';
  disableSchedule = false;
  work_table_form: FormGroup;
  work_table_form2: FormGroup;
  HFormGroupMedical = new FormData();
  HFormGroupEmergencyContact = new FormData();
  HFormGroupSchedule = new FormData();
  DocumentUploadForm = new FormData();
  HFormGroupParentAgreement = new FormData();
  files: FileList;
  hideOtherName: boolean = true;
  hideFearDetails: boolean = true;
  hideParent1occupation: boolean = true;
  hideParent2occupation: boolean = true;
  hideProhibitedContactDetails: boolean = true;
  hideChildDayRouting: boolean = true;
  hideOtherDocument: boolean = true;
  hideMedicalAllergiesDocument: boolean = true;
  hideRegularMedicineDocument: boolean = true;
  hideSpecialDietaryDocument: boolean = true;
  hideChildCustodyDocument: boolean = false;
  childEnrollmentFormRef = null;
  checked: any;
  indeterminate: any;
  keyPoints = [];
  SentMailFormRef = null;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;
  submitted5 = false;
  submittedSchedule = false;
  // isEditForm = false;
  // isEditFormSubmit = false;
  other_documents = [];
  other_documents_preview = [];
  deleted_other_documents = [];

  allergies_documents = [];
  allergies_documents_preview = [];
  deleted_allergies_documents = [];

  regular_medicine_documents = [];
  regular_medicine_documents_preview = [];
  deleted_regular_medicine_documents = [];

  special_dietary_documents = [];
  special_dietary_documents_preview = [];
  special_dietary_documents_mimeType = [];
  deleted_special_dietary_documents = [];

  custody_documents = [];
  custody_documents_preview = [];
  custody_documents_mimeType = [];

  suncream_documents = [];
  suncream_documents_preview = [];
  suncream_documents_mimeType = [];

  deleted_medical_documents = [];
  deleted_custody_document = [];
  deleted_suncream_documents = [];

  disableFormStatus = false;
  submittedForm = false;
  EnrollmentFormData: any = [];
  child_ref = null;
  parent_1_ref = null;
  parent_2_ref = null;
  childData: any;
  parent1Data: any;
  parent2Data: any;
  childDuplicate = false;
  parent1Duplicate = false;
  parent2Duplicate = false;
  ViewMoreHeader = '';
  duplicateData: any;
  checked_child = false;
  checked_parent1 = false;
  checked_parent2 = false;
  submittedDuplicate = false;
  submittedMedical = false;
  is_suncream_alergies = false;
  signatureImage = null;
  signatureRefParentAgreement: any;
  
  docList = [];
  docTypeList = [];
  docListEdit = [];
  deletedDocuments = [];

  docList_child_id = [];
  docListEdit_child_id = [];

  docList_medicare = [];
  docListEdit_medicare = [];

  docList_parent_id = [];
  docListEdit_parent_id = [];

  docList_immunisation_history = [];
  docListEdit_immunisation_history = [];

  docList_other_id = [];
  docListEdit_other_id = [];

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.5,
    'maxWidth': 2.5,
    'canvasWidth': 292.22,
    'canvasHeight': 100,
    'penColor': "rgb(5,5,5)",
    'backgroundColor': "rgba(255,255,255,0.01)"

  };
  createdAccounts = null;

  accessMode = false; // first time parent submit the form accessMode =  false = 'parent'
  editMode = false; // first time parent submit the form updateMode =  false
  ViewFromChildrenModule = false;
  signatureImg = '';
  signatureImg2 = '';
  isNewSignature = true;
  isNewSignature2 = true;
  states: any = [];
  countries: any = [];
  ShowP1relation = false;
  ShowP2relation = false;
  ShowE1relation = false;
  ShowE2relation = false;
  ShowCourtOrders = false;
  showOtherHealthProblem = false;
  loggedUser = null;
  OtherHealthProblem = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  disabledPG2 = false;
  addtionalUploadDocuments = false;
  parent_2_required = "required";
  cars = ["Saab", "Volvo", "BMW"];
  child_name = 'child name';
  showPriorityAcessText = false;
  SubmitHFormGroup5BtnClicked = false;
  constructor(
    private dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private webPagesService: WebPagesService,
    private childEnrollmentService: ChildEnrollmentService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private uppercasePipe: UpperCasePipe,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.initForm();
  }

  initForm() {

    this.HFormGroup1 = this.fb.group({
      child_surname: ['', [Validators.required]],
      child_givenname: ['', [Validators.required]],
      child_dob: ['', [Validators.required]],
      child_crn: ['', [Validators.required, customValidateCrn()]],
      child_address: ['', [Validators.required]],
      child_suburb: ['', [Validators.required]],
      child_state: ['', [Validators.required]],
      child_postal_code: ['', [Validators.required]],
      child_country_birth: ['', [Validators.required]],
      child_gender: ['', [Validators.required]],
      is_other_name: ['',],
      child_other_name: [''],
      child_cultural_background: ['',],
      aboriginal_strait_islander: [''],
      child_language_spoken: [''],
      child_school: ['',],
      child_year_level: ['',],
      child_fears: ['',],
      child_fears_details: [''],
      child_interest: ['',],
      enrolment_form_ref: [null],
      form_type: ['child_details']
    });
    this.HFormGroup2 = this.fb.group({
      parent1_name: ['', [Validators.required]],
      parent1_first_name: ['', [Validators.required]],
      parent1_last_name: ['', [Validators.required]],
      parent1_user_id: [''],
      parent1_dob: [null, [Validators.required]],
      parent1_relationship: ['Father', [Validators.required]],
      parent1_other: [''],
      parent1_crn: ['', [Validators.required, customValidateCrn()]],
      parent1_contact_no: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      parent1_work_phone: ['', Validators.pattern('[- +()0-9]+')],
      parent1_gender: [''],
      
      parent1_email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      parent1_address: ['', [Validators.required]],
      parent1_suburb: ['', [Validators.required]],
      parent1_state: ['', [Validators.required]],

      parent1_cultural_background: ['', [Validators.required]],
      parent1_aboriginal_strait_islander: ['', [Validators.required]],
      parent1_language_spoken: ['', [Validators.required]],

      parent1_postal_code: ['', [Validators.required]],


      parent1_is_working: ['', [Validators.required]],
      parent1_occupation: [''],
      parent1_work_address: [''],
      parent1_work_phone_no: [''],



      parent2_name: ['',],
      parent2_first_name: [''],
      parent2_last_name: [''],
      parent2_user_id: [''],
      parent2_dob: [null,],
      parent2_relationship: ['Mother'],
      parent2_other: [''],
      parent2_crn: [''],
      parent2_contact_no: ['', Validators.pattern('[- +()0-9]+')],
      parent2_work_phone: ['', Validators.pattern('[- +()0-9]+')],
      parent2_gender: [''],

      parent2_email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      parent2_address: ['',],
      parent2_suburb: ['',],
      parent2_state: ['',],
      parent2_postal_code: ['',],

      parent2_cultural_background: [''],
      parent2_aboriginal_strait_islander: [''],
      parent2_language_spoken: [''],

      parent2_is_working: [''],
      parent2_occupation: [''],
      parent2_work_address: [''],
      parent2_work_phone_no: [''],

      contact_child: ['0'],
      contact_child_details: [''],
      form_type: ['parent_details']
    });
    this.work_table_form = this.fb.group({
      parent1_occupation_temp: [''],
      parent1_work_address_temp: [''],
      parent1_work_phone_no_temp: ['']
    })
    this.work_table_form2 = this.fb.group({
      parent2_occupation_temp: [''],
      parent2_work_address_temp: [''],
      parent2_work_phone_no_temp: ['']
    })
    this.HFormGroup3 = this.fb.group({
      emergency1_last_name: ['', [Validators.required]],
      emergency1_first_name: ['', [Validators.required]],
      emergency1_relationship: ['Uncle', [Validators.required]],
      emergency1_other: [''],
      emergency1_home_phone: [''],
      emergency1_work_phone: [''],
      emergency1_mobile: ['', [Validators.required]],
      emergency1_address: ['', [Validators.required]],
      emergency1_is_collect_your_child: [''],
      emergency1_is_excursion_out_of_service: [''],
      emergency1_is_medical_treatment: [''],
      emergency1_is_permit_transportation: [''],
      emergency1_is_request_give_medication: [''],
      emergency1_is_notified: [''],
      emergency1_is_collecting_data: [''],
      emergency1_authorise_take_child_outside: [''],
      emergency1_authorise_child_transportation: [''],

      emergency2_last_name: ['',],
      emergency2_first_name: ['',],
      emergency2_relationship: ['Auntie',],
      emergency2_other: [''],
      emergency2_home_phone: ['',],
      emergency2_work_phone: ['',],
      emergency2_mobile: ['',],
      emergency2_address: ['',],
      emergency2_is_collect_your_child: [''],
      emergency2_is_excursion_out_of_service: [''],
      emergency2_is_medical_treatment: [''],
      emergency2_is_permit_transportation: [''],
      emergency2_is_request_give_medication: [''],
      emergency2_is_notified: [''],
      emergency2_is_collecting_data: [''],
      emergency2_authorise_take_child_outside: [''],
      emergency2_authorise_child_transportation: [''],
      is_collect_child: ['0', [Validators.required]],
      n_b_collect_data: ['0', [Validators.required]],

      form_type: ['emergency_contact_details'],
      custody_details: ['',],
      custody_notes: ['',]

    });
    this.HFormGroup4 = this.fb.group({
      immunisation_supervisor_name: [''],
      immunisation_signed: ['', [Validators.required]],
      immunisation_date: [''],

      child_doctor_name: ['', [Validators.required]],
      child_doctor_phone: ['', [Validators.required]],
      child_doctor_address: ['', [Validators.required]],
      child_dentist_name: [''],
      child_dentist_phone: [''],
      child_dentist_address: [''],
      health_fund_name: [''],
      health_fund_no: [''],
      medicare_no: ['', [Validators.required, Validators.minLength(10)]],

      is_add_adhd: [''],
      is_eilepsy: [''],
      is_physical_need: [''],
      is_haemophila: [''],
      is_asthma: [''],
      is_diabetes: [''],
      is_heart_problem: [''],
      is_anaphylaxis: [''],
      is_behavioural_needs: [''],
      is_other: [''],
      does_your_child_have_any_of_the_following: [''],
      toileting_requirements: [''],
      is_none: [''],

      other_problems: [''],
      other_documents: [null],

      is_allegies: ['', [Validators.required]],
      allergies_documents: [null],
      allergies_details: [''],
      is_regular_medication: ['', [Validators.required]],
      regular_medication_details: [''],
      regular_medication_documents: [null],
      is_special_dietary: ['', [Validators.required]],
      special_dietary_details: [''],
      special_dietary_documents: [null],
      is_nappies: [''],
      is_toilet_traning: [''],
      is_pull_ups: [''],
      is_fully_toilet: [''],
      other_details: [''],
      is_rest_during_day: ['', [Validators.required]],
      daily_route: [''],
      form_type: ['medical_details'],
      not_immunized: ['', [Validators.required, customValidateCheckbox()]],
      select_all_chx_box: ['']
    });
    this.HFormGroup5 = this.fb.group({
      // family_educator_name: ['', [Validators.required]],
      // family_educator_contact: ['', [Validators.required]],
      // family_educator_address: ['', [Validators.required]],
      // parent_name: ['', [Validators.required]],
      is_agree_parent_agreement: [false],
      hamc_checkbox1: [false],
      hamc_checkbox2: [false],
      hamc_checkbox3: [false],
      hamc_checkbox4: [false],
      hamc_checkbox5: [false],
      hamc_checkbox6: [false],
      tearoaf_checkbox1: [false],
      tearoaf_checkbox2: [false],
      tearoaf_checkbox3: [false],
      tearoaf_checkbox4: [false],
      tearoaf_checkbox5: [false],
      tearoaf_checkbox6: [false],
      tearoaf_checkbox7: [false],
      tearoaf_checkbox8: [false],
      tearoaf_checkbox9: [false],
      
      parent_agreement_signature_image: ['', [Validators.required]],
      parent_agreement_date: ['', [Validators.required]],
      is_allergies_for_sunscreen: [false,],
      is_allergies_for_some_sunscreen: [false,],
      is_agree_sunscreen_agreement: [false, [Validators.required]],
      sunscreen_brands: ['',],
      parent_guardian_agreement_full_name: ['',],
      prohibited_area_body: ['',],
      is_allow_record_learning_of_child: [false],
      is_allow_record_share_on_socialmedia_child: [false],
      is_agree_video_photo_agreement: [false, [Validators.required]],
      is_agree_transport_agreement: [false, [Validators.required]],
      form_type: ['parent_agreement_details']
    });

    this.HFormGroup6 = this.fb.group({
      input_child_id: ['', [Validators.required]],
      input_medicare: ['', [Validators.required]],
      input_parent_id: ['', [Validators.required]],
      input_immunisation_history: ['', [Validators.required]],
      other_to_provide_details: [''],
    })

    this.HFormGroup7  = this.fb.group({});
  }

  updateForm(event) {
    this.HFormGroup7 = event.form;
  }

  myControl = new FormControl();
  rows: Country[];
  filteredOptions: Observable<Country[]>;
  
  ngOnInit(): void {

    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    console.log(this.loggedUser);
    this.getStates();
    this.getCountries();
  }
  displayFn(user: Country): string {
    return user && user.country_name ? user.country_name : '';
  }

  private _filter(name: string): Country[] {
    const filterValue = name.toLowerCase();
    return this.rows.filter(option => option.country_name.toLowerCase().includes(filterValue));
  }
  getStates() {
    this.accountService.getStates().subscribe((res) => {
      this.states = res['states'];
    }, (error) => {

      this.globalService.showErrorNotification('Something went wrong!');
    }
    );
  }
  hepatitis_b_data = [];
  diphtheria_data = [];
  tetanus_data = [];
  pertusis_data = [];
  polio_data = [];
  hib_data = [];
  pneumococcal_data = [];
  rotavirus_data = [];
  measles_data = [];
  mumps_data = [];
  rubella_data = [];
  meningococcal_c_data = [];
  varicella_data = [];
  hepatitis_a_data = [];
  immunisation_columns = ['hepatitis_b','diphtheria','tetanus','pertusis','polio','hib','pneumococcal','rotavirus','measles','mumps','rubella','meningococcal_c','varicella','hepatitis_a'];
  resetUploadsArray()
  {
    this.docList = [];
    this.docTypeList = [];
    this.docListEdit = [];
    this.deletedDocuments = [];
    this.docList_child_id = [];
    this.docListEdit_child_id = [];
    this.docList_medicare = [];
    this.docListEdit_medicare = [];
    this.docList_parent_id = [];
    this.docListEdit_parent_id = [];
    this.docList_immunisation_history = [];
    this.docListEdit_immunisation_history = [];
    this.docList_other_id = [];
    this.docListEdit_other_id = [];
  }


  getEnrollmentFormData(enrollmentFormRef, edit, mode = true, fromChildrenModuleView = false, fromChildrenModuleEdit = false) {


    this.accessMode = mode;
    if (!this.accessMode) {
      this.editMode = true;
    } else {
      this.editMode = edit;
    }

    if(fromChildrenModuleEdit)
    {
      this.editMode = true;
      this.accessMode = false;
      this.resetUploadsArray();
    }
    if(fromChildrenModuleView)
    {
      this.ViewFromChildrenModule = true;
      this.editMode = false;
      this.resetUploadsArray();
    }
    // if(mode){
    //   this.isEditFormSubmit = edit;
    //   this.isLinear = false;
    //   this.isEditForm = edit;
    // }


    this.spinner.show();
    this.childEnrollmentService.getEnrollmentFormData(enrollmentFormRef).subscribe(res => {
      this.HFormGroup1.reset();
      this.HFormGroup2.reset();
      this.HFormGroup3.reset();
      this.HFormGroup4.reset();
      this.HFormGroup5.reset();
      this.HFormGroup6.reset();
      this.HFormGroup7.reset();
      this.routineSession = res['child_enrollment_form_data'].current_routine_session;
      this.casualSession = res['child_enrollment_form_data'].current_casual_session;
      this.startDate = res['child_enrollment_form_data'].created_at;
      this.wizardStepper.reset();
      if(res['enrolment_form_immunisation_data'])
      {
        if(res['enrolment_form_immunisation_data'].hepatitis_b == null || res['enrolment_form_immunisation_data'].hepatitis_b == '')
          console.log(res['enrolment_form_immunisation_data'].hepatitis_b);
        else
        {
          this.hepatitis_b_data = res['enrolment_form_immunisation_data'].hepatitis_b.split(',');
          this.hepatitis_b_val = this.hepatitis_b_data;
        }

        if(res['enrolment_form_immunisation_data'].diphtheria == null || res['enrolment_form_immunisation_data'].diphtheria == '')
          console.log(res['enrolment_form_immunisation_data'].diphtheria);
        else
        {
          this.diphtheria_data = res['enrolment_form_immunisation_data'].diphtheria.split(',');
          this.diphtheria_val = this.diphtheria_data;
        }

        if(res['enrolment_form_immunisation_data'].tetanus == null || res['enrolment_form_immunisation_data'].tetanus == '')
          console.log(res['enrolment_form_immunisation_data'].tetanus);
        else
        {
          this.tetanus_data = res['enrolment_form_immunisation_data'].tetanus.split(',');
          this.tetanus_val = this.tetanus_data;
        }

        if(res['enrolment_form_immunisation_data'].pertusis == null || res['enrolment_form_immunisation_data'].pertusis == '')
          console.log(res['enrolment_form_immunisation_data'].pertusis);
        else
        {
          this.pertusis_data = res['enrolment_form_immunisation_data'].pertusis.split(',');
          this.pertusis_val = this.pertusis_data;
        }

        if(res['enrolment_form_immunisation_data'].polio == null || res['enrolment_form_immunisation_data'].polio == '')
          console.log(res['enrolment_form_immunisation_data'].polio);
        else
        {
          this.polio_data = res['enrolment_form_immunisation_data'].polio.split(',');
          this.polio_val = this.polio_data;
        }

        if(res['enrolment_form_immunisation_data'].hib == null || res['enrolment_form_immunisation_data'].hib == '')
          console.log(res['enrolment_form_immunisation_data'].hib);
        else
        {
          this.hib_data = res['enrolment_form_immunisation_data'].hib.split(',');
          this.hib_val = this.hib_data;
        }

        if(res['enrolment_form_immunisation_data'].pneumococcal == null || res['enrolment_form_immunisation_data'].pneumococcal == '')
          console.log(res['enrolment_form_immunisation_data'].pneumococcal);
        else
        {
          this.pneumococcal_data = res['enrolment_form_immunisation_data'].pneumococcal.split(',');
          this.pneumococcal_val = this.pneumococcal_data;
        }
        if(res['enrolment_form_immunisation_data'].rotavirus == null || res['enrolment_form_immunisation_data'].rotavirus == '')
          console.log(res['enrolment_form_immunisation_data'].rotavirus);
        else
        {
          this.rotavirus_data = res['enrolment_form_immunisation_data'].rotavirus.split(',');
          this.rotavirus_val = this.rotavirus_data;
        }
        if(res['enrolment_form_immunisation_data'].measles == null || res['enrolment_form_immunisation_data'].measles == '')
          console.log(res['enrolment_form_immunisation_data'].measles);
        else
        {
          this.measles_data = res['enrolment_form_immunisation_data'].measles.split(',');
          this.measles_val = this.measles_data;
        }
        if(res['enrolment_form_immunisation_data'].mumps == null || res['enrolment_form_immunisation_data'].mumps == '')
          console.log(res['enrolment_form_immunisation_data'].mumps);
        else
        {
          this.mumps_data = res['enrolment_form_immunisation_data'].mumps.split(',');
          this.mumps_val = this.mumps_data;
        }
        if(res['enrolment_form_immunisation_data'].rubella == null || res['enrolment_form_immunisation_data'].rubella == '')
          console.log(res['enrolment_form_immunisation_data'].rubella);
        else
        {
          this.rubella_data = res['enrolment_form_immunisation_data'].rubella.split(',');
          this.rubella_val = this.rubella_data;
        }
        if(res['enrolment_form_immunisation_data'].meningococcal_c == null || res['enrolment_form_immunisation_data'].meningococcal_c == '')
          console.log(res['enrolment_form_immunisation_data'].meningococcal_c);
        else
        {
          this.meningococcal_c_data = res['enrolment_form_immunisation_data'].meningococcal_c.split(',');
          this.meningococcal_c_val = this.meningococcal_c_data;
        }
        if(res['enrolment_form_immunisation_data'].varicella == null || res['enrolment_form_immunisation_data'].varicella == '')
          console.log(res['enrolment_form_immunisation_data'].varicella);
        else
        {
          this.varicella_data = res['enrolment_form_immunisation_data'].varicella.split(',');
          this.varicella_val = this.varicella_data;
        }
        if(res['enrolment_form_immunisation_data'].hepatitis_a == null || res['enrolment_form_immunisation_data'].hepatitis_a == '')
          console.log(res['enrolment_form_immunisation_data'].hepatitis_a);
        else
        {
          this.hepatitis_a_data = res['enrolment_form_immunisation_data'].hepatitis_a.split(',');
          this.hepatitis_a_val = this.hepatitis_a_data;
        }
      }
      

      var parent1_occupation_array = (res['child_enrollment_form_data'].parent1_occupation == null) ? [] : res['child_enrollment_form_data'].parent1_occupation.split(',');;
      var parent1_work_address_array = (res['child_enrollment_form_data'].parent1_work_address == null) ? [] : res['child_enrollment_form_data'].parent1_work_address.split(',');;
      var parent1_work_phone_no_array = (res['child_enrollment_form_data'].parent1_work_phone_no == null) ? [] : res['child_enrollment_form_data'].parent1_work_phone_no.split(',');;
     
      if(parent1_occupation_array.length > 0)
      {
        for (let i = 0; i < parent1_occupation_array.length; i++) 
          this.dataSource.push([i + 1, parent1_occupation_array[i],  parent1_work_address_array[i],  parent1_work_phone_no_array[i]]);
      }

      var parent2_occupation_array = (res['child_enrollment_form_data'].parent2_occupation == null) ? [] : res['child_enrollment_form_data'].parent2_occupation.split(',');;
      var parent2_work_address_array = (res['child_enrollment_form_data'].parent2_work_address == null) ? [] : res['child_enrollment_form_data'].parent2_work_address.split(',');;
      var parent2_work_phone_no_array = (res['child_enrollment_form_data'].parent2_work_phone_no == null) ? [] : res['child_enrollment_form_data'].parent2_work_phone_no.split(',');;

      if(parent2_occupation_array.length > 0)
      {
        for (let i = 0; i < parent2_occupation_array.length; i++) 
          this.dataSource2.push([i + 1, parent2_occupation_array[i],  parent2_work_address_array[i],  parent2_work_phone_no_array[i]]);
      }
      if(res['child_enrollment_form_data'].parent2_relationship == 'Other')
      {
        this.HFormGroup2.get('parent2_other').setValidators(Validators.required);
        this.HFormGroup2.get('parent2_other').updateValueAndValidity();
      }
      else
      {
        this.HFormGroup2.get('parent2_other').reset();
        this.HFormGroup2.get('parent2_other').clearValidators();
        this.HFormGroup2.get('parent2_other').updateValueAndValidity();
        this.HFormGroup2.get('parent2_relationship').setValue( "Mother");
      }
      this.child_name = res['child_enrollment_form_data'].child_givenname + ' ' + res['child_enrollment_form_data'].child_surname;

      if(res['child_enrollment_form_data'].parent2_name)
        this.disabledPG2 = true;
      this.childEnrollmentFormRef = res['child_enrollment_form_data'].enrolment_form_ref;
      this.EnrollmentFormData = res['child_enrollment_form_data'];
      if(res['child_enrollment_form_data'].country_name)
      {
        this.myControl.setValue({ country_name: res['child_enrollment_form_data'].country_name.toString() });
        this.blurCheckValidCountry();
      }
      this.HFormGroup1.patchValue({
        child_surname: res['child_enrollment_form_data'].child_surname,
        child_givenname: res['child_enrollment_form_data'].child_givenname,
        child_dob: this.globalService.formatDatetoObject(res['child_enrollment_form_data'].child_dob != null ? new Date(res['child_enrollment_form_data'].child_dob) : null),
        child_crn: res['child_enrollment_form_data'].child_crn,
        child_address: res['child_enrollment_form_data'].child_address,
        child_suburb: res['child_enrollment_form_data'].child_suburb,
        child_state: res['child_enrollment_form_data'].child_state != null ? res['child_enrollment_form_data'].child_state.toString() : null,
        child_postal_code: res['child_enrollment_form_data'].child_postal_code,
        child_country_birth: res['child_enrollment_form_data'].child_country_birth.toString(),
        child_gender: res['child_enrollment_form_data'].child_gender.toString(),
        is_other_name: res['child_enrollment_form_data'].is_other_name.toString(),
        child_other_name: res['child_enrollment_form_data'].child_other_name,
        child_cultural_background: res['child_enrollment_form_data'].child_cultural_background,
        aboriginal_strait_islander: res['child_enrollment_form_data'].aboriginal_strait_islander.toString(),
        child_language_spoken: res['child_enrollment_form_data'].child_language_spoken,
        child_school: res['child_enrollment_form_data'].child_school,
        child_year_level: res['child_enrollment_form_data'].child_year_level,
        child_fears: res['child_enrollment_form_data'].child_fears.toString(),
        child_fears_details: res['child_enrollment_form_data'].child_fears_details,
        child_interest: res['child_enrollment_form_data'].child_interest,
        enrolment_form_ref: res['child_enrollment_form_data'].enrolment_form_ref,
        form_type: 'child_details'
      });
      this.AnyNameForChild(this.HFormGroup1.value.is_other_name, true);
      this.AnyFearsForChild(this.HFormGroup1.value.child_fears, true);

      this.HFormGroup2.patchValue({
        parent1_name: res['child_enrollment_form_data'].parent1_name,
        parent1_first_name: res['child_enrollment_form_data'].parent1_first_name,
        parent1_last_name: res['child_enrollment_form_data'].parent1_last_name,
        parent1_user_id: res['child_enrollment_form_data'].parent1_user_id,
        parent1_dob: this.globalService.formatDatetoObject(res['child_enrollment_form_data'].parent1_dob != null ? new Date(res['child_enrollment_form_data'].parent1_dob) : null),
        parent1_relationship: res['child_enrollment_form_data'].parent1_relationship,
        parent1_other: res['child_enrollment_form_data'].parent1_other,
        parent1_crn: res['child_enrollment_form_data'].parent1_crn,
        parent1_contact_no: res['child_enrollment_form_data'].parent1_contact_no,
        parent1_work_phone: res['child_enrollment_form_data'].parent1_work_phone,
        parent1_gender: res['child_enrollment_form_data'].parent1_gender,
        parent1_email: res['child_enrollment_form_data'].parent1_email,
        parent1_address: res['child_enrollment_form_data'].parent1_address,
        parent1_suburb: res['child_enrollment_form_data'].parent1_suburb,
        parent1_state: res['child_enrollment_form_data'].parent1_state != null ? res['child_enrollment_form_data'].parent1_state.toString() : null,
        parent1_postal_code: res['child_enrollment_form_data'].parent1_postal_code,
        parent1_is_working: res['child_enrollment_form_data'].parent1_is_working.toString(),
        parent1_occupation: res['child_enrollment_form_data'].parent1_occupation,
        parent1_work_address: res['child_enrollment_form_data'].parent1_work_address,
        parent1_work_phone_no: res['child_enrollment_form_data'].parent1_work_phone_no,
        
        parent1_cultural_background: res['child_enrollment_form_data'].parent1_cultural_background,
        parent1_aboriginal_strait_islander: res['child_enrollment_form_data'].parent1_aboriginal_strait_islander  != null ? res['child_enrollment_form_data'].parent1_aboriginal_strait_islander.toString() : null,

        parent1_language_spoken: res['child_enrollment_form_data'].parent1_language_spoken,

        
        
        
        parent2_name: res['child_enrollment_form_data'].parent2_name,
        parent2_first_name: res['child_enrollment_form_data'].parent2_first_name,
        parent2_last_name: res['child_enrollment_form_data'].parent2_last_name,
        parent2_user_id: res['child_enrollment_form_data'].parent2_user_id,
        parent2_dob: this.globalService.formatDatetoObject(res['child_enrollment_form_data'].parent2_dob != null ? new Date(res['child_enrollment_form_data'].parent2_dob) : null),
        parent2_relationship: res['child_enrollment_form_data'].parent2_relationship,
        parent2_other: res['child_enrollment_form_data'].parent2_other,
        
        parent2_crn: res['child_enrollment_form_data'].parent2_crn != null ? res['child_enrollment_form_data'].parent2_crn : '',
        parent2_contact_no: res['child_enrollment_form_data'].parent2_contact_no,
        parent2_work_phone: res['child_enrollment_form_data'].parent2_work_phone,
        parent2_gender: res['child_enrollment_form_data'].parent2_gender,

        parent2_email: res['child_enrollment_form_data'].parent2_email,
        parent2_address: res['child_enrollment_form_data'].parent2_address,
        parent2_suburb: res['child_enrollment_form_data'].parent2_suburb,
        parent2_state: res['child_enrollment_form_data'].parent2_state != null ? res['child_enrollment_form_data'].parent2_state.toString() : null,
        parent2_postal_code: res['child_enrollment_form_data'].parent2_postal_code,
       
        parent2_cultural_background: res['child_enrollment_form_data'].parent2_cultural_background,
        parent2_aboriginal_strait_islander: res['child_enrollment_form_data'].parent2_aboriginal_strait_islander   != null ? res['child_enrollment_form_data'].parent2_aboriginal_strait_islander.toString() : null,

        parent2_language_spoken: res['child_enrollment_form_data'].parent2_language_spoken,
       
       
        parent2_is_working: res['child_enrollment_form_data'].parent2_is_working ? res['child_enrollment_form_data'].parent2_is_working.toString() : '0',
        parent2_occupation: res['child_enrollment_form_data'].parent2_occupation,
        parent2_work_address: res['child_enrollment_form_data'].parent2_work_address,
        parent2_work_phone_no: res['child_enrollment_form_data'].parent2_work_phone_no,

        contact_child: res['child_enrollment_form_data'].contact_child.toString(),
        contact_child_details: res['child_enrollment_form_data'].contact_child_details,

        form_type: 'parent_details'
      });
      this.ParentOneDoing(this.HFormGroup2.value.parent1_is_working);
      this.ParentTwoDoing(this.HFormGroup2.value.parent2_is_working);
      this.haveProhibitedContact(this.HFormGroup2.value.contact_child);
      this.ShowP1relation = false;
      if (res['child_enrollment_form_data'].parent1_relationship == 'Other') {
        this.ShowP1relation = true;
      }

      this.ShowP2relation = false;
      if (res['child_enrollment_form_data'].parent2_relationship == 'Other') {
        this.ShowP2relation = true;
      }
      this.HFormGroup3.patchValue({
        emergency1_last_name: res['child_enrollment_form_data'].emergency1_last_name,
        emergency1_first_name: res['child_enrollment_form_data'].emergency1_first_name,
        emergency1_relationship: res['child_enrollment_form_data'].emergency1_relationship,
        emergency1_other: res['child_enrollment_form_data'].emergency1_other,
        emergency1_home_phone: res['child_enrollment_form_data'].emergency1_home_phone,
        emergency1_work_phone: res['child_enrollment_form_data'].emergency1_work_phone,
        emergency1_mobile: res['child_enrollment_form_data'].emergency1_mobile,
        emergency1_address: res['child_enrollment_form_data'].emergency1_address,
        emergency1_is_collect_your_child: res['child_enrollment_form_data'].emergency1_is_collect_your_child,
        emergency1_is_excursion_out_of_service: res['child_enrollment_form_data'].emergency1_is_excursion_out_of_service,
        emergency1_is_medical_treatment: res['child_enrollment_form_data'].emergency1_is_medical_treatment,
        emergency1_is_permit_transportation: res['child_enrollment_form_data'].emergency1_is_permit_transportation,
        emergency1_is_request_give_medication: res['child_enrollment_form_data'].emergency1_is_request_give_medication,
        emergency1_is_notified: res['child_enrollment_form_data'].emergency1_is_notified,
        emergency1_is_collecting_data: res['child_enrollment_form_data'].emergency1_is_collecting_data,
        emergency1_authorise_take_child_outside: res['child_enrollment_form_data'].emergency1_authorise_take_child_outside,
        emergency1_authorise_child_transportation: res['child_enrollment_form_data'].emergency1_authorise_child_transportation,

        emergency2_last_name: res['child_enrollment_form_data'].emergency2_last_name,
        emergency2_first_name: res['child_enrollment_form_data'].emergency2_first_name,
        emergency2_relationship: res['child_enrollment_form_data'].emergency2_relationship,
        emergency2_other: res['child_enrollment_form_data'].emergency2_other,
        emergency2_home_phone: res['child_enrollment_form_data'].emergency2_home_phone,
        emergency2_work_phone: res['child_enrollment_form_data'].emergency2_work_phone,
        emergency2_mobile: res['child_enrollment_form_data'].emergency2_mobile,
        emergency2_address: res['child_enrollment_form_data'].emergency2_address,
        custody_details: res['child_enrollment_form_data'].custody_details,
        custody_notes: res['child_enrollment_form_data'].custody_notes,

        emergency2_is_collect_your_child: res['child_enrollment_form_data'].emergency2_is_collect_your_child,
        emergency2_is_excursion_out_of_service: res['child_enrollment_form_data'].emergency2_is_excursion_out_of_service,
        emergency2_is_medical_treatment: res['child_enrollment_form_data'].emergency2_is_medical_treatment,
        emergency2_is_permit_transportation: res['child_enrollment_form_data'].emergency2_is_permit_transportation,
        emergency2_is_request_give_medication: res['child_enrollment_form_data'].emergency2_is_request_give_medication,
        emergency2_is_notified: res['child_enrollment_form_data'].emergency2_is_notified,
        emergency2_is_collecting_data: res['child_enrollment_form_data'].emergency2_is_collecting_data,
        emergency2_authorise_take_child_outside: res['child_enrollment_form_data'].emergency2_authorise_take_child_outside,
        emergency2_authorise_child_transportation: res['child_enrollment_form_data'].emergency2_authorise_child_transportation,
        is_collect_child: res['child_enrollment_form_data'].is_collect_child.toString(),
        n_b_collect_data: res['child_enrollment_form_data'].n_b_collect_data.toString(),

        form_type: 'emergency_contact_details'
      });

      this.ShowE1relation = false;
      if (res['child_enrollment_form_data'].emergency1_relationship == 'Other') {
        this.ShowE1relation = true;
      }
      this.ShowE2relation = false;
      if (res['child_enrollment_form_data'].emergency2_relationship == 'Other') {
        this.ShowE2relation = true;
      }
      this.HFormGroup4.patchValue({
        immunisation_supervisor_name: res['child_enrollment_form_data'].immunisation_supervisor_name,
        immunisation_signed: (!!+res['child_enrollment_form_data'].immunisation_signed),
        immunisation_date: this.globalService.formatDatetoObject(res['child_enrollment_form_data'].immunisation_date != null ? new Date(res['child_enrollment_form_data'].immunisation_date) : null),

        child_doctor_name: res['child_enrollment_form_data'].child_doctor_name,
        child_doctor_phone: res['child_enrollment_form_data'].child_doctor_phone,
        child_doctor_address: res['child_enrollment_form_data'].child_doctor_address,
        child_dentist_name: res['child_enrollment_form_data'].child_dentist_name,
        child_dentist_phone: res['child_enrollment_form_data'].child_dentist_phone,
        child_dentist_address: res['child_enrollment_form_data'].child_dentist_address,
        health_fund_name: res['child_enrollment_form_data'].health_fund_name,
        health_fund_no: res['child_enrollment_form_data'].health_fund_no,
        medicare_no: res['child_enrollment_form_data'].medicare_no,

        is_add_adhd: res['child_enrollment_form_data'].is_add_adhd,
        is_eilepsy: res['child_enrollment_form_data'].is_eilepsy,
        is_physical_need: res['child_enrollment_form_data'].is_physical_need,
        is_haemophila: res['child_enrollment_form_data'].is_haemophila,
        is_asthma: res['child_enrollment_form_data'].is_asthma,
        is_diabetes: res['child_enrollment_form_data'].is_diabetes,
        is_heart_problem: res['child_enrollment_form_data'].is_heart_problem,
        is_anaphylaxis: res['child_enrollment_form_data'].is_anaphylaxis,
        is_behavioural_needs: res['child_enrollment_form_data'].is_behavioural_needs,
        is_other: res['child_enrollment_form_data'].is_other,
        is_none: res['child_enrollment_form_data'].is_none,

        other_problems: res['child_enrollment_form_data'].other_problems,

        is_allegies: res['child_enrollment_form_data'].is_allegies.toString(),
        allergies_details: res['child_enrollment_form_data'].allergies_details,
        allergies_documents: null,
        is_regular_medication: res['child_enrollment_form_data'].is_regular_medication.toString(),
        regular_medication_details: res['child_enrollment_form_data'].regular_medication_details,
        regular_medication_documents: null,
        is_special_dietary: res['child_enrollment_form_data'].is_special_dietary.toString(),
        special_dietary_details: res['child_enrollment_form_data'].special_dietary_details,
        special_dietary_documents: null,
        is_nappies: res['child_enrollment_form_data'].is_nappies,
        is_toilet_traning: res['child_enrollment_form_data'].is_toilet_traning,
        is_pull_ups: res['child_enrollment_form_data'].is_pull_ups,
        is_fully_toilet: res['child_enrollment_form_data'].is_fully_toilet,
        other_details: res['child_enrollment_form_data'].other_details,
        is_rest_during_day: res['child_enrollment_form_data'].is_rest_during_day.toString(),
        daily_route: res['child_enrollment_form_data'].daily_route,
        not_immunized: (!!+res['child_enrollment_form_data'].not_immunized),
        select_all_chx_box: res['child_enrollment_form_data'].select_all_chx_box,
        form_type: 'medical_details'
      });
      this.OtherHealthProblem = [];
      this.OtherHealthProblem.push(
        res['child_enrollment_form_data'].is_add_adhd,
        res['child_enrollment_form_data'].is_eilepsy,
        res['child_enrollment_form_data'].is_physical_need,
        res['child_enrollment_form_data'].is_haemophila,
        res['child_enrollment_form_data'].is_asthma,
        res['child_enrollment_form_data'].is_diabetes,
        res['child_enrollment_form_data'].is_heart_problem,
        res['child_enrollment_form_data'].is_anaphylaxis,
        res['child_enrollment_form_data'].is_behavioural_needs,
        res['child_enrollment_form_data'].is_other,
      );

      let other = this.OtherHealthProblem.filter(
        item => item == 1);
      this.showOtherHealthProblem = false;
      if (other[0]) {
        this.showOtherHealthProblem = true;
      }
      this.checkBoxInfection('test');
      this.checkBoxToileting('test');
      
      if (res['child_enrollment_form_data'].is_none == 1) {
        this.showOtherHealthProblem = false;
      }
      this.other_documents = res['other_documents'];
      this.allergies_documents = res['allergies_documents'];
      this.regular_medicine_documents = res['regular_medicine_documents'];
      this.special_dietary_documents = res['special_dietary_documents'];
      this.custody_documents = res['custody_documents'];
      this.suncream_documents = res['suncream_documents'];

      this.childHaveAllergies(this.HFormGroup4.value.is_allegies, true);
      this.childRegularMedicine(this.HFormGroup4.value.is_regular_medication, true);
      this.childSpecialDietary(this.HFormGroup4.value.is_special_dietary, true);
      this.childRestDuringDay(this.HFormGroup4.value.is_rest_during_day, true);
      this.childCustody(this.HFormGroup3.value.is_collect_child, true);

      if (res['child_enrollment_form_data'].immunisation_signed) {
        this.isNewSignature2 = false;
        this.signatureImg2 = res['child_enrollment_form_data'].immunisation_signed;
      }
      this.HFormGroup5.patchValue({
        // family_educator_name: res['child_enrollment_form_data'].family_educator_name,
        // family_educator_contact: res['child_enrollment_form_data'].family_educator_contact,
        // family_educator_address: res['child_enrollment_form_data'].family_educator_address,
        // parent_name: res['child_enrollment_form_data'].parent_name,
        is_agree_parent_agreement: (!!+res['child_enrollment_form_data'].is_agree_parent_agreement),
        hamc_checkbox1: (!!+res['child_enrollment_form_data'].hamc_checkbox1),
        hamc_checkbox2: (!!+res['child_enrollment_form_data'].hamc_checkbox2),
        hamc_checkbox3: (!!+res['child_enrollment_form_data'].hamc_checkbox3),
        hamc_checkbox4: (!!+res['child_enrollment_form_data'].hamc_checkbox4),
        hamc_checkbox5: (!!+res['child_enrollment_form_data'].hamc_checkbox5),
        hamc_checkbox6: (!!+res['child_enrollment_form_data'].hamc_checkbox6),
        tearoaf_checkbox1: (!!+res['child_enrollment_form_data'].tearoaf_checkbox1),
        tearoaf_checkbox2: (!!+res['child_enrollment_form_data'].tearoaf_checkbox2),
        tearoaf_checkbox3: (!!+res['child_enrollment_form_data'].tearoaf_checkbox3),
        tearoaf_checkbox4: (!!+res['child_enrollment_form_data'].tearoaf_checkbox4),
        tearoaf_checkbox5: (!!+res['child_enrollment_form_data'].tearoaf_checkbox5),
        tearoaf_checkbox6: (!!+res['child_enrollment_form_data'].tearoaf_checkbox6),
        tearoaf_checkbox7: (!!+res['child_enrollment_form_data'].tearoaf_checkbox7),
        tearoaf_checkbox8: (!!+res['child_enrollment_form_data'].tearoaf_checkbox8),
        tearoaf_checkbox9: (!!+res['child_enrollment_form_data'].tearoaf_checkbox9),
        parent_agreement_signature_image: res['child_enrollment_form_data'].parent_agreement_signature_image,
        parent_agreement_date: this.globalService.formatDatetoObject(res['child_enrollment_form_data'].parent_agreement_date != null ? new Date(res['child_enrollment_form_data'].parent_agreement_date) : new Date().toISOString()),
        parent_guardian_agreement_full_name: res['child_enrollment_form_data'].parent_guardian_agreement_full_name,
        is_allergies_for_sunscreen: res['child_enrollment_form_data'].is_allergies_for_sunscreen,
        is_allergies_for_some_sunscreen: res['child_enrollment_form_data'].is_allergies_for_some_sunscreen,
        sunscreen_brands: res['child_enrollment_form_data'].sunscreen_brands,
        prohibited_area_body: res['child_enrollment_form_data'].prohibited_area_body,
        is_agree_sunscreen_agreement: (!!+res['child_enrollment_form_data'].is_agree_sunscreen_agreement),
        is_allow_record_learning_of_child: res['child_enrollment_form_data'].is_allow_record_learning_of_child,
        is_allow_record_share_on_socialmedia_child: res['child_enrollment_form_data'].is_allow_record_share_on_socialmedia_child,
        is_agree_video_photo_agreement: (!!+res['child_enrollment_form_data'].is_agree_video_photo_agreement),
        is_agree_transport_agreement: (!!+res['child_enrollment_form_data'].is_agree_transport_agreement),
        form_type: 'parent_agreement_details'

      });
      this.is_suncream_alergies = false;
      if (res['child_enrollment_form_data'].is_allergies_for_some_sunscreen == 1) {
        this.is_suncream_alergies = true;
      }
      if (res['child_enrollment_form_data'].parent_agreement_signature_image) {
        this.isNewSignature = false;
        this.signatureImage = res['child_enrollment_form_data'].parent_agreement_signature_image;
      }

      this.docListEdit = res['doc_list'];
      this.docListEdit.forEach(element => {
        if(element.form_document_type_id == 4)
          this.docListEdit_child_id.push(element)
        if(element.form_document_type_id == 5)
          this.docListEdit_medicare.push(element)
        if(element.form_document_type_id == 6)
          this.docListEdit_parent_id.push(element)
        if(element.form_document_type_id == 30)
          this.docListEdit_immunisation_history.push(element)
        if(element.form_document_type_id == 29)
          this.docListEdit_other_id.push(element)
      });
      if(this.docListEdit_other_id.length > 0)
      {
        this.addtionalUploadDocuments = true;
        this.HFormGroup6.get( 'other_to_provide_details' ).setValue( res['child_enrollment_form_data'].other_to_provide_details );
      }
      if(this.docListEdit_child_id.length > 0 )
        this.HFormGroup6.get( 'input_child_id' ).setValue( 'valid' );
      if(this.docListEdit_medicare.length > 0 )
        this.HFormGroup6.get( 'input_medicare' ).setValue( 'valid' );
      if(this.docListEdit_parent_id.length > 0 )
        this.HFormGroup6.get( 'input_parent_id' ).setValue( 'valid' );
      if(this.docListEdit_immunisation_history.length > 0 )
        this.HFormGroup6.get( 'input_immunisation_history' ).setValue( 'valid' );
      if (!this.editMode) {
        this.HFormGroup6.get('input_child_id').clearValidators();
        this.HFormGroup6.get('input_medicare').clearValidators();
        this.HFormGroup6.get('input_parent_id').clearValidators();
        this.HFormGroup6.get('input_immunisation_history').clearValidators();
        this.HFormGroup6.get('input_child_id').updateValueAndValidity();
        this.HFormGroup6.get('input_medicare').updateValueAndValidity();
        this.HFormGroup6.get('input_parent_id').updateValueAndValidity();
        this.HFormGroup6.get('input_immunisation_history').updateValueAndValidity();
        this.HFormGroup1.disable();
        this.HFormGroup2.disable();
        this.HFormGroup3.disable();
        this.HFormGroup4.disable();
        this.HFormGroup5.disable();
        this.HFormGroup7.disable();
        this.disableSchedule = true;
        // this.HFormGroup6.disable();
        this.myControl.disable();

      }
      else
      {
        this.HFormGroup6.get('input_child_id').setValidators(Validators.required);
        this.HFormGroup6.get('input_medicare').setValidators(Validators.required);
        this.HFormGroup6.get('input_parent_id').setValidators(Validators.required);
        this.HFormGroup6.get('input_immunisation_history').setValidators(Validators.required);
        this.HFormGroup6.get('input_child_id').updateValueAndValidity();
        this.HFormGroup6.get('input_medicare').updateValueAndValidity();
        this.HFormGroup6.get('input_parent_id').updateValueAndValidity();
        this.HFormGroup6.get('input_immunisation_history').updateValueAndValidity();
        this.disableSchedule = false;
      }
      
 
      this.checkIfAllSicknessChecked();
      this.spinner.hide();
    }, error => {
      this.globalService.showErrorNotification(error.error.message);
      this.spinner.hide();
    });
  }
  customValidateCrn2 = [Validators.required, customValidateCrn()];
  parent2_relationship_change(event)
  {
    console.log(event.value);
    if(event.value == 'Other')
    {
      this.ShowP2relation = true;
      this.HFormGroup2.get('parent2_other').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_other').updateValueAndValidity();
    }
    else
    {
      this.ShowP2relation = false;
      this.HFormGroup2.get('parent2_other').reset();
      this.HFormGroup2.get('parent2_other').clearValidators();
      this.HFormGroup2.get('parent2_other').updateValueAndValidity();
    }
  }
  confirmAssign(event: MatCheckboxChange) {
    console.log(this.disabledPG2);

    if(this.disabledPG2)
    {
      // this.HFormGroup2.get('parent2_name').reset();
      this.HFormGroup2.get('parent2_first_name').reset();
      this.HFormGroup2.get('parent2_last_name').reset();
      this.HFormGroup2.get('parent2_dob').reset();
      this.HFormGroup2.get('parent2_relationship').setValue( "Mother");
      this.ShowP2relation = false;
      this.HFormGroup2.get('parent2_other').reset();
      this.HFormGroup2.get('parent2_other').clearValidators();
      this.HFormGroup2.get('parent2_other').updateValueAndValidity();
      this.HFormGroup2.get('parent2_crn').reset();
      this.HFormGroup2.get('parent2_contact_no').reset();
      this.HFormGroup2.get('parent2_gender').reset();
      this.HFormGroup2.get('parent2_email').reset();
      this.HFormGroup2.get('parent2_address').reset();
      this.HFormGroup2.get('parent2_suburb').reset();
      this.HFormGroup2.get('parent2_state').reset();
      this.HFormGroup2.get('parent2_postal_code').reset();
      this.HFormGroup2.get('parent2_cultural_background').reset();
      this.HFormGroup2.get('parent2_language_spoken').reset();

      this.HFormGroup2.get('parent2_occupation').reset();
      this.HFormGroup2.get('parent2_work_address').reset();
      this.HFormGroup2.get('parent2_work_phone_no').reset();
      this.dataSource2 = [];
      this.HFormGroup2.get( 'parent2_is_working' ).setValue( "0" );

      this.hideParent2occupation = true;

      
      this.ParentTwoDoing("0");

      // this.HFormGroup2.get('parent2_name').clearValidators();
      this.HFormGroup2.get('parent2_first_name').clearValidators();
      this.HFormGroup2.get('parent2_last_name').clearValidators();
      this.HFormGroup2.get('parent2_dob').clearValidators();
      this.HFormGroup2.get('parent2_other').clearValidators();
      this.HFormGroup2.get('parent2_crn').clearValidators();
      this.HFormGroup2.get('parent2_contact_no').clearValidators();
      this.HFormGroup2.get('parent2_gender').clearValidators();
      this.HFormGroup2.get('parent2_email').clearValidators();
      this.HFormGroup2.get('parent2_address').clearValidators();
      this.HFormGroup2.get('parent2_suburb').clearValidators();
      this.HFormGroup2.get('parent2_state').clearValidators();
      this.HFormGroup2.get('parent2_postal_code').clearValidators();
      this.HFormGroup2.get('parent2_cultural_background').clearValidators();
      this.HFormGroup2.get('parent2_language_spoken').clearValidators();

      // this.HFormGroup2.get('parent2_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_first_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_last_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_dob').updateValueAndValidity();
      this.HFormGroup2.get('parent2_crn').updateValueAndValidity();
      this.HFormGroup2.get('parent2_contact_no').updateValueAndValidity();
      this.HFormGroup2.get('parent2_gender').updateValueAndValidity();
      this.HFormGroup2.get('parent2_email').updateValueAndValidity();
      this.HFormGroup2.get('parent2_address').updateValueAndValidity();
      this.HFormGroup2.get('parent2_suburb').updateValueAndValidity();
      this.HFormGroup2.get('parent2_state').updateValueAndValidity();
      this.HFormGroup2.get('parent2_postal_code').updateValueAndValidity();
      this.HFormGroup2.get('parent2_cultural_background').updateValueAndValidity();
      this.HFormGroup2.get('parent2_language_spoken').updateValueAndValidity();
    }
    else
    {
      // this.HFormGroup2.get('parent2_name').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_first_name').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_last_name').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_dob').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_contact_no').setValidators([Validators.required, Validators.pattern('[- +()0-9]+')]);
      this.HFormGroup2.get('parent2_crn').setValidators(this.customValidateCrn2);

      this.HFormGroup2.get('parent2_email').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_address').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_suburb').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_postal_code').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_cultural_background').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_language_spoken').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_state').setValidators(Validators.required);
      this.HFormGroup2.get('parent2_relationship').setValue( "Mother");
      this.HFormGroup2.get('parent2_relationship').setValidators(Validators.required);


      // this.HFormGroup2.get('parent2_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_first_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_last_name').updateValueAndValidity();
      this.HFormGroup2.get('parent2_dob').updateValueAndValidity();
      this.HFormGroup2.get('parent2_contact_no').updateValueAndValidity();
      // this.HFormGroup2.get('parent2_gender').updateValueAndValidity();
      this.HFormGroup2.get('parent2_email').updateValueAndValidity();
      this.HFormGroup2.get('parent2_address').updateValueAndValidity();
      this.HFormGroup2.get('parent2_suburb').updateValueAndValidity();
      this.HFormGroup2.get('parent2_postal_code').updateValueAndValidity();
      this.HFormGroup2.get('parent2_cultural_background').updateValueAndValidity();
      this.HFormGroup2.get('parent2_language_spoken').updateValueAndValidity();
      this.HFormGroup2.get('parent2_state').updateValueAndValidity();
      this.HFormGroup2.get('parent2_crn').updateValueAndValidity();

    }
  }
  onSubmit(formType = null) {

    if (this.editMode) 
      this.onSubmitEnrollment(formType);

    // if(this.isEditFormSubmit && this.isEditForm){
    //   this.onSubmitEnrollment(formType);
    // }else if(!this.isEditFormSubmit && !this.isEditForm){
    //   this.onSubmitEnrollment(formType);
    // }
  }
  onSelectionChangeCountry(event){
    console.log('onSelectionChange called', event.option.value.country_id);
    this.HFormGroup1.get('child_country_birth').setValue( event.option.value.country_id.toString());
  }
  onBlurCountryCBO() {
    setTimeout(() => {
        this.blurCheckValidCountry()
    }, 200);
  }
  blurCheckValidCountry()
  {
    if(this.myControl.value.country_id == undefined)
    {
      var cname = this.myControl.value.country_name;
      if(cname == undefined)
        cname = this.myControl.value;
      var data = this.rows.filter(function (Country) { return Country.country_name == cname.toUpperCase() });
      if(data[0] == undefined)
      {
        this.HFormGroup1.get('child_country_birth').setValue("");
        this.myControl.setValue("");
      }
      else
      {
        this.myControl.setValue(data[0]);
        this.HFormGroup1.get('child_country_birth').setValue( data[0].country_id.toString());

      }
    }
  }
  async onSubmitEnrollment(formType) {
    if (formType == 'child_details') {

      if (!this.HFormGroup1.valid || this.submitted1) {
        this.globalService.scrollToError();
        return;
      }

      this.submitted1 = true;
      this.HFormGroup1.patchValue({
        child_dob: this.globalService.formatDatetoServerSide(this.HFormGroup1.value.child_dob),
      });

      this.HFormGroup1.value.sent_mail_form_random_key = this.SentMailFormRef;
      this.HFormGroup1.value.enrollment_form_ref = this.childEnrollmentFormRef;
      
      await this.webPagesService.childEnrollmentFormSubmit(this.HFormGroup1.value).subscribe(res => {
        this.childEnrollmentFormRef = res['child_enrollment_ref'];
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submitted1 = false;

      }, error => {
        this.submitted1 = false;
        this.globalService.showErrorNotification(error.error.message);
      });
    }
    else if (formType == 'parent_details') {

      if (!this.HFormGroup2.valid || this.submitted2) {
        console.log(this.HFormGroup2);
        this.globalService.scrollToError();
        return;
      }
      this.submitted2 = true;
      let txt_parent1_name = null;
      if(this.HFormGroup2.value.parent1_first_name && this.HFormGroup2.value.parent1_last_name)
        txt_parent1_name = this.HFormGroup2.value.parent1_first_name + " " + this.HFormGroup2.value.parent1_last_name;
      let txt_parent2_name = null;
      if(this.HFormGroup2.value.parent2_first_name && this.HFormGroup2.value.parent2_last_name)
        txt_parent2_name = this.HFormGroup2.value.parent2_first_name + " " + this.HFormGroup2.value.parent2_last_name;
      let txt_parent2_fname = null;
      if(this.HFormGroup2.value.parent2_first_name)
        txt_parent2_fname = this.HFormGroup2.value.parent2_first_name;
      let txt_parent2_lname = null;
      if(this.HFormGroup2.value.parent2_last_name)
        txt_parent2_lname = this.HFormGroup2.value.parent2_last_name;
      let txt_parent2_user_id = null;
      if(this.HFormGroup2.value.parent2_user_id)
        txt_parent2_user_id = this.HFormGroup2.value.parent2_user_id;
      this.HFormGroup2.patchValue({
        parent1_dob: this.globalService.formatDatetoServerSide(this.HFormGroup2.value.parent1_dob),
        parent2_dob: this.globalService.formatDatetoServerSide(this.HFormGroup2.value.parent2_dob),
        parent2_user_id: txt_parent2_user_id,
        parent2_first_name: txt_parent2_fname,
        parent2_last_name: txt_parent2_lname,
        parent1_name: txt_parent1_name,
        parent2_name: txt_parent2_name
      });

      // if(this.disabledPG2)
      // {
      //   this.HFormGroup2.patchValue({
      //     parent1_dob: this.globalService.formatDatetoServerSide(this.HFormGroup2.value.parent1_dob),
      //     parent2_dob: this.globalService.formatDatetoServerSide(this.HFormGroup2.value.parent2_dob),
      //   });
      // }
      // else{
      //   this.HFormGroup2.patchValue({
      //     parent1_dob: this.globalService.formatDatetoServerSide(this.HFormGroup2.value.parent1_dob),
      //   });
      // }

      this.HFormGroup2.value.enrollment_form_ref = this.childEnrollmentFormRef;

      await this.webPagesService.childEnrollmentFormSubmit(this.HFormGroup2.value).subscribe(res => {
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submitted2 = false;
      }, error => {
        this.submitted2 = false;
        this.globalService.showErrorNotification(error.error.message);
      });
    }
    else if (formType == 'emergency_contact') {
      if (!this.HFormGroup3.valid || this.submitted3) {
        this.globalService.scrollToError();
        return;
      }
      this.submitted3 = true;
      this.HFormGroup3.value.enrollment_form_ref = this.childEnrollmentFormRef;
      this.HFormGroupEmergencyContact.delete('custody_files[]');
      this.HFormGroupEmergencyContact.delete('mimeTypes_custody[]');
      for (var i = 0; i < this.custody_documents_preview.length; i++) {

        this.HFormGroupEmergencyContact.append('custody_files[]', this.custody_documents_preview[i]);
        this.HFormGroupEmergencyContact.append('mimeTypes_custody[]', this.custody_documents_preview[i].type);
      }

      this.HFormGroupEmergencyContact.append('deleted_custody_data', JSON.stringify(this.deleted_custody_document));
      this.HFormGroupEmergencyContact.append('emergency_data', JSON.stringify(this.HFormGroup3.value));
      await this.webPagesService.childEnrollmentFormEmergencySubmit(this.HFormGroupEmergencyContact).subscribe(res => {
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submitted3 = false;
      }, error => {

        this.globalService.showErrorNotification(error.error.message);
        this.submitted3 = false;
      });
    } else if (formType == 'schedule') {
      if (!this.HFormGroup7.valid || this.submittedSchedule) {
        this.globalService.scrollToError();
        return;
      }
      this.HFormGroupSchedule = new FormData();
      this.HFormGroup7.patchValue({
        form_type: 'schedule'
      })
      this.submittedSchedule = true;
      this.HFormGroupSchedule.append('enrollment_form_ref', this.childEnrollmentFormRef);
      this.HFormGroupSchedule.append('schedule_data', JSON.stringify(this.HFormGroup7.value));
      await this.webPagesService.childEnrollmentFormScheduleSubmit(this.HFormGroupSchedule).subscribe(res => {
        this.childEnrollmentFormRef = res['child_enrollment_ref'];
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submittedSchedule = false;
      }, error => {

        this.globalService.showErrorNotification(error.error.message);
        this.submittedSchedule = false;
      });
    }
    else if (formType == 'medical_details') {
      this.checkValidImunisation();
      if (!this.HFormGroup4.valid || this.submittedMedical) {
        this.globalService.scrollToError();
        return;
      }

      this.submittedMedical = true;
      this.HFormGroup4.patchValue({
        immunisation_date: this.globalService.formatDatetoServerSide(this.HFormGroup4.value.immunisation_date),
        enrollment_form_ref: this.childEnrollmentFormRef
      });

      this.HFormGroup4.value.enrollment_form_ref = this.childEnrollmentFormRef;

      this.HFormGroupMedical.delete('other_files[]');
      this.HFormGroupMedical.delete('allergies_files[]');
      this.HFormGroupMedical.delete('mimeTypes_allergies[]');
      this.HFormGroupMedical.delete('regular_medicine_files[]');
      this.HFormGroupMedical.delete('mimeTypes_regular_medicine[]');
      this.HFormGroupMedical.delete('special_dietary_files[]');
      this.HFormGroupMedical.delete('mimeTypes_special_dietary[]');

      for (var i = 0; i < this.other_documents_preview.length; i++) {
        this.HFormGroupMedical.append('other_files[]', this.other_documents_preview[i]);
        this.HFormGroupMedical.append('mimeTypes_other[]', this.other_documents_preview[i].type);
      }

      for (var i = 0; i < this.allergies_documents_preview.length; i++) {
        this.HFormGroupMedical.append('allergies_files[]', this.allergies_documents_preview[i]);
        this.HFormGroupMedical.append('mimeTypes_allergies[]', this.allergies_documents_preview[i].type);
      }

      for (var i = 0; i < this.regular_medicine_documents_preview.length; i++) {
        this.HFormGroupMedical.append('regular_medicine_files[]', this.regular_medicine_documents_preview[i]);
        this.HFormGroupMedical.append('mimeTypes_regular_medicine[]', this.regular_medicine_documents_preview[i].type);
      }

      for (var i = 0; i < this.special_dietary_documents_preview.length; i++) {
        this.HFormGroupMedical.append('special_dietary_files[]', this.special_dietary_documents_preview[i]);
        this.HFormGroupMedical.append('mimeTypes_special_dietary[]', this.special_dietary_documents_preview[i].type);
      }
      this.HFormGroupMedical.append('deleted_other_documents', JSON.stringify(this.deleted_other_documents));
      this.HFormGroupMedical.append('deleted_allergies_documents', JSON.stringify(this.deleted_allergies_documents));
      this.HFormGroupMedical.append('deleted_regular_medicine_documents', JSON.stringify(this.deleted_regular_medicine_documents));
      this.HFormGroupMedical.append('deleted_special_dietary_documents', JSON.stringify(this.deleted_special_dietary_documents));
      // this.HFormGroupMedical.append('deleted_medical_data', JSON.stringify(this.deleted_medical_documents));
      delete this.HFormGroup4.value["does_your_child_have_any_of_the_following"];
      delete this.HFormGroup4.value["toileting_requirements"];
      
      this.HFormGroupMedical.append('medical_data', JSON.stringify(this.HFormGroup4.value));
      const sickness = {
        hepatitis_b: this.hepatitis_b_val.join(),
        diphtheria: this.diphtheria_val.join(),
        tetanus: this.tetanus_val.join(),
        pertusis: this.pertusis_val.join(),
        polio: this.polio_val.join(),
        hib: this.hib_val.join(),
        pneumococcal: this.pneumococcal_val.join(),
        rotavirus: this.rotavirus_val.join(),
        measles: this.measles_val.join(),
        mumps: this.mumps_val.join(),
        rubella: this.rubella_val.join(),
        meningococcal_c: this.meningococcal_c_val.join(),
        varicella: this.varicella_val.join(),
        hepatitis_a: this.hepatitis_a_val.join()
      }
      this.HFormGroupMedical.append('sickness', JSON.stringify(sickness));

      await this.webPagesService.childEnrollmentFormMedicalSubmit(this.HFormGroupMedical).subscribe(res => {
        this.other_documents_preview = [];
        this.allergies_documents_preview = [];
        this.regular_medicine_documents_preview = [];
        this.special_dietary_documents_preview = [];

        this.other_documents = res['other_documents'];
        this.allergies_documents = res['allergies_documents'];
        this.regular_medicine_documents = res['regular_medicine_documents'];
        this.special_dietary_documents = res['special_dietary_documents'];
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submittedMedical = false;
      }, error => {
        this.globalService.showErrorNotification(error.error.message);
        this.submittedMedical = false;
      });
    }else if (formType == 'document_uploads') {
      if(this.docList_child_id.length == 0 && this.docListEdit_child_id.length == 0)
      {
        this.globalService.showErrorNotification("Please Upload Child's Identification - Birth Certificate and/or Immigration Card.");
        this.spinner.hide();
        return;
      }
      if(this.docList_medicare.length == 0 && this.docListEdit_medicare.length == 0)
      {
        this.globalService.showErrorNotification("Please Upload Medicare Card and/or Private Health Care Card");
        this.spinner.hide();
        return;
      }
      if(this.docList_parent_id.length == 0 && this.docListEdit_parent_id.length == 0)
      {
        this.globalService.showErrorNotification("Please Upload Parent Identification (Birth Certificate, Current Passport, Drivers Licence).");
        this.spinner.hide();
        return;
      }
      if(this.docList_immunisation_history.length == 0 && this.docListEdit_immunisation_history.length == 0)
      {
        this.globalService.showErrorNotification("Please Upload Child's Immunisation History from Australian Childhood Immunisation Register.");
        this.spinner.hide();
        return;
      }

      this.submitted4 = true;
      this.DocumentUploadForm.delete('files[]');
      this.DocumentUploadForm.delete('mimeTypes_files[]');
      this.DocumentUploadForm.delete('docTypes_files[]');
      this.DocumentUploadForm.delete('enrollment_form_ref');
      this.DocumentUploadForm.delete('form_type');
      this.DocumentUploadForm.delete('deleted_doc');

      for (var i = 0; i < this.docList.length; i++) {
        this.DocumentUploadForm.append('files[]', this.docList[i][0]);
        this.DocumentUploadForm.append('mimeTypes_files[]', this.docList[i][0].type);
        this.DocumentUploadForm.append('docTypes_files[]', this.docTypeList[i]);
      }
      this.DocumentUploadForm.append('enrollment_form_ref', this.childEnrollmentFormRef);
      this.DocumentUploadForm.append('form_type', 'document_uploads');
      this.DocumentUploadForm.append('edit_doc_list', JSON.stringify(this.docListEdit));
      this.DocumentUploadForm.append('deleted_doc', JSON.stringify(this.deletedDocuments));
      this.DocumentUploadForm.append('other_to_provide_details', this.HFormGroup6.value.other_to_provide_details);
      this.DocumentUploadForm.append('addtionalUploadDocuments', this.addtionalUploadDocuments.toString());
      
      // this.HFormGroup3.value.other_to_provide_details = this.childEnrollmentFormRef;

      await this.webPagesService.childEnrollmentFormDocumentSubmit(this.DocumentUploadForm).subscribe(res => {
        this.docList = [];
        this.docTypeList = [];
        this.deletedDocuments = [];
        this.docListEdit = res['doc_list'];
        if(res['message'] != 'No changes')
          this.globalService.showSuccessNotification(res['message']);
        this.submitted4 = false;
      }, error => {

        this.globalService.showErrorNotification(error.error.message);
        this.submitted4 = false;
      });
    }
    else if (formType == 'parent_agreement') {
      this.SubmitHFormGroup5BtnClicked = true;
      if (!this.HFormGroup5.valid || this.submitted5) {
        this.globalService.scrollToError();
        return;
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ',
          cancelButton: 'btn btn-danger m-r-10'
        },
        buttonsStyling: false
      });

      await swalWithBootstrapButtons
        .fire({
          title: 'Are you sure you want to submit this?',
          text: "",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, submit',
          cancelButtonText: 'No, cancel',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) {


            this.submitted5 = true;

            this.HFormGroup5.patchValue({
              parent_agreement_date: this.globalService.formatDatetoServerSide(this.HFormGroup5.value.parent_agreement_date),
            });

            this.HFormGroup5.patchValue({
              sunscreen_agreement_date: this.globalService.formatDatetoServerSide(this.HFormGroup5.value.sunscreen_agreement_date),
            });

            this.HFormGroup5.patchValue({
              takephoto_agreement_date: this.globalService.formatDatetoServerSide(this.HFormGroup5.value.takephoto_agreement_date),
            });
            this.HFormGroup5.patchValue({
              transport_outing_agreement_date: this.globalService.formatDatetoServerSide(this.HFormGroup5.value.transport_outing_agreement_date),
            });
            this.HFormGroup5.value.enrollment_form_ref = this.childEnrollmentFormRef;
            this.HFormGroup5.value.is_edit = this.editMode;


            this.HFormGroupParentAgreement.delete('suncream_files[]');
            this.HFormGroupParentAgreement.delete('mimeTypes_suncream[]');

            for (var i = 0; i < this.suncream_documents_preview.length; i++) {

              this.HFormGroupParentAgreement.append('suncream_files[]', this.suncream_documents_preview[i]);
              this.HFormGroupParentAgreement.append('mimeTypes_suncream[]', this.suncream_documents_preview[i].type);
            }

            this.HFormGroupParentAgreement.append('deleted_suncream_data', JSON.stringify(this.deleted_suncream_documents));
            this.HFormGroupParentAgreement.append('parent_agreement_data', JSON.stringify(this.HFormGroup5.value));
            this.webPagesService.childEnrollmentFormAgreementSubmit(this.HFormGroupParentAgreement).subscribe(res => {
              this.globalService.showSuccessNotification(res['message']);
              this.submitted5 = false;
              this.getStatus.emit('true');
            }, error => {
              this.globalService.showErrorNotification(error.error.message);
              this.submitted5 = false;
              this.getStatus.emit('false');
              this.globalService.showErrorNotification(error.error.message);
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Enrollment Form Not Submitted. :)',
            //   'error'
            // );
          }
        });
    }
  }
  checkValidImunisation()
  {
    if(this.HFormGroup4.value.not_immunized == 0 || this.HFormGroup4.value.not_immunized == '0' || this.HFormGroup4.value.not_immunized == null)
    {
      if(
        this.hepatitis_b_val.join() == "" &&
        this.diphtheria_val.join() == "" &&
        this.tetanus_val.join() == "" &&
        this.pertusis_val.join() == "" &&
        this.polio_val.join() == "" &&
        this.hib_val.join() == "" &&
        this.pneumococcal_val.join() == "" &&
        this.rotavirus_val.join() == "" &&
        this.measles_val.join() == "" &&
        this.mumps_val.join() == "" &&
        this.rubella_val.join() == "" &&
        this.meningococcal_c_val.join() == "" &&
        this.varicella_val.join() == "" &&
        this.hepatitis_a_val.join() == "" 
      )
      {
        this.globalService.showErrorNotification("Kindly fill up Immunisation Details Or Tagged 'Not immunised' as checked.");
        return false;
      }
      return true;
    }
  }
  AnyNameForChild(value, edit = false) {
    if (value == '1') {
      if (this.editMode == false) {
        this.HFormGroup1.get('child_other_name').setValidators([Validators.required]);
        this.HFormGroup1.get('child_other_name').updateValueAndValidity();
      }
      this.hideOtherName = false;
    } else {
      this.hideOtherName = true;
      this.HFormGroup1.get('child_other_name').clearValidators();
      this.HFormGroup1.get('child_other_name').updateValueAndValidity();
    }
  }

  AnyFearsForChild(value, edit = false) {
    if (value == '1') {
      if (this.editMode == false) {
        this.HFormGroup1.get('child_fears_details').setValidators([Validators.required]);
        this.HFormGroup1.get('child_fears_details').updateValueAndValidity();
      }
      this.hideFearDetails = false;
    } else {
      this.HFormGroup1.get('child_fears_details').clearValidators();
      this.HFormGroup1.get('child_fears_details').updateValueAndValidity();
      this.hideFearDetails = true;
    }
  }

  haveProhibitedContact(value) {
    if (value == '1') {
      this.HFormGroup2.get('contact_child_details').setValidators([Validators.required]);
      this.HFormGroup2.get('contact_child_details').updateValueAndValidity();
      this.hideProhibitedContactDetails = false;
    } else {
      this.HFormGroup2.get('contact_child_details').clearValidators();
      this.HFormGroup2.get('contact_child_details').updateValueAndValidity();
      this.hideProhibitedContactDetails = true;
    }
  }

  childCustody(value, edit = false) {
    if (value == '1') {
      this.hideChildCustodyDocument = true;
    } else {
      this.hideChildCustodyDocument = false;
    }
  }

  childHaveAllergies(value, edit = false) {
    if (value == '1') {
      this.hideMedicalAllergiesDocument = false;
      // if (this.editMode == false) {
        // this.HFormGroup4.get('allergies_documents').setValidators([Validators.required]);
        // this.HFormGroup4.get('allergies_documents').updateValueAndValidity();
        this.HFormGroup4.get('allergies_details').setValidators([Validators.required]);
        this.HFormGroup4.get('allergies_details').updateValueAndValidity();
      // }
    } else {
      this.hideMedicalAllergiesDocument = true;
      this.HFormGroup4.get('allergies_documents').clearValidators();
      this.HFormGroup4.get('allergies_documents').updateValueAndValidity();
      this.HFormGroup4.get('allergies_details').clearValidators();
      this.HFormGroup4.get('allergies_details').updateValueAndValidity();
    }
  }

  childRegularMedicine(value, edit = false) {
    if (value == '1') {
      this.hideRegularMedicineDocument = false;
      // if (this.editMode == false) {
        this.HFormGroup4.get('regular_medication_details').setValidators([Validators.required]);
        this.HFormGroup4.get('regular_medication_details').updateValueAndValidity();
      // }
    }
    else {
      this.hideRegularMedicineDocument = true;
      this.HFormGroup4.get('regular_medication_documents').clearValidators();
      this.HFormGroup4.get('regular_medication_documents').updateValueAndValidity();
      this.HFormGroup4.get('regular_medication_details').clearValidators();
      this.HFormGroup4.get('regular_medication_details').updateValueAndValidity();
    }
  }

  childSpecialDietary(value, edit = false) {
    if (value == '1') {
      this.hideSpecialDietaryDocument = false;
      // if (this.editMode == false) {
        this.HFormGroup4.get('special_dietary_details').setValidators([Validators.required]);
        this.HFormGroup4.get('special_dietary_details').updateValueAndValidity();
      // }
    }
    else {
      this.hideSpecialDietaryDocument = true;
      this.HFormGroup4.get('special_dietary_documents').clearValidators();
      this.HFormGroup4.get('special_dietary_documents').updateValueAndValidity();
      this.HFormGroup4.get('special_dietary_details').clearValidators();
      this.HFormGroup4.get('special_dietary_details').updateValueAndValidity();
    }
  }

  childRestDuringDay(value, edit = false) {
    if (value == '1') {
      this.hideChildDayRouting = false;
      if (this.editMode == false) {
        this.HFormGroup4.get('daily_route').setValidators([Validators.required]);
        this.HFormGroup4.get('daily_route').updateValueAndValidity();
      }
    } else {
      this.hideChildDayRouting = true;
      this.HFormGroup4.get('daily_route').clearValidators();
      this.HFormGroup4.get('daily_route').updateValueAndValidity();
    }
  }

  ParentOneDoing(value) {
    if (value == '1') {
      // this.HFormGroup2.get('parent1_occupation').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent1_occupation').updateValueAndValidity();
      // this.HFormGroup2.get('parent1_work_address').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent1_work_address').updateValueAndValidity();
      // this.HFormGroup2.get('parent1_work_phone_no').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent1_work_phone_no').updateValueAndValidity();
      this.hideParent1occupation = false;
    } else {
      // this.HFormGroup2.get('parent1_occupation').clearValidators();
      // this.HFormGroup2.get('parent1_occupation').updateValueAndValidity();
      // this.HFormGroup2.get('parent1_work_address').clearValidators();
      // this.HFormGroup2.get('parent1_work_address').updateValueAndValidity();
      // this.HFormGroup2.get('parent1_work_phone_no').clearValidators();
      // this.HFormGroup2.get('parent1_work_phone_no').updateValueAndValidity();
      this.hideParent1occupation = true;
    }
  }

  ParentTwoDoing(value) {
    if (value == '1') {
      console.log('ParentTwoDoing 1 = ' + value)
      // this.HFormGroup2.get('parent2_occupation').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent2_occupation').updateValueAndValidity();
      // this.HFormGroup2.get('parent2_work_address').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent2_work_address').updateValueAndValidity();
      // this.HFormGroup2.get('parent2_work_phone_no').setValidators([Validators.required]);
      // this.HFormGroup2.get('parent2_work_phone_no').updateValueAndValidity();
      this.hideParent2occupation = false;
    } else {
      console.log('ParentTwoDoing 1 = ' + value)

      this.HFormGroup2.get('parent2_occupation').clearValidators();
      this.HFormGroup2.get('parent2_occupation').updateValueAndValidity();

      this.HFormGroup2.get('parent2_work_address').clearValidators();
      this.HFormGroup2.get('parent2_work_address').updateValueAndValidity();

      this.HFormGroup2.get('parent2_work_phone_no').clearValidators();
      this.HFormGroup2.get('parent2_work_phone_no').updateValueAndValidity();
      this.hideParent2occupation = true;
    }
  }

  setParentFormRef(formRef, enrollmentFormRef) {
    this.SentMailFormRef = formRef;
    if (enrollmentFormRef != null) {
      this.getEnrollmentFormData(enrollmentFormRef, true, false);
    }
  }
  uploadOtherDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.other_documents_preview.push(fileList[i]);
    }
  }
  uploadAllergiesDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.allergies_documents_preview.push(fileList[i]);
    }
  }

  uploadRegularMedicineDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.regular_medicine_documents_preview.push(fileList[i]);
    }
  }

  uploadSpecialDietaryDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.special_dietary_documents_preview.push(fileList[i]);
    }

    this.HFormGroupMedical.delete('special_dietary_files[]');
    this.HFormGroupMedical.delete('mimeTypes_special_dietary[]');
  }

  uploadCustodyDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.custody_documents_preview.push(fileList[i]);
    }
  }

  uploadSuncreamDocuments(data: any) {
    let fileList: FileList = data;
    for (var i = 0; i < fileList.length; i++) {
      this.suncream_documents_preview.push(fileList[i]);
    }
  }

  ApproveRejectEnrollmentForm(status) {
    this.disableFormStatus = true;
    this.submittedForm = true;
    if (status == 0) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ',
          cancelButton: 'btn btn-danger m-r-10'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You want to update this as Reject?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, Reject it',
          cancelButtonText: 'No, cancel',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) {
            var data = {
              'status': status,
              'enrollment_form_ref': this.childEnrollmentFormRef
            };
            this.childEnrollmentService.ApproveRejectEnrollment(data).subscribe(res => {
              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showSuccessNotification(res['message']);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
              swalWithBootstrapButtons.fire(
                'Rejected!',
                'Enrolment Form has been Rejected.',
                'success'
              );
              this.CloseForm.emit(true);
            }, error => {
              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showErrorNotification(error.error.message);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
            });

          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Enrollment Form has been Safe. :)',
            //   'error'
            // );
          }
        });
    }
    else if (status == 2) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ',
          cancelButton: 'btn btn-danger m-r-10'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You want to update this as Pending?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, Pending it',
          cancelButtonText: 'No, cancel',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) {
            var data = {
              'status': status,
              'enrollment_form_ref': this.childEnrollmentFormRef
            };
            this.childEnrollmentService.ApproveRejectEnrollment(data).subscribe(res => {
              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showSuccessNotification(res['message']);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
              this.CloseForm.emit(true);
            }, error => {
              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showErrorNotification(error.error.message);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
            });

          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Enrollment Form has been Safe. :)',
            //   'error'
            // );
          }
        });
    }

    if (status == 1) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success ',
          cancelButton: 'btn btn-danger m-r-10'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You want to update this as Approve?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, Approve it',
          cancelButtonText: 'No, cancel',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) {
            var data = {
              'status': status,
              'enrollment_form_ref': this.childEnrollmentFormRef
            };
            this.childEnrollmentService.ApproveRejectEnrollment(data).subscribe(res => {
              this.createdAccounts = res['created_accounts'];

              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showSuccessNotification(res['message']);

              var sentense = this.createdAccounts.child_givenname + ' ' + this.createdAccounts.child_surname + `  Child Account Created. <br/>`
                + this.createdAccounts.parent1_name + ` Parent Account Created.<br/>`;
              if (this.createdAccounts.parent2_name != null) {
                sentense += this.createdAccounts.parent2_name + ` Parent Account Created.<br/>`
              }
              this.spinner.hide();
              swalWithBootstrapButtons.fire(
                'Approved!',
                sentense,
                'success'
              );
              this.CloseForm.emit(true);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
            }, error => {
              this.disableFormStatus = false;
              this.submittedForm = false;
              this.globalService.showErrorNotification(error.error.message);
              // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            // swalWithBootstrapButtons.fire(
            //   'Cancelled',
            //   'Enrollment Form has been Safe. :)',
            //   'error'
            // );
          }
        });
    }
  }

  changeEditToView(status) {
    this.editMode = status;
  }
  show_parent1_add_fields_for_workschool = false;
  disable_btn_add_work_school = false;
  AddWorkSchoolParent1() {
    this.show_parent1_add_fields_for_workschool = true;
    this.disable_btn_add_work_school = true;
    this.work_table_form.get('parent1_occupation_temp').setValidators([Validators.required]);
    this.work_table_form.get('parent1_occupation_temp').updateValueAndValidity();
    this.work_table_form.get('parent1_work_address_temp').setValidators([Validators.required]);
    this.work_table_form.get('parent1_work_address_temp').updateValueAndValidity();
    this.work_table_form.get('parent1_work_phone_no_temp').setValidators([Validators.required, Validators.pattern('[- +()0-9]+')]);
    this.work_table_form.get('parent1_work_phone_no_temp').updateValueAndValidity();
    this.mode = 'add'; 

  }

  CancelWorkSchoolParent1(){
    this.show_parent1_add_fields_for_workschool = false;
    this.disable_btn_add_work_school = false;
    // this.work_table_form.get( 'parent1_occupation_temp' ).setValue( '' );
    // this.work_table_form.get( 'parent1_work_address_temp' ).setValue( '' );
    // this.work_table_form.get( 'parent1_work_phone_no_temp' ).setValue( '' );

 


    // this.work_table_form.get('parent1_occupation_temp').clearValidators();
    // this.work_table_form.get('parent1_occupation_temp').updateValueAndValidity();
    // this.work_table_form.get('parent1_work_address_temp').clearValidators();
    // this.work_table_form.get('parent1_work_address_temp').updateValueAndValidity();
    // this.work_table_form.get('parent1_work_phone_no_temp').clearValidators();
    // this.work_table_form.get('parent1_work_phone_no_temp').updateValueAndValidity();

    this.work_table_form.get( 'parent1_occupation_temp' ).reset();
    this.work_table_form.get( 'parent1_work_address_temp' ).reset();
    this.work_table_form.get( 'parent1_work_phone_no_temp' ).reset();
    this.table_parent1_index = null;
    this.mode = '';
  }
  DeleteWorkSchoolParent1(row)
  {
    var parent1_occupation_array = this.HFormGroup2.controls['parent1_occupation'].value.split(',');
    var parent1_work_address_array = this.HFormGroup2.controls['parent1_work_address'].value.split(',');
    var parent1_work_phone_no = this.HFormGroup2.controls['parent1_work_phone_no'].value.split(',');
    parent1_occupation_array.splice(row[0] -1, 1);
    parent1_work_address_array.splice(row[0] -1, 1);
    parent1_work_phone_no.splice(row[0] -1, 1);
    this.HFormGroup2.get( 'parent1_occupation' ).setValue( parent1_occupation_array.join() );
    this.HFormGroup2.get( 'parent1_work_address' ).setValue( parent1_work_address_array.join() );
    this.HFormGroup2.get( 'parent1_work_phone_no' ).setValue( parent1_work_phone_no.join() );

    this.dataSource.splice(row[0] -1, 1);

  }
  EditWorkSchoolParent1(row)
  {
    this.table_parent1_index = row[0] - 1;
    this.AddWorkSchoolParent1();
    this.mode = 'edit';
    this.work_table_form.get( 'parent1_occupation_temp' ).setValue( row[1] );
    this.work_table_form.get( 'parent1_work_address_temp' ).setValue( row[2] );
    this.work_table_form.get( 'parent1_work_phone_no_temp' ).setValue( row[3] );
  }
  mode = '';
  table_parent1_index = null;
  SaveWorkSchoolParent1(){
    if( this.work_table_form.valid){
      
      var parent1_occupation_temp_val =  this.work_table_form.controls['parent1_occupation_temp'].value.trim().replace(/,/g, '-');
      var parent1_work_address_temp_val =  this.work_table_form.controls['parent1_work_address_temp'].value.trim().replace(/,/g, '-');
      var parent1_work_phone_no_temp_val =  this.work_table_form.controls['parent1_work_phone_no_temp'].value.trim().replace(/,/g, '-');
      console.log(this.HFormGroup2.controls['parent1_occupation'].value );

      var parent1_occupation_array = (this.HFormGroup2.controls['parent1_occupation'].value) ? this.HFormGroup2.controls['parent1_occupation'].value.split(',') : [];
      var parent1_work_address_array = (this.HFormGroup2.controls['parent1_work_address'].value) ? this.HFormGroup2.controls['parent1_work_address'].value.split(',') : [];
      var parent1_work_phone_no_array = (this.HFormGroup2.controls['parent1_work_phone_no'].value) ? this.HFormGroup2.controls['parent1_work_phone_no'].value.split(',') : [];

      let valid = this.CheckTableDuplicate(this.dataSource, this.table_parent1_index, parent1_occupation_temp_val, parent1_work_address_temp_val, parent1_work_phone_no_temp_val)
      console.log('valid  = ' + valid );
      if(!valid)
        return;
        
      if(this.mode == 'edit')
      {
        parent1_occupation_array[this.table_parent1_index] = parent1_occupation_temp_val;
        parent1_work_address_array[this.table_parent1_index] = parent1_work_address_temp_val;
        parent1_work_phone_no_array[this.table_parent1_index] = parent1_work_phone_no_temp_val;

        this.HFormGroup2.get( 'parent1_occupation' ).setValue( parent1_occupation_array.join() );
        this.HFormGroup2.get( 'parent1_work_address' ).setValue( parent1_work_address_array.join() );
        this.HFormGroup2.get( 'parent1_work_phone_no' ).setValue( parent1_work_phone_no_array.join() );

        this.dataSource[this.table_parent1_index] = [this.table_parent1_index + 1, parent1_occupation_temp_val, parent1_work_address_temp_val,  parent1_work_phone_no_temp_val]
      }
      
      if(this.mode == 'add')
      {
        var parent1_occupation_val = (this.HFormGroup2.controls['parent1_occupation'].value) ? (this.HFormGroup2.controls['parent1_occupation'].value) : "";
        if(parent1_occupation_val == "")
          parent1_occupation_val = parent1_occupation_temp_val;
        else
          parent1_occupation_val = this.HFormGroup2.controls['parent1_occupation'].value + "," + parent1_occupation_temp_val;
  
        var parent1_work_address_val = (this.HFormGroup2.controls['parent1_work_address'].value) ? (this.HFormGroup2.controls['parent1_work_address'].value) : "";
        if(parent1_work_address_val == "")
          parent1_work_address_val = parent1_work_address_temp_val;
        else
          parent1_work_address_val = this.HFormGroup2.controls['parent1_work_address'].value + "," + parent1_work_address_temp_val;
  
        var parent1_work_phone_no_val = (this.HFormGroup2.controls['parent1_work_phone_no'].value) ? (this.HFormGroup2.controls['parent1_work_phone_no'].value) : "";
        if(parent1_work_phone_no_val == "")
          parent1_work_phone_no_val = parent1_work_phone_no_temp_val;
        else
          parent1_work_phone_no_val = this.HFormGroup2.controls['parent1_work_phone_no'].value + "," + parent1_work_phone_no_temp_val;

        this.dataSource.push([this.dataSource.length + 1, parent1_occupation_temp_val, parent1_work_address_temp_val,  parent1_work_phone_no_temp_val]);
        
        this.HFormGroup2.get( 'parent1_occupation' ).setValue( parent1_occupation_val );
        this.HFormGroup2.get( 'parent1_work_address' ).setValue( parent1_work_address_val );
        this.HFormGroup2.get( 'parent1_work_phone_no' ).setValue( parent1_work_phone_no_val );
      }
      this.CancelWorkSchoolParent1();
    }
  }
  CheckTableDuplicate(table_data, table_index, occupation, address, phone)
  {
    console.log('table_index = ' + table_index);
    console.log('occupation = ' + occupation);
    console.log('address = ' + address);
    console.log('phone = ' + phone);
    for (let index = 0; index < table_data.length; index++) {
      const element = this.dataSource[index];
      console.log(element[0]); 
      console.log(element[1]); 
      console.log(element[2]); 
      console.log(element[3]); 
      if(table_index == null)
      {
        if( occupation == element[1] && address == element[2] && phone == element[3])
        {
          this.globalService.showErrorNotification('Duplicate found on row ' + element[0]);
          return false;
        }
      }
      else
      {
        if((table_index + 1) != element[0])
        {
          if( occupation == element[1] && address == element[2] && phone == element[3])
          {
            this.globalService.showErrorNotification('Duplicate found on row ' + element[0]);
            return false;
          }
        }
      }

    }
    return true;
  }
  show_parent2_add_fields_for_workschool = false;
  disable_btn_add_work_school2 = false;
  mode2 = '';
  AddWorkSchoolParent2() {
    this.show_parent2_add_fields_for_workschool = true;
    this.disable_btn_add_work_school2 = true;
    this.work_table_form2.get('parent2_occupation_temp').setValidators([Validators.required]);
    this.work_table_form2.get('parent2_occupation_temp').updateValueAndValidity();
    this.work_table_form2.get('parent2_work_address_temp').setValidators([Validators.required]);
    this.work_table_form2.get('parent2_work_address_temp').updateValueAndValidity();
    this.work_table_form2.get('parent2_work_phone_no_temp').setValidators([Validators.required, Validators.pattern('[- +()0-9]+')]);
    this.work_table_form2.get('parent2_work_phone_no_temp').updateValueAndValidity();
    this.mode2 = 'add'; 
  }
  CancelWorkSchoolParent2(){
    this.show_parent2_add_fields_for_workschool = false;
    this.disable_btn_add_work_school2 = false;
    this.work_table_form2.get( 'parent2_occupation_temp' ).reset();
    this.work_table_form2.get( 'parent2_work_address_temp' ).reset();
    this.work_table_form2.get( 'parent2_work_phone_no_temp' ).reset();
    this.table_parent2_index = null;
    this.mode2 = '';
  }
  DeleteWorkSchoolParent2(row)
  {
    var parent2_occupation_array = this.HFormGroup2.controls['parent2_occupation'].value.split(',');
    var parent2_work_address_array = this.HFormGroup2.controls['parent2_work_address'].value.split(',');
    var parent2_work_phone_no = this.HFormGroup2.controls['parent2_work_phone_no'].value.split(',');

    parent2_occupation_array.splice(row[0] -1, 1);
    parent2_work_address_array.splice(row[0] -1, 1);
    parent2_work_phone_no.splice(row[0] -1, 1);
    this.HFormGroup2.get( 'parent2_occupation' ).setValue( parent2_occupation_array.join() );
    this.HFormGroup2.get( 'parent2_work_address' ).setValue( parent2_work_address_array.join() );
    this.HFormGroup2.get( 'parent2_work_phone_no' ).setValue( parent2_work_phone_no.join() );

    this.dataSource2.splice(row[0] -1, 1);

  }
  EditWorkSchoolParent2(row)
  {
    this.table_parent2_index = row[0] - 1;
    this.AddWorkSchoolParent2();
    this.mode2 = 'edit';
    this.work_table_form2.get( 'parent2_occupation_temp' ).setValue( row[1] );
    this.work_table_form2.get( 'parent2_work_address_temp' ).setValue( row[2] );
    this.work_table_form2.get( 'parent2_work_phone_no_temp' ).setValue( row[3] );
  }
  table_parent2_index = null;
  SaveWorkSchoolParent2(){
    if( this.work_table_form2.valid){
      
      var parent2_occupation_temp_val =  this.work_table_form2.controls['parent2_occupation_temp'].value.trim().replace(/,/g, '-');
      var parent2_work_address_temp_val =  this.work_table_form2.controls['parent2_work_address_temp'].value.trim().replace(/,/g, '-');
      var parent2_work_phone_no_temp_val =  this.work_table_form2.controls['parent2_work_phone_no_temp'].value.trim().replace(/,/g, '-');

      var parent2_occupation_array = (this.HFormGroup2.controls['parent2_occupation'].value) ? this.HFormGroup2.controls['parent2_occupation'].value.split(',') : [];
      var parent2_work_address_array = (this.HFormGroup2.controls['parent2_work_address'].value) ? this.HFormGroup2.controls['parent2_work_address'].value.split(',') : [];
      var parent2_work_phone_no_array = (this.HFormGroup2.controls['parent2_work_phone_no'].value) ? this.HFormGroup2.controls['parent2_work_phone_no'].value.split(',') : [];

      let valid = this.CheckTableDuplicate(this.dataSource2, this.table_parent2_index, parent2_occupation_temp_val, parent2_work_address_temp_val, parent2_work_phone_no_temp_val)
      console.log('valid  = ' + valid );
      if(!valid)
        return;
        
      if(this.mode2 == 'edit')
      {
        parent2_occupation_array[this.table_parent2_index] = parent2_occupation_temp_val;
        parent2_work_address_array[this.table_parent2_index] = parent2_work_address_temp_val;
        parent2_work_phone_no_array[this.table_parent2_index] = parent2_work_phone_no_temp_val;

        this.HFormGroup2.get( 'parent2_occupation' ).setValue( parent2_occupation_array.join() );
        this.HFormGroup2.get( 'parent2_work_address' ).setValue( parent2_work_address_array.join() );
        this.HFormGroup2.get( 'parent2_work_phone_no' ).setValue( parent2_work_phone_no_array.join() );

        this.dataSource2[this.table_parent2_index] = [this.table_parent2_index + 1, parent2_occupation_temp_val, parent2_work_address_temp_val,  parent2_work_phone_no_temp_val]
      }
      
      if(this.mode2 == 'add')
      {
        var parent2_occupation_val = (this.HFormGroup2.controls['parent2_occupation'].value) ? (this.HFormGroup2.controls['parent2_occupation'].value) : "";
        if(parent2_occupation_val == "")
          parent2_occupation_val = parent2_occupation_temp_val;
        else
          parent2_occupation_val = this.HFormGroup2.controls['parent2_occupation'].value + "," + parent2_occupation_temp_val;
  
        var parent2_work_address_val = (this.HFormGroup2.controls['parent2_work_address'].value) ? (this.HFormGroup2.controls['parent2_work_address'].value) : "";
        if(parent2_work_address_val == "")
          parent2_work_address_val = parent2_work_address_temp_val;
        else
          parent2_work_address_val = this.HFormGroup2.controls['parent2_work_address'].value + "," + parent2_work_address_temp_val;
  
        var parent2_work_phone_no_val = (this.HFormGroup2.controls['parent2_work_phone_no'].value) ? (this.HFormGroup2.controls['parent2_work_phone_no'].value) : "";
        if(parent2_work_phone_no_val == "")
          parent2_work_phone_no_val = parent2_work_phone_no_temp_val;
        else
          parent2_work_phone_no_val = this.HFormGroup2.controls['parent2_work_phone_no'].value + "," + parent2_work_phone_no_temp_val;

        this.dataSource2.push([this.dataSource2.length + 1, parent2_occupation_temp_val, parent2_work_address_temp_val,  parent2_work_phone_no_temp_val]);
        
        this.HFormGroup2.get( 'parent2_occupation' ).setValue( parent2_occupation_val );
        this.HFormGroup2.get( 'parent2_work_address' ).setValue( parent2_work_address_val );
        this.HFormGroup2.get( 'parent2_work_phone_no' ).setValue( parent2_work_phone_no_val );
      }
      this.CancelWorkSchoolParent2();
    }
  }
  showViewMoreModal(type) {
    if (type == 'child') {
      this.ViewMoreHeader = 'Duplicate Child Details';
      this.duplicateData = [
        {
          'field': 'Surname',
          'new_value': this.parent1Data.parent_name,
          'existing_value': this.EnrollmentFormData.parent_name,
        },
        {
          'field': 'Given Name',
          'new_value': this.childData.child_givenname,
          'existing_value': this.EnrollmentFormData.child_givenname,
        },
        {
          'field': 'DoB',
          'new_value': this.datePipe.transform(this.childData.child_dob, 'longDate'),
          'existing_value': this.datePipe.transform(this.EnrollmentFormData.child_dob, 'longDate'),
        }, {
          'field': 'Gender',
          'new_value': this.uppercasePipe.transform(this.childData.child_gender),
          'existing_value': this.uppercasePipe.transform(this.EnrollmentFormData.child_gender),
        }, {
          'field': 'School',
          'new_value': this.childData.child_school,
          'existing_value': this.EnrollmentFormData.child_school,
        }, {
          'field': 'School Year',
          'new_value': this.childData.child_school,
          'existing_value': this.EnrollmentFormData.child_school,
        }, {
          'field': 'Address',
          'new_value': this.childData.child_address,
          'existing_value': this.EnrollmentFormData.child_address,
        },
        {
          'field': 'Suburb',
          'new_value': this.childData.child_suburb,
          'existing_value': this.EnrollmentFormData.child_suburb,
        }, {
          'field': 'State',
          'new_value': this.childData.child_state,
          'existing_value': this.EnrollmentFormData.child_state,
        },
      ];
    }

    if (type == 'parent1') {
      this.ViewMoreHeader = 'Duplicate Parent 1 Details';
      this.duplicateData = [
        {
          'field': 'Name',
          'new_value': this.parent1Data.parent_name,
          'existing_value': this.EnrollmentFormData.parent_name,
        },
        {
          'field': 'Email',
          'new_value': this.parent1Data.parent_email,
          'existing_value': this.EnrollmentFormData.parent1_email,
        },
        {
          'field': 'DoB',
          'new_value': this.datePipe.transform(this.parent1Data.parent_dob, 'longDate'),
          'existing_value': this.datePipe.transform(this.EnrollmentFormData.parent1_dob, 'longDate'),
        },
        {
          'field': 'Work Address',
          'new_value': this.parent1Data.parent_work_address,
          'existing_value': this.EnrollmentFormData.parent1_work_address,
        },
        {
          'field': 'Work Phone',
          'new_value': this.parent1Data.parent_work_phone,
          'existing_value': this.EnrollmentFormData.parent1_work_phone,
        },
        {
          'field': 'Address',
          'new_value': this.parent1Data.parent_address,
          'existing_value': this.EnrollmentFormData.parent1_address,
        },
        {
          'field': 'Contact Number',
          'new_value': this.parent1Data.parent_contact_no,
          'existing_value': this.EnrollmentFormData.parent1_contact_no,
        },
      ];
    }
    if (type == 'parent2') {
      this.ViewMoreHeader = 'Duplicate Parent 2 Details'
      this.duplicateData = [
        {
          'field': 'Name',
          'new_value': this.parent2Data.parent_name,
          'existing_value': this.EnrollmentFormData.parent_name,
        },
        {
          'field': 'Email',
          'new_value': this.parent2Data.parent_email,
          'existing_value': this.EnrollmentFormData.parent2_email,
        },
        {
          'field': 'DoB',
          'new_value': this.datePipe.transform(this.parent2Data.parent_dob, 'longDate'),
          'existing_value': this.datePipe.transform(this.EnrollmentFormData.parent2_dob, 'longDate'),
        },
        {
          'field': 'Work Address',
          'new_value': this.parent2Data.parent_work_address,
          'existing_value': this.EnrollmentFormData.parent2_work_address,
        },
        {
          'field': 'Work Phone',
          'new_value': this.parent2Data.parent_work_phone,
          'existing_value': this.EnrollmentFormData.parent2_work_phone,
        },
        {
          'field': 'Address',
          'new_value': this.parent2Data.parent_address,
          'existing_value': this.EnrollmentFormData.parent2_address,
        },
        {
          'field': 'Contact Number',
          'new_value': this.parent2Data.parent_contact_no,
          'existing_value': this.EnrollmentFormData.parent2_contact_no,
        },
      ]
    }
    this.modalConfig.size = 'md';
    this.viewMoreModalRef = this.modalService.open(this.templateRefViewMore, this.modalConfig);
  }

  SaveParentChildData(child_checked, parent1_checked, parent2_checked) {
    this.submittedDuplicate = true;
    var data = {
      enrollment_form_ref: this.childEnrollmentFormRef,
      child_checked: child_checked,
      child_ref: this.child_ref,
      parent1_checked: parent1_checked,
      parent1_ref: this.parent_1_ref,
      parent2_checked: parent2_checked,
      parent2_ref: this.parent_2_ref,
    };

    this.childEnrollmentService.SaveChildParentData(data).subscribe(res => {
      this.submittedDuplicate = false;
      this.childModalRef.close();
      this.globalService.showSuccessNotification(res['message']);
      // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
      this.CloseForm.emit(true);
    }, error => {
      this.submittedDuplicate = false;
      this.childModalRef.close();
      this.globalService.showErrorNotification(error.statusText);
      // this.getEnrollmentFormData(this.childEnrollmentFormRef,this.isEditFormSubmit);
    });
  }

  setEnrollmentFormData(enrollment_form_data) {
    if (enrollment_form_data.enrolment_form_ref) {
      this.getEnrollmentFormData(enrollment_form_data.enrolment_form_ref, true, false);
    }
  }

  resetUpload(key, removing_item, type) {
    Swal.fire(<any>{
      title: 'Delete?',
      text: 'Do you want to delete this ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete'
    }).then((result) => {
      if (result.value) {
        var data = {
          removing_item: removing_item,
          enrollment_ref: this.childEnrollmentFormRef,
          type: type
        };
        this.deleted_medical_documents.push(data);
        
        if (type == 'is_others') {
          if (key > -1) {
            this.other_documents.splice(key, 1);
          }
        }
        if (type == 'is_allergies') {
          if (key > -1) {
            this.allergies_documents.splice(key, 1);
          }
        }

        if (type == 'is_regular_medication') {
          if (key > -1) {
            this.regular_medicine_documents.splice(key, 1);
          }
        }

        if (type == 'is_special_dietary') {
          if (key > -1) {
            this.special_dietary_documents.splice(key, 1);
          }
        }
      }
    });
  }

  resetUploadPreview(key, type) {
    Swal.fire(<any>{
      title: 'Delete?',
      text: 'Do you want to delete this ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete'
    }).then((result) => {
      if (result.value) {
        if (type == 'is_others') {
          if (key > -1) {
            this.other_documents_preview.splice(key, 1);
          }
        }
        if (type == 'is_allergies') {
          if (key > -1) {
            this.allergies_documents_preview.splice(key, 1);
          }
        }

        if (type == 'is_regular_medication') {
          if (key > -1) {
            this.regular_medicine_documents_preview.splice(key, 1);
          }
        }

        if (type == 'is_special_dietary') {
          if (key > -1) {
            this.special_dietary_documents_preview.splice(key, 1);
            this.special_dietary_documents_mimeType.splice(key, 1);
          }
        }

        if (type == 'is_custody') {
          if (key > -1) {
            this.custody_documents_preview.splice(key, 1);
            this.custody_documents_mimeType.splice(key, 1);
          }
        }

        if (type == 'suncream') {
          if (key > -1) {
            this.suncream_documents_preview.splice(key, 1);
            this.suncream_documents_mimeType.splice(key, 1);
          }
        }
      }
    });
  }

  resetUploadReceived(key, type) {
    Swal.fire(<any>{
      title: 'Delete?',
      text: 'Do you want to delete this ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete'
    }).then((result) => {
      if (result.value) {
        if (type == 'is_custody') {
          var data = {
            removing_item: this.custody_documents[key],
            type: type
          };
          this.deleted_custody_document.push(data);
          if (key > -1) {
            this.custody_documents.splice(key, 1);
          }
        }

        if (type == 'suncream') {
          var data = {
            removing_item: this.suncream_documents[key],
            type: type
          };
          this.deleted_suncream_documents.push(data);
          if (key > -1) {
            this.suncream_documents.splice(key, 1);
          }
        }
        if (type == 'is_others') {
          var data = {
            removing_item: this.other_documents[key],
            type: type
          };
          this.deleted_other_documents.push(data);
          if (key > -1) {
            this.other_documents.splice(key, 1);
          }
        }
        if (type == 'is_allergies') {
          var data = {
            removing_item: this.allergies_documents[key],
            type: type
          };
          this.deleted_allergies_documents.push(data);
          if (key > -1) {
            this.allergies_documents.splice(key, 1);
          }
        }

        if (type == 'is_regular_medication') {
          var data = {
            removing_item: this.regular_medicine_documents[key],
            type: type
          };
          this.deleted_regular_medicine_documents.push(data);
          if (key > -1) {
            this.regular_medicine_documents.splice(key, 1);
          }
        }

        if (type == 'is_special_dietary') {
          var data = {
            removing_item: this.special_dietary_documents[key],
            type: type
          };
          this.deleted_special_dietary_documents.push(data);
          if (key > -1) {
            this.special_dietary_documents.splice(key, 1);
          }
        }
      }
    });
  }

  drawComplete(type) {
    // will be notified of szimek/signature_pad's onEnd event
    if (type == 'parent_agreement') {
      // this.signatureImage = this.signaturePad.toDataURL();
      this.HFormGroup5.patchValue({
        parent_agreement_signature_image: this.signaturePad.toDataURL()
      });
    }
  }

  signatureImgOutput($event) {
    this.signatureImg = $event;
    this.HFormGroup5.patchValue({
      parent_agreement_signature_image: this.signatureImg
    });
  }

  signatureImgOutput2($event) {
    this.signatureImg2 = $event;
    this.HFormGroup4.patchValue({
      immunisation_signed: this.signatureImg2
    });
  }

  drawStart(type) {
    if (type == 'parent_agreement') {
      this.signatureRefParentAgreement = this.signaturePad;
    }
  }

  clearPad(type) {
    if (type == 'parent_agreement') {
      this.isNewSignature = true;
      this.signatureImage = null;
      this.HFormGroup5.patchValue({
        parent_agreement_signature_image: ''
      });
      // this.signatureRefParentAgreement.clear();
    } else if (type == 'immunisation_signed') {
      this.isNewSignature2 = true;
      this.signatureImg2 = null;
      this.HFormGroup4.patchValue({
        immunisation_signed: ''
      });

    }
  }

  onStepChange(event) {
    if (event.selectedIndex == 5) {
      // this.signatureRefParentAgreement = this.signaturePad;
      // this.signatureRefSunsCreen = this.signaturePadSunsCreen;
      // this.signatureRefPhotoVideo = this.signaturePadPhotoVideograph;
      // this.signatureRefTransportOutingPermission = this.signaturePadtransportOutingPermission;
      if (this.signaturePad != undefined) {
        this.signaturePad.clear();
      }
      // this.signatureRefParentAgreement.clear();
      // this.signaturePadSunsCreen.clear();
      // this.signaturePadPhotoVideograph.clear();
    }
  }

  getFiles(data: any, type = "") {
    let fileList: FileList = data;

    for (var i = 0; i < fileList.length; i++) {
      var valueToPush = new Array();
      valueToPush[0] = fileList[i];
      valueToPush[1] = type;
      this.docList.push(valueToPush);
      if(type == "child_id")
      {
        this.docList_child_id.push(fileList[i]);
        this.docTypeList.push("4");
        if(this.docList_child_id.length > 0 || this.docListEdit_child_id.length > 0 )
          this.HFormGroup6.get( 'input_child_id' ).setValue( 'valid' );
      }
      if(type == 'medicare')
      {
        this.docList_medicare.push(fileList[i]);
        this.docTypeList.push("5");
        if(this.docList_medicare.length > 0|| this.docListEdit_medicare.length > 0 )
        this.HFormGroup6.get( 'input_medicare' ).setValue( 'valid' );
      }
      if(type == 'parent_id')
      {
        this.docList_parent_id.push(fileList[i]);
        this.docTypeList.push("6");
        if(this.docList_parent_id.length > 0|| this.docListEdit_parent_id.length > 0 )
          this.HFormGroup6.get( 'input_parent_id' ).setValue( 'valid' );
      }
      if(type == 'immunisation_history')
      {
        this.docList_immunisation_history.push(fileList[i]);
        this.docTypeList.push("30");
        if(this.docList_immunisation_history.length > 0|| this.docListEdit_immunisation_history.length > 0 )
          this.HFormGroup6.get( 'input_immunisation_history' ).setValue( 'valid' );
      }
      if(type == 'other_id')
      {
        this.docList_other_id.push(fileList[i]);
        this.docTypeList.push("29");
      }
    }
  }


  deleteDocuments(key, type = "") {
    let cnt = 0;
    let cnt_on_type = 0;
    this.docList.forEach(element => {
      if(element[1] == type)
      {
        if(key == cnt_on_type)
        {
          this.docList.splice(cnt, 1);
          this.docTypeList.splice(cnt, 1);
        }
        else
          cnt_on_type++;
      }
      cnt++;
    });
    if(type == "child_id")
    {
      this.docList_child_id.splice(key, 1);
      if(this.docList_child_id.length == 0 && this.docListEdit_child_id.length == 0)
        this.HFormGroup6.get( 'input_child_id' ).reset();
    }
    if(type == 'medicare')
    {
      this.docList_medicare.splice(key, 1);
      if(this.docList_medicare.length == 0 && this.docListEdit_medicare.length == 0)
        this.HFormGroup6.get( 'input_medicare' ).reset();
    }
    if(type == 'parent_id')
    {
      this.docList_parent_id.splice(key, 1);
      if(this.docList_parent_id.length == 0 && this.docListEdit_parent_id.length == 0)
        this.HFormGroup6.get( 'input_parent_id' ).reset();
    }
    if(type == 'immunisation_history')
    {
      this.docList_immunisation_history.splice(key, 1);
      if(this.docList_immunisation_history.length == 0 && this.docListEdit_immunisation_history.length == 0)
        this.HFormGroup6.get( 'input_immunisation_history' ).reset();
    }
    if(type == 'other_id')
      this.docList_other_id.splice(key, 1);
  }

  DocumentType(type, key) {
    this.docTypeList[key] = type;
  }

  DocumentTypeEdit(type, key) {
    this.docListEdit[key].form_document_type_id = type;
  }

  deleteDocumentsEdit(key, type = "") {
    let cnt = 0;
    let cnt_on_type = 0;
    this.docListEdit.forEach(element => {
      if(element.form_document_type_id == type)
      {
        if(key == cnt_on_type)
        {
          this.deletedDocuments.push(this.docListEdit[cnt]);
          this.docListEdit.splice(cnt, 1);
        }
        else
          cnt_on_type++;
      }
      cnt++;
    });
    if(type == "4")
    {
      this.docListEdit_child_id.splice(key, 1);
      if(this.docList_child_id.length == 0 && this.docListEdit_child_id.length == 0)
        this.HFormGroup6.get( 'input_child_id' ).reset();
    }
    if(type == '5')
    {
      this.docListEdit_medicare.splice(key, 1);
      if(this.docList_medicare.length == 0 && this.docListEdit_medicare.length == 0)
        this.HFormGroup6.get( 'input_medicare' ).reset();
    }
    if(type == '6')
    {
      this.docListEdit_parent_id.splice(key, 1);
      if(this.docList_parent_id.length == 0 && this.docListEdit_parent_id.length == 0)
        this.HFormGroup6.get( 'input_parent_id' ).reset();
    }
    console.log(key);
    console.log(type);
    if(type == '30')
    {

      this.docListEdit_immunisation_history.splice(key, 1);
      if(this.docList_immunisation_history.length == 0 && this.docListEdit_immunisation_history.length == 0)
        this.HFormGroup6.get( 'input_immunisation_history' ).reset();
    }
    if(type == '29')
      this.docListEdit_other_id.splice(key, 1);
    return;
  }

  toggle1($event) {
    this.is_suncream_alergies = $event.checked;
  }

  downloadDoc(childEnrollmentFormRef) {
    var data = {
      child_enrollment_form_ref: childEnrollmentFormRef
    };
    this.webPagesService.downloadDocument(data).subscribe(res => {
      window.open(res['url']);
    }, error => {
      this.webPagesService.downloadDocument(error.statusText);
    });
  }

  getCountries() {
    this.accountService.getCountries().subscribe((res) => {
      this.rows = res['countries']; 
      this.countries = res['countries'];
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name => (name ? this._filter(name) : this.rows.slice())),
      );
    }, (error) => {
      this.globalService.showErrorNotification('Something went wrong!');
    }
    );
  }

  toggle(event) {
    console.log('none', event);
  }
  clickPriorityAcessText(){
    (this.showPriorityAcessText) ? this.showPriorityAcessText = false : this.showPriorityAcessText = true;
  }

  checkBoxInfection(event, key = null) {
    this.showOtherHealthProblem = false;
    if (key != null) {
      this.OtherHealthProblem[key] = event ? 1 : 0;
    }

    for (let item of this.OtherHealthProblem) {
      if (item == 1) {
        this.showOtherHealthProblem = true;
      }
    }

    console.log(key);
    const list_checkbox = ['is_none','is_add_adhd','is_eilepsy','is_physical_need','is_haemophila','is_asthma','is_diabetes','is_heart_problem','is_anaphylaxis','is_behavioural_needs', 'is_other'];
    let valid = 0;
    list_checkbox.forEach(element => {
      console.log(element + " = " + this.HFormGroup4.get(element).value);
      if(this.HFormGroup4.get(element).value == true)
       valid++;
      if(this.HFormGroup4.get('is_none').value == true && element != 'is_none')
      {
        this.HFormGroup4.get(element).reset();
        this.HFormGroup4.get(element).disable();
        this.showOtherHealthProblem = false;
      }
      if(this.HFormGroup4.get('is_none').value == false && element != 'is_none')
        this.HFormGroup4.get(element).enable();
    });
    if(valid > 0)
    {
      this.HFormGroup4.get('does_your_child_have_any_of_the_following').clearValidators();
      if(this.HFormGroup4.get('is_none').value)
        this.HFormGroup4.get('other_problems').clearValidators();
      else  
        this.HFormGroup4.get('other_problems').setValidators([Validators.required]);
    }
    else
    {
      this.HFormGroup4.get('does_your_child_have_any_of_the_following').setValidators([Validators.required]);
      this.HFormGroup4.get('other_problems').clearValidators();
      this.showOtherHealthProblem = false;
    } 
    this.HFormGroup4.get('does_your_child_have_any_of_the_following').updateValueAndValidity();
    this.HFormGroup4.get('other_problems').updateValueAndValidity();



  }
  checkEmailExists(type) {
    console.log(this.HFormGroup2.get('parent2_email').value);
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    console.log(this.loggedUser);
    if (type == 'P2') {
      var email = this.isValidEmail(this.HFormGroup2.get('parent2_email').value);
      if (email != false) {
        var data = {
          'email': email,
          'type': type,
          'branch_id' : this.loggedUser.user.branch_id
        }
        this.childEnrollmentService.checkEmailExsist(data).subscribe((res) => {
          console.log(res['parent']);
          if (res['status'] == 201) {
            Swal.fire(<any>{
              title: 'Parent 2 is already registered',
              text: 'Do you want to fill the data with previous data?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              allowOutsideClick: false,
            }).then((result) => {
              if (result.value) {
                this.HFormGroup2.patchValue({
                  'parent2_duplicate': true,
                  'parent2_name': res['parent'].parent_name,
                  'parent2_first_name': res['parent'].first_name,
                  'parent2_user_id': res['parent'].user_id,
                  'parent2_last_name': res['parent'].last_name,
                  'parent2_email': res['parent'].parent_email,
                  'parent2_contact_no': res['parent'].parent_contact_no,
                  'parent2_address': res['parent'].parent_address,
                  'parent2_suburb': res['parent'].parent_suburb,
                  'parent2_state': res['parent'].parent_state.toString(),
                  'parent2_postal_code': res['parent'].parent_postal_code,
                  'parent2_ref': res['parent'].parent_ref,
                });
              } else {
                this.HFormGroup2.patchValue({
                  'parent2_duplicate': false,
                  'parent2_email': '',
                });

              }
            });
          }
        }, error => {
          this.globalService.showErrorNotification(error.statusText);
        });
      }

    }
  }
  isValidEmail(email) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(mailformat))
      return email;
    else
      return false;
  }
  checkBoxToileting(is_checked, key = null,event =null) {
    const list_checkbox = ['is_nappies','is_pull_ups','is_toilet_traning','is_fully_toilet'];

    list_checkbox.forEach(element => {
      if(event != null)
      {
        if(event.source.name != element && event.checked)
          this.HFormGroup4.get(element).reset();
      }
    });



    let valid = 0;
    list_checkbox.forEach(element => {
      console.log(element + " = " + this.HFormGroup4.get(element).value);
      if(this.HFormGroup4.get(element).value == true)
       valid++;
    });
    if(valid > 0)
    {
      this.HFormGroup4.get('toileting_requirements').clearValidators();
    }
    else
    {
      this.HFormGroup4.get('toileting_requirements').setValidators([Validators.required]);
    } 
    this.HFormGroup4.get('toileting_requirements').updateValueAndValidity();
  }
  hepatitis_b_val = [];
  diphtheria_val = [];
  tetanus_val = [];
  pertusis_val = [];
  polio_val = [];
  hib_val = [];
  pneumococcal_val = [];
  rotavirus_val = [];
  measles_val = [];
  mumps_val = [];
  rubella_val = [];
  meningococcal_c_val = [];
  varicella_val = [];
  hepatitis_a_val = [];
  onChangeChkState(event)
  {
    console.log(event);
    console.log(event.checked);
    console.log(event.source.value);
    var name_sickness = event.source.name;
    if(name_sickness.includes("hepatitis_b"))
    {
      if(event.checked)
        this.hepatitis_b_val.push(event.source.value);
      else
      {
        console.log('remove');

        const id = this.hepatitis_b_val.indexOf(event.source.value); // 2
        this.hepatitis_b_val.splice(id,  1);
      }
      console.log(this.hepatitis_b_val);
    }
    if(name_sickness.includes("diphtheria"))
    {
      if(event.checked)
        this.diphtheria_val.push(event.source.value);
      else
      {
        const id = this.diphtheria_val.indexOf(event.source.value); // 2
        this.diphtheria_val.splice(id,  1);
      }
      console.log(this.diphtheria_val);
    }
    if(name_sickness.includes("tetanus"))
    {
      if(event.checked)
        this.tetanus_val.push(event.source.value);
      else
      {
        const id = this.tetanus_val.indexOf(event.source.value); // 2
        this.tetanus_val.splice(id,  1);
      }
      console.log(this.tetanus_val);
    }
    if(name_sickness.includes("pertusis"))
    {
      if(event.checked)
        this.pertusis_val.push(event.source.value);
      else
      {
        const id = this.pertusis_val.indexOf(event.source.value); // 2
        this.pertusis_val.splice(id,  1);
      }
      console.log(this.pertusis_val);
    }
    if(name_sickness.includes("polio"))
    {
      if(event.checked)
        this.polio_val.push(event.source.value);
      else
      {
        const id = this.polio_val.indexOf(event.source.value); // 2
        this.polio_val.splice(id,  1);
      }
      console.log(this.polio_val);
    }
    if(name_sickness.includes("hib"))
    {
      if(event.checked)
        this.hib_val.push(event.source.value);
      else
      {
        const id = this.hib_val.indexOf(event.source.value); // 2
        this.hib_val.splice(id,  1);
      }
      console.log(this.hib_val);
    }
    if(name_sickness.includes("pneumococcal"))
    {
      if(event.checked)
        this.pneumococcal_val.push(event.source.value);
      else
      {
        const id = this.pneumococcal_val.indexOf(event.source.value); // 2
        this.pneumococcal_val.splice(id,  1);
      }
      console.log(this.pneumococcal_val);
    }
    if(name_sickness.includes("rotavirus"))
    {
      if(event.checked)
        this.rotavirus_val.push(event.source.value);
      else
      {
        const id = this.rotavirus_val.indexOf(event.source.value); // 2
        this.rotavirus_val.splice(id,  1);
      }
      console.log(this.rotavirus_val);
    }
    if(name_sickness.includes("measles"))
    {
      if(event.checked)
        this.measles_val.push(event.source.value);
      else
      {
        const id = this.measles_val.indexOf(event.source.value); // 2
        this.measles_val.splice(id,  1);
      }
      console.log(this.measles_val);
    }
    if(name_sickness.includes("mumps"))
    {
      if(event.checked)
        this.mumps_val.push(event.source.value);
      else
      {
        const id = this.mumps_val.indexOf(event.source.value); // 2
        this.mumps_val.splice(id,  1);
      }
      console.log(this.mumps_val);
    }
    if(name_sickness.includes("rubella"))
    {
      if(event.checked)
        this.rubella_val.push(event.source.value);
      else
      {
        const id = this.rubella_val.indexOf(event.source.value); // 2
        this.rubella_val.splice(id,  1);
      }
      console.log(this.rubella_val);
    }
    if(name_sickness.includes("meningococcal_c"))
    {
      if(event.checked)
        this.meningococcal_c_val.push(event.source.value);
      else
      {
        const id = this.meningococcal_c_val.indexOf(event.source.value); // 2
        this.meningococcal_c_val.splice(id,  1);
      }
      console.log(this.meningococcal_c_val);
    }
    if(name_sickness.includes("varicella"))
    {
      if(event.checked)
        this.varicella_val.push(event.source.value);
      else
      {
        const id = this.varicella_val.indexOf(event.source.value); // 2
        this.varicella_val.splice(id,  1);
      }
      console.log(this.varicella_val);
    }
    if(name_sickness.includes("hepatitis_a"))
    {
      if(event.checked)
        this.hepatitis_a_val.push(event.source.value);
      else
      {
        const id = this.hepatitis_a_val.indexOf(event.source.value); // 2
        this.hepatitis_a_val.splice(id,  1);
      }
      console.log(this.hepatitis_a_val);
    }
    this.checkIfAllSicknessChecked();
  }
  setAll(event)
  {
    const check : any =  [this.Chepatitis_b0,this.Chepatitis_b2,this.Chepatitis_b4,this.Chepatitis_b6,this.Cdiphtheria2,this.Cdiphtheria4,this.Cdiphtheria6,this.Cdiphtheria18,this.Cdiphtheria48,this.Ctetanus2,this.Ctetanus4,this.Ctetanus6,this.Ctetanus18,this.Ctetanus48,this.Cpertusis2,this.Cpertusis4,this.Cpertusis6,this.Cpertusis18,this.Cpertusis48,this.Cpolio2,this.Cpolio4,this.Cpolio6,this.Cpolio48,this.Chib2,this.Chib4,this.Chib6,this.Chib18,this.Cpneumococcal2,this.Cpneumococcal4,this.Cpneumococcal6,this.Cpneumococcal12,this.Cpneumococcal48,this.Crotavirus2,this.Crotavirus4,this.Cmeasles12,this.Cmeasles18,this.Cmumps12,this.Cmumps18,this.Crubella12,this.Crubella18,this.Cmeningococcal_c12,this.Cvaricella18,this.Chepatitis_a12,this.Chepatitis_a18]
    check.forEach(element => {
          var chbox = document.getElementsByName(element.name);
          if(event.checked)
          {
            if(element._checked == false)
              chbox[1].click()
          }
          else
          {
            if(element._checked == true)
              chbox[1].click()
          }
    });
    
  }
  notImmunizeRemoveAll(event)
  {
    if(!event.checked)
      return;
    const check : any =  [this.Chepatitis_b0,this.Chepatitis_b2,this.Chepatitis_b4,this.Chepatitis_b6,this.Cdiphtheria2,this.Cdiphtheria4,this.Cdiphtheria6,this.Cdiphtheria18,this.Cdiphtheria48,this.Ctetanus2,this.Ctetanus4,this.Ctetanus6,this.Ctetanus18,this.Ctetanus48,this.Cpertusis2,this.Cpertusis4,this.Cpertusis6,this.Cpertusis18,this.Cpertusis48,this.Cpolio2,this.Cpolio4,this.Cpolio6,this.Cpolio48,this.Chib2,this.Chib4,this.Chib6,this.Chib18,this.Cpneumococcal2,this.Cpneumococcal4,this.Cpneumococcal6,this.Cpneumococcal12,this.Cpneumococcal48,this.Crotavirus2,this.Crotavirus4,this.Cmeasles12,this.Cmeasles18,this.Cmumps12,this.Cmumps18,this.Crubella12,this.Crubella18,this.Cmeningococcal_c12,this.Cvaricella18,this.Chepatitis_a12,this.Chepatitis_a18]
    check.forEach(element => {
      var chbox = document.getElementsByName(element.name);
      if(element._checked == true)
        chbox[1].click()
    });
    
  }
  checkIfAllSicknessChecked()
  {
    let count_all_checked = this.hepatitis_b_val.length + this.diphtheria_val.length + this.tetanus_val.length + this.pertusis_val.length + this.polio_val.length + this.hib_val.length + this.pneumococcal_val.length + this.rotavirus_val.length + this.measles_val.length + this.mumps_val.length + this.rubella_val.length + this.meningococcal_c_val.length + this.varicella_val.length + this.hepatitis_a_val.length;
    console.log('count_all_checked = ' + count_all_checked);
    if(count_all_checked == 0){
      this.HFormGroup4.get('not_immunized').setValidators([Validators.required, customValidateCheckbox()]);
      this.HFormGroup4.get('not_immunized').updateValueAndValidity();
      this.HFormGroup4.patchValue({
        not_immunized: 1
      });
    }
    else
    {
      this.HFormGroup4.get('not_immunized').clearValidators();
      this.HFormGroup4.get('not_immunized').updateValueAndValidity();
      this.HFormGroup4.patchValue({
        not_immunized: 0
      });
    }  
    if(count_all_checked == 44)
    {
      this.HFormGroup4.patchValue({
        select_all_chx_box: 1
      });
    }
    else
    {
      this.HFormGroup4.patchValue({
        select_all_chx_box: 0
      });
    }
  }
}
export function customValidateCheckbox(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { not_immunized: true };
    }
    return null;
  }
}
export function customValidateCrn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    if (value.length == 10) {
      var firstset = value.substring(0, 9);
      if (!(/^\d+$/.test(firstset))) {
        return { crn: true };
      }
      var lastChar = value[value.length - 1];
      if (!lastChar.match(/[a-z]/i)) {
        return { crn: true };
      }
    } else if (value.toLowerCase() == 'na') {
      return null;
    } else {
      return { crn: true };
    }

    // const hasUpperCase = /[A-Z]+/.test(value);
    //
    // const hasLowerCase = /[a-z]+/.test(value);
    //
    // const hasNumeric = /[0-9]+/.test(value);
    //
    // const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
    //
    // return !passwordValid ? {passwordStrength:true}: null;
  }
}

