<form [formGroup]="form" class="shadow px-3 py-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-end mb-3">
        <button
          class="bg-light-gray"
          mat-icon-button
          (click)="addNewBookingSession()"
          *ngIf="index === 0"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          class="bg-light-gray"
          mat-icon-button
          (click)="removeBookingSession()"
          *ngIf="index !== 0"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Increase Entitlement</mat-label>
          <mat-select formControlName="increase_entitlement">
            <mat-option value="not_eligible">Not eligible</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Hours To Claim</mat-label>
          <mat-select formControlName="hours_to_claim">
            <mat-option value="not_eligible">Not eligible</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Std Hours</mat-label>
              <input matInput formControlName="std_hours" type="number" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Std Amount</mat-label>
              <input matInput formControlName="std_amount" type="number" />
              <mat-icon matPrefix>attach_money</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Non Std Hours</mat-label>
              <input matInput formControlName="non_std_hours" type="number" />
              <mat-icon matPrefix>attach_money</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Non Std Amount</mat-label>
              <input matInput formControlName="non_std_amount" type="number" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Total</mat-label>
          <input matInput formControlName="std_total" type="number" />
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Hours To Claim</mat-label>
          <input
            matInput
            formControlName="hours_to_claim_total"
            type="number"
          />
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Child Count</mat-label>
          <input matInput formControlName="child_count" type="number" />
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Levy</mat-label>
          <input matInput formControlName="levy" type="number" />
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Non Eligible</mat-label>
          <input matInput formControlName="non_eligible" type="number" />
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
