<table #attendanceTable mat-table [dataSource]="tableData" class="w-100 mat-table-responsive t-b-1">
    <ng-container matColumnDef="check_box">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleAllSelection($event)" disabled></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox (change)="toggleSelection($event, element)" [(ngModel)]="element.isSelected"
                disabled></mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element; let key = index">{{ formatDateToDay(element.date) }}, {{
            formatDateWithoutTime(element.date) }} </td>
    </ng-container>
    <ng-container matColumnDef="sched">
        <th mat-header-cell *matHeaderCellDef> Schedule </th>
        <td mat-cell *matCellDef="let element; let key = index">{{ formatTime(element.sched_start) }} - {{
            formatTime(element.sched_end) }}</td>
    </ng-container>
    <ng-container matColumnDef="sign_in">
        <th mat-header-cell *matHeaderCellDef> Sign In </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_in) }}</span>
            <span *ngIf="element.status == 2">Absent</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="signed_in_by">
        <th mat-header-cell *matHeaderCellDef> Signed In By </th>
        <td mat-cell *matCellDef="let element">
            <span>{{element.signed_in_by}}</span> <br />
            <span *ngIf="element.signed_in_role">({{element.signed_in_role | titlecase}})</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="sign_out">
        <th mat-header-cell *matHeaderCellDef> Sign Out </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_out) }} </span>
            <span *ngIf="element.status == 2">Absent</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="signed_out_by">
        <th mat-header-cell *matHeaderCellDef> Signed Out By </th>
        <td mat-cell *matCellDef="let element">
            <span>{{element.signed_out_by}}</span> <br />
            <span *ngIf="element.signed_out_role">({{element.signed_out_role | titlecase}})</span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>