<div class="modal-header m-t-15">
    <div class="modalHeader clearfix">
        <div class="modal-about contact-header">
            <div class="contact-details-heading">
                <div class="f-20 mb-2 w-100-p f-w-600"> {{title}}</div>
            </div>
        </div>
    </div>
</div>
<div class="modal-body">
    <mat-card-content>
        <p class="p-10">{{message}}</p>
    </mat-card-content>
</div>
<div mat-dialog-actions>
    <button class="pull-right text-white m-r-10 m-b-10" mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
    <button mat-raised-button class="pull-right cancel-btn text-white mr-3 bg-light-red m-b-10" (click)="onDismiss()">Cancel</button>
</div>