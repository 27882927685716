import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InConfiguration } from '../core/models/config.interface';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData: InConfiguration;

  constructor(private http: HttpClient, private globeService: GlobalService) {
    this.setConfigData();
    
  }

  getSidebarMenu() {
    return this.http.get(this.globeService.baseUrl + 'get-menu', this.globeService.getHttpOptionsAuth());
  }
  
  setConfigData() {
    this.configData = {
      layout: {
        variant: 'light', // options:  light & dark
        theme_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: false, // options:  true & false
          backgroundColor: 'light', // options:  light & dark
        },
      },
    };
  }
}
