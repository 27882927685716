import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-esi-layout',
  templateUrl: './esi-layout.component.html',
  styleUrls: ['./esi-layout.component.css']
})
export class EsiLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
