import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global/global.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AccountDashboardService {
  private _hasNotifications$ = new BehaviorSubject<boolean>(false);

  public get hasNotifications(): Observable<boolean> {
    return this._hasNotifications$.asObservable();
  }

  public setHasNotifications(value: boolean) {
    this._hasNotifications$.next(value);
  }

  constructor(private http: HttpClient, private globeService: GlobalService) {}

  createCenter(data) {
    return this.http.post(
      this.globeService.baseUrl + 'account/create-care-center',
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  updateSentEmail(sendEmailId: number, email_address: string) {
    const httpOptions = this.globeService.getHttpOptionsAuth();
    httpOptions.headers.append('_method', 'PATCH');

    return this.http.patch(
      this.globeService.baseUrl + 'general/update-email-status/' + sendEmailId,
      {
        email_address
      },
      httpOptions
    );
  }
}
