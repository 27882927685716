<ng-container [formGroup]="routineForm">
    <td>{{index == 0 ? day : ""}}</td>
    <td *ngIf="isCasualWithDate">
      <mat-form-field class="example-full-width w-140px" appearance="outline">
        <input type="date" formControlName="casual_date" matInput (blur)="updateData($event)">
        <mat-error *ngIf="routineForm.get('casual_date').touched && routineForm.get('casual_date').hasError('required')">
          Date is not valid
        </mat-error>
      </mat-form-field>
    </td>
    <td>
      <mat-form-field class="example-full-width w-140px" appearance="outline">
        <input type="time" formControlName="start_time" matInput placeholder="HH:mm" (blur)="updateData($event)">
        <mat-error *ngIf="routineForm.get('start_time').touched && routineForm.get('start_time').hasError('required')">
          Start time is not a valid time
        </mat-error>
        <mat-error *ngIf="routineForm.get('start_time').touched && routineForm.get('start_time').hasError('invalidTimeRange')">
          Start time cannot be after end time
        </mat-error>
      </mat-form-field>
    </td>
    <td>
      <mat-form-field class="example-full-width w-140px" appearance="outline">
        <input type="time" formControlName="end_time" matInput placeholder="HH:mm" (blur)="updateData($event)">
        <mat-error *ngIf="routineForm.get('end_time').touched &&  routineForm.get('end_time').hasError('required')">
          End time is not a valid time
        </mat-error>
        <mat-error *ngIf="routineForm.get('end_time').touched && routineForm.get('end_time').hasError('invalidTimeRange')">
          End time cannot be before start time
        </mat-error>
        <mat-error *ngIf="routineForm.get('end_time').touched && routineForm.get('end_time').hasError('overTimeLimit')">
          Session hours cannot be more than 12 hours
        </mat-error>
      </mat-form-field>
    </td> 
    <td>
      <mat-form-field class="example-full-width w-140px" appearance="outline">
        <input type="text" matInput formControlName="fee" placeholder="$$$.cc" (blur)="updateData($event)">
        <mat-error *ngIf="routineForm.get('fee').touched &&  routineForm.get('fee').hasError('pattern')">
          Must be in the format $$$.cc
        </mat-error>
      </mat-form-field>
    </td>
    <td>
      <mat-form-field class="example-full-width w-140px" appearance="outline">
        <mat-label>Please select</mat-label>
        <mat-select formControlName="unit" (selectionChange)="updateData($event)">
          <mat-option value=1>Session</mat-option>
          <mat-option value=2>Hourly</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
    <td>
      <button *ngIf="index == 0 && !isDisabled" type="button" mat-mini-fab color="primary" (click)='addRow.emit()' title="Add row">
        <mat-icon class="col-white">add</mat-icon>
      </button>
      <button *ngIf="index > 0 && !isDisabled" type="button" mat-icon-button (click)="removeRow.emit()" class="bg-light-gray m-r-14" aria-label="Remove Row">
          <mat-icon>close</mat-icon>
      </button>
    </td>
</ng-container>