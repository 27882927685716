import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: '[app-routine-day]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './routine-day.component.html',
  styleUrls: ['./routine-day.component.sass']
})
export class RoutineDayComponent implements OnInit {
  @Input('group')
  public routineForm: FormGroup;
  @Input('index')
  public index: Number; 
  @Input('obj_name')
  public obj_name: String; 
  @Input('type')
  public type: Number; 
  @Input('dayIdx')
  public dayIdx: Number; 
  @Input('checker')
  public checker: boolean; 
  @Input('isDisabled')
  public isDisabled: boolean; 
  @Output("getCheckerChange") 
  getCheckerChange = new EventEmitter<any>();
  @Output("dataChange") 
  dataChange = new EventEmitter<any>();
  @Input('day')
  public day: String; 
  @Output("addRoutineRow") addRow: EventEmitter<any> = new EventEmitter();
  @Output("removeRoutineRow") removeRow: EventEmitter<any> = new EventEmitter();
  @Input('isCasualWithDate')
  public isCasualWithDate: boolean; 

  role_id = 0;

  constructor() { 
  }

  ngOnInit(): void {
    var user = JSON.parse(localStorage.getItem('user'));
    this.role_id = user['role_id'];
    if (this.isDisabled) {
      this.routineForm.disable();
    } else {
      if(this.routineForm.get('unit') && this.routineForm.get('fee')){
        if (this.role_id == 1 || this.role_id == 4) {
          this.routineForm.get('unit').enable();
          this.routineForm.get('fee').enable();
        } else {
          this.routineForm.get('unit').disable();
          this.routineForm.get('fee').disable();
        }
      }
  
      this.routineForm.get('end_time').valueChanges
      .subscribe(value => {
          if (value != '') {
            this.routineForm.get('start_time').updateValueAndValidity();
          }
      });
      this.routineForm.get('fee').valueChanges
      .subscribe(value => {
          this.routineForm.get('start_time').updateValueAndValidity();
      });
      this.routineForm.get('fee').valueChanges
      .subscribe(value => {
          this.routineForm.get('end_time').updateValueAndValidity();
      });
      this.routineForm.get('unit').valueChanges
      .subscribe(value => {
          this.routineForm.get('start_time').updateValueAndValidity();
      });
      this.routineForm.get('unit').valueChanges
      .subscribe(value => {
          this.routineForm.get('end_time').updateValueAndValidity();
      });
    }
  }

  updateData($event) {
    this.dataChange.emit({form: this.routineForm.getRawValue(), index: this.index, obj_name: this.obj_name});
    if (this.routineForm.get('start_time').value && this.routineForm.get('end_time').value && this.routineForm.valid) {
      this.checker = true;
    } else {
      this.checker = false;
    }
    this.getCheckerChange.emit({type: this.type, checker: this.checker, i: this.dayIdx, index: this.index, obj_name: this.obj_name});
  }

}
