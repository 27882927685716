import { Injectable } from "@angular/core";
import { DayCoverage, WeekCoverage } from "../models/coverage";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { ChildScheduleService } from "./child-schedule.service";
import { ChildAttendanceService } from "./child-attendance.service";
import { EducatorsService } from "src/app/accounts/educators.service";
import {
  ChildAttendance,
  ChildCasualSchedule,
  ChildSchedule,
} from "../models/attendance-row";
import { EducatorModel } from "src/app/accounts/fhc-ihc-educators/educator.model";

import * as moment from "moment";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ChildAttendanceUtilService {
  private _triggerValidate: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private _validation_errors: BehaviorSubject<string[]> = new BehaviorSubject(
    []
  );

  private _triggerReport: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _checkedChildIds: BehaviorSubject<number[]> = new BehaviorSubject([]);

  private _triggerSave: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get triggerValidate(): Observable<boolean> {
    return this._triggerValidate.asObservable();
  }

  get validation_errors(): Observable<string[]> {
    return this._validation_errors.asObservable();
  }

  get triggerReport(): Observable<boolean> {
    return this._triggerReport.asObservable();
  }

  get checkedChildIds(): Observable<number[]> {
    return this._checkedChildIds.asObservable();
  }

  get triggerSave(): Observable<boolean> {
    return this._triggerSave.asObservable();
  }

  public setTriggerValidate(value: boolean) {
    this._triggerValidate.next(value);
  }

  public setValidationErrors(errors: string[]) {
    this._validation_errors.next(errors);
  }

  public addToValidationErrors(error: string) {
    const currentValue = _.cloneDeep(this._validation_errors.getValue());
    currentValue.push(error);
    this.setValidationErrors(currentValue);
  }

  public setTriggerReport(value: boolean) {
    this._triggerReport.next(value);
  }

  public setCheckedChildIds(value: number[]) {
    this._checkedChildIds.next(value);
  }

  public setTriggerSave(value: boolean) {
    this._triggerSave.next(value);
  }

  constructor(
    private childScheduleService: ChildScheduleService,
    private childAttendanceService: ChildAttendanceService,
    private educatorService: EducatorsService
  ) {}

  computeHours(startTime: string, endTime: string): number {
    const datePrefix = "1970-01-01 "; // Arbitrary date, just to make the time strings valid
    const start = new Date(datePrefix + startTime);
    const end = new Date(datePrefix + endTime);
    const hours = (end.getTime() - start.getTime()) / 3600000;
    return hours;
  }

  splitIntoWeeks(
    start_day: Date,
    end_day: Date
  ): { start_date: Date; end_date: Date }[] {
    // Make sure to clone the dates to avoid side effects
    const week1Start = new Date(start_day);
    const week2End = new Date(end_day);

    // Set the end of the first week to the coming Sunday
    const week1End = new Date(start_day);
    week1End.setDate(week1Start.getDate() + (7 - week1Start.getDay()));

    // Set the start of the second week to the following Monday
    const week2Start = new Date(week1End);
    week2Start.setDate(week2Start.getDate() + 1);

    return [
      { start_date: week1Start, end_date: week1End },
      { start_date: week2Start, end_date: week2End },
    ];
  }

  getDatesBetween(startDate: Date, endDate: Date): Date[] {
    const dates: Date[] = [];
    let currentDate = new Date(startDate);

    while (currentDate.getTime() <= endDate.getTime()) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  computeWeeklyValues(days: DayCoverage[][]) {
    // console.log('days: ', days);

    const totalEducatorFee = days.reduce((acc, day) => {
      return acc + day.reduce((a, d) => a + d.educator_fee, 0);
    }, 0);

    const totalAdminLevy = days.reduce((acc, day) => {
      return acc + day.reduce((a, d) => a + d.admin_levy, 0);
    }, 0);

    const totalHours = days.reduce((acc, day) => {
      return acc + day.reduce((a, d) => a + d.booked_hours, 0);
    }, 0);

    const totalFoodFee = days.reduce((acc, day) => {
      return acc + (day[0].food_meal_fee ? day[0].food_meal_fee : 0);
    }, 0);

    const totalTravelFee = days.reduce((acc, day) => {
      return acc + (day[0].travel_fee ? day[0].travel_fee : 0);
    }, 0);

    return {
      totalEducatorFee: Math.round(totalEducatorFee * 100) / 100,
      totalAdminLevy: Math.round(totalAdminLevy * 100) / 100,
      totalHours: Math.round(totalHours * 100) / 100,
      totalFoodFee: Math.round(totalFoodFee * 100) / 100,
      totalTravelFee: Math.round(totalTravelFee * 100) / 100,
    };
  }

  validateMissingSignIns(
    bookedTimes: { start_time: string; end_time: string }[]
  ): string | null {
    const missingSignIns = [];

    if (bookedTimes.length === 0) {
      return "Missing sign in and sign out";
    }

    for (let i = 0; i < bookedTimes.length; i++) {
      const bookedTime = bookedTimes[i];

      if (bookedTime.start_time || bookedTime.end_time) {
        break;
      }

      if (!bookedTime.start_time && !bookedTime.end_time) {
        missingSignIns.push(bookedTime);
      }
    }

    if (missingSignIns.length > 0) {
      return "Missing sign in and sign out";
    }

    return null;
  }

  validateBrokenSignIns(
    bookedTimes: { start_time: string; end_time: string }[]
  ): string | null {
    const brokenSignIns = [];

    for (let i = 0; i < bookedTimes.length; i++) {
      const bookedTime = bookedTimes[i];

      if (bookedTime.start_time || bookedTime.end_time) {
        if (bookedTime.start_time && bookedTime.end_time) {
          continue;
        }

        brokenSignIns.push(bookedTime);
      }
    }

    if (brokenSignIns.length > 0) {
      return "Missing sign in or sign out.";
    }
    return null;
  }

  validateSignInWithinBookedTime(
    bookedTimes: { start_time: string; end_time: string }[],
    bookedStartTime: string,
    bookedEndTime: string
  ): string | null {
    const signInsNotWithinBookedTime = [];

    for (let i = 0; i < bookedTimes.length; i++) {
      const bookedTime = bookedTimes[i];

      if (bookedTime.start_time && bookedTime.end_time) {
        const isWithin = this.isWithinBookedTime(
          bookedStartTime,
          bookedEndTime,
          bookedTime.start_time,
          bookedTime.end_time
        );

        if (!isWithin) {
          signInsNotWithinBookedTime.push(bookedTime);
        }
      }
    }
    if (signInsNotWithinBookedTime.length > 0) {
      return "Sign in or sign out not within booked time.";
    }

    return null;
  }

  isWithinBookedTime(booked_start_time, booked_end_time, sign_in, sign_out) {
    const bookedStartTime = new Date(
      `1970-01-01T${booked_start_time}:00Z`
    ).getTime();
    const bookedEndTime = new Date(
      `1970-01-01T${booked_end_time}:00Z`
    ).getTime();
    const signInTime = new Date(`1970-01-01T${sign_in}:00Z`).getTime();
    const signOutTime = new Date(`1970-01-01T${sign_out}:00Z`).getTime();

    if (signInTime >= bookedStartTime && signOutTime <= bookedEndTime) {
      return true;
    }
  }

  validateStartAndEndTime(
    bookedTimes: { start_time: string; end_time: string }[]
  ): string | null {
    const signOutIsLessThanSignIn = [];

    for (let i = 0; i < bookedTimes.length; i++) {
      const bookedTime = bookedTimes[i];

      const signInTime = new Date(
        `1970-01-01T${bookedTime.start_time}:00Z`
      ).getTime();
      const signOutTime = new Date(
        `1970-01-01T${bookedTime.end_time}:00Z`
      ).getTime();

      if (signOutTime < signInTime) {
        signOutIsLessThanSignIn.push(bookedTime);
      }
    }

    if (signOutIsLessThanSignIn.length > 0) {
      return "Sign in time cannot be greater than sign out time";
    }

    return null;
  }

  validateSignInOverlaps(
    bookedTimes: { start_time: string; end_time: string }[]
  ): string | null {
    const overlaps = [];
    for (let i = 0; i < bookedTimes.length; i++) {
      if (!bookedTimes[i].start_time || !bookedTimes[i].end_time) {
        continue;
      }
      const startTimeA = this.convertToMinutes(bookedTimes[i].start_time);
      const endTimeA = this.convertToMinutes(bookedTimes[i].end_time);

      for (let j = i + 1; j < bookedTimes.length; j++) {
        if (!bookedTimes[j].start_time || !bookedTimes[j].end_time) {
          continue;
        }
        const startTimeB = this.convertToMinutes(bookedTimes[j].start_time);
        const endTimeB = this.convertToMinutes(bookedTimes[j].end_time);

        // Check for overlap
        if (startTimeA < endTimeB && endTimeA > startTimeB) {
          overlaps.push(bookedTimes[i]);
        }
      }
    }

    if (overlaps.length > 0) {
      return "Overlapping sigins.";
    }

    return null;
  }

  convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  setChildDailyAttendanceData(
    childId: number,
    educatorId: number,
    weekRange: "fortnightly" | "weekly",
    startDate: Date,
    endDate: Date,
    child_full_name: string,
    childSchedule: ChildSchedule,
    childCasualSchedule: ChildCasualSchedule,
    childAttendance: ChildAttendance[],
    educator: EducatorModel
  ) {
    let weeks = [];

    if (weekRange === "fortnightly") {
      weeks = this.splitIntoWeeks(startDate, endDate);
    } else {
      weeks = [
        {
          start_date: startDate,
          end_date: endDate,
        },
      ];
    }

    const weeklyChildAttendance = weeks.map((week) => {
      const days2D = this.setWeekChildAttendances(
        week.start_date,
        week.end_date,
        childSchedule,
        educator,
        childAttendance,
        childCasualSchedule,
        childId,
        child_full_name,
        [educatorId]
      );

      const {
        totalAdminLevy,
        totalEducatorFee,
        totalHours,
        totalFoodFee,
        totalTravelFee,
      } = this.computeWeeklyValues(days2D);

      return {
        startDate: week.start_date,
        endDate: week.end_date,
        year: startDate.getFullYear(),
        days: days2D,
        totalEducatorFee: totalEducatorFee,
        totalHours: totalHours,
        totalAdminLevy: totalAdminLevy,
        totalFoodMealFee: totalFoodFee,
        totalTravelFee: totalTravelFee,
      };
    });

    return weeklyChildAttendance;
  }



  setWeekChildAttendances(
    start_date: Date,
    end_date: Date,
    childSchedule: ChildSchedule,
    educator: EducatorModel,
    childAttendance: ChildAttendance[],
    childCasualSchedule: ChildCasualSchedule,
    child_id: number,
    child_full_name: string,
    educatorIds: number[]
  ): DayCoverage[][] {
    let days: DayCoverage[][] = [];

    // routine session
    if (childSchedule.session_type === 2) {
      const datesCovered = this.getDatesBetween(start_date, end_date);

      days = datesCovered.map((dc) => {
        const day = new Date(dc);

        // 0 - sunday, 1 - monday, ...
        const dayOfTheWeek = day.getDay();

        const {
          mon_sched,
          tue_sched,
          wed_sched,
          thu_sched,
          fri_sched,
          sat_sched,
          sun_sched,
        } = childSchedule;

        const weekSched = [
          sun_sched,
          mon_sched,
          tue_sched,
          wed_sched,
          thu_sched,
          fri_sched,
          sat_sched,
        ];

        // set the booked time
        const dailySched = weekSched[dayOfTheWeek];

        // check if this day has multiple booked hours
        if (dailySched.length === 0) {
          // throw new Error("No booked hours for this day");
          // return a blank Day Coverage
          const dayCoverage: DayCoverage = {
            child_attendance_id: null,
            schedule_id: childAttendance[0].schedule_id, // schedule id should be the same for any booked times
            child_id: child_id,
            child_name: child_full_name,
            attendance_date: moment(day).format("YYYY-MM-DD"),
            educator_ids: educatorIds,
            booked_start_time: null,
            booked_end_time: null,
            booked_hours: 0,
            educator_fee: 0,
            admin_levy:
              0,
            fees_session: "standard",
            booked_times: [],
            food_meal_fee: 0,
            travel_fee: 0,
            notes: "",
            has_booked_session: false,
            location: "no",
            is_absent: false,
            is_saved: false,
            is_submitted: false,
            submitted_at: null,
            absence_reason: "",
            absence_document_held:false,
          };
          return [dayCoverage];

        }

        const scheds = dailySched.map((sched, index) => {
          let bookedStartTime = sched.start_time;
          let bookedEndTime = sched.end_time;



          let attendanceDay = childAttendance.filter((ca) => {
            const signInTime = ca.sign_in
              ? moment(ca.sign_in).format("HH:mm")
              : null;
            const signOutTime = ca.sign_out
              ? moment(ca.sign_out).format("HH:mm")
              : null;
            return (
              moment(ca.date).isSame(moment(day)) &&
              this.isWithinBookedTime(
                bookedStartTime,
                bookedEndTime,
                signInTime,
                signOutTime
              )
            );
          });

          // if attendanceDay is empty, this means, no signs in from the database
          // check now if this day has no booked session
          if (attendanceDay.length === 0) {
            attendanceDay = childAttendance.filter((ca) => {
              return (
                moment(ca.date).isSame(moment(day))
              );
            });
          }

          let attendanceList = attendanceDay.map((ca) => {
            const signInTime = ca.sign_in
              ? moment(ca.sign_in).format("HH:mm")
              : null;
            const signOutTime = ca.sign_out
              ? moment(ca.sign_out).format("HH:mm")
              : null;

            return {
              start_time: signInTime,
              end_time: signOutTime,
            };
          });

          // TODO
          // attendanceDay[index] may not always have a value
          let otherDetails =
            attendanceDay[0] && attendanceDay[0].child_attendance_other_details
              ? attendanceDay[0].child_attendance_other_details
              : null;

          // if attendance row exist; need to update start_time and endtime
          if (attendanceDay[index]) {
            bookedStartTime = attendanceDay[index].sched_start;
            bookedEndTime = attendanceDay[index].sched_end;
          } else {
            bookedStartTime = sched.start_time;
            bookedEndTime = sched.end_time;
          }

          let childAttendanceId = attendanceDay[0]
            ? attendanceDay[0].child_attendance_id
            : null;
          let isSave = attendanceDay[0] ? attendanceDay[0].is_save : false;
          let isSubmitted = attendanceDay[0]
            ? attendanceDay[0].is_submitted
            : false;
          let submittedAt = attendanceDay[0]
            ? attendanceDay[0].submitted_at
            : null;

          // compute for the educator's fee
          const totalHours = this.computeHours(bookedStartTime, bookedEndTime);
          const fee = educator.fees[0];

          const total = parseFloat(fee.educators_rate) * totalHours;

          let adminLevy = Math.round(educator.admin_levy * totalHours * 100) / 100;
          let bookedHours = totalHours;
          let educatorFee = Math.round(total * 100) / 100;


          if (attendanceDay[index]) {
            bookedStartTime = attendanceDay[index].sched_start;
            bookedEndTime = attendanceDay[index].sched_end;
            childAttendanceId = attendanceDay[index].child_attendance_id;
            isSave = attendanceDay[index].is_save;
            isSubmitted = attendanceDay[index].is_submitted;
            submittedAt = attendanceDay[index].submitted_at;
            otherDetails = attendanceDay[index].child_attendance_other_details;

            if (!attendanceDay[index].has_booked_session) {
              adminLevy = 0;
              bookedHours = 0;
              educatorFee = 0;
            }

            if (attendanceDay[index].child_attendance_other_details) {
              if (attendanceDay[index].child_attendance_other_details.is_absent) {
                adminLevy = 0;
                bookedHours = 0;
                educatorFee = 0;
              }
            }
          }


          const dayCoverage: DayCoverage = {
            child_attendance_id: childAttendanceId,
            schedule_id: childAttendance[0].schedule_id, // schedule id should be the same for any booked times
            child_id: child_id,
            child_name: child_full_name,
            attendance_date: moment(day).format("YYYY-MM-DD"),
            educator_ids: educatorIds,
            booked_start_time: bookedStartTime,
            booked_end_time: bookedEndTime,
            booked_hours: bookedHours,
            educator_fee: educatorFee,
            admin_levy:
              adminLevy,
            fees_session: "standard",
            booked_times: attendanceList,
            food_meal_fee: otherDetails ? otherDetails.food_fee : 0,
            travel_fee: otherDetails ? otherDetails.travel_fee : 0,
            notes: otherDetails ? otherDetails.notes : "",
            has_booked_session: !!bookedStartTime && !!bookedEndTime,
            location: otherDetails ? otherDetails.location : "no",
            is_absent: otherDetails ? otherDetails.is_absent : false,
            is_saved: isSave,
            is_submitted: isSubmitted,
            submitted_at: submittedAt,
            absence_reason: otherDetails ? otherDetails.absence_reason : "",
            absence_document_held: otherDetails
              ? otherDetails.absence_document_held
              : false,
          };
          return dayCoverage;
        });

        return scheds;
      });
    } else {
      // casual session
    }

    return days;
  }

  isDateWithinRange(
    dateToCheck: string,
    startDate: string | Date,
    endDate: string | Date
  ): boolean {
    const momentDateToCheck = moment(dateToCheck);
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    // Check if the dateToCheck is between startDate and endDate (inclusive)
    return momentDateToCheck.isBetween(
      momentStartDate,
      momentEndDate,
      "day",
      "[]"
    );
  }
}
