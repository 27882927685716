import { Component, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attendance-export-modal',
  templateUrl: './attendance-export-modal.component.html',
  styleUrls: ['./attendance-export-modal.component.sass']
})
export class AttendanceExportModalComponent implements OnInit {
  isLoading = false;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  generate(select: MatSelect): void {
    this.modal.close(select.value);
  }
}
