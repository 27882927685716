import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phoneValue: string, country: string): any {
    // try {
    //   const first =  phoneValue.substring(0, 3);
    //   const second =  phoneValue.substring(3, 6);
    //   const third =  phoneValue.substring(6, 9);
    //   let phoneNumber = phoneValue;
    //   if (country === 'aus'){
    //     phoneNumber = '+61 ' + first + ' ' + second + ' ' + third;
    //   }
    //   return phoneNumber;
    // } catch (error) {
    //   return phoneValue;
    // }
    return phoneValue;
  }
}
