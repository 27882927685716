import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from "../../global/global.service";

@Component({
  selector: 'app-child-attendance-modal',
  templateUrl: './child-attendance-modal.component.html',
  styleUrls: ['./child-attendance-modal.component.sass']
})
export class ChildAttendanceModalComponent implements OnInit {

  @Input()
  public data: any;

  constructor(
    public activeModal: NgbActiveModal,
    public globeService: GlobalService) { }

  ngOnInit(): void {
  }

}
