<div class="modal-header editRowModal">
    <h4 class="modal-title" id="modal-basic-title">Enable Child Attendance</h4>
    <button mat-icon-button (click)="modal.close()" class="bg-light-gray m-r-14" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body">
    <div class="row p-l-r-30">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Select Inactive Child</mat-label>
            <mat-select #select multiple>
                <div class="select-all">
                    <input matInput placeholder="Search..." (keyup)="onKey($event.target.value)" style="height: 40px;">
                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection()" *ngIf="selectedRecipients.length > 1">Select All</mat-checkbox>
                </div>
                <mat-option (click)="optionClick()" *ngFor="let child of selectedRecipients;let key = index"
                    value="{{child.child_id}}">{{ child.child_givenname }} {{ child.child_surname }} - {{ formatDateWithoutTime(child.created_at) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="modal-footer">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
        <button mat-flat-button color="primary" [disabled]="isLoading" [class.not-allowed]="isLoading"
            [class.spinner]="isLoading" class="pull-right  text-white" type="submit"
            (click)="saveModal(select)">Add</button>
        <button (click)="modal.close()" class="pull-right cancel-btn text-white mr-3 bg-light-red" type="button"
            mat-raised-button data-dismiss="modal">Cancel</button>
    </div>
</div>