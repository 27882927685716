import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalService } from "src/app/global/global.service";

@Injectable({
  providedIn: "root",
})
export class ChildScheduleService {
  constructor(private http: HttpClient, private globeService: GlobalService) {}

  getChildSchedule(childId: number) {
    return this.http.get(
      this.globeService.baseUrl + "child-schedule/" + childId,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildCasualSchedule(childId: number) {
    return this.http.get(
      this.globeService.baseUrl + "child-casual-schedule/" + childId,
      this.globeService.getHttpOptionsAuth()
    );
  }
}
