import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit, Output, EventEmitter, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { GlobalService } from "../../global/global.service";
import { AccountService } from "../../accounts/account.service";
import { SimpleDialogComponent } from "../../ui/modal/simpleDialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogformComponent } from "../../ui/modal/dialogform/dialogform.component";
import { SearchMenuComponent } from "../../ui/modal/search-menu/search-menu.component";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  // @Output() closeModal = new EventEmitter<boolean>();
  @ViewChild('formModal') formModal: NgbModal;
  modalRef: NgbModalRef;
  public config: any = {};
  isNavbarCollapsed = true;
  userData: any = [];
  loggedUser: any = [];
  notificationUnreadTotal = 0;
  notificationUnread: any = [];
  selectedChild: any = [];
  filters: any = [];
  notifications: any = [];
  notificationsRead = 0;
  error = '';
  currentPage = 1;
  pageSize = 5;
  selectedType = '';
  selectedRef = '';
  notificationRef = '';
  posstion = 'top';
  simpleDialog: MatDialogRef<SimpleDialogComponent>;
  branch_count = 0;
  modalConfig = {
    ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true,
    backdrop: false
  };
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private globeService: GlobalService,
    private accountService: AccountService,
    private dialogModel: MatDialog,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));

  }

  ngOnInit() {
    this.config = this.configService.configData;

    this.setUserData();

    this.globeService.centerNameEvent.subscribe(data => {
      this.userData['user']['center_name'] = data;
    });

    this.globeService.profilePhotoEvent.subscribe(data => {
      this.userData['profile_image'] = data;
    });

    this.globeService.notifications.subscribe(data => {
      if (data) {
        this.notifications = data;
        this.notificationUnreadTotal = data['unread_total'];
        this.notificationUnread = data['unread'];
      }
    });

    this.globeService.getBranchCount.subscribe(data => {

      this.branch_count = data;

    })
    caches
  }

  dialog() {
    const dialogRef = this.dialogModel.open(SearchMenuComponent, {
      width: '640px',
      disableClose: true
    });
  }

  setUserData() {
    var data = localStorage.getItem('user');
    this.userData = JSON.parse(data);
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }


    this.branch_count = parseInt(localStorage.getItem('branch_count'));
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }
  logout() {
    localStorage.setItem('STATE', 'false');
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  switchChild() {
    if (this.router.url == '/admin/dashboard') {
      this.globeService.changeChildrenChange(true);
    } else {
      this.router.navigate(['/admin/dashboard'], { queryParams: { 'child-pop-up': true, 'returnUrl': '/' } });
    }
  }

  switchBranch() {

    this.route.queryParams.subscribe(params => {
      if (params['branch-pop-up']) {
        this.globeService.changeBranch(true);
      }
    });
    if (this.router.url == '/admin/dashboard') {
      this.globeService.changeBranch(true);
    } else {
      this.router.navigate(['/admin/dashboard'], { queryParams: { 'branch-pop-up': true } });
    }

  }

  navigateProfile() {
    this.router.navigate(['/admin/accounts/me/profile'], { queryParams: { 'role': 'me', 'ref': 'auth' } });
  }
  navigateSupportAcess() {
    this.router.navigate(["/admin/support-acess"]);
  }

  openNotificationItem(event, notification) {
    event.preventDefault();
    this.selectedRef = notification['ref_table_ref'];
    this.selectedType = notification['ref_table'];
    this.notificationRef = notification['notification_ref'];
    this.markAsRead(this.notificationRef, notification['is_read']);
    this.refreshList();

    switch (this.selectedType) {

      case 'enrolments_form':
        this.setRedirectUri('/admin/child-enrollment/enrollment-authorisation');
        return;

      case 'inductions':
        this.setRedirectUri('/admin/child-enrollment/inductions-list');
        return;

      case 'records_of_visitors_fds_form':
        this.setRedirectUri('/admin/physical-env-form/record-of-visitors-to-fdc');
        return;

      case 'inclusion_support_form':
          this.setRedirectUri('/admin/families-communities/inclusion/inclusion-support');
          return;

      case 'food_s_n_change_forms':
        this.setRedirectUri('/admin/health-safety-form/physical-env-form/sleep-food-nappy-change-record');
        return;
        
      case 'venue_assessment_form':
        const url = `admin/staff-and-educator/venue-assessment?ref=${notification['ref_table_ref']}`;
        window.open(url, '_blank');
        return

      case 'daily_home_safety_check_form':
        this.setRedirectUri('/admin/physical-env-form/daily-home-safety-checklist');
        return;

      default:
        this.modalRef = this.modalService.open(this.formModal, this.modalConfig);
        break;
    }
  }

  setRedirectUri(uri) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${uri}`], { queryParams: { id: this.selectedRef }})
    );
    window.open(url, '_blank');
  }

  markAsRead(notificationRef: any, readStatus) {
    if (readStatus == 0) {
      this.accountService.markAsReadNotifications(notificationRef).subscribe((res) => {
        let index = this.notifications.findIndex(element => {
          return element.notification_ref == notificationRef;
        });

        this.notifications[index]['is_read'] = 1;

        let indexUnread = this.notificationUnread.findIndex(element => {
          return element.notification_ref == notificationRef;
        });
        this.notificationUnread.splice(indexUnread, 1);

        this.notificationUnreadTotal--;
        this.globeService.showSuccessNotification('Notification status updated.');
      }, (error) => {
        this.error = error.error.error;
        this.globeService.showErrorNotification('Something went wrong!');
      }
      );
    }
  }

  refreshList() {
    this.globeService.notifications.subscribe(data => {
      if (data) {
        this.notifications = data;
        this.notificationUnreadTotal = data['unread_total'];
        this.notificationUnread = data['unread'];
      }
    });
  }

  closeModelFun($event: boolean) {
    if ($event) {
      this.modalRef.close();
    }
  }
}
