<!-- <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
    <mat-label>Year Test</mat-label>
    <input matInput formControlName="year_id" [matDatepicker]="pickerFilter"
      (click)="pickerFilter.open()">
    <mat-datepicker-toggle matSuffix [for]="pickerFilter"></mat-datepicker-toggle>
    <mat-datepicker #pickerFilter (dateChange)="onDateSelected($event)"></mat-datepicker>
</mat-form-field>   -->

<mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Year</mat-label>
    <input
      matInput
      placeholder="YYYY"
      [matDatepicker]="datePicker"
      [(ngModel)]="selectedDate"
      (focus)="datePicker?.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker
      #datePicker
      color="primary"
      startView="multi-year"
      (yearSelected)="onYearSelected($event, datePicker)"
      [startAt]="minDate"
    ></mat-datepicker>
</mat-form-field>
