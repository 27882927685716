import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: 'root',
})

export class StaffAndEducatorService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }

  createCenter(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-care-center', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getCenters(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'account/get-care-centers?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteCenter(rowElement: any) {
    return this.http.get(this.globeService.baseUrl + 'account/delete-care-center?ref=' + rowElement, this.globeService.getHttpOptionsAuth());
  }

  getChildren(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'account/get-children?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getBranches(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'account/get-branches?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createBranch(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-branch', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteBranch(rowElement: any) {
    return this.http.get(this.globeService.baseUrl + 'account/delete-branch?ref=' + rowElement, this.globeService.getHttpOptionsAuth());
  }

  getAllCenters() {
    return this.http.get(this.globeService.baseUrl + 'account/get-centers-list', this.globeService.getHttpOptionsAuth());
  }

  getStates() {
    return this.http.get(this.globeService.baseUrl + 'general/get-states', this.globeService.getHttpOptionsAuth());
  }

  getCountries() {
    return this.http.get(this.globeService.baseUrl + 'general/get-countries', this.globeService.getHttpOptionsAuth());
  }
  getAllBranches(centerId) {
    return this.http.get(this.globeService.baseUrl + 'account/get-branches-list?center_id=' + centerId, this.globeService.getHttpOptionsAuth());
  }

  getParents(filters, pageSize, currentPage) {
    return this.http.post(this.globeService.baseUrl + 'account/get-parents?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getBranchesByCenter(centerID) {
    return this.http.get(this.globeService.baseUrl + 'account/get-branches-by-center?center_id=' + centerID, this.globeService.getHttpOptionsAuth());
  }

  getDirectors(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'account/get-directors?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getEducators(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'account/get-educators?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getEducatorsList() {
    return this.http.get(this.globeService.baseUrl + 'account/educators-list', this.globeService.getHttpOptionsAuth());
  }

  getEducatorsDropdown() {
    return this.http.get(this.globeService.baseUrl + 'account/get-educators-dropdown', this.globeService.getHttpOptionsAuth());
  }

  createDirector(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-director', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteDirector(rowElement: any) {
    return this.http.get(this.globeService.baseUrl + 'account/delete-director?ref=' + rowElement, this.globeService.getHttpOptionsAuth());
  }
  deleteEducator(rowElement: any) {
    return this.http.get(this.globeService.baseUrl + 'account/delete-educator?ref=' + rowElement, this.globeService.getHttpOptionsAuth());
  }

  getDirector() {
    return this.http.get(this.globeService.baseUrl + 'account/get-director', this.globeService.getHttpOptionsAuth());
  }
  getEducator() {
    return this.http.get(this.globeService.baseUrl + 'account/get-educator', this.globeService.getHttpOptionsAuth());
  }

  createEducator(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-educator', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createParent(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-parent', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createChild(data) {
    return this.http.post(this.globeService.baseUrl + 'account/create-child', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getPreAssessmentList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-pre-assessment-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createPreAssessmentForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-pre-assessment', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deletePreAssessmentForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-pre-assessment?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createDailyReflectionForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-daily-reflection', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getDailyReflectionList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-daily-reflection-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteDailyReflectionForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-daily-reflection?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewDailyReflections(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-daily-reflections-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadDailyReflections(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-daily-reflections-form', data, this.globeService.getHttpOptionsBlob());
  }

  getViewForPreAssessmentEducatorAspirantAssessment(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-pre-assessment-educator-aspirant-assessment-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadPreAssessmentEducatorAspirantAssessment(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-pre-assessment-educator-aspirant-assessment-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadPreAssessmentEduCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/pre-assessment-educator-csv', data, this.globeService.getHttpOptionsBlob());
  }

  getChildEducator(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-child-educator', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createFoodAndSleepForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-food-and-Sleep', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteFoodAndSleepForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-food-and-Sleep?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getFoodAndSleepList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-food-and-sleep?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getTableRowData() {
    return this.http.get(this.globeService.baseUrl + 'forms/get-venue-row-data', this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadFoodSleepAndRestTimeDailyChart(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-food-sleep-and-rest-time-daily-chart', data, this.globeService.getHttpOptionsBlob());
  }

  viewFoodSleepAndRestTimeDailyChart(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-food-sleep-and-rest-time-daily-chart', data, this.globeService.getHttpOptionsAuth());
  }

  createVenueAssessmentForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-venue-assessment', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getVenueAssessmentList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-venue-assessment-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteVenueAssessmentForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-venue-assessment?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  // Educator leave form
  createEducatorLeaveForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator-leave', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorLeaveList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-leave-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorLeaveForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator-leave?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewEducatorLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-leave-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-leave-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadEducatorLeaveCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/educator-leave-csv', filters, this.globeService.getHttpOptionsBlob());
  }

  // Educator permanent leave form
  createEducatorPLeaveForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator-p-leave', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorPLeaveList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-p-leave-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorPLeaveForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator-p-leave?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewEducatorPLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-p-leave-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorPLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-p-leave-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorPLeaveCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/educator-permanent-leave-csv', data, this.globeService.getHttpOptionsAuth());
  }


  // Educator clearance item
  createEducatorClearanceForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator-clearance-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorClearanceForm(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-clearance-form?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorClearanceForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator-clearance-form?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewEducatorClearanceForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-clearance-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorClearanceForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-clearance-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadEducatorClearanceCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/educator-clearance-csv', data, this.globeService.getHttpOptionsBlob());
  }


  // Educator care relative
  createEducatorCareRelativeForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator-care-relative-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorCareRelativeForm(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-care-relative-form?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorCareRelativeForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator-care-relative-form?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewEducatorCareRelativeForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-care-relative-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorCareRelativeForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-care-relative-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadEducatorCareRelativeCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/educator-care-relative-csv', data, this.globeService.getHttpOptionsBlob());
  }


  // Educator document checklist
  getTableRowDataChecklist() {
    return this.http.get(this.globeService.baseUrl + 'forms/get-document-checklist', this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createEducatorChecklistForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator-checklist-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorChecklistForm(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-checklist-form?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorChecklistForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator-checklist-form?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewEducatorChecklistForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-checklist-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadEducatorChecklistForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-checklist-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadResidenceOrApprovedVenueAssessmentForm(data: { venue_assessment_form_ref: string[] | any; type: number }) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-residence-or-approved-venue-assessment', data, this.globeService.getHttpOptionsBlob());
  }

  deleteReliefEducatorForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-relief_educator?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createReliefEducatorForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-relief-educator', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getReliefEducatorList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-relief-educator-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  getChildrenByEducatorId(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-children-by-educator', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getParentByChilId(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-parent-by-child-id', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadEducatorDevelopmentAppraisal(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-educator-development-appraisal', data, this.globeService.getHttpOptionsAuth());
  }

  downloadReliefEducatorNotificationAndPermission(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-relief-educator-notification-and-Permission', data, this.globeService.getHttpOptionsAuth());
  }

  downloadReliefEducatorNotificationCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/relief-educator-notification-csv', filters, this.globeService.getHttpOptionsAuth());
  }

  downloadMonitoringSupportVisitReport(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-monitoring-support-visit-report', data, this.globeService.getHttpOptionsAuth());
  }

  nofityToParent(ref: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/notify-relief-notification?ref=' + ref, this.globeService.getHttpOptionsAuth());
  }

  getStaffMembers() {
    return this.http.get(this.globeService.baseUrl + 'forms/get-staff-members', this.globeService.getHttpOptionsAuth());
  }

  notificationDevelopmentAppraisal(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-development-appraisal', data, this.globeService.getHttpOptionsAuth());
  }

  notificationMonitoringSupportVisitReport(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/monitoring-support-visit', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEducatorLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-leave', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEducatorPLeave(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-p-leave', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEducatorCareRelative(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-care-relative', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEducatorClearance(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-clearance-item', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsReliefEducator(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/relief-educator', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsEducatorDocumentChecklist(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/educator-document-checklist', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsVenueAssessments(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/venue-assessment', data, this.globeService.getHttpOptionsAuth());
  }

  createPreAssessmentStaffForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-pre-assessment-staff', data, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getPreAssessmentStaffList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-pre-assessment-staff-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deletePreAssessmentStaffForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-pre-assessment-staff?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadPreAssessmentStaffAspirantAssessment(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-pre-assessment-staff-aspirant-assessment-form', data, this.globeService.getHttpOptionsBlob());
  }
}
