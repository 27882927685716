import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: 'root',
})
export class ManagementFormService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }


  getList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'education/get-daily-reflections?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  createGrievanceForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-grievance', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getGrievanceList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-grievance-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteGrievanceForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-grievance?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }


  createServicePoliciesForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-service-policies-feedback', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getServicePolicesList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-service-policies-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteServicePoliciesForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-service-policies-feedback?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorsList(childId = null) {
    return this.http.get(this.globeService.baseUrl + 'account/educators-list?child_id=' + childId, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createFirstAidCheckForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-first-aid-check', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getFirstAidCheckList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-first-aid-check?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteFirstAidCheckForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-first-aid-check?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createDailyHomeSafetyCheckForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-daily-home-safety-check', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getDailyHomeSafetyCheckList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-daily-home-safety-check?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }


  deleteDailyHomeSafetyCheckForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-daily-home-safety-check?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadDocument(selectedCell) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-grievance', selectedCell, this.globeService.getHttpOptionsBlob());
  }

  downloadGrievanceCSV(selectedCell) {
    return this.http.post(this.globeService.baseUrl + 'reports/grievance-csv', selectedCell, this.globeService.getHttpOptionsBlob());
  }

  viewGrivenceDocument(selectedCell) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-grievance', selectedCell, this.globeService.getHttpOptionsAuth());
  }

  downloadDailyHomeSafetyCheckList(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-daily-home-safety-check-list', data, this.globeService.getHttpOptionsBlob());
  }

  getRiskEducatorsList() {
    return this.http.get(this.globeService.baseUrl + 'account/risk-educators-list', this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getViewForDailyHomeSafetyCheckList(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-daily-home-safety-check-list', data, this.globeService.getHttpOptionsAuth());
  }

  getViewForFirstAidCheckForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-first-aid-check-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadFirstAidCheckForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-first-aid-check-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadFirstAidCheckFormCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/first-aid-check-form-csv', filters, this.globeService.getHttpOptionsBlob());
  }

  getViewForServicePoliciesFeedbackForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-service-policies-feedback-form', data, this.globeService.getHttpOptionsAuth());
  }

  downloadServicePoliciesFeedbackForm(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-service-policies-feedback-form', data, this.globeService.getHttpOptionsBlob());
  }

  downloadServicePoliciesFeedbackCSV(data) {
    return this.http.post(this.globeService.baseUrl + 'reports/service-policies-feedback-csv', data, this.globeService.getHttpOptionsBlob());
  }

  createMissedMonitoringForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-missed-monitoring-form', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getMissedMonitoringList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-missed-monitoring-form?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteMissedMonitoringData(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-missed-monitoring?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getTableRowData() {
    return this.http.get(this.globeService.baseUrl + 'forms/get-document-checklist', this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  viewMissedMonitoringAndSupportVisitRecord(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-missed-monitoring-and-support-visit-record', data, this.globeService.getHttpOptionsAuth());
  }

  downloadMissedMonitoringAndSupportVisitRecord(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-missed-monitoring-and-support-visit-record', data, this.globeService.getHttpOptionsAuth());
  }

  downloadMissedMonitoringCSV(filters) {
    return this.http.post(this.globeService.baseUrl + 'reports/missed-monitoring-csv', filters, this.globeService.getHttpOptionsAuth());
  }


  createAssessmentEducatorForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-assessment-educator-background', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getAssessmentEducatorList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-assessment-educator-background-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }
  getAssessmentStaffList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-assessment-staff-background-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteAssessmentEducatorForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-assessment-educator-background?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  createEducatorPerformanceeForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-educator_performance', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  getEducatorPerformanceList(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(this.globeService.baseUrl + 'forms/get-educator-performance-list?page_size=' + pageSize + '&page_no=' + currentPage, filters, this.globeService.getHttpOptionsAuth());
  }

  deleteEducatorPerformanceForm(formRef: any) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-educator_performance?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }
  getDirectorList() {
    return this.http.get(this.globeService.baseUrl + 'forms/directors-list', this.globeService.getHttpOptionsAuth());
  }

  downloadAssessmentEducatorBackgroundAndScreening(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-assessment-educator-background-and-screening', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsMissedMonitoring(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/missed-monitoring', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsGrievance(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/grievance', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsServicePoliciesFeedback(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/service-polices-feedback', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsFirstAidCheckForm(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/first-aid-check', data, this.globeService.getHttpOptionsAuth());
  }

  notificationsDailyHomeCheckList(data) {
    return this.http.post(this.globeService.baseUrl + 'notify/daily-home-check-list', data, this.globeService.getHttpOptionsAuth());
  }

  createAssessmentStaffForm(xForm: FormData) {
    return this.http.post(this.globeService.baseUrl + 'forms/create-assessment-staff-background', xForm, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  deleteAssessmentStaffForm(formRef) {
    return this.http.get(this.globeService.baseUrl + 'forms/delete-assessment-staff-background?ref=' + formRef, this.globeService.getHttpOptionsAuthWithOutContentType());
  }

  downloadAssessmentStaffBackgroundAndScreening(data) {
    return this.http.post(this.globeService.baseUrl + 'forms/download-assessment-staff-background-and-screening', data, this.globeService.getHttpOptionsAuth());
  }
}
