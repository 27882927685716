<div class="row p-15">
  <h4 class="text-black" *ngIf="headerHide != 1">Food, Sleep, Rest & Nappy Chart</h4>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <div class="m-t-15" [innerHTML]="htmlBody"></div>
    <ng-container *ngIf="loggedUser['role_id'] ==  6">
      <h5 class="form-group-heading">Parent Feedback</h5>
      <div class="row">
        <div class="col-12"  *ngFor="let feedback of parentsFeedback">
          <p><strong>{{ feedback['child_name'] }}'s parent - {{ feedback['parent_name']}}</strong> ({{ feedback['created_date'] | date : 'longDate' }}) : <br/> {{ feedback['feedback'] }}</p>
        </div>
      </div>
      <p *ngIf="parentsFeedback.length < 1">No feedback found.</p>
    </ng-container>
    <br>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
    <form class="register-form filter-form" [formGroup]="sectionForm" *ngIf="loggedUser['role_id'] ==  6">
      <h5 class="form-group-heading">Add new feedback</h5>
      <div class="row" *ngIf="data['feedback'] == null">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
            <textarea matInput formControlName="feedback" rows="2" placeholder="feedback"></textarea>
          </mat-form-field>
        </div>
      </div>
      <input type="hidden" formControlName="ref">
      <input type="hidden" formControlName="parent_id">
      <input type="hidden" formControlName="child_id">
      <br/>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0" *ngIf="data['feedback'] == null">
        <button mat-flat-button color="primary"  (click)="formSubmit(sectionForm)" class="pull-right  text-white" type="submit">
          Submit
        </button>
        <button   class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" (click)="modalClose()" mat-raised-button data-dismiss="modal">Close</button>
      </div>
    </form>
  </div>
</div>

