import { AuthService } from 'src/app/core/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  hide = true;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['admin', Validators.required],
      password: ['admin', Validators.required],
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {}
  // onSubmit() {
  //   this.submitted = true;
  //   this.error = '';
  //   if (this.loginForm.invalid) {
  //     this.error = 'Username and Password not valid !';
  //     return;
  //   } else {
  //     this.authService
  //       .login(this.f.username.value, this.f.password.value)
  //       .subscribe(
  //         (res) => {
  //           if (res.success) {
  //             this.router.navigate(['/dashboard/main']);
  //           } else {
  //             this.error = 'Invalid Login';
  //           }
  //         },
  //         (error) => {
  //           this.error = error;
  //           this.submitted = false;
  //         }
  //       );
  //   }
  // }
}
