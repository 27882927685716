import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SharedComponentService} from "../../shared-component.service";
import {GlobalService} from "../../../global/global.service";
import {FamiliesCommunitiesFormService} from "../../../families-communities-form/families-communities-form.service";

@Component({
  selector: 'app-child-termination-view',
  templateUrl: './child-termination-view.component.html',
  styleUrls: ['./child-termination-view.component.sass']
})
export class ChildTerminationViewComponent implements OnInit {
  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted =  false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  signatureLength = 0;
  currentIndex: any = -1;
  htmlBody = null;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private familyService: FamiliesCommunitiesFormService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: [''],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
      date: [''],
      signature: [''],
      request_type: ['']
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'termination_care',
      notification_ref: this.notificationRef,
      request_type: 'own-table'
    });
    this.getNotification();
    if(this.loggedUser['role_id'] == 6){
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }
  }

  getNotification() {
    this.htmlBody = null;
    var data = {termination_ref: this.rowRef, type : 2,};
    this.familyService.downloadTerminationOfCareForm(data).subscribe(res => {
      this.htmlBody = res['view_html'];
      this.signatureLength  = res['signature_length'];
    },error => {
      this.globeService.showErrorNotification(error.statusText);
    });
  }

  formSubmit(sectionForm: any) {

    this.sectionForm.patchValue({
      date: this.globeService.formatDatetoServerSide(this.sectionForm.value.date),
      signature: this.signatureImg,
    });

    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    this.shardService.updateParentSignature(this.sectionForm.value).subscribe((res) => {
        this.globeService.showSuccessNotification('Successfully updated');
        this.modalClose();
      }, (error) => {
        this.error = error.error.error;
        this.globeService.showErrorNotification('Something went wrong!');
      }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

  removeSignature() { this.isNewSignature = true; }

  signatureImgOutput($event) { this.signatureImg = $event; }


}
