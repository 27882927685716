import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../core/service/auth.service";
import { EsiService} from  "./../esi.service";
import Swal from "sweetalert2";
// import { any } from "codelyzer/util/function";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-esi-login',
  templateUrl: './esi-login.component.html',
  styleUrls: ['./esi-login.component.css']
})
export class EsiLoginComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private esiService: EsiService,
    private route: ActivatedRoute,
    private router: Router,
  ) 
  {  }
  @ViewChild("first", { static: true }) first: ElementRef;
  @ViewChild("second", { static: true }) second: ElementRef;
  @ViewChild("third", { static: true }) third: ElementRef;
  @ViewChild("fourth", { static: true }) fourth: ElementRef;
 
  hide = true;
  submitted = false;
  loginForm: FormGroup;
  validLogin = false;
  display: string = "";
  service_name: string = ""
  loggedUser: any = [];
  verifiedUser: any = [];
  numpadRows: number[][] = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9]
  ];

  valid_phone = false;
  pincode_exist = false;
  position: number;
  disableSendPinBtn = true;
  initial_pin = true;
  first_pin: number;
  second_pin: number;

  currentDate: Date = new Date();
  private intervalId: any;

  login_data : any = [];

  verified_phone_user_id: number;
  verified_phone_user_role_id: number;
  selection_portal = '';
  enable_selection_portal = false;
  IsFDCEd = false;
  ngOnInit(): void {
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000); // Update every 1000 milliseconds (1 second)


    this.loginForm = this.formBuilder.group({
      name: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.position = 1;
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
  private updateTime() {
    this.currentDate = new Date();
  }
  /**
   * ESIlogin function
   */
  async onSubmitESignIN() {
    localStorage.clear();
    this.submitted = true;
    // Add your login logic here
    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    } 
    else 
    {
      await this.authService.loginESI(this.loginForm.value).subscribe((res) => {
          this.login_data = res;
          if(res)
          {
            if(res['user'].account_details.center_type_id == 2 && res['user'].role_id == 7)
              this.IsFDCEd = true;
            else
              this.IsFDCEd = false;
          }
          localStorage.setItem('STATE', 'false');
          localStorage.setItem('access_token', res['access_token']);
          localStorage.setItem('user', JSON.stringify(res['user']));
          this.service_name = res['user']['account_details']['center_name'];
          this.submitted = false;
          this.validLogin = true;
        }, (errorMSG) => {
          this.popUpErrorMsg(errorMSG.error.message);
          this.submitted = false;
          this.validLogin = false;
          return;
        }
      );
    }
  }
  /**
   * Verify Phone Function
   */
  verifyPhone()
  {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.esiService.verifyPhone(this.display, this.selection_portal).subscribe((res) => {
      localStorage.setItem('verified_phone_user', JSON.stringify(res['data']));
      this.verified_phone_user_id = res['data']['user_id'];
      this.verified_phone_user_role_id= res['data']['role_id'];
      this.valid_phone = true;
      this.checkPincodeExist();
    }, (errorMSG) => {
      this.popUpErrorMsg(errorMSG.error.error);
      this.valid_phone = false;
    });
  }
  /**
   * Check If PIN already setup
   */
  checkPincodeExist()
  {
    // console.log('checkPincodeExist');
    // console.log(localStorage);
    // console.log('verified_phone_user_id = ' + this.verified_phone_user_id );
    // console.log('verified_phone_user_role_id = ' + this.verified_phone_user_role_id );
    this.verifiedUser = JSON.parse(localStorage.getItem('verified_phone_user'));
    // console.log(this.verifiedUser );
    
    this.esiService.checkPincodeExist(this.verified_phone_user_id).subscribe((res) => {
      // console.log(res);
      this.pincode_exist = true;
    }, (errorMSG) => {
      // console.log(errorMSG);
      if(errorMSG.error.error == 'Pin code is not yet setup')
        this.pincode_exist = false;
    });
  }
  /**
   * Submit PIN Either to Add or Pin Verification
   */
  submitPin()
  {
    if(this.pincode_exist == true)
    {
      const xForm = new FormData();
      this.esiService.verifyPin(this.getPinVal(), this.verified_phone_user_id).subscribe((res) => {
        // console.log(res);
        //// add code to save login info and redirect to ESI Dashboard
        localStorage.setItem('STATE', 'true');
        localStorage.setItem('esi_mode', 'true');
        localStorage.setItem('verified_phone_user_id', this.verified_phone_user_id.toString());
        localStorage.setItem('verified_phone_user_role_id', this.verified_phone_user_role_id.toString());
        this.router.navigate(['/esi/dashboard']);
      }, (errorMSG) => {
        // console.log(errorMSG);
        this.popUpErrorMsg(errorMSG.error.error);
      });
    }
    else
    {
      // console.log('initial_pin' + this.initial_pin);
      if(this.initial_pin)
      {
        this.first_pin = this.getPinVal();
        // console.log('first_pin');
        // console.log(this.first_pin);
        if(this.first_pin == 0)
          return;
        this.initial_pin = false;
        this.clearPinData();
      }
      else
      {
        this.second_pin = this.getPinVal();
        // console.log('second_pin');
        // console.log(this.second_pin);
        if(this.second_pin == 0)
          return;
        const xForm = new FormData();
        xForm.append('user_id', this.verified_phone_user_id.toString());
        xForm.append('pincode', this.first_pin.toString());
        xForm.append('confirm_pincode', this.second_pin.toString());
        this.esiService.addPinCode(xForm).subscribe((res) => {
          // console.log(res);
          this.clearPinData();
          this.pincode_exist = true;
        }, (errorMSG) => {
          // console.log(errorMSG);
          this.popUpErrorMsg(errorMSG.error.error);
        });
      }
    }

  }

  fogotPin()
  {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      text: 'Login to your ACESS account to reset your pin.',
      icon: 'info'
    })
  }
  selectionPortal(mode: string)
  {
    this.selection_portal = mode;
  }
  /**
   * Display value to Input field
   * Phone validation
   */
  appendToDisplay(number: number) {
    this.display =   this.display + number;
  }
  /**
   * Delete input one at a time
   * Phone validation
   */
  deleteLastDigit() {
    let val = this.display.slice(0, -1);
    this.display = val;
  }
  /**
   * Delete all input
   * Phone validation
   */
  clearInputData()
  {
    this.display = "";
  }
  /**
   * Display value per Input
   * PIN validation
   */
  enter(n: number) {
    if (this.position < 5) {
      this.disableSendPinBtn = true;
      if (this.position === 1) {
        this.first.nativeElement.value = n;
      }
      if (this.position === 2) {
        this.second.nativeElement.value = n;
      }
      if (this.position === 3) {
        this.third.nativeElement.value = n;
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = n;
        this.disableSendPinBtn = false;
      }
      this.position++;
      // console.log(
      //     "position is " +
      //     this.position +
      //      " " +
      //    "PIN is " +
      //   this.first.nativeElement.value +
      //     this.second.nativeElement.value +
      //     this.third.nativeElement.value +
      //     this.fourth.nativeElement.value
      // );
    }
  }
  /**
   * Delete PIN one at a time
   * PIN validation
   */
  back() {
    this.disableSendPinBtn = true;
    if (this.position > 1) {
      this.position--;
      if (this.position === 1) {
        this.first.nativeElement.value = "";
      }
      if (this.position === 2) {
        this.second.nativeElement.value = "";
      }
      if (this.position === 3) {
        this.third.nativeElement.value = "";
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = "";
      }
      // console.log(
      //    "position is " +
      //     this.position +
      //     " " +
      //     "PIN is " +
      //   this.first.nativeElement.value +
      //     this.second.nativeElement.value +
      //     this.third.nativeElement.value +
      //     this.fourth.nativeElement.value
      // );
    }

  }
  /**
   * Delete all PIN 
   * PIN validation
   */
  clearPinData()
  {
    this.position = 1;
    this.first.nativeElement.value = "";
    this.second.nativeElement.value = "";
    this.third.nativeElement.value = "";
    this.fourth.nativeElement.value = "";
    this.disableSendPinBtn = true;
  }
  /**
   * Back button to refresh PIN
   * PIN validation
   */
  refreshPin()
  {
    this.position = 1;
    this.first.nativeElement.value = "";
    this.second.nativeElement.value = "";
    this.third.nativeElement.value = "";
    this.fourth.nativeElement.value = "";
    this.initial_pin = true;
  }
  /**
   * Get pin data by adding all four input 
   * PIN validation
   */
  getPinVal()
  {
    if( this.first.nativeElement.value &&
        this.second.nativeElement.value &&
        this.third.nativeElement.value &&
        this.fourth.nativeElement.value
      ) 
      return this.first.nativeElement.value + this.second.nativeElement.value + this.third.nativeElement.value + this.fourth.nativeElement.value;
    else
      return 0;
  }
  // global error message
  /**
   * error message 
   * Global
   */
  popUpErrorMsg(error: string)
  {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      text: error,
      icon: 'error'
    })
  }
}
