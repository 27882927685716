import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

import Swal from "sweetalert2";

import { DayCoverage } from "../models/coverage";
import { MatDialog } from "@angular/material/dialog";
import { EducatorModel } from "src/app/accounts/fhc-ihc-educators/educator.model";
import { AttendanceEducatorDialogComponent } from "../attendance-educator-dialog/attendance-educator-dialog.component";
import { ChildAttendanceUtilService } from "../services/child-attendance-util.service";

import * as moment from "moment";
import { GlobalService } from "src/app/global/global.service";

@Component({
  selector: "app-attendance-day",
  templateUrl: "./attendance-day.component.html",
  styleUrls: ["./attendance-day.component.sass"],
})
export class AttendanceDayComponent implements OnInit, OnChanges {
  @Input() dayCoverage: DayCoverage;
  @Input() educatorsList: EducatorModel[];
  @Input() dayIndex: number[];

  @Output() updateDayCoverage = new EventEmitter<{
    day: DayCoverage;
    index: number[];
  }>();

  @Output() validationErrors = new EventEmitter<{
    errors: string[];
  }>();

  public form: FormGroup;

  childAbsenseReasons = [
    {
      value: "child_ill",
      text: "Child Ill",
    },
    {
      value: "carer_ill",
      text: "Individual caring for child is ill",
    },
    {
      value: "partner_ill",
      text: "Partner of the individual child is ill",
    },
    {
      value: "individual_ill",
      text: "Individual who lives with the child is ill",
    },
    {
      value: "attending_preschool",
      text: "Child attending preschool",
    },
    {
      value: "free_day",
      text: "Pupil free day",
    },
    {
      value: "order_in_place",
      text: "Court order or parenting order in place",
    },
    {
      value: "service_closed",
      text: "Local emergency - service closed",
    },
    {
      value: "unable_to_attend",
      text: "Local emergency - unable to attend",
    },
    {
      value: "carer_wont_attend",
      text: "Local emergency - child's carer does not wish to attend",
    },
    {
      value: "not_immunised",
      text: "Not immunised against particular infectious disease and absence during grace period",
    },
    {
      value: "changed_ownership",
      text: "Prescribed - Service has changed ownership",
    },
    {
      value: "usual_service_closed",
      text: "Prescribed - Usual service closed and child attending different service under the same provider",
    },
    {
      value: "enrolment_ceased_incorrectly",
      text: "Prescribed - Enrolment ceased incorrectly",
    },
    {
      value: "family_tradegy",
      text: "Prescribed - Family tradegy",
    },
  ];

  validation_errors: string[] = [];
  outsideSignIns = [];

  user;

  get booked_times(): FormArray {
    return this.form.get("booked_times") as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private childAttendanceUtilService: ChildAttendanceUtilService,
    private datePipe: DatePipe,
    private globalService: GlobalService
  ) {
    this.user = this.globalService.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dayCoverage && changes.dayCoverage.currentValue) {
      this.initializeForm();
      this.populateForm(changes.dayCoverage.currentValue);
      this.validateAttendanceForm();
    }
  }

  ngOnInit(): void {
    this.initializeForm();
    this.listenOnFormChanges();
  }

  initializeForm() {
    if (!this.form) {
      this.form = this.fb.group({
        absence_document_held: [false],
        admin_levy: [0],
        absence_reason: [""],
        attendance_date: this.dayCoverage?.attendance_date,
        booked_end_time: [new Date(), Validators.required],
        booked_hours: [0],
        booked_start_time: [new Date(), Validators.required],
        booked_times: this.fb.array([]),
        child_attendance_id: null,
        child_id: [],
        child_name: [],
        educator_fee: [0, Validators.required],
        educator_ids: [],
        fees_session: ["standard"],
        food_meal_fee: [0],
        has_booked_session: [true],
        is_absent: [false],
        location: ["no"],
        notes: [""],
        schedule_id: null,
        travel_fee: [0],
      });
    }
  }

  populateForm(dayCoverage: DayCoverage) {
    this.form.patchValue(dayCoverage);

    const bookedTimes = dayCoverage.booked_times;

    if (bookedTimes) {
      bookedTimes.forEach((bookedTime) => {
        console.log(bookedTime);
        if (
          this.childAttendanceUtilService.isWithinBookedTime(
            this.form.controls.booked_start_time.value,
            this.form.controls.booked_end_time.value,
            bookedTime.start_time,
            bookedTime.end_time
          )
        ) {
          this.addBookedTime(bookedTime.start_time, bookedTime.end_time);
        } else {
          if (bookedTime.start_time && bookedTime.end_time) {
            this.outsideSignIns.push(bookedTime);
          }
        }
      });

      const count = this.booked_times.length;

      if (count < 3) {
        for (let i = count; i < 3; i++) {
          this.addBookedTime();
        }
      }
    }

    this.form.updateValueAndValidity();

    const today = moment();
    const attendanceDate = moment(this.form.controls.attendance_date.value);

    if ((attendanceDate.isAfter(today) && this.user && this.user.role !== 'director')) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  addBookedTime(start_time?: string, end_time?: string) {
    const row = this.fb.group({
      start_time: [start_time],
      end_time: [end_time],
    });

    this.booked_times.push(row);
  }

  async activateDay() {
    if (this.dayCoverage.has_booked_session) {
      return;
    }

    const response = await Swal.fire({
      title: "Active Day?",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes, add",
      cancelButtonText: "Cancel",
    });

    if (response && response.isConfirmed) {
      this.dayCoverage.has_booked_session = true;
      this.initializeForm();
      const count = this.booked_times.length;

      if (count < 3) {
        for (let i = count; i < 3; i++) {
          this.addBookedTime();
        }
      }

      this.form.patchValue({
        has_booked_session: true,
      });

      setTimeout(() => {
        this.updateDayCoverage.emit({
          day: this.form.getRawValue(),
          index: this.dayIndex,
        });
      }, 1500);
    } else {
      this.dayCoverage.has_booked_session = false;
    }
  }

  async removeSession() {
    if (!this.dayCoverage.has_booked_session) {
      return;
    }

    const response = await Swal.fire({
      title: "Deactivate session?",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (response && response.isConfirmed) {
      this.dayCoverage.has_booked_session = false;

      this.form.patchValue({
        has_booked_session: false,
        booked_start_time: null,
        booked_end_time: null,
        educator_fee: 0,
        food_meal_fee: 0,
        travel_fee: 0,
      });

      this.booked_times.clear();

      this.form.updateValueAndValidity();


      setTimeout(() => {
        this.updateDayCoverage.emit({
          day: this.form.getRawValue(),
          index: this.dayIndex,
        });
      }, 1500);
    }
  }

  async addEducator() {
    const dialogRef = this.dialog.open(AttendanceEducatorDialogComponent, {
      width: "450px",
      data: { educators: this.educatorsList },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form.patchValue({
          educator_ids: [result],
        });
        // TODO - Recompute Fee

        const selectedEducator = this.educatorsList.find(
          (e) => e.educator_id === parseInt(result)
        );

        const totalBookedHours = this.childAttendanceUtilService.computeHours(
          this.dayCoverage.booked_start_time,
          this.dayCoverage.booked_end_time
        );

        const fee = selectedEducator.fees[0];

        const updatedEducatorsFee =
          Math.round(totalBookedHours * fee.educators_rate * 100) / 100;

        this.form.patchValue({
          educator_fee: updatedEducatorsFee,
        });

        this.form.updateValueAndValidity();

        setTimeout(() => {
          this.updateDayCoverage.emit({
            day: {
              ...this.dayCoverage,
              educator_ids: [result],
              educator_fee: updatedEducatorsFee,
            },
            index: this.dayIndex,
          });
        }, 1500);
      }
    });
  }

  async setChildAttendancePresent() {
    if (this.form.controls.is_absent.value) {
      const attendanceDay = this.datePipe.transform(
        this.dayCoverage.attendance_date,
        "dd/MM/yyyy"
      );

      const response = await Swal.fire({
        title: `Mark ${this.dayCoverage.child_name} present on ${attendanceDay}?`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (response && response.isConfirmed) {
        this.form.patchValue({
          is_absent: false,
        });
        this.form.updateValueAndValidity();
        this.validateAttendanceForm();

        setTimeout(() => {
          this.updateDayCoverage.emit({
            day: this.form.getRawValue(),
            index: this.dayIndex,
          });
        }, 1500);
      }
    }
  }

  async setChildAttendanceAbsent() {
    if (!this.form.controls.is_absent.value) {
      const attendanceDay = this.datePipe.transform(
        this.dayCoverage.attendance_date,
        "dd/MM/yyyy"
      );
      const response = await Swal.fire({
        title: `Mark ${this.dayCoverage.child_name} absent on ${attendanceDay}?`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (response && response.isConfirmed) {
        this.form.patchValue({
          is_absent: true,
        });
        this.form.updateValueAndValidity();

        this.validation_errors = [];
        this.validateMissingAbsenceReason();

        setTimeout(() => {
          this.updateDayCoverage.emit({
            day: this.form.getRawValue(),
            index: this.dayIndex,
          });
        }, 1500);
      }
    }
  }

  listenOnFormChanges() {
    this.form.valueChanges.subscribe((form) => {
      this.validateAttendanceForm();

      if (this.validation_errors.length === 0) {
        setTimeout(() => {
          this.updateDayCoverage.emit({
            day: this.form.getRawValue(),
            index: this.dayIndex,
          });
        }, 1500);
      }
    });
  }

  validateAttendanceForm() {
    this.validation_errors = [];

    if (!this.form.controls.is_absent.value) {
      this.validateMissingSignIns();
      this.validateBrokenSignIns();
      this.validateStartAndEndTime();
      this.validateSignInWithinBookedTime();
      this.validateMissingBookedTimes();
      this.validateMissingEducatorsFee();
      this.validateSignInOverlaps();
    }

    this.validateMissingAbsenceReason();
  }

  /**
   * Looks when both start and end time are missing
   */
  validateMissingSignIns() {
    const bookedTimes = this.booked_times.value;
    const missingSignInsError =
      this.childAttendanceUtilService.validateMissingSignIns(bookedTimes);
    if (missingSignInsError) {
      this.validation_errors.push(missingSignInsError);
    }
  }

  /**
   * Looks when either one of start and end time are missing
   */
  validateBrokenSignIns() {
    const bookedTimes = this.booked_times.value;

    const brokenSignInsError =
      this.childAttendanceUtilService.validateBrokenSignIns(bookedTimes);
    if (brokenSignInsError) {
      this.validation_errors.push(brokenSignInsError);
    }
  }

  validateSignInWithinBookedTime() {
    const startTime = this.form.controls.booked_start_time.value;
    const endTime = this.form.controls.booked_end_time.value;

    const bookedTimes = this.booked_times.value;
    const error =
      this.childAttendanceUtilService.validateSignInWithinBookedTime(
        bookedTimes,
        startTime,
        endTime
      );
    if (error) {
      this.validation_errors.push(error);
    }
  }

  validateMissingAbsenceReason() {
    if (
      this.form.controls.is_absent.value &&
      !this.form.controls.absence_reason.value
    ) {
      this.validation_errors.push("Missing absence reason.");
    }
  }

  validateMissingEducatorsFee() {
    if (
      !this.form.controls.educator_fee.value ||
      this.form.controls.educator_fee.value < 1
    ) {
      this.validation_errors.push("Missing educator fee.");
    }
  }

  validateMissingBookedTimes() {
    if (
      !this.form.controls.booked_start_time.value &&
      !this.form.controls.booked_end_time.value
    ) {
      this.validation_errors.push("Missing booked times.");
    }
  }

  validateStartAndEndTime() {
    const bookedTimes = this.booked_times.value;
    const error =
      this.childAttendanceUtilService.validateStartAndEndTime(bookedTimes);
    if (error) {
      this.validation_errors.push(error);
    }
  }

  validateSignInOverlaps() {
    const bookedTimes = this.booked_times.value;
    const error =
      this.childAttendanceUtilService.validateSignInOverlaps(bookedTimes);
    if (error) {
      this.validation_errors.push(error);
    }
  }
}
