import { Injectable } from '@angular/core';
import { GlobalService } from "../global/global.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EsiService {

  constructor(private http: HttpClient, private globeService: GlobalService) { }
  /**
     * Http Option for auth routers
     */
  getHttpOptionsAuth() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }
  getHttpOptionsAuthWithOutContentType() {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }
  verifyPhone(telephone: any, selection_portal: string) {
    let params = new HttpParams().set("telephone", telephone).set("selection_portal", selection_portal);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'esi/verify-phone', {params, headers} );
  }
  checkPincodeExist(user_id: any) {
    let params = new HttpParams().set("user_id", user_id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'esi/check-pincode', {params, headers});
  }
  addPinCode(data) {
    return this.http.post(
      this.globeService.baseUrl + "esi/add-pincode",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  verifyPin(pincode: any, user_id: any) {
    let params = new HttpParams().set("pincode", pincode).set('user_id', user_id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'esi/verify-pincode', {params, headers} );
  }
  getAttendanceList(user_id: any, role_id: any) {
    let params = new HttpParams().set("user_id", user_id).set('role_id', role_id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'attendance/attendance-list', {params, headers} );
  }
  getAttendanceTableList(user_id: any, role_id: any, start_date: any , end_date: any ,week_range: any, filterKey: any, searchValue: any) {
    let params = new HttpParams()
                              .set("user_id", user_id)
                              .set('role_id', role_id)
                              .set('start_date', start_date)
                              .set('end_date', end_date)
                              .set("week_range", week_range)
                              .set("filterKey", filterKey)
                              .set("searchValue", searchValue);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'attendance/attendance-table-list', {params, headers} );
  }
  getAttendanceTablePerChild(child_attendance_ids: any) {
    let params = new HttpParams()
                              .set("child_attendance_ids", child_attendance_ids);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    });
    return  this.http.get(this.globeService.baseUrl + 'attendance/attendance-per-child', {params, headers} );
  }
  markAsAbsent(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/mark-absent",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  editAttendance(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/update-attendance",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  signIn(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/sign-in",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  signOut(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/sign-out",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  downloadAttendance(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/download-attendance",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  sendReportToEmail(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/send-email-attendance",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  parentValidatePastAttendance(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/parent-validate",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
  batchAction(data) {
    return this.http.post(
      this.globeService.baseUrl + "attendance/batch-action",
      data,
      this.getHttpOptionsAuthWithOutContentType()
    );
  }
}
