
    <div class="modal-header m-t-15">
        <div class="modalHeader clearfix">
            <div class="modal-about contact-header">
                <div class="contact-details-heading">
                    <div class="contact-details-name mb-2 w-100-p">Attendance</div>
                    <div class="badge col-green">{{ data.date }} {{data.day}}</div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="activeModal.dismiss('Cross click')" class="bg-light-gray m-r-14" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="modal-body p-b-30 p-t-5 p-l-5">
        <mat-card-content>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600 m-b">
                    Child's Name:
                </div>
                <div class="col-6">
                    {{data.child_full_name}}
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Scheduled Start:
                </div>
                <div class="col-6">
                    {{globeService.formatTimeString(data.sched_start)}}
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Scheduled End:
                </div>
                <div class="col-6">
                    {{globeService.formatTimeString(data.sched_end)}}
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Sign in:
                </div>
                <div class="col-6">
                    {{data.sign_in ? globeService.formatTimeTo12Hours(data.sign_in) : "Absent"}}
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Sign out:
                </div>
                <div class="col-6">
                    {{data.sign_out ? globeService.formatTimeTo12Hours(data.sign_out) : "Absent"}} 
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Signed in by:
                </div>
                <div class="col-6" *ngIf="data.signed_in_by && data.signed_in_role">
                    {{data.signed_in_by}} <b>( {{ data.signed_in_role | titlecase }} )</b>
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Signed out by:
                </div>
                <div class="col-6" *ngIf="data.signed_out_by && data.signed_out_role">
                    {{data.signed_out_by}} <b>( {{data.signed_out_role | titlecase }} )</b>
                </div>
            </div>
            <div class="row m-b-10">
                <div class="col-4 m-l-10 f-w-600">
                    Date of creation:
                </div>
                <div class="col-6">
                    {{globeService.formatDatetoObject(data.created_at)}}
                </div>
            </div>       
        </mat-card-content>
    </div>
