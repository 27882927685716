import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { User } from '../models/user';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import {GlobalService} from "../../global/global.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogin = false;
  users: User[] = [
    {
      id: 1,
      username: 'admin',
      password: 'admin',
      firstName: 'Sarah',
      lastName: 'Smith',
    },
  ];

  constructor(private http: HttpClient,
              private globeService: GlobalService,
  ) {}

  /**
   * Http Option for auth routers
   */
  getHttpOptions() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  /**
   * Http Option for auth routers
   */
  getHttpOptionsAuth() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  login(data) {
    return this.http.post(this.globeService.baseUrl + 'auth/login', data, this.getHttpOptions());
  }
  loginESI(data) {
    return this.http.post(this.globeService.baseUrl + 'auth/e-login', data, this.getHttpOptions());
  }
  logout() {
    this.isLogin = false;
    localStorage.setItem('STATE', 'false');
    return  this.http.get(this.globeService.baseUrl + 'auth/logout', this.getHttpOptionsAuth());
  }
  checkValidKey(randkey, form_type, type) {
    let params = new HttpParams().set("randkey", randkey).set("form_type", form_type).set("type", type);
    return  this.http.get(this.globeService.baseUrl + 'link/check-random-key', {params});
  }
  verifyLink(data) {
    return this.http.post(this.globeService.baseUrl + 'link/verify', data, this.getHttpOptions());
  }
  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn === 'true') {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    return this.isLogin;
  }

  forgotPassword(data: any) {
    return this.http.post(this.globeService.baseUrl + 'auth/forgot-password', data, this.getHttpOptions());
  }

  tokenVerify(token: string) {
    return this.http.get(this.globeService.baseUrl + 'auth/verify-token?token=' + token, this.getHttpOptions());
  }

  resetPassword(value: any, token: string) {
    return this.http.post(this.globeService.baseUrl + 'auth/reset-password?token=' + token, value, this.getHttpOptions());
  }

  loginLocked(value: any) {
    return this.http.post(this.globeService.baseUrl + 'auth/login-locked', value, this.getHttpOptions());
  }

  checkUrlPermission(url) {
    return this.http.post(this.globeService.baseUrl  + 'auth/check-menu-permission',{'state' : url} , this.getHttpOptionsAuth());
  }

  getBranchIdEnrolmentRef(data) {
    return this.http.post(this.globeService.baseUrl  + 'auth/get-branch-enrolment-ref',data, this.getHttpOptionsAuth());

  }
  getBranchIdStaffBackground(data) {
    return this.http.post(this.globeService.baseUrl  + 'auth/get-branch-staff-background-ref',data, this.getHttpOptionsAuth());

  }
  getBranchIdStaffPreAssesment(data) {
    return this.http.post(this.globeService.baseUrl  + 'auth/get-branch-staff-assessment-ref',data, this.getHttpOptionsAuth());

  }
}
