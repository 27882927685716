<div class="container-fluid">

  <app-esi-header (sendCurrentDate)="receiveCurrentDate($event)"></app-esi-header>
      
  <div class="row" *ngIf="verifiedUser.role_id == 7">
    <div class="col-lg-12 container text-align-right p-r-60">
      <a mat-raised-button color="primary" routerLink="/esi/past-attendance">View Past Attendance</a>
    </div>
  </div>
</div>

<div class="row">
  <div class="inboxContainer">
    <div class="table-container" *ngFor="let row of attendance_list; let i = index">
      <table style="width: 100%">
        <tr *ngIf="attendance_list[i - 1]?.child_id != row?.child_id">
          <td colspan="3"><h3 style="margin-top: 20px !important;">{{row.child_full_name}}</h3></td>
        </tr>
        <tr *ngIf="row.sched_start && !row.is_manually_created">
          <td colspan="3"><h4 style="margin-top: 20px !important;">{{row.sched_start}} - {{row.sched_end}}</h4></td>
        </tr>
        <tr *ngIf="!row.sched_start">
          <td colspan="3"><h4 style="margin-top: 20px !important;">No Booking</h4></td>
        </tr>
        <tr *ngIf="attendance_list[i - 1]?.child_id != row?.child_id">
          <td colspan="3"></td>
        </tr>

        <tr>
          <td style="width: 45%">
            <button *ngIf="row?.sign_in == null && row.status != 2" mat-raised-button  class="center-button fill-button orange-btn" (click)="signIn(row.child_attendance_id, row.child_id)">
              <h3 style="margin-top: 6px !important;">SIGN IN</h3>
            </button>
            <button *ngIf="row?.sign_in != null && row.status != 2" mat-raised-button  class="center-button fill-button grn-btn">
              <h3 style="margin-top: 6px !important;">SIGNED IN</h3>
              <h3 style="margin-top: 6px !important;">{{ row.sign_in | date:'shortTime' }}</h3>
            </button>
            <button *ngIf="row.status == 2" mat-raised-button class="center-button fill-button absent-btn">
              <h3 style="margin-top: 6px !important;">SIGN IN</h3>
            </button>
          </td>
          <td style="width: 10%"></td>
          <td style="width: 45%">
            <!-- <button class="fill-button" >Sign Out</button> -->
            <button *ngIf="row?.sign_out == null && row.status != 2" mat-raised-button class="center-button fill-button orange-btn " (click)="signOut(row.child_attendance_id, row.sign_in, row.child_id)">
              <h3 style="margin-top: 6px !important;">SIGN OUT</h3>
            </button>
            <button *ngIf=" row?.sign_out != null && row.status != 2 " mat-raised-button class="center-button fill-button grn-btn">
              <h3 style="margin-top: 6px !important;">SIGNED OUT</h3>
              <h3 style="margin-top: 6px !important;">{{ row.sign_out | date:'shortTime' }}</h3>
            </button>
            <button *ngIf="row.status == 2" mat-raised-button class="center-button fill-button absent-btn">
              <h3 style="margin-top: 6px !important;">SIGN OUT</h3>
            </button>
          </td>
        </tr>
        
        <tr *ngIf="row?.sign_in != null && row?.showAdditionalEntry">
          <td style="width: 45%">
            <button mat-raised-button  class="center-button fill-button orange-btn" (click)="signIn(row.child_attendance_id, row.child_id)">
              <h3 style="margin-top: 6px !important;">SIGN IN</h3>
            </button>
          </td>
          <td style="width: 10%"></td>
          <td style="width: 45%">
            <button mat-raised-button class="center-button fill-button orange-btn " (click)="signOut(row.child_attendance_id, row.sign_in, row.child_id)">
              <h3 style="margin-top: 6px !important;">SIGN OUT</h3>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  
</div>

<footer class="footer">
  <div class="container">
    <mat-form-field>
      <mat-label>BULK ACTIONS</mat-label>
      <mat-select [(ngModel)]="selectedOption"  (selectionChange)="triggerBatchAction($event)">
        <mat-option *ngFor="let action of actions" [value]="action">{{action}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</footer>

