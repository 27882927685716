import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { ConfigService } from 'src/app/config/config.service';
import { GlobalService } from 'src/app/global/global.service';
import { Subscription } from 'rxjs';
import { AccountDashboardService } from 'src/app/account-dashboard/account-dashboard.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  showMenu = 'dashboard';
  showSubMenu = '';
  showSubSubMenu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  menus: any = [];
  userData: any = [];
  loggedUser: any;
  hasNotifications = false;
  hasNotificationsSubscriptions: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private router: Router,
    private globeService: GlobalService,
    private accountDashboardService: AccountDashboardService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.split('/')[1] === 'multilevel') {
          this.showMenu = event.url.split('/')[1];
        } else {
          this.showMenu = event.url.split('/').slice(-2)[0];
        }
        this.showSubMenu = event.url.split('/').slice(-2)[0];
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall() {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callMenuToggle(event: any, element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }

  callSubMenuToggle(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  ngOnInit() {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.setMenuData();
    this.setUserData();
    this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    this.globeService.centerLogoEvent.subscribe((data) => {
      this.userData['center_logo'] = data;
    });

    this.checkNotifications();
  }

  checkNotifications() {
    this.hasNotificationsSubscriptions =
      this.accountDashboardService.hasNotifications.subscribe((res: any) => {
        this.hasNotifications = res;
      });
  }

  setUserData() {
    var data = localStorage.getItem('user');
    this.userData = JSON.parse(data);
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  setMenuData() {
    this.configService.getSidebarMenu().subscribe(
      (res) => {
        if (res) {
          this.menus = res;
        }
      },
      () => {
        this.globeService.showErrorNotification('Something went wrong!');
      }
    );
  }

  navigateLock() {
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('access_token', '');
    this.router.navigate(['/authentication/locked']);
  }

  goDashboard() {
    this.router.navigate(['/admin/dashboard']);
  }

  navigateToEmail() {
    this.router.navigate(['/admin/send-email/email-template']);
  }
  navigateToEmailAdmin() {
    this.router.navigate(['/admin/send-email/email-template-super-admin']);
  }

  navigateProfile() {
    this.router.navigate(['/admin/accounts/me/profile'], {
      queryParams: { role: 'me', ref: 'auth' },
    });
  }

  ngOnDestroy(): void {
    if (this.hasNotificationsSubscriptions) {
      this.hasNotificationsSubscriptions.unsubscribe();
    }
  }
}
