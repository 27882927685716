import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetIconFileTypesPipe } from './get-icon-file-types.pipe';
import { StatusToNamePipe } from './status-to-name.pipe';
import {YesNoPipe} from "./yes_no.pipe";


@NgModule({
  declarations: [GetIconFileTypesPipe, StatusToNamePipe,YesNoPipe],
  imports: [
    CommonModule,
  ],
  exports: [
    GetIconFileTypesPipe,
    StatusToNamePipe,
    YesNoPipe,
  ]
})
export class PipesModule { }
