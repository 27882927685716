<div class="container-fluid">
    <app-esi-header></app-esi-header>
    <div class="page-container">
        <div class="col-8">
            <a class="back-button" routerLink="/esi/dashboard"> 
                <mat-icon class="back-button" >chevron_left</mat-icon>
                Go to dashboard 
            </a>
            <ul class="breadcrumb breadcrumb-style ">
                <li class="breadcrumb-item">
                <h4 class="font-weight-bold">Hi {{userName}}!</h4>
                </li>
            </ul>
        </div>
        <div class="page-title text-align-center">
            <h3 class="font-weight-bold">Attendance</h3>
        </div>
        <app-child-attendance userAttendanceType="attendance-educator"></app-child-attendance>
    </div>
</div>
