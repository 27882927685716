<div class="container-fluid" *ngIf="!isLoading">
  <div class="row attendance-form">
    <div
      class="col-sm-6 px-3 py-3 d-flex justify-content-start align-items-center"
    >
      <h4 class="mr-5">{{ attendanceRow?.child_full_name }}</h4>
      <h5>{{ attendanceRow?.child_start_date | date : "longDate" }}</h5>
    </div>
    <div class="col-sm-6 px-3 py-3 d-flex justify-content-end">
      <button
        mat-icon-button
        (click)="modal.close()"
        class="bg-light-gray m-r-14"
        aria-label="Close dialog"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 px-1 py-1">
      <div
        class="d-flex justify-content-center flex-column mb-3 px-1"
        *ngFor="let week of weeksCovered; let i = index"
      >
        <div class="d-flex justify-content-between mb-3">
          <div
            *ngFor="let day of week.days; let j = index"
            class="d-flex justify-content-between flex-column"
          >
            <app-attendance-day
              *ngFor="let sched of day; let k = index"
              [dayCoverage]="sched"
              [educatorsList]="educatorsList"
              [dayIndex]="[i, j, k]"
              class="attendance-day mb-3"
              (updateDayCoverage)="handleUpdateDayCoverage($event)"
            ></app-attendance-day>
          </div>
        </div>
        <app-attendance-weekly-computation-form
          [weekCoverage]="week"
          [index]="i"
          (addNewSession)="handleAddNewSession($event)"
          (removeSession)="handleRemoveSession($event)"
        ></app-attendance-weekly-computation-form>
      </div>
    </div>
  </div>
  <div class="row attendance-form">
    <div class="col-sm-12 px-3 py-3 d-flex justify-content-end">
      <button mat-raised-button (click)="modal.close()" class="mr-2">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="saveAttendance()"
      >
        Save
      </button>
    </div>
  </div>
</div>
