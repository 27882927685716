<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <h3 *ngIf="headerHide != 1">
      Authorisation and Risk Assessment for Transport and Excursion
    </h3>
    <!-- <h6>{{ data.created_at | date: 'longDate' }}</h6> -->
    <span
      class="label l-bg-red shadow-style m-b-14"
      *ngIf="data.is_approval == 2"
      >Declined</span
    >
    <span
      class="label l-bg-green shadow-style m-b-14"
      *ngIf="data.is_approval == 1"
      >Approved</span
    >
    <br />

    <h5 class="form-group-heading">Child Details</h5>
    <ng-container *ngIf="loggedUser['role_id'] != 6">
      <div class="row">
        <div class="col-3" *ngFor="let sChild of selectedChildren">
          <div class="display-inline">
            <img
              class="rounded-circle size-40"
              src="{{ sChild['url'] }}"
              alt="user"
              title=""
              matTooltip="{{ sChild['child_givenname'] }} {{
                sChild['child_surname']
              }}"
            />
          </div>
          <div class="display-inline m-l-11">
            {{ sChild['child_givenname'] }} {{ sChild['child_surname'] }}
          </div>
        </div>
      </div>
      <br />
      <p>
        <strong>Effective Date : </strong
        >{{ data['created_at'] | date : 'longDate' }}
      </p>
      <p>
        <strong>To be evaluated and reviewed on : </strong
        >{{ data['valid_to_date'] | date : 'longDate' }}
      </p>
    </ng-container>
    <ng-container *ngIf="loggedUser['role_id'] == 6">
      <table class="table table-sm border-0">
        <tr>
          <td style="width: 20%">Child Name</td>
          <td>: {{ data['child_name'] }}</td>
        </tr>
        <tr>
          <td>Date of birth</td>
          <td>: {{ data['child_dob'] | date : 'longDate' }}</td>
        </tr>
        <tr>
          <td>Created Date</td>
          <td>: {{ data['created_at'] | date : 'longDate' }}</td>
        </tr>
        <tr>
          <td>To be evaluated and reviewed on</td>
          <td>: {{ data['valid_to_date'] | date : 'longDate' }}</td>
        </tr>
      </table>
    </ng-container>
  </div>
</div>
<div class="row p-15 m-t-28-min">
  <h5 class="form-group-heading m-l-11">
    Excursion and Non-Excursion/Regular Outing and Transport Details
  </h5>
</div>
<ng-container *ngFor="let destination of destinations; let i = index">
  <div class="row p-15 m-t-28-min">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13">
      <ul>
        <li *ngIf="destination.destination == 'school'">
          <strong> Pick-up location and Outing Destination : </strong> School
        </li>
        <li *ngIf="destination.destination == 'local_shop'">
          <strong> Pick-up location and Outing Destination : </strong> Local
          Shop
        </li>
        <li *ngIf="destination.destination == 'Local_parks'">
          <strong> Pick-up location and Outing Destination : </strong> Local
          Park/Playground
        </li>
        <li *ngIf="destination.destination == 'libraries'">
          <strong> Pick-up location and Outing Destination : </strong> Library
        </li>
        <li *ngIf="destination.destination == 'education_house'">
          <strong> Pick-up location and Outing Destination : </strong> Playgroup
        </li>
        <li *ngIf="destination.destination == 'other'">
          <strong> Pick-up location and Outing Destination : </strong> Other
          Location
        </li>
        <li *ngIf="destination.destination == 'other_FDC_education_residence'">
          <strong> Pick-up location and Outing Destination : </strong> Other FDC
          Educator Residence
        </li>
        <li>
          <strong> Method of Transport : </strong>
          {{ destination.transport_method }}
        </li>
        <li>
          <strong> Activities/ Learning Experience : </strong>
          {{ destination.activities }}
        </li>
        <li *ngIf="destination.destination == 'other'">
          <strong> Other Location - provide details of location : </strong>
          {{ destination.destination_other }}
        </li>
        <li>
          <strong> Name and Address : </strong>
          {{ destination.name_and_address }}
        </li>
        <li>
          <strong
            ><span *ngIf="destination.route == true"
              >Proposed route attached? :
              <mat-icon class="f-15">check</mat-icon>
            </span></strong
          >
        </li>
        <li>
          <strong
            ><span *ngIf="destination.route == 0">
              Proposed route attached? :
              <mat-icon class="f-15">close</mat-icon></span
            ></strong
          >
        </li>
        <li>
          <strong
            ><span *ngIf="destination.is_regular_outing == true"
              >Is this a regular outing? :
              <mat-icon class="f-15">check</mat-icon>
            </span></strong
          >
        </li>
        <li>
          <strong
            ><span *ngIf="destination.is_regular_outing == 0">
              Is this a regular outing? :
              <mat-icon class="f-15">close</mat-icon></span
            ></strong
          >
        </li>
        <li>
          <strong
            ><span *ngIf="destination.is_an_excursion == true"
              >Is this an excursion? : <mat-icon class="f-15">check</mat-icon>
            </span></strong
          >
        </li>
        <li>
          <strong
            ><span *ngIf="destination.is_an_excursion == 0">
              Is this an excursion? :
              <mat-icon class="f-15">close</mat-icon></span
            ></strong
          >
        </li>
        <li *ngIf="destination.is_an_excursion == true">
          <strong> Date of Excursion : </strong>
          {{ destination.excursion_date | date : 'longDate' }}
        </li>
        <li>
          <strong> Departure Time : </strong> {{ destination.duration_h }}
        </li>
        <li><strong> Arrival Time : </strong> {{ destination.duration_m }}</li>
      </ul>
    </div>
  </div>
  <div class="row p-15 m-t-28-min">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13">
      <span><strong> Days of Outing : </strong> </span><br />
      <p>
        <span *ngIf="destination.day_m == true"
          >Monday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_m == 0">
          Monday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_t == true">
          Tuesday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_t == 0">
          Tuesday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_w == true">
          Wednesday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_w == 0">
          Wednesday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_th == true">
          Thursday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_th == 0">
          Thursday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_f == true">
          Friday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_f == 0">
          Friday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_sat == true">
          Saturday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_sat == 0">
          Saturday : <mat-icon class="f-15">close</mat-icon></span
        >

        <span *ngIf="destination.day_sun == true">
          Sunday : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.day_sun == 0">
          Sunday : <mat-icon class="f-15">close</mat-icon></span
        >
        <li>
          <strong>
            Is the regular outing of the child on a weekly or fortnightly cycle?
          </strong>
        </li>
        <span *ngIf="destination.is_weekly == true">
          Weekly? : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.is_weekly == 0">
          Weekly? : <mat-icon class="f-15">close</mat-icon></span
        >
        <span *ngIf="destination.is_weekly == null">
          Weekly? : <mat-icon class="f-15">close</mat-icon></span
        >
        <span *ngIf="destination.is_fortnightly == true">
          Fortnightly? : <mat-icon class="f-15">check</mat-icon>
        </span>
        <span *ngIf="destination.is_fortnightly == 0">
          Fortnightly? : <mat-icon class="f-15">close</mat-icon></span
        >
        <span *ngIf="destination.is_fortnightly == null">
          Fortnightly? : <mat-icon class="f-15">close</mat-icon></span
        >
      </p>
      <!--    <ul>-->
      <!--    <span><strong> Days of Outing : </strong> </span><br/>-->
      <!--          <li *ngIf="destination.day_m == true" >-Monday  <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_m == 0" >-Monday  <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_t== true">-Tuesday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_t== 0">-Tuesday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_w== true">- Wednesday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_w== 0">- Wednesday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_th== true">- Thursday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_th== 0">- Thursday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_f== true">- Friday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_f== 0">- Friday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_sat== true">- Saturday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_sat== 0">- Saturday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--          <li *ngIf="destination.day_sun== true">- Sunday <mat-icon class="f-15 ">check</mat-icon></li>-->
      <!--          <li *ngIf="destination.day_sun== 0">- Sunday <mat-icon class="f-15 ">close</mat-icon></li>-->

      <!--        </ul>-->
    </div>
  </div>
</ng-container>

<div class="row m-t-5 py-3">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
    <p>Proposed route attachment</p>
    <app-dropzone-recieved
      [items]="proposedRouteDropZoneFiles"
      [showDelete]="false"
    >
    </app-dropzone-recieved>
  </div>
</div>

<div class="row p-15 m-t-28-min">
  <h5 class="form-group-heading m-l-11">
    Risk Assessment and Management - Excursion and Transporting Children
  </h5>
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 f-13">
    <ul>
      <li><strong>Staff Name : </strong> {{ data.educator_name }}</li>
      <li *ngIf="data.contact_number_bh != null">
        <strong>Business Hours Phone : </strong>
        {{ data.contact_number_bh | phone : 'aus' }}
      </li>
      <li *ngIf="data.contact_number_m != null">
        <strong>Mobile Number : </strong>
        {{ data.contact_number_m | phone : 'aus' }}
      </li>
      <li *ngIf="data.numbers_and_names != null">
        <strong
          >The number and full names of each adult involved in excursions and in
          the transportation of children e.g. educators, parents, volunteers :
        </strong>
        {{ data.numbers_and_names }}
      </li>
      <li *ngIf="data.numbers_children_involve != null">
        <strong
          >The number of children involved in the excursion and being
          transported :
        </strong>
        {{ data.numbers_children_involve }}
      </li>
      <li *ngIf="data.describe_the_process != null">
        <strong
          >Describe the process for entering and exiting the service premises
          and the pick-up location or destination (as required); (include how
          each child is accounted for) :
        </strong>
        {{ data.describe_the_process }}
      </li>
      <li *ngIf="data.describe_the_procedure != null">
        <strong
          >Describe the procedures for embarking and disembarking the vehicle;
          (include how each child is accounted for in embarking and
          disembarking) :
        </strong>
        {{ data.describe_the_procedure }}
      </li>
      <li *ngIf="data.no_of_educators != null">
        <strong>The number of educators : </strong> {{ data.no_of_educators }}
      </li>
      <li *ngIf="data.child_ratio != null">
        <strong>Child ratio : </strong> {{ data.child_ratio }}
      </li>
    </ul>
  </div>
  <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 f-13">
    <ul>
      <li *ngIf="data.no_of_educators != null"><strong>The number of educators : </strong> {{data.no_of_educators}}</li>
      <li *ngIf="data.child_ratio != null"><strong>Child ratio : </strong> {{data.child_ratio}}</li>
    </ul>
  </div> -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13">
    <ul>
      <li *ngIf="data.water_hazard != null && data.water_hazard == 1">
        <strong>
          Are there any water hazards on proposed travel route and/or at any
          stop and/or at the destination?
        </strong>
        Yes
      </li>
      <li *ngIf="data.water_hazard != null && data.water_hazard == 1">
        <strong> Details in the risk assessment : </strong>
        {{ data.water_hazard_description }}
      </li>
      <li *ngIf="data.water_hazard == null || data.water_hazard == 0">
        <strong>
          Are there any water hazards on proposed travel route and/or at any
          stop and/or at the destination?
        </strong>
        No
      </li>
      <li *ngIf="data.have_seatbelt != null && data.have_seatbelt == 1">
        <strong>
          Requirements for seatbelts or safety restraints in your state or
          territory have been met :
        </strong>
        Yes
      </li>
      <li *ngIf="data.have_seatbelt != null && data.have_seatbelt == 1">
        <strong> Comments : </strong> {{ data.have_seatbelt_description }}
      </li>
      <li *ngIf="data.have_seatbelt == null || data.have_seatbelt == 0">
        <strong>
          Requirements for seatbelts or safety restraints in your state or
          territory have been met :
        </strong>
        No
      </li>
    </ul>
  </div>
</div>
<!--<div class="row p-15">-->

<!--</div>-->
<h5 class="form-group-heading m-t-15 m-l-11">
  Transport and Excursion/Non-Excursion Checklist
</h5>
<p class="m-l-11">
  Items to be readily available when transporting children and during excursion
</p>
<div class="row p-15 m-t-0">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 f-13">
    <ul>
      <li *ngIf="data.first_aid_kit != null && data.first_aid_kit == 1">
        <strong> First aid kit : </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.first_aid_kit == null || data.first_aid_kit == 0">
        <strong> First aid kit : </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>
      <li *ngIf="data.reqular_outing != null && data.reqular_outing == 1">
        <strong>
          List of children involved in the excursion and transportation :
        </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.reqular_outing == null || data.reqular_outing == 0">
        <strong>
          List of children involved in the excursion and transportation
          :</strong
        >
        <mat-icon class="f-15">close</mat-icon>
      </li>

      <li
        *ngIf="
          data.contact_information != null && data.contact_information == 1
        "
      >
        <strong> Contact information for each child : </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li
        *ngIf="
          data.contact_information == null || data.contact_information == 0
        "
      >
        <strong> Contact information for each child : </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>

      <li *ngIf="data.medication != null && data.medication == 1">
        <strong>
          Medication, health plans and risk assessments for individual children
          :
        </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.medication == null || data.medication == 0">
        <strong>
          Medication, health plans and risk assessments for individual children
          :
        </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>
    </ul>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 f-13">
    <ul>
      <li *ngIf="data.transportation != null && data.transportation == 1">
        <strong>
          List of adults participating in the excursion/regular outing and
          <br />involved in transportation :
        </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.transportation == null || data.transportation == 0">
        <strong>
          List of adults participating in the excursion/regular outing and
          involved <br />in transportation :
        </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>
      <li *ngIf="data.adult_contact != null && data.adult_contact == 1">
        <strong> Contact information for each adult : </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.adult_contact == null || data.adult_contact == 0">
        <strong> Contact information for each adult : </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>

      <li *ngIf="data.mobile_phone != null && data.mobile_phone == 1">
        <strong
          >Mobile phone / other means of communicating with the service <br />
          & emergency services :
        </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.mobile_phone == null || data.mobile_phone == 0">
        <strong>
          Mobile phone / other means of communicating with the service <br />
          & emergency services :
        </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>

      <li *ngIf="data.other != null && data.other == 1">
        <strong>Other items, please list : </strong>
        <mat-icon class="f-15">check</mat-icon>
      </li>
      <li *ngIf="data.other == null || data.other == 0">
        <strong> Other items, please list : </strong>
        <mat-icon class="f-15">close</mat-icon>
      </li>
      <li *ngIf="data.other != null && data.other == 1">
        <strong>List : </strong> {{ data.other_list }}
      </li>
    </ul>
  </div>
</div>
<h5 class="form-group-heading-no-underline m-t-15 m-l-11">Risk Assessment</h5>
<div
  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13"
  style="margin-top: 15px"
>
  <em
    >Use the table below to identify and assess risks to the safety, health or
    wellbeing of children attending the excursion and being transported, and
    specify how these risks will be managed and minimised (regulation 101(1),
    102C(1)). 'This must include any risks associated with water-based
    activities.
  </em>
</div>
<div class="row p-15 m-t-0">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13">
    <table
      class="table table-bordered table-risk-assessment"
      style="width: 100%; table-layout: fixed"
    >
      <thead>
        <tr>
          <th [style.width.%]="3">#</th>
          <th [style.width.%]="10">Activity</th>
          <th [style.width.%]="10">Hazard identified</th>
          <th [style.width.%]="13">Likelihood</th>
          <th [style.width.%]="13">Consequence</th>
          <th [style.width.%]="13">Risk Assessment</th>
          <th [style.width.%]="16">Elimination/ control measures/ Actions</th>
          <th [style.width.%]="12">Responsibility</th>
          <th [style.width.%]="10">When</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let risk of risks; let i = index">
          <tr>
            <td style="vertical-align: top; width: 20px">{{ i + 1 }}</td>
            <td>{{ risk.r_activity }}</td>
            <td>{{ risk.r_hazard_identified }}</td>

            <td *ngIf="risk.r_likelihood_id == 1">Rare</td>
            <td *ngIf="risk.r_likelihood_id == 2">Unlikely</td>
            <td *ngIf="risk.r_likelihood_id == 3">Possible</td>
            <td *ngIf="risk.r_likelihood_id == 4">Likely</td>
            <td *ngIf="risk.r_likelihood_id == 5">Almost Certain</td>
            <td *ngIf="!risk.r_likelihood_id"></td>

            <td *ngIf="risk.r_consequence_id == 1">Insignificant</td>
            <td *ngIf="risk.r_consequence_id == 2">Minor</td>
            <td *ngIf="risk.r_consequence_id == 3">Moderate</td>
            <td *ngIf="risk.r_consequence_id == 4">Major</td>
            <td *ngIf="risk.r_consequence_id == 5">Catastrophic</td>
            <td *ngIf="!risk.r_consequence_id"></td>

            <td *ngIf="risk.r_risk_assessment_id == 1">Low</td>
            <td *ngIf="risk.r_risk_assessment_id == 2">Moderate</td>
            <td *ngIf="risk.r_risk_assessment_id == 3">Extreme</td>
            <td *ngIf="risk.r_risk_assessment_id == 4">High</td>
            <td *ngIf="!risk.r_risk_assessment_id"></td>

            <td>{{ risk.r_elimination }}</td>
            <td>{{ risk.r_responsibility }}</td>
            <td>{{ risk.r_when | date : 'dd/MM/yyyy' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="row p-15 m-t-10">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 align-center">
    <!-- <img src="../../../assets/images/matrixCapture.PNG" alt="Girl in a jacket" width="800" height="300"> -->
    <img
      src="../../../assets/images/matrix.PNG"
      alt="Girl in a jacket"
      style="max-width: 100%"
    />
  </div>
</div>
<!-- <div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-center">
    <img src="../../../assets/images/gride1.PNG" alt="Girl in a jacket" width="800" height="500">
  </div>
</div>
<div class="row m-t-0">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 align-center">
    <img src="../../../assets/images/gride2new.PNG" alt="Girl in a jacket" width="800" height="500">
  </div>
</div> -->
<h3 class="form-group-heading m-t-15 m-l-11">Plan and Review</h3>
<div class="row p-15 m-t-10">
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 f-13">
    <h5 class="form-group-heading-no-underline">
      Venue and Vehicle Safety Information
    </h5>
    <ul>
      <li *ngIf="data.venue == 1">
        <strong>Venue and safety information reviewed and attached : </strong>
        Yes
      </li>
      <li *ngIf="data.venue == null || data.venue == 0">
        <strong>Venue and safety information reviewed and attached : </strong>
        No
      </li>
      <li *ngIf="data.comment != null">
        <strong>Venue and safety information comment : </strong>
        {{ data.comment }}
      </li>

      <li *ngIf="data.vehicle == 1">
        <strong>Vehicle and safety information reviewed and attached : </strong>
        Yes
      </li>
      <li *ngIf="data.vehicle == null || data.vehicle == 0">
        <strong>Vehicle and safety information reviewed and attached : </strong>
        No
      </li>
      <li *ngIf="data.comment_vehicle != null">
        <strong>Vehicle and safety information comment : </strong>
        {{ data.comment_vehicle }}
      </li>
    </ul>
  </div>
  <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 f-13">
    <span>Communicated to</span>
    <ul>
      <li *ngIf=" data.communicated_children ==1"><strong>Children : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_children == null || data.communicated_children ==0"><strong>Children : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>

      <li *ngIf=" data.communicated_parent ==1"><strong>Parents : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_parent == null || data.communicated_parent ==0"><strong>Parents : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>

      <li *ngIf=" data.communicated_educator ==1"><strong>Educators : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_educator == null || data.communicated_educator ==0"><strong>Educators : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>
    </ul>
  </div> -->
</div>
<div class="row m-t-5">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
    <p>Venue Safety Attachments</p>
    <app-dropzone-recieved
      [items]="venueSafetyDropZoneFiles"
      [showDelete]="false"
    >
    </app-dropzone-recieved>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
    <p>Vehicle Safety Attachments</p>
    <app-dropzone-recieved
      [items]="vehicleSafetyDropZoneFiles"
      [showDelete]="false"
    >
    </app-dropzone-recieved>
  </div>
</div>

<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <ul>
      <li><strong>Plan prepared by : </strong> {{ data.plan_prepared_by }}</li>
      <li>
        <strong>Position / Role : </strong>
        {{ data.plan_prepared_by_role_or_position }}
      </li>
      <img
        src="{{ data.signature1 }}"
        *ngIf="data.signature1 && data.signature1 !== 'null'"
      />
      <li>
        <strong>Signature Date : </strong>
        {{ data.signature_date | date : 'dd/MM/yyyy' }}
      </li>
    </ul>
  </div>
</div>
<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <table class="w-100-p" width="100%">
      <tr>
        <td><strong>Communicated to: </strong></td>
        <td></td>
        <td>
          <table>
            <tr>
              <td width="30%" *ngIf="data.communicated_children == 1">
                <strong>Children : </strong>
                <mat-icon class="f-15">check</mat-icon>
              </td>
              <td
                width="30%"
                *ngIf="
                  data.communicated_children == null ||
                  data.communicated_children == 0
                "
              >
                <strong>Children : </strong>
                <mat-icon class="f-15">close</mat-icon>
              </td>

              <td width="30%" *ngIf="data.communicated_parent == 1">
                <strong>Parents : </strong>
                <mat-icon class="f-15">check</mat-icon>
              </td>
              <td
                width="30%"
                *ngIf="
                  data.communicated_parent == null ||
                  data.communicated_parent == 0
                "
              >
                <strong>Parents : </strong>
                <mat-icon class="f-15">close</mat-icon>
              </td>

              <td width="30%" *ngIf="data.communicated_educator == 1">
                <strong>Educators : </strong>
                <mat-icon class="f-15">check</mat-icon>
              </td>
              <td
                width="30%"
                *ngIf="
                  data.communicated_educator == null ||
                  data.communicated_educator == 0
                "
              >
                <strong>Educators : </strong>
                <mat-icon class="f-15">close</mat-icon>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <!-- <span>Communicated to</span>
    <ul>
      <li *ngIf="data.communicated_children ==1"><strong>Children : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_children == null || data.communicated_children ==0"><strong>Children : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>

      <li *ngIf=" data.communicated_parent ==1"><strong>Parents : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_parent == null || data.communicated_parent ==0"><strong>Parents : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>

      <li *ngIf=" data.communicated_educator ==1"><strong>Educators : </strong>
        <mat-icon class=" f-15">check</mat-icon>
      </li>
      <li *ngIf="data.communicated_educator == null || data.communicated_educator ==0"><strong>Educators : </strong>
        <mat-icon class=" f-15">close</mat-icon>
      </li>
    </ul> -->
  </div>
</div>
<ng-container *ngFor="let consulation of consulation_table; let i = index">
  <div class="row p-15 m-t-28-min">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 f-13">
      <ul>
        <li>
          <strong>Prepared in consultation with : </strong>
          {{ consulation.consultation_with }}
        </li>
        <li>
          <strong>Position / Role : </strong>
          {{ consulation.consultation_with_role_or_position }}
        </li>
        <img src="{{ consulation.consultation_signature }}" />
        <li>
          <strong>Signature Date : </strong>
          {{ consulation.supervisor_signature_date | date : 'dd/MM/yyyy' }}
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <span
      ><strong
        >Reminder: Monitor the effectiveness of controls and change if
        necessary. Review the risk assessment if an incident or significant
        change occurs.
      </strong>
    </span>
  </div>
</div>

<!-- <div class="row m-t-10" *ngIf="data['signature3']">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2 f-13">
    <img src="{{ data['signature3'] }}">
    <br />
    <em>{{ data['supervisor_name'] }} (Nominated Supervisor Name1) - {{ data.supervisor_signature_date | date:
      'longDate'}}</em>
  </div>
</div> -->
<ng-container
  *ngIf="
    loggedUser['role_id'] == 6 &&
    (data.notify_parent_id == null || data.notify_parent_id == '')
  "
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
    <h5 class="form-group-heading" *ngIf="loggedUser['role_id'] == 6">
      Authorisation for Transport and Excursion
    </h5>
    <em *ngIf="loggedUser['role_id'] == 6"
      >By ticking the boxes below and having signed next to my full name:</em
    >
    <form class="register-form filter-form" [formGroup]="sectionForm">
      <div class="row" *ngIf="loggedUser['role_id'] == 6">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-checkbox formControlName="parent_authorisation_checkbox_1">
            I acknowledge and understand the excursion and non-excursion and
            transport details. </mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_1').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-checkbox formControlName="parent_authorisation_checkbox_2">
            I was given details and opportunity to ask questions in relation to
            the excursion and non-excursion and transport risk
            assessment.</mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_2').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-checkbox formControlName="parent_authorisation_checkbox_3">
            I give consent to the staff, educator, educator assistant, relief
            educator of {{ this.loggedUser['user'].center_name }} to take my
            child to the excursion and non-excursion and/or as the detailed on
            the Authorisation and Risk Assessment for Transport and
            Excursion/Non-Excursion Form.</mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_3').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-checkbox formControlName="parent_authorisation_checkbox_4">
            I authorise the staff, educator, educator assistant, relief educator
            of {{ this.loggedUser['user'].center_name }} to transport my child
            to and from the service, to an excursion / regular outings, to a
            child's home, to other location or as detailed on the Authorisation
            and Risk Assessment for Transport and Excursion/Non-Excursion
            Form.</mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_4').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-checkbox formControlName="parent_authorisation_checkbox_5">
            I give consent to the emergency contact/s and authorised person to
            authorise the educator to take the child outside the education and
            care service premises.</mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_5').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-checkbox formControlName="parent_authorisation_checkbox_6">
            I give consent to the emergency contact/s and authorised person to
            authorise the {{ this.loggedUser['user'].center_name }} to transport
            my child or arrange transportation of my child.</mat-checkbox
          ><br />
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_checkbox_6').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="padding-top: 0 !important"
          >
            <mat-label>Parent Full Name:</mat-label>
            <input
              type="text"
              matInput
              formControlName="parent_authorisation_fullname"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_authorisation_fullname').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            style="margin-top: 10px"
          >
            <mat-label>Signature Date</mat-label>
            <input
              matInput
              formControlName="parent_signature_date"
              [matDatepicker]="picker"
              (click)="picker.open()"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-error
            *ngIf="
              this.sectionForm.get('parent_signature_date').invalid &&
              this.submitBtnClick
            "
          >
            This is a required field!
          </mat-error>
          <label class="f-12">Signature of Parent</label>
          <div *ngIf="!isNewSignature">
            <span
              *ngIf="isEdit"
              (click)="removeSignature()"
              class="badge badge-danger remove-signature pointer b-r-15"
              >x</span
            >
            <img src="{{ signatureImg }}" />
          </div>
          <ng-container *ngIf="isNewSignature">
            <app-signature-pads
              (signaturePadEmit)="signatureImgOutput($event)"
            ></app-signature-pads>
          </ng-container>
          <br />
          <span class="text-danger" *ngIf="sigError"
            >Signature is required</span
          >
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 m-b-10">
          <button
            mat-flat-button
            color="primary"
            (click)="formSubmit(sectionForm, 1)"
            [class.spinner]="submitted"
            class="pull-right text-white"
            type="submit"
          >
            Approved
          </button>
          <button
            (click)="formSubmit(sectionForm, 2)"
            class="pull-right cancel-btn text-white mr-3 bg-light-red"
            type="button"
            mat-raised-button
            data-dismiss="modal"
          >
            Decline
          </button>
        </div>
      </div>

      <input type="hidden" formControlName="ref" />
    </form>
  </div>
</ng-container>
<ng-container
  *ngIf="loggedUser['role_id'] != 6 && parentsSignatures.length > 0"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
    <h5 class="form-group-heading">
      Authorisation for Transport and Excursion
    </h5>
    <h5 class="form-group-heading" *ngIf="loggedUser['role_id'] == 6">
      Authorisation for Transport and Excursion
    </h5>
    <em>By ticking the boxes below and having signed next to my full name:</em>
    <form class="register-form filter-form">
      <table>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_1" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_1 == 0 ||
                data.parent_authorisation_checkbox_1 == null ||
                !data.parent_authorisation_checkbox_1
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I acknowledge and understand the excursion and non-excursion and
              transport details.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_2" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_2 == 0 ||
                data.parent_authorisation_checkbox_2 == null ||
                !data.parent_authorisation_checkbox_2
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I was given details and opportunity to ask questions in relation
              to the excursion and non-excursion and transport risk
              assessment.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_3" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_3 == 0 ||
                data.parent_authorisation_checkbox_3 == null ||
                !data.parent_authorisation_checkbox_3
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the staff, educator, educator assistant, relief
              educator of {{ this.loggedUser['user'].center_name }} to take my
              child to the excursion and non-excursion and/or as the detailed on
              the Authorisation and Risk Assessment for Transport and
              Excursion/Non-Excursion Form.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_4" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_4 == 0 ||
                data.parent_authorisation_checkbox_4 == null ||
                !data.parent_authorisation_checkbox_4
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I authorise the staff, educator, educator assistant, relief
              educator of {{ this.loggedUser['user'].center_name }} to transport
              my child to and from the service, to an excursion / regular
              outings, to a child's home, to other location or as detailed on
              the Authorisation and Risk Assessment for Transport and
              Excursion/Non-Excursion Form.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_5" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_5 == 0 ||
                data.parent_authorisation_checkbox_5 == null ||
                !data.parent_authorisation_checkbox_5
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the emergency contact/s and authorised person
              to authorise the educator to take the child outside the education
              and care service premises and to transport the child or arrange
              transportation of the child.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_6" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_6 == 0 ||
                data.parent_authorisation_checkbox_6 == null ||
                !data.parent_authorisation_checkbox_6
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the emergency contact/s and authorised person
              to authorise the {{ this.loggedUser['user'].center_name }} to
              transport my child or arrange transportation of my child.</strong
            >
          </td>
        </tr>
      </table>
    </form>
    <br />
    <h5 class="form-group-heading">Parent Signatory</h5>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Child name</th>
              <th>Parent name</th>
              <th>Signature</th>
              <th>Signed Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feedback of parentsSignatures">
              <td>{{ feedback['child_name'] }}</td>
              <td>
                {{
                  feedback['parent_authorisation_fullname']
                    ? feedback['parent_authorisation_fullname']
                    : feedback['parent_name']
                }}
              </td>
              <td>
                <img
                  width="auto"
                  height="60px"
                  src="{{ feedback['signature'] }}"
                />
              </td>
              <td>
                {{
                  feedback['parent_authorisation_fullname']
                    ? (feedback['parent_signature_date'] | date : 'dd/MM/yyyy')
                    : (feedback['created_date'] | date : 'dd/MM/yyyy')
                }}
              </td>
              <td *ngIf="feedback['is_approval'] == 1">Approved</td>
              <td *ngIf="feedback['is_approval'] == 2">Declined</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-container
  *ngIf="loggedUser['role_id'] != 6 && parentsSignatures.length < 1"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
    <h5 class="form-group-heading">Parent Signatory</h5>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Child name</th>
              <th>Parent name</th>
              <th>Signature</th>
              <th>Signed Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feedback of parentsSignatures">
              <td>{{ feedback['child_name'] }}</td>
              <td>
                {{
                  feedback['parent_authorisation_fullname']
                    ? feedback['parent_authorisation_fullname']
                    : feedback['parent_name']
                }}
              </td>
              <td>
                <img
                  width="auto"
                  height="60px"
                  src="{{ feedback['signature'] }}"
                />
              </td>
              <td>
                {{
                  feedback['parent_authorisation_fullname']
                    ? (feedback['parent_signature_date'] | date : 'dd/MM/yyyy')
                    : (feedback['created_date'] | date : 'dd/MM/yyyy')
                }}
              </td>
              <td *ngIf="feedback['is_approval'] == 1">Approved</td>
              <td *ngIf="feedback['is_approval'] == 2">Declined</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p *ngIf="parentsSignatures.length < 1">No signatures found.</p>
  </div>
</ng-container>

<ng-container
  *ngIf="
    loggedUser['role_id'] == 6 && data.is_approval != 0 && data.notify_parent_id
  "
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
    <h5 class="form-group-heading" *ngIf="loggedUser['role_id'] == 6">
      Authorisation for Transport and Excursion
    </h5>
    <em *ngIf="loggedUser['role_id'] == 6"
      >By ticking the boxes below and having signed next to my full name:</em
    >
    <form class="register-form filter-form">
      <table>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_1" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_1 == 0 ||
                data.parent_authorisation_checkbox_1 == null ||
                !data.parent_authorisation_checkbox_1
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I acknowledge and understand the excursion and non-excursion and
              transport details.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_2" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_2 == 0 ||
                data.parent_authorisation_checkbox_2 == null ||
                !data.parent_authorisation_checkbox_2
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I was given details and opportunity to ask questions in relation
              to the excursion and non-excursion and transport risk
              assessment.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_3" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_3 == 0 ||
                data.parent_authorisation_checkbox_3 == null ||
                !data.parent_authorisation_checkbox_3
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the staff, educator, educator assistant, relief
              educator of {{ this.loggedUser['user'].center_name }} to take my
              child to the excursion and non-excursion and/or as the detailed on
              the Authorisation and Risk Assessment for Transport and
              Excursion/Non-Excursion Form.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_4" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_4 == 0 ||
                data.parent_authorisation_checkbox_4 == null ||
                !data.parent_authorisation_checkbox_4
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I authorise the staff, educator, educator assistant, relief
              educator of {{ this.loggedUser['user'].center_name }} to transport
              my child to and from the service, to an excursion / regular
              outings, to a child's home, to other location or as detailed on
              the Authorisation and Risk Assessment for Transport and
              Excursion/Non-Excursion Form.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_5" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_5 == 0 ||
                data.parent_authorisation_checkbox_5 == null ||
                !data.parent_authorisation_checkbox_5
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the emergency contact/s and authorised person
              to authorise the educator to take the child outside the education
              and care service premises and to transport the child or arrange
              transportation of the child.</strong
            >
          </td>
        </tr>
        <tr>
          <td width="30%">
            <mat-icon *ngIf="data.parent_authorisation_checkbox_6" class="f-15"
              >check</mat-icon
            >
            <mat-icon
              *ngIf="
                data.parent_authorisation_checkbox_6 == 0 ||
                data.parent_authorisation_checkbox_6 == null ||
                !data.parent_authorisation_checkbox_6
              "
              class="f-15"
              >check_box_outline_blank</mat-icon
            >
            <strong
              >I give consent to the emergency contact/s and authorised person
              to authorise the {{ this.loggedUser['user'].center_name }} to
              transport my child or arrange transportation of my child.</strong
            >
          </td>
        </tr>
      </table>
      <br />
      <ul>
        <li><strong>Parent Full Name: </strong> {{ data.notify_sign_by }}</li>
        <li>
          <strong>Signature Date: </strong>
          {{ data.notify_sign_date | date : 'dd/MM/yyyy' }}
        </li>
        <li><strong>Signature of Parent: </strong></li>
        <li><img src="{{ data['notify_signature'] }}" /></li>
      </ul>
    </form>
  </div>
</ng-container>

<style>
  .table-risk-assessment th {
    color: black;
    background: #00b0f0;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .table-risk-assessment td {
    border: 1px solid black;
    border-collapse: collapse;
  }
</style>
