<table class="table table-sm c-61676c drop-zone-preview f-12" *ngIf="previewType == 1">
  <tr *ngFor="let item of items; let key = index">
    <td>
      <a href="{{ item.url}}" target="_blank">
        <img src="{{ item | getIconFileTypes}}" class="img-fluid preview-img">
      </a>
    </td>
    <td width="75%"><a href="{{ item.url}}" target="_blank">{{ item.real_name}}</a></td>
    <td width="10%" *ngIf="showDelete">
      <a style="font-size: 7px;color: #ff4f3b" class="pointer" (click)="removeItem(key)">
        <mat-icon>close</mat-icon>
      </a>
    </td>
  </tr>
</table>

<div class="row text-center text-lg-left" *ngIf="previewType == 2">
  <div class="d-block p-15 img-preview" *ngFor="let item of items; let key = index">
    <ng-container *ngIf="item['thumbnails'] != null">
      <mat-icon class="center-icon" *ngIf="item['type'].split('/')[0] == 'video'">play_arrow</mat-icon>
      <img class="img-fluid img-thumbnail uploaded" src="{{ item.thumbnails[200] }}" alt="">
    </ng-container>
    <ng-container *ngIf="item['thumbnails'] == null">
      <img class="img-fluid img-thumbnail uploaded" src="{{ item | getIconFileTypes}}" alt="">
    </ng-container>
    <a style="font-size: 7px;color: #ff4f3b; position: absolute;" class="pointer" (click)="removeItem(key)">
      <mat-icon class="f-20">close</mat-icon>
    </a>
  </div>
</div>


<div class="row text-center text-lg-left" *ngIf="previewType == 3" align="center">
  <div class="d-block p-15 img-preview" *ngFor="let item of items; let key = index">
    <ng-container *ngIf="item['type'].split('/')[0] == 'image'">
      <img class="img-fluid img-thumbnail radius-50p" src="{{ item.previewUrl }}" alt="">
    </ng-container>
    <a style="font-size: 7px;color: #ff4f3b; position: absolute;" class="pointer" (click)="removeItem(key)">
      <mat-icon class="f-20">close</mat-icon>
    </a>
  </div>
</div>
