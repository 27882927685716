<div class="container-fluid">
  <ngx-spinner></ngx-spinner>
  <div class="row">
    <div style="padding: 0px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 m-t-27">
      <div class="card">
        <div class="body">
          <div class="row p-l-r-30 m-t-15" *ngIf="accessMode">
            <div class="col-4">
              <h3>Child Enrolment Form</h3>
            </div>
            
            <div class="col-8" align="right">
              <button mat-icon-button class="modal-print-btn2 pull-right m-l-5 shadow-style"
                *ngIf="EnrollmentFormData.status != 2" (click)="downloadDoc(childEnrollmentFormRef)"><i
                  class="material-icons color-icon print-icon-color">print</i></button>
              <!--              <button mat-raised-button  class="pull-right m-l-5 shadow-style" color="primary" *ngIf="!isEditFormSubmit && EnrollmentFormData.status != 2" (click)="changeEditToView(true)">View</button>-->
              <ng-container *ngIf="!editMode">
                <button mat-raised-button class="pull-right m-l-5 shadow-style" color="primary"
                  *ngIf="EnrollmentFormData.status == 0 || EnrollmentFormData.status == 3"
                  (click)="changeEditToView(true)">Edit</button>
              </ng-container>
              <button mat-raised-button *ngIf="EnrollmentFormData.status == 3" color="warn"
                class="pull-right m-l-5 shadow-style" (click)="ApproveRejectEnrollmentForm(0)">Reject</button>
              <button mat-raised-button *ngIf="EnrollmentFormData.status == 3 || EnrollmentFormData.status == 0"
                class="pull-right m-l-5 shadow-style color-bg-green text-white"
                (click)="ApproveRejectEnrollmentForm(1)">Approve</button>
              <!--              <button mat-raised-button class="pull-right m-l-5 shadow-style color-bg-orange text-white" (click)="ApproveRejectEnrollmentForm(2)">Pending</button>-->
            </div>
          </div>

          <div class="clearfix"></div>
          <mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">

            <mat-step [stepControl]="HFormGroup1">

              <form [formGroup]="HFormGroup1" style="margin-top: 5px;" (submit)="onSubmit('child_details')">
                <ng-template matStepLabel>Child's Details</ng-template>
                <div class="row m-t-20">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Child's First Name </mat-label>
                      <input formControlName="child_givenname" matInput required>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Child's Last Name </mat-label>
                      <input formControlName="child_surname" matInput required>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Date of Birth</mat-label>
                      <input matInput (click)="child_dob.open()" formControlName="child_dob" [matDatepicker]="child_dob"
                        (click)="child_dob.open()">
                      <mat-datepicker-toggle matSuffix [for]="child_dob"></mat-datepicker-toggle>
                      <mat-datepicker #child_dob></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline"
                      matTooltip="CRN field should be mandatory, and requires 9 digits and a letter 'Please write NA if not applicable'">
                      <mat-label>CRN</mat-label>
                      <input formControlName="child_crn" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Address</mat-label>
                      <input formControlName="child_address" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Suburb</mat-label>
                      <input formControlName="child_suburb" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="child_state" required>
                        <mat-option *ngFor="let state  of states" value="{{ state.state_id }}">{{ state.state_name }}
                        </mat-option>
                        <!--                        <mat-option value="NSW">New South Wales</mat-option>-->
                        <!--                        <mat-option value="QLD">Queensland</mat-option>-->
                        <!--                        <mat-option value="SA">South Australia</mat-option>-->
                        <!--                        <mat-option value="TAS">Tasmania</mat-option>-->
                        <!--                        <mat-option value="VIC">Victoria</mat-option>-->
                        <!--                        <mat-option value="WA">Western Australia</mat-option>-->
                        <!--                        <mat-option value="ACT">Australian Capital Territory</mat-option>-->
                        <!--                        <mat-option value="NT">Northern Territory</mat-option>-->
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Postal Code</mat-label>
                      <input type="number" formControlName="child_postal_code" matInput required>
                    </mat-form-field>
                  </div>

                  <input formControlName="child_country_birth" matInput required hidden>


                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Country Of Birth</mat-label>
                      <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" (blur)="onBlurCountryCBO()" required>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChangeCountry($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{option.country_name}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>



                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select formControlName="child_gender" required>
                        <mat-option value="none">Prefer Not To Say</mat-option>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                        <mat-option value="other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Any other name for child</mat-label>
                      <mat-select formControlName="is_other_name" #child_have_name
                        (selectionChange)="AnyNameForChild($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>

                      </mat-select>
                    </mat-form-field>

                  </div>

                  <div *ngIf="!hideOtherName" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Other Name</mat-label>
                      <input formControlName="child_other_name" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Cultural Background</mat-label>
                      <input formControlName="child_cultural_background" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label> Aboriginal/Torres Strait Islander</mat-label>
                      <mat-select formControlName="aboriginal_strait_islander">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Language Spoken</mat-label>
                      <textarea formControlName="child_language_spoken" matInput required></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>School</mat-label>
                      <input formControlName="child_school" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Year Level</mat-label>
                      <input formControlName="child_year_level" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Does child have any fears?</mat-label>
                      <mat-select formControlName="child_fears" #childFear
                        (selectionChange)="AnyFearsForChild($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="!hideFearDetails" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Fears</mat-label>
                      <textarea formControlName="child_fears_details" matInput></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Child's interests</mat-label>
                      <textarea formControlName="child_interest" matInput></textarea>
                    </mat-form-field>
                  </div>




                </div>
                <div>
                  <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>

                  <button mat-raised-button *ngIf="editMode" [class.spinner]="submitted1"
                    class="m-lg-2 text-white enable-color"
                    matTooltipPosition="above">Save</button>
                </div>
              </form>

            </mat-step>

            <mat-step [stepControl]="HFormGroup2">
              <form [formGroup]="HFormGroup2" (submit)="onSubmit('parent_details')">
                <ng-template matStepLabel>Parent/Guardian Details</ng-template>
                <h5 class="form-group-heading">Parent/Guardian 1 (Please Write Full Name)</h5>
                <div class="row p-t-12">

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input formControlName="parent1_first_name" matInput required>
                      <input formControlName="parent1_name" matInput hidden>
                      <input formControlName="parent1_user_id" matInput hidden>
                    </mat-form-field>

                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input formControlName="parent1_last_name" matInput required>
                    </mat-form-field>

                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Date of Birth</mat-label>
                      <input matInput (click)="parent_one_dob.open()" formControlName="parent1_dob"
                        [matDatepicker]="parent_one_dob" required>
                      <mat-datepicker-toggle matSuffix [for]="parent_one_dob"></mat-datepicker-toggle>
                      <mat-datepicker #parent_one_dob></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Relationship to the Child</mat-label>
                      <!--                                            <input formControlName="parent1_relationship" matInput required>-->
                      <mat-select formControlName="parent1_relationship" 
                        (selectionChange)="$event.value == 'Other' ? ShowP1relation = true :ShowP1relation = false">
                        <mat-option value="Brother">Brother</mat-option>
                        <mat-option value="Sister">Sister</mat-option>
                        <mat-option value="Uncle">Uncle</mat-option>
                        <mat-option value="Auntie">Auntie</mat-option>
                        <mat-option value="Grandfather">Grandfather</mat-option>
                        <mat-option value="Grandmother">Grandmother</mat-option>
                        <mat-option value="Mother">Mother</mat-option>
                        <mat-option value="Father">Father</mat-option>
                        <mat-option value="Friend">Friend</mat-option>
                        <mat-option value="Cousin">Cousin</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <div *ngIf="ShowP1relation" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Other - provide details of relationship.</mat-label>
                      <input formControlName="parent1_other" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline" required
                      matTooltip="CRN field should be mandatory, and requires 9 digits and a letter 'Please write NA if not applicable'">
                      <mat-label>CRN</mat-label>
                      <input formControlName="parent1_crn" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Mobile Number</mat-label>
                      <input formControlName="parent1_contact_no" matInput required>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Alternative Phone Number</mat-label>
                      <input formControlName="parent1_work_phone" matInput>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select formControlName="parent1_gender">
                        <mat-option value="none">Prefer Not To Say</mat-option>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                        <mat-option value="other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input formControlName="parent1_email" matInput required>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Postal Address</mat-label>
                      <input formControlName="parent1_address" matInput required>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Suburb</mat-label>
                      <input formControlName="parent1_suburb" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="parent1_state" required>
                        <mat-option *ngFor="let state  of states" value="{{ state.state_id }}">{{ state.state_name }}
                        </mat-option>
                        <!--                        <mat-option value="NSW">New South Wales</mat-option>-->
                        <!--                        <mat-option value="QLD">Queensland</mat-option>-->
                        <!--                        <mat-option value="SA">South Australia</mat-option>-->
                        <!--                        <mat-option value="TAS">Tasmania</mat-option>-->
                        <!--                        <mat-option value="VIC">Victoria</mat-option>-->
                        <!--                        <mat-option value="WA">Western Australia</mat-option>-->
                        <!--                        <mat-option value="ACT">Australian Capital Territory</mat-option>-->
                        <!--                        <mat-option value="NT">Northern Territory</mat-option>-->
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Postal Code</mat-label>
                      <input type="number" formControlName="parent1_postal_code" matInput required>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Cultural Background</mat-label>
                      <input formControlName="parent1_cultural_background" matInput required>
                    </mat-form-field>
                  </div>
                  
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label> Aboriginal/Torres Strait Islander</mat-label>
                      <mat-select formControlName="parent1_aboriginal_strait_islander" required>
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Language Spoken</mat-label>
                      <textarea formControlName="parent1_language_spoken" matInput required></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Working / Studying</mat-label>
                      <mat-select formControlName="parent1_is_working" (selectionChange)="ParentOneDoing($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <ng-container *ngIf="false">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working or Studying Institute / Occupation</mat-label>
                        <input formControlName="parent1_occupation" matInput>
                      </mat-form-field>

                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working or Studying Address</mat-label>
                        <textarea matInput formControlName="parent1_work_address"></textarea>
                      </mat-form-field>

                    </div>

                    <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working / Studying Work Phone</mat-label>
                        <input formControlName="parent1_work_phone_no" matInput>
                      </mat-form-field>

                    </div>
              
                  </ng-container>
                  <ng-container *ngIf="!hideParent1occupation">
                    <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <button type="button" (click)="AddWorkSchoolParent1()" mat-stroked-button color="primary" [disabled]="disable_btn_add_work_school">Add Work / School</button>

                    </div>
                    <ng-container *ngIf="show_parent1_add_fields_for_workschool">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form [formGroup]="work_table_form" (ngSubmit)="SaveWorkSchoolParent1()">
                          <div class="row p-t-12">
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Institute / Occupation</mat-label>
                                <input formControlName="parent1_occupation_temp" matInput >
                              </mat-form-field>
                            </div>
        
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="parent1_work_address_temp" ></textarea>
                              </mat-form-field>
                            </div>
        
                            <div  class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Phone</mat-label>
                                <input formControlName="parent1_work_phone_no_temp" matInput >
                              </mat-form-field>
                            </div>
                            <div  class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2 example-button-row">
                              <button mat-stroked-button color="primary">Save Record</button>
                              <button type="button" mat-stroked-button color="accent" (click)="CancelWorkSchoolParent1()"><mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></button>
                            </div>
                          </div>
                          
                        </form>
                      </div>
                      
                    </ng-container>
                   
                    
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      
                      <div class="card">
                        <div class="body table-responsive">
                          <table class="table">
                            <thead>
                                <tr>
                                    <th *ngFor="let each of displayedColumns"> {{each}} </th>
                                    <th> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dataSource">
                                    <td *ngFor="let each of item | keyvalue"> {{each.value}} </td>
                                    <td>
                                      <button mat-icon-button (click)="EditWorkSchoolParent1(item)" type="button" mat-button color="primary">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                      <button mat-icon-button (click)="DeleteWorkSchoolParent1(item)" type="button" mat-button color="accent">
                                        <mat-icon>delete_forever</mat-icon>
                                      </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>
                <mat-checkbox (click)="confirmAssign($event)" class="example-margin" [(ngModel)]="disabledPG2"  [ngModelOptions]="{standalone: true}">Include Parent/Guardian 2</mat-checkbox>

                <h5 *ngIf="disabledPG2" class="form-group-heading">Parent/Guardian 2 (Please Write Full Name)</h5>
                <div *ngIf="disabledPG2" class="row p-t-12">
                
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>FIrst Name</mat-label>
                      <input formControlName="parent2_first_name" matInput>
                      <input formControlName="parent2_name" matInput hidden>
                      <input formControlName="parent2_user_id" matInput hidden>
                    </mat-form-field>

                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input formControlName="parent2_last_name" matInput>
                    </mat-form-field>

                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Date of Birth</mat-label>
                      <input matInput (click)="parent_two_dob.open()" formControlName="parent2_dob"
                        [matDatepicker]="parent_two_dob">
                      <mat-datepicker-toggle matSuffix [for]="parent_two_dob"></mat-datepicker-toggle>
                      <mat-datepicker #parent_two_dob></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Relationship to the Child</mat-label>
                      <mat-select formControlName="parent2_relationship"
                        (selectionChange)="parent2_relationship_change($event)">
                        <mat-option value="Brother">Brother</mat-option>
                        <mat-option value="Sister">Sister</mat-option>
                        <mat-option value="Uncle">Uncle</mat-option>
                        <mat-option value="Auntie">Auntie</mat-option>
                        <mat-option value="Grandfather">Grandfather</mat-option>
                        <mat-option value="Grandmother">Grandmother</mat-option>
                        <mat-option value="Mother">Mother</mat-option>
                        <mat-option value="Father">Father</mat-option>
                        <mat-option value="Friend">Friend</mat-option>
                        <mat-option value="Cousin">Cousin</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <div *ngIf="ShowP2relation" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Other - provide details of relationship.</mat-label>
                      <input formControlName="parent2_other" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline"
                      matTooltip="CRN field should be mandatory, and requires 9 digits and a letter 'Please write NA if not applicable'">
                      <mat-label>CRN</mat-label>
                      <input formControlName="parent2_crn" matInput>
                    </mat-form-field>
                  </div>


                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Mobile Number</mat-label>
                      <input formControlName="parent2_contact_no" matInput>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Alternative Phone Number</mat-label>
                      <input formControlName="parent2_work_phone" matInput>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select formControlName="parent2_gender">
                        <mat-option value="none">Prefer Not To Say</mat-option>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                        <mat-option value="other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input formControlName="parent2_email" matInput (blur)="checkEmailExists('P2')">
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Postal Address</mat-label>
                      <input matInput formControlName="parent2_address">
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Suburb</mat-label>
                      <input formControlName="parent2_suburb" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="parent2_state">
                        <mat-option *ngFor="let state  of states" value="{{ state.state_id }}">{{ state.state_name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Postal Code</mat-label>
                      <input type="number" formControlName="parent2_postal_code" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Cultural Background</mat-label>
                      <input formControlName="parent2_cultural_background" matInput>
                    </mat-form-field>
                  </div>
                  
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label> Aboriginal/Torres Strait Islander</mat-label>
                      <mat-select formControlName="parent2_aboriginal_strait_islander">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Language Spoken</mat-label>
                      <textarea formControlName="parent2_language_spoken" matInput></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Working / Studying</mat-label>
                      <mat-select formControlName="parent2_is_working" (selectionChange)="ParentTwoDoing($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  <ng-container *ngIf="false">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working or Studying Institute / Occupation</mat-label>
                        <input formControlName="parent2_occupation" matInput>
                      </mat-form-field>

                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working or Studying Address</mat-label>
                        <textarea matInput formControlName="parent2_work_address"></textarea>
                      </mat-form-field>

                    </div>

                    <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Working / Studying Work Phone</mat-label>
                        <input formControlName="parent2_work_phone_no" matInput>
                      </mat-form-field>

                    </div>
              
                  </ng-container>
                  <ng-container *ngIf="!hideParent2occupation">

                    <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <button type="button" (click)="AddWorkSchoolParent2()" mat-stroked-button color="primary" [disabled]="disable_btn_add_work_school2">Add Work / School</button>

                    </div>
                    <ng-container *ngIf="show_parent2_add_fields_for_workschool">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form [formGroup]="work_table_form2" (ngSubmit)="SaveWorkSchoolParent2()">
                          <div class="row p-t-12">
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Institute / Occupation</mat-label>
                                <input formControlName="parent2_occupation_temp" matInput >
                              </mat-form-field>
                            </div>
        
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="parent2_work_address_temp" ></textarea>
                              </mat-form-field>
                            </div>
        
                            <div  class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Phone</mat-label>
                                <input formControlName="parent2_work_phone_no_temp" matInput >
                              </mat-form-field>
                            </div>
                            <div  class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2 example-button-row">
                              <button mat-stroked-button color="primary">Save Record</button>
                              <button type="button" mat-stroked-button color="accent" (click)="CancelWorkSchoolParent2()"><mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></button>
                            </div>
                          </div>
                          
                        </form>
                      </div>
                      
                    </ng-container>
                   
                    
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      
                      <div class="card">
                        <div class="body table-responsive">
                          <table class="table">
                            <thead>
                                <tr>
                                    <th *ngFor="let each of displayedColumns"> {{each}} </th>
                                    <th> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dataSource2">
                                    <td *ngFor="let each of item | keyvalue"> {{each.value}} </td>
                                    <td>
                                      <button mat-icon-button (click)="EditWorkSchoolParent2(item)" type="button" mat-button color="primary">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                      <button mat-icon-button (click)="DeleteWorkSchoolParent2(item)" type="button" mat-button color="accent">
                                        <mat-icon>delete_forever</mat-icon>
                                      </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </ng-container>


                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Prohibited from having contact with or collecting the child</mat-label>
                      <mat-select formControlName="contact_child"
                        (selectionChange)="haveProhibitedContact($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>


                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2" *ngIf="!hideProhibitedContactDetails">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Prohibited Contacts</mat-label>
                      <input formControlName="contact_child_details" matInput>
                    </mat-form-field>

                  </div>
                </div>
                <div>
                  <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                      <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
                      <button mat-raised-button *ngIf="editMode" [class.spinner]="submitted2"
                        class="m-lg-2 text-white enable-color" 
                        [ngClass]="!HFormGroup2.valid ? 'disable-color' : 'enable-color'">Save</button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="HFormGroup3">
              <form [formGroup]="HFormGroup3" (submit)="onSubmit('emergency_contact')">
                <ng-template matStepLabel>Emergency Contact and Authorisations</ng-template>
                <h5 class="form-group-heading">Emergency Contact and Authorisations</h5>
                <em class="f-12">(Other than Parent/Guardian, the Emergency Contacts and
                  Authorised Person must be aged over 18 years of age)</em>

                <h5 class="form-group-heading m-t-8">Contact 1</h5>
                <div class="row p-t-12">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input formControlName="emergency1_last_name" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input formControlName="emergency1_first_name" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Relationship to the Child</mat-label>
                      <mat-select formControlName="emergency1_relationship" required
                        (selectionChange)="$event.value == 'Other' ? ShowE1relation = true :ShowE1relation = false">
                        <mat-option value="Brother">Brother</mat-option>
                        <mat-option value="Sister">Sister</mat-option>
                        <mat-option value="Uncle">Uncle</mat-option>
                        <mat-option value="Auntie">Auntie</mat-option>
                        <mat-option value="Grandfather">Grandfather</mat-option>
                        <mat-option value="Grandmother">Grandmother</mat-option>
                        <mat-option value="Mother">Mother</mat-option>
                        <mat-option value="Father">Father</mat-option>
                        <mat-option value="Friend">Friend</mat-option>
                        <mat-option value="Cousin">Cousin</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="ShowE1relation" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Other - provide details of relationship.</mat-label>
                      <input formControlName="emergency1_other" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-t-12">

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Mobile Phone</mat-label>
                      <input formControlName="emergency1_mobile" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Work Phone</mat-label>
                      <input formControlName="emergency1_work_phone" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Home Phone</mat-label>
                      <input formControlName="emergency1_home_phone" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-t-12">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Address</mat-label>
                      <textarea formControlName="emergency1_address" matInput required></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-2">
                    <h6>This person has the authority to (Please tick all that apply)</h6>
                    <mat-checkbox class="example-margin" formControlName="emergency1_authorise_take_child_outside">
                      Authorise an educator/staff to take the child outside the education and care service premises</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_authorise_child_transportation">
                      Authorise the education and care service to transport or arrange transportation of your child</mat-checkbox><br /> 
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_collect_your_child">
                      Collect/Deliver your child to/from the service</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_excursion_out_of_service">Give
                      permission for excursion out of the service</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_medical_treatment">Consent to
                      medical treatment for your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_permit_transportation">Permit
                      transportation of your child by an ambulance</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_request_give_medication">
                      Request/Permit medication to be given to your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin test" formControlName="emergency1_is_notified">If the
                      parent/guardians cannot be contacted, this person should be notified of any accident, injury,
                      trauma or illness involving your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency1_is_collecting_data">
                      Permission to collect data</mat-checkbox><br />
                  </div>
                </div>
                <hr />
                <h5 class="form-group-heading">Contact 2</h5>

                <div class="row p-t-12">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input formControlName="emergency2_last_name" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>FIrst Name</mat-label>
                      <input formControlName="emergency2_first_name" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Relationship to the Child</mat-label>
                      <!--                                            <input formControlName="emergency2_relationship" matInput>-->
                      <mat-select formControlName="emergency2_relationship"
                        (selectionChange)="$event.value == 'Other' ? ShowE2relation = true :ShowE2relation = false">
                        <mat-option value="Brother">Brother</mat-option>
                        <mat-option value="Sister">Sister</mat-option>
                        <mat-option value="Uncle">Uncle</mat-option>
                        <mat-option value="Auntie">Auntie</mat-option>
                        <mat-option value="Grandfather">Grandfather</mat-option>
                        <mat-option value="Grandmother">Grandmother</mat-option>
                        <mat-option value="Mother">Mother</mat-option>
                        <mat-option value="Father">Father</mat-option>
                        <mat-option value="Friend">Friend</mat-option>
                        <mat-option value="Cousin">Cousin</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="ShowE2relation" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Other - provide details of relationship.</mat-label>
                      <input formControlName="emergency2_other" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-t-12">
                 
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Mobile Phone</mat-label>
                      <input formControlName="emergency2_mobile" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Work Phone</mat-label>
                      <input formControlName="emergency2_work_phone" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Home Phone</mat-label>
                      <input formControlName="emergency2_home_phone" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-t-12">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Address</mat-label>
                      <textarea formControlName="emergency2_address" matInput></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-2">
                    <h6>This person has the authority to (Please tick all that apply)</h6>
                    <mat-checkbox class="example-margin" formControlName="emergency2_authorise_take_child_outside">
                      Authorise an educator/staff to take the child outside the education and care service premises</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_authorise_child_transportation">
                      Authorise the education and care service to transport or arrange transportation of your child</mat-checkbox><br />  
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_collect_your_child">
                      Collect/Deliver your child to/from the service</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_excursion_out_of_service">Give
                      permission for excursion out of the service</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_medical_treatment">Consent to
                      medical treatment for your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_permit_transportation">Permit
                      transportation of your child by an ambulance</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_request_give_medication">
                      Request/Permit medication to be given to your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_notified">If the
                      parent/guardians cannot be contacted, this person should be notified of any accident, injury,
                      trauma or illness involving your child</mat-checkbox><br />
                    <mat-checkbox class="example-margin" formControlName="emergency2_is_collecting_data">
                      Permission to collect data</mat-checkbox><br />
                  </div>
                </div>
                
                <p class="f-12"><b>Please Note</b> We may not release your child to an unlisted person without prior written
                  notification. If any person not listed and not known to the
                  education and care service staff should attempt to collect your child from the service,
                  permission will be refused.
                </p>
                <hr />

                
                <!-- <div class="row p-t-12">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Permission to Collect Data</mat-label>
                      <mat-select formControlName="n_b_collect_data">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div> -->
                <h5 class="form-group-heading">Custody Arrangement</h5>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Court orders affecting custody of the child?</mat-label>
                      <mat-select formControlName="is_collect_child" (selectionChange)="childCustody($event.value)">
                        <mat-option value="0">No</mat-option>
                        <mat-option value="1">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                    <div *ngIf="hideChildCustodyDocument" >
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Please Provide Details</mat-label>
                        <textarea formControlName="custody_details" matInput></textarea>
                      </mat-form-field>
                    </div>
                    <p *ngIf="hideChildCustodyDocument" class="f-12">
                      Please provide all current court orders relating to this child. Copies should be verified, and updated if changes occur.  Custody arrangements are only able to be adhered to if relevant court documents are provided.
                    </p>
                  </div>
                    
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">

                    <ng-container *ngIf="!accessMode && hideChildCustodyDocument">
                      <p class="f-12">Upload Court orders affecting custody documents</p>
                      <app-custom-dropzone (filePass)="uploadCustodyDocuments($event)"></app-custom-dropzone>
                      <app-dropzone-priview [items]="custody_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_custody')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="true" [items]="custody_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_custody')"></app-dropzone-recieved>
                    </ng-container>

                    <ng-container *ngIf="accessMode && hideChildCustodyDocument">
                      <p class="f-12">Court orders affecting custody documents</p>
                      <app-dropzone-priview [items]="custody_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_custody')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="editMode" [items]="custody_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_custody')"></app-dropzone-recieved>
                    </ng-container>

                  </div>
                </div>
               
                <div *ngIf="hideChildCustodyDocument" >
                  <p *ngIf="hideChildCustodyDocument" class="f-12">
                    Please list below
                    any other specific instructions or information you can provide that would be helpful and assist us in
                    the care of your child.
                  </p>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Write Notes</mat-label>
                    <textarea formControlName="custody_notes" matInput></textarea>
                  </mat-form-field>

                </div>

                <div>
                  <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                      <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
                      <button mat-raised-button *ngIf="editMode" [class.spinner]="submitted3"
                        class="m-lg-2 text-white enable-color" matTooltip="Submit Form From this Step"
                        matTooltipPosition="above"
                        [ngClass]="!HFormGroup3.valid ? 'disable-color' : 'enable-color'">Save</button>

                </div>
              </form>

            </mat-step>

            <mat-step [stepControl]="HFormGroup7">
                <ng-template matStepLabel>Schedule</ng-template>
                <div class="row m-t-15"[formGroup]="HFormGroup7">
                  <app-children-schedule [routineSession]="routineSession" [casualSession] = "casualSession" 
                   (updateForm) = "updateForm($event)" [startDate]="startDate"
                  [isEdit]="editMode" [isDisabled]="disableSchedule"></app-children-schedule>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <button mat-raised-button matStepperPrevious color="warn" class="mr-2"><< Back</button>
                    <button mat-raised-button matStepperNext class="text-white color-bg-purple" (click)="onSubmit('schedule')">Next >></button>
                    <button mat-raised-button (click)="onSubmit('schedule')" *ngIf="editMode" [class.spinner]="submitted4"
                      [class.not-allowed]="submittedSchedule" class="m-lg-2 text-white"
                      matTooltip="Submit Form From this Step" matTooltipPosition="above"
                      [ngClass]="!HFormGroup7.valid ? 'disable-color' : 'enable-color'"> Save</button>
                  </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="HFormGroup4">
              <form [formGroup]="HFormGroup4" (submit)="onSubmit('medical_details')" enctype="multipart/form-data">
                <ng-template matStepLabel>Immunisation Details, Medical and Allergy Information</ng-template>
                <h5 class="form-group-heading">Immunisation Details</h5>

                <div class="row m-t-20">
                  <table class="mat-elevation-z8 demo-table" style="table-layout: fixed">
                    <tr>
                      <td></td>
                      <td class="checkbox"><b>Birth</b></td>
                      <td class="checkbox"><b>2 mo</b></td>
                      <td class="checkbox"><b>4 mo</b></td>
                      <td class="checkbox"><b>6 mo</b></td>
                      <td class="checkbox"><b>12 mo</b></td>
                      <td class="checkbox"><b>18 mo</b></td>
                      <td class="checkbox"><b>4 yrs</b></td>
                    </tr>
                    <tr>
                      <td class="sickness">Hepatitis B</td>
                      <td class="checkbox" *ngIf="hepatitis_b_data.includes('0'); else hepatitis_b0">
                        <mat-checkbox class="example-margin" #Chepatitis_b0 name="hepatitis_b0" (change)="onChangeChkState($event)" value="0" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #hepatitis_b0>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chepatitis_b0 name="hepatitis_b0" (change)="onChangeChkState($event)" value="0" ></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox" *ngIf="hepatitis_b_data.includes('2'); else hepatitis_b2">
                        <mat-checkbox class="example-margin" #Chepatitis_b2 name="hepatitis_b2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #hepatitis_b2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chepatitis_b2 name="hepatitis_b2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox"  *ngIf="hepatitis_b_data.includes('4'); else hepatitis_b4">
                        <mat-checkbox class="example-margin" #Chepatitis_b4 name="hepatitis_b4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #hepatitis_b4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chepatitis_b4 name="hepatitis_b4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox"  *ngIf="hepatitis_b_data.includes('6'); else hepatitis_b6">
                        <mat-checkbox class="example-margin" #Chepatitis_b6 name="hepatitis_b6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
  
                      </td>
                      <ng-template #hepatitis_b6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chepatitis_b6 name="hepatitis_b6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Diphtheria</td>
                      <td></td>
  
                      <td class="checkbox" *ngIf="diphtheria_data.includes('2'); else diphtheria2">
                        <mat-checkbox class="example-margin" #Cdiphtheria2 name="diphtheria2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #diphtheria2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cdiphtheria2 name="diphtheria2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox"  *ngIf="diphtheria_data.includes('4'); else diphtheria4">
                        <mat-checkbox class="example-margin" #Cdiphtheria4 name="diphtheria4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #diphtheria4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cdiphtheria4 name="diphtheria4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox"  *ngIf="diphtheria_data.includes('6'); else diphtheria6">
                        <mat-checkbox class="example-margin" #Cdiphtheria6 name="diphtheria6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
  
                      </td>
                      <ng-template #diphtheria6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cdiphtheria6 name="diphtheria6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td></td>
  
                      <td class="checkbox" *ngIf="diphtheria_data.includes('18'); else diphtheria18">
                        <mat-checkbox class="example-margin" #Cdiphtheria18 name="diphtheria18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #diphtheria18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cdiphtheria18 name="diphtheria18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
  
                      <td class="checkbox" *ngIf="diphtheria_data.includes('48'); else diphtheria48">
                        <mat-checkbox class="example-margin" #Cdiphtheria48 name="diphtheria48" (change)="onChangeChkState($event)" value="48" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #diphtheria48>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cdiphtheria48 name="diphtheria48" (change)="onChangeChkState($event)" value="48" ></mat-checkbox>
                        </td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="sickness">Tetanus</td>
                      <td></td>
                      <td class="checkbox" *ngIf="tetanus_data.includes('2'); else tetanus2">
                        <mat-checkbox class="example-margin" #Ctetanus2 name="tetanus2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #tetanus2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Ctetanus2 name="tetanus2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="tetanus_data.includes('4'); else tetanus4">
                        <mat-checkbox class="example-margin" #Ctetanus4 name="tetanus4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #tetanus4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Ctetanus4 name="tetanus4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="tetanus_data.includes('6'); else tetanus6">
                        <mat-checkbox class="example-margin" #Ctetanus6 name="tetanus6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
                      
                      </td>
                      <ng-template #tetanus6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Ctetanus6 name="tetanus6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="tetanus_data.includes('18'); else tetanus18">
                        <mat-checkbox class="example-margin" #Ctetanus18 name="tetanus18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #tetanus18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Ctetanus18 name="tetanus18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="tetanus_data.includes('48'); else tetanus48">
                        <mat-checkbox class="example-margin" #Ctetanus48 name="tetanus48" (change)="onChangeChkState($event)" value="48" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #tetanus48>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Ctetanus48 name="tetanus48" (change)="onChangeChkState($event)" value="48" ></mat-checkbox>
                        </td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="sickness">Pertussis</td>
                      <td></td>
                      <td class="checkbox" *ngIf="pertusis_data.includes('2'); else pertusis2">
                        <mat-checkbox class="example-margin" #Cpertusis2 name="pertusis2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #pertusis2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpertusis2 name="pertusis2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="pertusis_data.includes('4'); else pertusis4">
                        <mat-checkbox class="example-margin" #Cpertusis4 name="pertusis4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #pertusis4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpertusis4 name="pertusis4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="pertusis_data.includes('6'); else pertusis6">
                        <mat-checkbox class="example-margin" #Cpertusis6 name="pertusis6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
                      
                      </td>
                      <ng-template #pertusis6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpertusis6 name="pertusis6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="pertusis_data.includes('18'); else pertusis18">
                        <mat-checkbox class="example-margin" #Cpertusis18 name="pertusis18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #pertusis18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpertusis18 name="pertusis18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="pertusis_data.includes('48'); else pertusis48">
                        <mat-checkbox class="example-margin" #Cpertusis48 name="pertusis48" (change)="onChangeChkState($event)" value="48" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #pertusis48>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpertusis48 name="pertusis48" (change)="onChangeChkState($event)" value="48" ></mat-checkbox>
                        </td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="sickness">Polio</td>
                      <td></td>
                      <td class="checkbox" *ngIf="polio_data.includes('2'); else polio2">
                        <mat-checkbox class="example-margin" #Cpolio2 name="polio2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #polio2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpolio2 name="polio2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="polio_data.includes('4'); else polio4">
                        <mat-checkbox class="example-margin" #Cpolio4 name="polio4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #polio4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpolio4 name="polio4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="polio_data.includes('6'); else polio6">
                        <mat-checkbox class="example-margin" #Cpolio6 name="polio6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
                      
                      </td>
                      <ng-template #polio6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpolio6 name="polio6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="polio_data.includes('48'); else polio48">
                        <mat-checkbox class="example-margin" #Cpolio48 name="polio48" (change)="onChangeChkState($event)" value="48" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #polio48>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpolio48 name="polio48" (change)="onChangeChkState($event)" value="48" ></mat-checkbox>
                        </td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="sickness">Hib</td>
                      <td></td>
                      <td class="checkbox" *ngIf="hib_data.includes('2'); else hib2">
                        <mat-checkbox class="example-margin" #Chib2 name="hib2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #hib2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chib2 name="hib2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="hib_data.includes('4'); else hib4">
                        <mat-checkbox class="example-margin" #Chib4 name="hib4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #hib4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chib4 name="hib4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="hib_data.includes('6'); else hib6">
                        <mat-checkbox class="example-margin" #Chib6 name="hib6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
                      
                      </td>
                      <ng-template #hib6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chib6 name="hib6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="hib_data.includes('18'); else hib18">
                        <mat-checkbox class="example-margin" #Chib18 name="hib18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #hib18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chib18 name="hib18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Pneumococcal</td>
                      <td></td>
                      <td class="checkbox" *ngIf="pneumococcal_data.includes('2'); else pneumococcal2">
                        <mat-checkbox class="example-margin" #Cpneumococcal2 name="pneumococcal2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #pneumococcal2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpneumococcal2 name="pneumococcal2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="pneumococcal_data.includes('4'); else pneumococcal4">
                        <mat-checkbox class="example-margin" #Cpneumococcal4 name="pneumococcal4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #pneumococcal4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpneumococcal4 name="pneumococcal4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="pneumococcal_data.includes('6'); else pneumococcal6">
                        <mat-checkbox class="example-margin" #Cpneumococcal6 name="pneumococcal6" (change)="onChangeChkState($event)" value="6" [checked]="true"></mat-checkbox>
                        <p style="color:blue; font-size:xx-small">Medically at-risk and Indigenous*</p>
                      </td>
                      <ng-template #pneumococcal6>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpneumococcal6 name="pneumococcal6" (change)="onChangeChkState($event)" value="6"></mat-checkbox>
                          <p style="color:blue; font-size:xx-small">Medically at-risk and Indigenous*</p>
                        </td>
                      </ng-template>
                      
                      
                      <td class="checkbox" *ngIf="pneumococcal_data.includes('12'); else pneumococcal12">
                        <mat-checkbox class="example-margin" #Cpneumococcal12 name="pneumococcal12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #pneumococcal12>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpneumococcal12 name="pneumococcal12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="pneumococcal_data.includes('48'); else pneumococcal48">
                        <mat-checkbox class="example-margin" #Cpneumococcal48 name="pneumococcal48" (change)="onChangeChkState($event)" value="48" [checked]="true"></mat-checkbox>
                        <p style="color:blue; font-size:xx-small">Medically at-risk</p>
                      </td> 
                      <ng-template #pneumococcal48>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cpneumococcal48 name="pneumococcal48" (change)="onChangeChkState($event)" value="48" ></mat-checkbox>
                          <p style="color:blue; font-size:xx-small">Medically at-risk</p>
                        </td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="sickness">Rotavirus</td>
                      <td></td>
                      <td class="checkbox" *ngIf="rotavirus_data.includes('2'); else rotavirus2">
                        <mat-checkbox class="example-margin" #Crotavirus2 name="rotavirus2" (change)="onChangeChkState($event)" value="2" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #rotavirus2>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Crotavirus2 name="rotavirus2" (change)="onChangeChkState($event)" value="2"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox"  *ngIf="rotavirus_data.includes('4'); else rotavirus4">
                        <mat-checkbox class="example-margin" #Crotavirus4 name="rotavirus4" (change)="onChangeChkState($event)" value="4" [checked]="true"></mat-checkbox>
                      </td>
                      <ng-template #rotavirus4>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Crotavirus4 name="rotavirus4" (change)="onChangeChkState($event)" value="4"></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Measles</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="measles_data.includes('12'); else measles12">
                        <mat-checkbox class="example-margin" #Cmeasles12 name="measles12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #measles12>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cmeasles12 name="measles12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="measles_data.includes('18'); else measles18">
                        <mat-checkbox class="example-margin" #Cmeasles18 name="measles18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #measles18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cmeasles18 name="measles18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Mumps</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="mumps_data.includes('12'); else mumps12">
                        <mat-checkbox class="example-margin" #Cmumps12 name="mumps12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #mumps12>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cmumps12 name="mumps12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="mumps_data.includes('18'); else mumps18">
                        <mat-checkbox class="example-margin" #Cmumps18 name="mumps18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #mumps18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cmumps18 name="mumps18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Rubella</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="rubella_data.includes('12'); else rubella12">
                        <mat-checkbox class="example-margin" #Crubella12 name="rubella12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #rubella12>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Crubella12 name="rubella12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="rubella_data.includes('18'); else rubella18">
                        <mat-checkbox class="example-margin" #Crubella18 name="rubella18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #rubella18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Crubella18 name="rubella18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Meningococcal C</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="meningococcal_c_data.includes('12'); else meningococcal_c12">
                        <mat-checkbox class="example-margin" #Cmeningococcal_c12 name="meningococcal_c12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #meningococcal_c12>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cmeningococcal_c12 name="meningococcal_c12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Varicella</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="varicella_data.includes('18'); else varicella18">
                        <mat-checkbox class="example-margin" #Cvaricella18 name="varicella18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                      </td> 
                      <ng-template #varicella18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Cvaricella18 name="varicella18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>
                    <tr>
                      <td class="sickness">Hepatitis A</td>
                      <td></td>
                      <td></td>
                      
                      <td></td>
                      
                      <td></td>
                      
                      <td class="checkbox" *ngIf="hepatitis_a_data.includes('12'); else hepatitis_a12">
                        <mat-checkbox #Chepatitis_a12 class="example-margin" #Chepatitis_a12 name="hepatitis_a12" (change)="onChangeChkState($event)" value="12" [checked]="true"></mat-checkbox>
                        <p style="color:blue; font-size:xx-small">Indigenous*</p>
                      </td> 
                      <ng-template #hepatitis_a12>
                        <td class="checkbox">
                          <mat-checkbox #Chepatitis_a12 class="example-margin" #Chepatitis_a12 name="hepatitis_a12" (change)="onChangeChkState($event)" value="12" ></mat-checkbox>
                          <p style="color:blue; font-size:xx-small">Indigenous*</p>
                        </td>
                      </ng-template>
                      
                      <td class="checkbox" *ngIf="hepatitis_a_data.includes('18'); else hepatitis_a18">
                        <mat-checkbox class="example-margin" #Chepatitis_a18 name="hepatitis_a18" (change)="onChangeChkState($event)" value="18" [checked]="true"></mat-checkbox>
                        <p style="color:blue; font-size:xx-small">Indigenous*</p>
                      </td> 
                      <ng-template #hepatitis_a18>
                        <td class="checkbox">
                          <mat-checkbox class="example-margin" #Chepatitis_a18 name="hepatitis_a18" (change)="onChangeChkState($event)" value="18" ></mat-checkbox>
                          <p style="color:blue; font-size:xx-small">Indigenous*</p>
                        </td>
                      </ng-template>
                      
                      <td></td>
                    </tr>

                  </table>

                </div>
                <div class="row m-t-20">
                  <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mb-2">
                    <sub style="margin-top: 20px;">(Please note additional vaccines for Aboriginal and Torres Strait Islander children and medically at risk children)<br /></sub>
                    <sub>*The term Indigenous in the Immunisations table is inclusive of Aboriginal and Torres Strait Islander people (QLD, NT, WA and SA) <br /><br /></sub>
                    <sub>The National Immunisation Program (NIP) Schedule (0-4 Years)<br /></sub>
                    <sub>Valid from 1 April 2019</sub>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <span class="example-list-section">
                      <ul>
                        <li>
                          <mat-checkbox class="example-margin" formControlName="select_all_chx_box" (change)="setAll($event)">Select All</mat-checkbox>
                        </li>
                        <!-- <li>
                          <button mat-button color="primary">Select All</button>
                        </li> -->
                        <li>
                          <mat-checkbox class="example-margin" formControlName="not_immunized" (change)="notImmunizeRemoveAll($event)">Not immunised</mat-checkbox> 
                          <!-- <mat-checkbox class="example-margin" formControlName="not_immunized"
                            #not_immunized>Not immunised</mat-checkbox>
                          <mat-error align="end" class="f-12" *ngIf="showNotImminizedError">This field is
                            required.
                          </mat-error> -->
                          <!-- <mat-error align="end" class="f-12" *ngIf="!not_immunized.checked">This field is
                            required.
                          </mat-error> -->
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Not immunised</mat-label>
                            <input formControlName="not_immunized" matInput >
                          </mat-form-field> -->

                        </li>
                      </ul>
                    </span>
                  </div>
                </div>

                <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

                <!-- <p class="f-12">Please provide a statement of your child's immunisation history from Australian

                  Childhood Immunisation Register</p> -->
                <!-- <div class="row m-t-20">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">

                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Supervisor Name</mat-label>
                      <input formControlName="immunisation_supervisor_name" matInput required>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">

                    <div *ngIf="!isNewSignature2 && signatureImage != ''">
                      <span *ngIf="!accessMode" (click)="clearPad('immunisation_signed')"
                        class="badge badge-danger remove-signature pointer b-r-15">x</span>
                      <img src="{{ signatureImg2 }}">
                    </div>
                    <ng-container *ngIf="isNewSignature2 || signatureImg2 == ''">
                      <app-signature-pads (signaturePadEmit)="signatureImgOutput2($event)"></app-signature-pads>
                    </ng-container>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Date</mat-label>
                      <input matInput (click)="immunisation_date.open()" formControlName="immunisation_date"
                        [matDatepicker]="immunisation_date" required>
                      <mat-datepicker-toggle matSuffix [for]="immunisation_date"></mat-datepicker-toggle>
                      <mat-datepicker #immunisation_date></mat-datepicker>
                    </mat-form-field>

                  </div>

                </div> -->

                <h5 style="margin-top: 20px;" class="form-group-heading">Medical Information</h5>
                <div class="row p-t-12">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Child's Doctor</mat-label>
                      <input formControlName="child_doctor_name" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Phone</mat-label>
                      <input formControlName="child_doctor_phone" matInput required>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Address</mat-label>
                      <textarea formControlName="child_doctor_address" matInput required></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Child's Dentist</mat-label>
                      <input formControlName="child_dentist_name" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Phone</mat-label>
                      <input formControlName="child_dentist_phone" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Address</mat-label>
                      <textarea formControlName="child_dentist_address" matInput></textarea>
                    </mat-form-field>
                  </div>


                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Health Fund Name ( if available )</mat-label>
                      <input formControlName="health_fund_name" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Health Fund No ( if available )</mat-label>
                      <input formControlName="health_fund_no" matInput>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Medicare No</mat-label>
                      <input formControlName="medicare_no" matInput required>
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <h6>Does your child have any of the following?</h6>
                    <mat-checkbox class="example-margin" formControlName="is_none"
                      (change)="checkBoxInfection($event.checked)">None</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_add_adhd"
                      (change)="checkBoxInfection($event.checked,0)">A.D.D/A.D.H.D</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_eilepsy"
                      (change)="checkBoxInfection($event.checked,1)">Epilepsy</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_physical_need"
                      (change)="checkBoxInfection($event.checked,2)">Physical Needs
                    </mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_haemophila"
                      (change)="checkBoxInfection($event.checked,3)">Haemophilia </mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_asthma"
                      (change)="checkBoxInfection($event.checked,4)">Asthma</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_diabetes"
                      (change)="checkBoxInfection($event.checked,5)">Diabetes</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_heart_problem"
                      (change)="checkBoxInfection($event.checked,6)">Heart Problems
                    </mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_anaphylaxis"
                      (change)="checkBoxInfection($event.checked,7)">Anaphylaxis</mat-checkbox>

                    <mat-checkbox class="example-margin" formControlName="is_behavioural_needs"
                      (change)="checkBoxInfection($event.checked,8)">Behavioural Needs
                    </mat-checkbox>
                    <mat-checkbox class="example-margin" formControlName="is_other"
                      (change)="checkBoxInfection($event.checked,9)">Other
                    </mat-checkbox>
                    <mat-error *ngIf="this.HFormGroup4.get('does_your_child_have_any_of_the_following').invalid">
                      Select at least 1 checkbox above.
                    </mat-error>
                    <input formControlName="does_your_child_have_any_of_the_following" matInput readonly >
                  </div>

                  <div *ngIf="showOtherHealthProblem" class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-2 m-t-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Provide Details</mat-label>
                      <textarea formControlName="other_problems" [rows]="1" matInput></textarea>
                    </mat-form-field>
                    <ng-container *ngIf="(!accessMode) && (
                      this.HFormGroup4.get('is_add_adhd').value == true ||
                      this.HFormGroup4.get('is_eilepsy').value == true ||
                      this.HFormGroup4.get('is_physical_need').value == true ||
                      this.HFormGroup4.get('is_haemophila').value == true ||
                      this.HFormGroup4.get('is_heart_problem').value == true ||
                      this.HFormGroup4.get('is_behavioural_needs').value == true ||
                      this.HFormGroup4.get('is_other').value == true
                      )">
                  <!-- <ng-container> -->
                    <p class="f-11 m-b-0">Upload Other Documents</p>
                    <app-custom-dropzone (filePass)="uploadOtherDocuments($event)"></app-custom-dropzone>
                    <app-dropzone-priview [items]="other_documents_preview"
                      (removeItemEvent)="resetUploadPreview($event, 'is_others')"></app-dropzone-priview>
                    <app-dropzone-recieved [showDelete]="true" [items]="other_documents"
                      (removeItemEvent)="resetUploadReceived($event, 'is_others')"></app-dropzone-recieved>
                  </ng-container>

                  <ng-container *ngIf="(accessMode) && (
                    this.HFormGroup4.get('is_add_adhd').value == true ||
                    this.HFormGroup4.get('is_eilepsy').value == true ||
                    this.HFormGroup4.get('is_physical_need').value == true ||
                    this.HFormGroup4.get('is_haemophila').value == true ||
                    this.HFormGroup4.get('is_heart_problem').value == true ||
                    this.HFormGroup4.get('is_behavioural_needs').value == true ||
                    this.HFormGroup4.get('is_other').value == true
                    )">
                    <!-- <ng-container > -->
                    <p class="f-11 m-b-0">Upload Other Documents</p>
                    <app-dropzone-priview [items]="other_documents_preview"
                      (removeItemEvent)="resetUploadPreview($event, 'is_others')"></app-dropzone-priview>
                    <app-dropzone-recieved [showDelete]="editMode" [items]="other_documents"
                      (removeItemEvent)="resetUploadReceived($event, 'is_others')"></app-dropzone-recieved>
                  </ng-container>
                  </div>



                </div>
                <div class="row m-t-20">
                  <div class="col-12">
                    <h6 class="m-b-15">Allergies</h6>
                  </div>
                  <div class=" col-md-12 col-sm-12 mb-2"
                    [ngClass]="!hideMedicalAllergiesDocument ? 'col-xl-6 col-lg-6' : 'col-xl-12 col-lg-12'">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Allergies? (e.g. food, medications, animals)</mat-label>
                      <mat-select formControlName="is_allegies" (selectionChange)="childHaveAllergies($event.value)">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="!accessMode && !hideMedicalAllergiesDocument">
                      <p class="f-11 m-b-0">Upload Documents</p>
                      <app-custom-dropzone (filePass)="uploadAllergiesDocuments($event)"></app-custom-dropzone>
                      <app-dropzone-priview [items]="allergies_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_allergies')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="true" [items]="allergies_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_allergies')"></app-dropzone-recieved>
                    </ng-container>

                    <ng-container *ngIf="accessMode && !hideMedicalAllergiesDocument">
                      <p class="f-11 m-b-0">Allergies Documents</p>
                      <app-dropzone-priview [items]="allergies_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_allergies')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="editMode" [items]="allergies_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_allergies')"></app-dropzone-recieved>
                    </ng-container>

                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" *ngIf="!hideMedicalAllergiesDocument">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Provide Details</mat-label>
                      <textarea formControlName="allergies_details" matInput></textarea>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row m-t-20">
                  <div class="col-12">
                    <h6 class="m-b-15">Medication</h6>
                  </div>
                  <div class="col-md-12 col-sm-12 mb-2"
                    [ngClass]="!hideRegularMedicineDocument ? 'col-xl-6 col-lg-6' : 'col-xl-12 col-lg-12'">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Does your child take regular medication?</mat-label>
                      <mat-select formControlName="is_regular_medication"
                        (selectionChange)="childRegularMedicine($event.value)">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="!accessMode && !hideRegularMedicineDocument">
                      <p class="f-11 m-b-0">Upload Documents</p>
                      <app-custom-dropzone (filePass)="uploadRegularMedicineDocuments($event)"></app-custom-dropzone>
                      <app-dropzone-priview [items]="regular_medicine_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_regular_medication')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="true" [items]="regular_medicine_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_regular_medication')">
                      </app-dropzone-recieved>
                    </ng-container>

                    <ng-container *ngIf="accessMode && !hideRegularMedicineDocument">
                      <p class="f-11 m-b-0">Regular Medicine</p>
                      <app-dropzone-priview [items]="regular_medicine_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_regular_medication')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="editMode" [items]="regular_medicine_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_regular_medication')">
                      </app-dropzone-recieved>
                    </ng-container>

                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" *ngIf="!hideRegularMedicineDocument">
                    <mat-form-field class="example-full-width field-with-eg" appearance="outline">
                      <mat-label>Provide Details</mat-label>
                      <textarea formControlName="regular_medication_details" matInput></textarea>
                    </mat-form-field>
                    <em class="eg-text ">* If your child suffer from Asthma/Epilepsy/Diabetes/Anaphylaxis/diagnosed at
                      risk of anaphylaxis or any other specific medical condition please
                      discuss this with the Nominated Supervisor / Family Day Care Educator and fill in additional forms
                      such as medical condition or action plan.</em>
                  </div>


                  <!--                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-b-12" *ngIf="!hideRegularMedicineDocument && !isEditForm" style="margin-bottom: 45px!important;">-->
                  <!--                    <em>Upload documents pertaining to regular medications your child takes</em>-->
                  <!--                    <app-custom-dropzone   (filePass)="uploadRegularMedicineDocuments($event)"></app-custom-dropzone>-->

                  <!--                  </div>-->

                </div>
                <div class="row m-t-20">
                  <div class="col-12">
                    <h6 class="m-b-15">Special Dietary Requirements</h6>
                  </div>
                  <div class="col-md-12 col-sm-12 mb-2"
                    [ngClass]="!hideSpecialDietaryDocument ? 'col-xl-6 col-lg-6' : 'col-xl-12 col-lg-12'">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Does your child have any special dietary requirements?</mat-label>
                      <mat-select formControlName="is_special_dietary"
                        (selectionChange)="childSpecialDietary($event.value)">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="!accessMode && !hideSpecialDietaryDocument">
                      <p class="f-11 m-b-0">Upload Documents</p>
                      <app-custom-dropzone (filePass)="uploadSpecialDietaryDocuments($event)"></app-custom-dropzone>
                      <app-dropzone-priview [items]="special_dietary_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_special_dietary')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="true" [items]="special_dietary_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_special_dietary')"></app-dropzone-recieved>
                    </ng-container>

                    <ng-container *ngIf="accessMode && !hideSpecialDietaryDocument">
                      <p class="f-11 m-b-0">Special Dietary Documents</p>
                      <app-dropzone-priview [items]="special_dietary_documents_preview"
                        (removeItemEvent)="resetUploadPreview($event, 'is_special_dietary')"></app-dropzone-priview>
                      <app-dropzone-recieved [showDelete]="editMode" [items]="special_dietary_documents"
                        (removeItemEvent)="resetUploadReceived($event, 'is_special_dietary')"></app-dropzone-recieved>
                    </ng-container>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" *ngIf="!hideSpecialDietaryDocument">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Provide Details</mat-label>
                      <textarea formControlName="special_dietary_details" matInput></textarea>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <h6>Toileting Requirements</h6>

                    <mat-checkbox class="example-margin" name="is_nappies" formControlName="is_nappies" (change)="checkBoxToileting($event.checked,1,$event)">Nappies</mat-checkbox>

                    <mat-checkbox class="example-margin" name="is_pull_ups" formControlName="is_pull_ups" (change)="checkBoxToileting($event.checked,2,$event)">Pull Ups</mat-checkbox>

                    <mat-checkbox class="example-margin" name="is_toilet_traning" formControlName="is_toilet_traning" (change)="checkBoxToileting($event.checked,3,$event)">Toilet Training
                    </mat-checkbox>

                    <mat-checkbox class="example-margin" name="is_fully_toilet" formControlName="is_fully_toilet" (change)="checkBoxToileting($event.checked,4,$event)">Fully Toilet Trained
                    </mat-checkbox>

                    <mat-error *ngIf="this.HFormGroup4.get('toileting_requirements').invalid">
                      Select at least 1 checkbox above.
                    </mat-error>
                    <input formControlName="toileting_requirements" matInput readonly >
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Provide further details if needed</mat-label>
                      <textarea formControlName="other_details" matInput></textarea>
                    </mat-form-field>

                  </div>

                </div>
                <div class="row m-t-20">
                  <div class="col-12">
                    <h6 class="m-b-15">Sleep and Rest Requirements</h6>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Your child have a sleep or rest during the day?</mat-label>
                      <mat-select formControlName="is_rest_during_day"
                        (selectionChange)="childRestDuringDay($event.value)">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="!hideChildDayRouting">
                      <mat-label>Provide Details</mat-label>
                      <textarea formControlName="daily_route" matInput [required]="!hideChildDayRouting"> </textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                    << Back</button>
                      <button mat-raised-button matStepperNext class="text-white color-bg-purple">Next >></button>
                      <button mat-raised-button *ngIf="editMode" [class.spinner]="submittedMedical"
                        class="m-lg-2 text-white enable-color" 
                        [ngClass]="!HFormGroup4.valid ? 'disable-color' : 'enable-color'">
                        Save</button>

                </div>
              </form>

            </mat-step>

            <mat-step [stepControl]="HFormGroup6">
              <form [formGroup]="HFormGroup6" (submit)="onSubmit('document_uploads')">
                <ng-template matStepLabel>Upload Required Documents</ng-template>

                <div class="row m-t-15">

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                    <p class="font-weight-bold">Please provide the following required documents:</p>
                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>
                    <div >
                      <mat-card class="example-card">
                        <mat-card-title-group>
                          <mat-card-subtitle>Child's Identification - Birth Certificate and/or Immigration Card.</mat-card-subtitle>
                        </mat-card-title-group>
                        <mat-card-content>
                          <app-custom-dropzone *ngIf="editMode" (filePass)="getFiles($event, 'child_id')"></app-custom-dropzone>
                          <table class="table table-striped f-11" *ngIf="docList_child_id.length != 0 || docListEdit.length != 0">
                            <thead>
                              <tr>
                                <th width="55">File Name</th>
                                <th width="10%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let doc of docList_child_id;let key = index;">
                                <td style="cursor: not-allowed" matTooltip="{{doc.name}}">
                                  <img src="{{doc | getIconFileTypes}}" width="45px" alt="file">
                                  {{(doc.name)}}
                                </td>
                              
                                <td>
                                  <button mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocuments(key, 'child_id')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
      
                              <tr *ngFor="let doc of docListEdit_child_id;let key = index;">
                                <td>
                                  <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                    <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                    {{(doc.real_name)}}
                                  </a>
                                </td>
                            
                                <td>
                                  <button [disabled]="!editMode" mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocumentsEdit(key, '4')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>


                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>






                    <div >
                      <mat-card class="example-card">
                        <mat-card-title-group>
                          <mat-card-subtitle>Medicare Card and/or Private Health Care Card. (Ensure child's name is displayed on the card)</mat-card-subtitle>
                        </mat-card-title-group>
                        <mat-card-content>
                          <app-custom-dropzone *ngIf="editMode" (filePass)="getFiles($event, 'medicare')"></app-custom-dropzone>
                          <table class="table table-striped f-11" *ngIf="docList.length != 0 || docListEdit.length != 0">
                            <thead>
                              <tr>
                                <th width="55">File Name</th>
                                <th width="10%" >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let doc of docList_medicare;let key = index;">
                                <td style="cursor: not-allowed" matTooltip="{{doc.name}}">
                                  <img src="{{doc | getIconFileTypes}}" width="45px" alt="file">
                                  {{(doc.name)}}
                                </td>
                                <td>
                                  <button mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocuments(key, 'medicare')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
      
                              <tr *ngFor="let doc of docListEdit_medicare;let key = index;">
                                <td>
                                  <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                    <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                    {{(doc.real_name)}}
                                  </a>
                                </td>
      
                                <td>
                                  <button [disabled]="!editMode"  mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocumentsEdit(key, '5')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>



                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

                    <div >
                      <mat-card class="example-card">
                        <mat-card-title-group>
                          <mat-card-subtitle>Parent Identification (Birth Certificate, Current Passport, Drivers Licence).</mat-card-subtitle>
                        </mat-card-title-group>
                        <mat-card-content>
                          <app-custom-dropzone *ngIf="editMode" (filePass)="getFiles($event, 'parent_id')"></app-custom-dropzone>
                          <table class="table table-striped f-11" *ngIf="docList.length != 0 || docListEdit.length != 0">
                            <thead>
                              <tr>
                                <th width="55">File Name</th>
                                <th width="10%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let doc of docList_parent_id;let key = index;">
                                <td style="cursor: not-allowed" matTooltip="{{doc.name}}">
                                  <img src="{{doc | getIconFileTypes}}" width="45px" alt="file">
                                  {{(doc.name)}}
                                </td>
                                <td>
                                  <button mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocuments(key, 'parent_id')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
      
                              <tr *ngFor="let doc of docListEdit_parent_id;let key = index;">
                                <td>
                                  <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                    <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                    {{(doc.real_name)}}
                                  </a>
                                </td>
                                <td>
                                  <button [disabled]="!editMode" mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocumentsEdit(key, '6')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

                    <div *ngIf="(editMode && !accessMode) || ViewFromChildrenModule">
                      <mat-card class="example-card">
                        <mat-card-title-group>
                          <mat-card-subtitle>Please provide a statement of your child's immunisation history from Australian Childhood Immunisation Register.</mat-card-subtitle>
                        </mat-card-title-group>
                        <mat-card-content>
                          <app-custom-dropzone *ngIf="editMode" (filePass)="getFiles($event, 'immunisation_history')"></app-custom-dropzone>
                          <table class="table table-striped f-11" *ngIf="docList.length != 0 || docListEdit.length != 0">
                            <thead>
                              <tr>
                                <th width="55">File Name</th>
                                <th width="10%" *ngIf="editMode">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let doc of docList_immunisation_history;let key = index;">
                                <td style="cursor: not-allowed" matTooltip="{{doc.name}}">
                                  <img src="{{doc | getIconFileTypes}}" width="45px" alt="file">
                                  {{(doc.name)}}
                                </td>
                                <td>
                                  <button mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocuments(key, 'immunisation_history')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
      
                              <tr *ngFor="let doc of docListEdit_immunisation_history;let key = index;">
                                <td>
                                  <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                    <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                    {{(doc.real_name)}}
                                  </a>
                                </td>
                                <td>
                                  <button *ngIf="editMode" mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocumentsEdit(key, '30')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>
                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>
                    <div class="row m-t-20">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <mat-checkbox class="example-margin" [(ngModel)]="addtionalUploadDocuments"  [ngModelOptions]="{standalone: true}">Other</mat-checkbox>
                      </div>
                    </div>
                    <div *ngIf="addtionalUploadDocuments">
                      <mat-card class="example-card">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Provide Details.</mat-label>
                              <textarea formControlName="other_to_provide_details" matInput></textarea>
                            </mat-form-field>
                        <mat-card-content>
                          <app-custom-dropzone *ngIf="editMode" (filePass)="getFiles($event, 'other_id')"></app-custom-dropzone>
                          <table class="table table-striped f-11" *ngIf="docList_other_id.length != 0 || docListEdit_other_id.length != 0">
                            <thead>
                              <tr>
                                <th width="55">File Name</th>
                                <th width="10%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let doc of docList_other_id;let key = index;">
                                <td style="cursor: not-allowed" matTooltip="{{doc.name}}">
                                  <img src="{{doc | getIconFileTypes}}" width="45px" alt="file">
                                  {{(doc.name)}}
                                </td>
                                <td>
                                  <button mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocuments(key, 'other_id')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
      
                              <tr *ngFor="let doc of docListEdit_other_id;let key = index;">
                                <td>
                                  <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                    <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                    {{(doc.real_name)}}
                                  </a>
                                </td>
                                <td>
                                  <button [disabled]="!editMode" mat-icon-button color="accent" class="btn-tbl-delete"
                                    (click)="deleteDocumentsEdit(key, '29')">
                                    <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </div>


                    <mat-divider style="margin-top: 10px;margin-bottom: 10px;"></mat-divider>

                    <!-- Added only to have validation per upload box -->
                    <ng-container *ngIf="false"> 
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Child ID</mat-label>
                          <input formControlName="input_child_id" matInput>
                        </mat-form-field>
  
                      </div>
                      <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Medicare</mat-label>
                          <input formControlName="input_medicare" matInput>
                        </mat-form-field>
  
                      </div>
                      <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Parent ID</mat-label>
                          <input formControlName="input_parent_id" matInput>
                        </mat-form-field>
  
                      </div>
                      <div  class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Immunisation History</mat-label>
                          <input formControlName="input_immunisation_history" matInput>
                        </mat-form-field>
                      </div>
                    </ng-container>


                    <!-- <p class="font-weight-bold">Please provide supporting document such as Immunisation Statement,
                      Medical, Asthma or Anaphylaxis Plan, Medication Form,
                      Inclusion Support Plan etc</p>
                    <div *ngIf="(editMode && !accessMode) || ViewFromChildrenModule">
                      <app-custom-dropzone (filePass)="getFiles($event)"></app-custom-dropzone>
                    </div>
                    <br /> -->
                    <div *ngIf="false" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                      <table class="table table-striped f-11" *ngIf="docList.length != 0 || docListEdit.length != 0">
                        <thead>
                          <tr>
                            <th width="55">File Name</th>
                            <th width="35%">Document Type</th>
                            <th width="10%" *ngIf="editMode">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let doc of docList;let key = index;">
                            <td style="cursor: not-allowed" matTooltip="{{doc[0].name}}">
                              <img src="{{doc[0] | getIconFileTypes}}" width="45px" alt="file">
                              {{(doc[0].name.length>10)? (doc[0].name | slice:0:10)+'..':(doc[0].name)}}
                            </td>
                            <td>
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Document Types</mat-label>
                                <mat-select #documentType 
                                  value="{{(doc[1] == 'child_id') ? 4 : (doc[1] == 'medicare')? 5 : (doc[1] == 'parent_id')? 6 : 29}}"
                                  (selectionChange)="DocumentType(documentType.value,key)">
                                  <mat-option value="4">Child Birth Certificate</mat-option>
                                  <mat-option value="5">Medical Card</mat-option>
                                  <mat-option value="6">Driving License</mat-option>
                                  <mat-option value="29">Other</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </td>
                            <td>
                              <button mat-icon-button color="accent" class="btn-tbl-delete pull-right"
                                (click)='deleteDocuments(key)'>
                                <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                              </button>
                            </td>
                          </tr>
                          <tr *ngFor="let doc of docListEdit;let key = index;">
                            <td>
                              <a href="{{doc.url}}" target="_blank" matTooltip="{{doc.real_name}}">
                                <img src="{{doc|getIconFileTypes}}" width="45px" alt="file">
                                {{(doc.real_name.length>10)? (doc.real_name | slice:0:10)+'..':(doc.real_name)}}
                              </a>
                            </td>
                            <td>
                              <mat-form-field class="example-full-width m-t-20" appearance="outline">
                                <mat-label>Document Types</mat-label>
                                <mat-select #documentType value="{{doc.form_document_type_id}}"
                                  (selectionChange)="DocumentTypeEdit(documentType.value,key)">
                                  <mat-option value="4">Child's Birth Certificate</mat-option>
                                  <mat-option value="5">Medicare Card</mat-option>
                                  <mat-option value="6">Driver's License</mat-option>
                                  <mat-option value="29">other</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </td>
                            <td>
                              <button *ngIf="editMode" mat-icon-button color="accent" class="btn-tbl-delete pull-right"
                                (click)='deleteDocumentsEdit(key)'>
                                <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
            

                  </div>

                </div>
                <div class="row">

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                    <button mat-raised-button matStepperPrevious color="warn" class="mr-2">
                      << Back</button>
                        <button *ngIf="editMode" mat-raised-button matStepperNext [disabled]="!HFormGroup6.valid"
                          class="text-white mr-3 color-bg-purple"
                          [ngClass]="!HFormGroup6.valid ? 'disable-color' : 'enable-color-enroll'"
                          >Next >>
                        </button>
                        <button *ngIf="!editMode" mat-raised-button matStepperNext [disabled]="!HFormGroup6.valid"
                          class="text-white mr-3 color-bg-purple"
                          [ngClass]="!HFormGroup6.valid ? 'disable-color' : 'enable-color-enroll'"
                          >Next >>
                        </button>
                        <button mat-raised-button *ngIf="editMode" [class.spinner]="submitted4"
                          [class.not-allowed]="submitted4" [disabled]="!HFormGroup6.valid" class="m-lg-2 text-white"
                          matTooltip="Submit Form From this Step" matTooltipPosition="above"
                          [ngClass]="!HFormGroup6.valid ? 'disable-color' : 'enable-color'"> Save</button>

                  </div>
                </div>
              </form>

            </mat-step>

            <ng-container *ngIf="ParentAgreementSection">
              <mat-step [stepControl]="HFormGroup5">
                <form [formGroup]="HFormGroup5" (submit)="onSubmit('parent_agreement')">
                  <ng-template matStepLabel>Parent Agreement and Consent</ng-template>
                  <p class="f-12" *ngIf="!showPriorityAcessText" (click)="clickPriorityAcessText()" style="color: blue; cursor: pointer;"><i>Learn more about Priority Access...</i></p>
                  <p class="f-12" *ngIf="showPriorityAcessText">{{loggedUser.user.center_name}} follows the Priority of Access please refer to Orientation and Enrolment
                    Policy. The Commonwealth Government funds child care with a major purpose of meeting the child
                    care needs of families. Where the demand for child care exceeds the available Child Care Subsidy
                    (CCS) places, the Family Assistance legislation requires that child care services allocate places to
                    families with the greatest need for child care. Under the Priority of Access Guidelines, a child care
                    service may require a Priority 3 child only to vacate a place to make room for a higher priority child.
                    The service can only do so if the person liable for the payment of the child care fees was notified
                    when the child first entered care that the service followed this policy, and the service gives the
                    person at least 14 days notice of the requirement for the child to vacate the place.</p>
                  <p class="f-12" *ngIf="showPriorityAcessText" (click)="clickPriorityAcessText()" style="color: gray; cursor: pointer;"><i>Learn less about Priority Access...</i></p>
                  <!-- <div class="row m-t-20">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="is_agree_parent_agreement" required
                        #is_agree_parent_agreement>I
                        acknowledge and understand the Parent Agreement.*</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!is_agree_parent_agreement.checked">This field is
                        required.
                      </mat-error>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h5 class="form-group-heading">Health and Medical Consent</h5>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox1">I give consent to the staff, educator, educator assistant and/or relief educator of {{loggedUser.user.center_name}} 
                        to administer medically prescribed medication to my child, record each
                        administration of medication by completing the Medical Information & Authorisation Form.</mat-checkbox>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox2">I acknowledge that my child cannot attend the education and care service if suffering from
                        an infectious or communicable disease that has been identified by the Department of
                        Health.</mat-checkbox>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox3">When applicable, I will notify {{loggedUser.user.center_name}} when my child carries medication and
                        if possible, will self-medicate. I will provide a letter/plan from a doctor to support this and
                        will fill in the Medical Information & Authorisation Form.</mat-checkbox>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox4">I hereby give my permission for the {{loggedUser.user.center_name}} staff, educator, educator
                        assistant and/or relief educator to apply first aid, treat my child if a minor accident occurs,
                        illness, dental, medical, and emergency care as well as to seek medical treatment for the
                        child from a registered medical practitioner, hospital or ambulance service and permit
                        transportation of the child by an ambulance service, request/permit medication to the child
                        and this person should be notified of any accident, injury, trauma or illness involving the
                        children if the parent/guardians cannot be contacted. Everything possible will be done to
                        contact emergency persons before such treatment is sought. In the case of a more urgent
                        scenario. I understand an ambulance will be contacted first then I will be notified and agree
                        to meet any expenses incurred. If my child is diagnosed with asthma or anaphylaxis and an
                        emergency occurs the Nominated Supervisor or educators may administer first aid without
                        making contact. Educators will notify the child's parents and/or emergency services as soon
                        as possible.</mat-checkbox>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox5">The emergency contact/s and authorised person is authorised to consent medical treatment
                        of the child, for the staff, educator, educator assistant and/or relief educator to seek medical
                        treatment for the child from a registered medical practitioner, hospital or ambulance service
                        and permit transportation of the child by an ambulance service, request/permit medication
                        to the child and this person should be notified of any accident, injury, trauma or illness
                        involving the children if the parent/guardians cannot be contacted.</mat-checkbox>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="hamc_checkbox6"><h6>I understand and agree to the Health and Medical Consent.</h6></mat-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h5 class="form-group-heading">Sunscreen Consent Form</h5>
                      <p class="f-12">
                        As per our Sun Protection Policy we suggest all children to be protected against the sun with
                        SPF 30+ sunscreen when exposed to sunlight. If
                        your child is allergic, sensitive or you would like to use another brand not used at the
                        Service, please be advised that we ask that you provide
                        this brand. We ask that each family apply SPF 30+ sunscreen to their child prior to their
                        arrival at the service in the morning. Our Service Sun Protection Policy is available for you to
                        review and 
                        are available fo families to view. Please ask one of our educator or staff to supply you with
                        one.
                        We ask that all children have sunscreen applied prior to leaving home, as it needs to be applied
                        20 minutes before your child is exposed to the
                        sun. If sunscreen is not applied, it will be applied at the centre / service upon arrival and
                        your child will need to engage in experiences in a
                        covered/shaded for 20 minutes when outside.
                        Children are required to wear sun-safe clothing that covers as much skin as possible. For
                        example,
                        loose-fitting shirts with sleeves and covered necklines, and longer-style skirts,
                        shorts and trousers. Midriff, crop or singlet tops are not recommended.
                      </p>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="is_allergies_for_sunscreen"> I do not know
                        of any allergies my child has to sunscreen.</mat-checkbox>

                      <mat-checkbox class="example-margin" formControlName="is_allergies_for_some_sunscreen"
                        (change)="toggle1($event)">My child is allergic to some sunscreen</mat-checkbox>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2 m-t-10">
                      <mat-form-field class="example-full-width" appearance="outline"
                        matTooltip="Ensure to label your child name on the sunscreen" matTooltipPosition="above">
                        <mat-label> Please use only the following brand/type of sunscreen</mat-label>
                        <textarea formControlName="sunscreen_brands" matInput></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2 m-t-10">
                      <mat-form-field class="example-full-width" appearance="outline"
                        matTooltip="For medical or other reasons, please do not apply sunscreen to the following areas of my child's body"
                        matTooltipPosition="above">
                        <mat-label> For medical or other reasons, please do not apply sunscreen to the following areas
                          of my child's body</mat-label>

                        <textarea formControlName="prohibited_area_body" matInput></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7" *ngIf="is_suncream_alergies">

                      <ng-container *ngIf="!accessMode">
                        <p class="f-11 m-b-0">Upload Medical documents for SunCream</p>
                        <app-custom-dropzone (filePass)="uploadSuncreamDocuments($event)"></app-custom-dropzone>
                        <app-dropzone-priview [items]="suncream_documents_preview"
                          (removeItemEvent)="resetUploadPreview($event, 'suncream')"></app-dropzone-priview>
                        <app-dropzone-recieved [items]="suncream_documents"
                          (removeItemEvent)="resetUploadReceived($event, 'suncream')"></app-dropzone-recieved>
                      </ng-container>

                      <ng-container *ngIf="accessMode && suncream_documents.length > 0">
                        <p class="f-11 m-b-0">Medical documents for SunCream</p>
                        <app-dropzone-recieved [showDelete]="editMode" [items]="suncream_documents"
                          (removeItemEvent)="resetUploadReceived($event, 'suncream')"></app-dropzone-recieved>
                      </ng-container>

                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <mat-checkbox class="example-margin" formControlName="is_agree_sunscreen_agreement" required
                        #is_agree_sunscreen_agreement>
                        According to the Service Sunscreen Policy,
                        I give permission to the educator,
                        educator assistant or staff to apply sunscreen to my child.*</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!is_agree_sunscreen_agreement.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                  </div>



                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h5 class="form-group-heading">Photograph and Video Consent</h5>
                      <p class="f-12">
                        I acknowledge that my child may be photographed, or images recorded whilst my child(ren) attends
                        the education and care service; during
                        normal day care hours, regular outing and excursions. I understand that these photographs and
                        videos are to be used for the purpose of planning,
                        programming and documenting children's learning and development and may be used in promoting

                        {{loggedUser.user.center_name}}
                        used in promoting our education and care service through either in print or on internet.
                      </p>
                      <p>I give consent to the following:</p>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="is_allow_record_learning_of_child">For my
                        child(ren) to be
                        photographed by the educator, educator assistant,

                        relief educator and/or staff at the Centre/Service <br />
                        {{loggedUser.user.center_name}}</mat-checkbox>

                      <mat-checkbox class="example-margin" formControlName="is_allow_record_share_on_socialmedia_child">
                        For my child(ren) to be photographed, or their images recorded for marketing and promoting our
                        education and care
                        services via internet and social media <br />e.g. {{loggedUser.user.center_name}} Facebook
                        Page.</mat-checkbox>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <mat-checkbox class="example-margin" formControlName="is_agree_video_photo_agreement" required
                        #is_agree_video_photo_agreement>And
                        I acknowledge the Service Photograph and Video Policy *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!is_agree_video_photo_agreement.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h5 class="form-group-heading">Transport, Excursion and Regular Outing Authorisation Form</h5>
                    </div>

                    <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="is_agree_transport_agreement" required
                        #is_agree_transport_agreement>I
                        acknowledge and understand the Transport,
                        Excursion and Regular Outing Authorisation Form *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!is_agree_transport_agreement.checked">This field is
                        required.
                      </mat-error>
                    </div> -->
                    <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox1" required
                        #tearoaf_checkbox1>I agree and understand the Excursion and Non-Excursion/Regular Outing and Transport Policy. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox1.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div> -->
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox2" required
                        #tearoaf_checkbox2>I give consent to the educator, educator assistant and/or relief educator of {{loggedUser.user.center_name}} to
                        take my child outside the education and care service premises such as regular outing, excursion,
                        and/or emergency evacuation and when applicable to attend play group, visit to the principal office
                        and/or other location as detailed on the authorisation for excursion and non/excursion and a risk
                        management plan will be undertaken. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox2.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox3" required
                        #tearoaf_checkbox3>I give consent to the educator, educator assistant and/or relief educator of {{loggedUser.user.center_name}} to
                        transport my child or arrange transportation of my child to the excursions/regular outings, and when
                        applicable to transport my child to and from the service, to his/her school and vice versa if and when
                        required to a child's home, or other location as detailed on the authorisation for transport and a risk
                        management plan will be undertaken. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox3.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox4" required
                        #tearoaf_checkbox4>I give consent to the emergency contact/s and authorised person, to collect/deliver my child to/from
                        the service. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox4.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox5" required
                        #tearoaf_checkbox5>I give consent to the emergency contact/s and authorised person to authorise the staff, educator,
                        educator assistant and/or relief educator to take my child outside the education and care service
                        premises such as regular outing, excursion, emergency evacuation, attend play group and/or visit to
                        the head office when applicable. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox5.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox6" required
                        #tearoaf_checkbox6>I give consent to the emergency contact/s and authorised person to authorise the education and care
                        service to transport my child or arrange transportation of my child. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox6.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox7" required
                        #tearoaf_checkbox7>I give consent to the emergency contact/s and authorised person to authorise the educator and/or
                        educator assistant to transport my child or arrange transportation of my child to the excursions/regular
                        outings, and when applicable to transport my child to and from the service, to his/her school and vice
                        versa if and when required to a child's home, or other location as detailed on the authorisation for
                        transport and a risk management plan will be undertaken. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox7.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox8" required
                        #tearoaf_checkbox8>I was given details and opportunity to ask questions in relation to the Excursion and Non-
                        Excursion/Regular Outing and Transport Authorisation and Risk Management Plan. *</mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox8.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-t-12">
                      <mat-checkbox class="example-margin" formControlName="tearoaf_checkbox9" required
                        #tearoaf_checkbox9><h6>I understand and agree to the Excursion and Non-Excursion/ReguIar Outing and Transport
                          Authorisation and Risk Management Plan. *</h6></mat-checkbox>
                      <mat-error align="end" class="f-12" *ngIf="!tearoaf_checkbox9.checked && SubmitHFormGroup5BtnClicked">This field is
                        required.
                      </mat-error>
                    </div>
                    
                  </div>

                  <p style="font-weight: bold">By having signed below, I acknowledge that I understand and agree to the above information and the details
                    I have provided are true and correct.</p>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 m-b-unset">

                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Parent/Guardian Full Name</mat-label>
                      <input formControlName="parent_guardian_agreement_full_name" matInput required>
                    </mat-form-field>

                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">

                    <div *ngIf="!isNewSignature && signatureImage != ''">
                      <p>Parent/Guardian Signature</p>
                      <span *ngIf="!accessMode" (click)="clearPad('parent_agreement')"
                        class="badge badge-danger remove-signature pointer b-r-15">x</span>
                      <img src="{{ signatureImage }}">
                    </div>
                    <ng-container *ngIf="isNewSignature || signatureImage == ''">
                      <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
            
                    </ng-container>
     
                  </div>
                  <div style="margin-top: 20px;" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" *ngIf="!this.HFormGroup5.controls.parent_agreement_signature_image.valid && SubmitHFormGroup5BtnClicked">
                    <mat-error  class="f-12" >Signature is
                      required.
                    </mat-error>
                  </div>
                  <br />
                  <br />
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Signing Date</mat-label>
                      <input matInput (click)="parent_agreement_date.open()" formControlName="parent_agreement_date"
                        [matDatepicker]="parent_agreement_date" disabled>
                      <mat-datepicker-toggle matSuffix [for]="parent_agreement_date"></mat-datepicker-toggle>
                      <mat-datepicker #parent_agreement_date></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <br />

                  <div>
                    <button mat-raised-button matStepperPrevious type="button" color="warn" class="mr-2">
                      << Back</button>
                        <!--                                        <button mat-raised-button matStepperNext color="primary" [disabled]="!HFormGroup5.valid">Next</button>-->
                        <button class="text-white m-r-14 m-l-14" type="button" (click)="CloseForm.emit(true)"
                          mat-raised-button color="accent">Close</button>
                        <button *ngIf="editMode" class="text-white m-r-14 enable-color" type="submit" mat-raised-button
                          [class.spinner]="submitted5"
                          [ngClass]="!HFormGroup5.valid ? 'disable-color' : 'enable-color'">Submit</button>

                  </div>
                </form>
              </mat-step>
            </ng-container>

          </mat-vertical-stepper>
        </div>
      </div>
    </div>
  </div>


</div>




<ng-template #showDuplicateChild let-modal>
  <div class="modal-header editRowModal">
    <h4 class="modal-title" id="modal-send-mail">
      <div class="modal-header ">
        <div class="modal-about">
          <div class="font-weight-bold p-t-10 font-17">
            Duplicate Record Found </div>
        </div>
      </div>
    </h4>

    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true"><i class="material-icons" (click)="childModalRef.close()">close</i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card" *ngIf="childDuplicate">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6>Child Data Duplicate</h6>
              </div>
              <div class="col-md-6">
                <div class="form-row">
                  <mat-checkbox class="example-margin" [(ngModel)]="checked_child"></mat-checkbox>
                  <mat-label>Create New Record</mat-label>
                </div>
                <a class="text-underline" href="javascript:void(0);" (click)="showViewMoreModal('child')">View More</a>
              </div>
            </div>

          </div>
        </div>

        <div class="card" *ngIf="parent1Duplicate">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6>Parent 1 Data Duplicate</h6>
              </div>
              <div class="col-md-6">
                <div class="form-row">
                  <mat-checkbox class="example-margin" [(ngModel)]="checked_parent1"></mat-checkbox>
                  <mat-label>Create New Record</mat-label>
                </div>
                <a class="text-underline" href="javascript:void(0);" (click)="showViewMoreModal('parent1')">View
                  More</a>

              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="parent2Duplicate">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6>Parent 2 Data Duplicate</h6>
              </div>
              <div class="col-md-6">
                <div class="form-row">
                  <mat-checkbox class="example-margin" [(ngModel)]="checked_parent2"></mat-checkbox>
                  <mat-label>Create New Record</mat-label>
                </div>

                <a class="text-underline" href="javascript:void(0);" (click)="showViewMoreModal('parent2')">View
                  More</a>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

        <button class="pull-right text-white" type="button" color="warn" mat-raised-button
          (click)="childModalRef.close()">Close</button>

        <button class="pull-right mr-3 text-white" [class.spinner]="submittedDuplicate"
          (click)="SaveParentChildData(checked_child,checked_parent1,checked_parent2)" type="button" color="primary"
          mat-raised-button>Add New Child to Existing Family Account</button>


      </div>
    </div>

  </div>
</ng-template>


<ng-template #showViewMore let-modal>
  <div class="modal-header editRowModal">
    <h4 class="modal-title" id="modal-send-mail">
      <div class="modal-header ">
        <div class="modal-about">
          <div class="font-weight-bold p-t-10 font-17">
            {{ViewMoreHeader}}</div>
        </div>
      </div>
    </h4>

    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true"><i class="material-icons" (click)="viewMoreModalRef.close()">close</i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm table-striped font-size-12">
          <thead>
            <tr>
              <td>#</td>
              <td>Requested Data</td>
              <td>Existing Data</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of duplicateData let key = index">
              <td><strong>{{detail.field}}</strong></td>
              <td>{{detail.new_value}}</td>
              <td>{{detail.existing_value}}</td>
            </tr>
          </tbody>
        </table>


      </div>

    </div>
  </div>
</ng-template>
<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  /* tr:nth-child(even) {
    background-color: #dddddd;
  } */
  .checkbox{
    text-align: center;
 /*Centering the text in a td of the table*/
  }
  .sickness{
    text-align: right;
  }
  </style>
