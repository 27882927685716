import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import { FamiliesCommunitiesFormService } from '../../../families-communities-form/families-communities-form.service';

@Component({
  selector: 'app-vacation-care-view',
  templateUrl: './vacation-care-view.component.html',
  styleUrls: ['./vacation-care-view.component.sass']
})
export class VacationCareViewComponent implements OnInit {

  @Input() headerHide: number = 0;
  @Input() rowData: any;
  @Input() htmlData: any;
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  signatureLength = 0;
  currentIndex: any = -1;
  //htmlBody = null;
  isSignedByParent = false;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      cwa_id: [''],
      signed_by_parent_date: [''],
      parent_signature: [''],
      signed_by_user_parent: [''],
      sched_id: [''],
      child_id: [''],
      date:['']
    });
  }

  ngOnInit(): void {
    this.sectionForm.patchValue({
      sched_id: this.rowData['sched_id'],
      cwa_id: this.rowData['cwa_id']
    });
    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }

    if (this.rowData['signed_by_user_parent']) {
      this.isSignedByParent = true;
      this.signatureImg = this.rowData['parent_signature'];
      this.isNewSignature = false;
    } else {
      this.isSignedByParent = false;
      this.isNewSignature = true;
      this.signatureImg = null
    }

  }


  formSubmit() {

    this.sectionForm.patchValue({
      signed_by_parent_date: this.loggedUser['role_id'] == 6 ? this.globeService.formatDatetoServerSide(this.sectionForm.value.date) : null,
      parent_signature: this.signatureImg,
      signed_by_user_parent: this.loggedUser['role_id'] == 6 ? this.loggedUser['user'].user_id : null
    });

    this.sigError = false;
    if (this.signatureImg == null) {
      this.sigError = true;
      return false;
    }

    this.shardService.updateCWAParentSignature(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.modalClose();
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

  removeSignature() { this.isNewSignature = true; }

  signatureImgOutput($event) { this.signatureImg = $event; }

}
