import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropzone-priview',
  templateUrl: './dropzone-priview.component.html',
  styleUrls: ['./dropzone-priview.component.css']
})
export class DropzonePriviewComponent implements OnInit {
  @Input() items: any = [];
  @Input() previewType = 1;
  @Output() removeItemEvent = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  removeItem(key: number) {
    this.removeItemEvent.emit(key);
  }
}
