import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
//const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//const EXCEL_EXTENSION = '.csv';


//application/vnd.ms-excel
@Injectable()

export class ExcelService {

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: 'generated',
    title: 'My Awesome CSV',
    useTextFile: false,
    useBom: false,
    useKeysAsHeaders: true,
    headers: []
  };

  constructor() {}

  public exportAsExcelFile(json: any[], excelFileName: string, headers: string[] = []): void {
    this.options.title = excelFileName;
    if ( headers.length ) {
      this.options.useKeysAsHeaders = false;
      this.options.headers = headers;
    }
    this.options.filename =  excelFileName + '_' + new Date().getTime();
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(json);
  }

}
