import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { API_BASE_URL } from "./config";
import { type } from 'os';



@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  @Output() centerLogoEvent: EventEmitter<string> = new EventEmitter();
  @Output() profilePhotoEvent: EventEmitter<string> = new EventEmitter();
  @Output() centerNameEvent: EventEmitter<string> = new EventEmitter();
  @Output() changeChildrenEvent: EventEmitter<string> = new EventEmitter();
  @Output() notifications: EventEmitter<boolean> = new EventEmitter();
  @Output() changeBranchEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() getBranchCount: EventEmitter<number> = new EventEmitter();

  private baseURL = environment.url;
  risk_status = null;
  selectedChild: any = [];
  @Output() matrixEvent: EventEmitter<string> = new EventEmitter();
  constructor(
    //private http: HttpClient,
    private _snackBar: MatSnackBar,
    private http: HttpClient, private router: Router, @Inject(API_BASE_URL) public baseUrl?: string
  ) {
    // this.selectedChild = JSON.parse(localStorage.getItem('child'));
  }

  showSuccessNotification(message, duration?) {

    if (!duration) {
      duration = 3000;
    }

    return this._snackBar.open(message, 'Close', {
      duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'bg-green',
    });
  }

  showErrorNotification(message) {
    this._snackBar.open(message, 'Close', {
      duration: 200000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'bg-red',
    });
  }

  showWarningNotification(message) {
    this._snackBar.open(message, 'Close', {
      duration: 200000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'bg-orange',
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }

  // profile photo change
  profilePhotoChange(url) {
    this.profilePhotoEvent.emit(url);
  }

  // Center logo change
  centerLogoChange(logoUrl) {
    this.centerLogoEvent.emit(logoUrl);
  }

  // Center name change
  centerNameChange(name) {
    this.centerNameEvent.emit(name);
  }

  // Change children change
  changeChildrenChange(status) {
    this.changeChildrenEvent.emit(status);
  }

  // get notifications change
  unreadNotifications(notifications) {
    this.notifications.emit(notifications);
  }

  getAPIURL() {
    return environment.url;
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  /**
   * Http Option for auth routers
   */
  getHttpOptionsAuth() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        // 'Child-id' : this.selectedChild['child_id'].toString()
      })
    };
  }


  /**
   * Http Option for auth and upload file
   */
  getHttpOptionsAuthWithOutContentType() {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  getHttpOptionsBlob() {
    return {
      // responseType: "blob" as "json",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'access-control-allow-origin': "*",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      }),
    };
  }

  getHttpOptionsBlob2() {
    return {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }


  getHttpOptionsWithFile() {
    return {
      headers: new HttpHeaders({

      })
    };
  }


  //use for format date
  formatDatetoObject(date) {

    if (date != null) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }

  }

  formatDatetoServerSide(date) {
    if (date != undefined) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
    return null;
  }

  formatDateTimetoServerSide(date) {
    if (date != undefined) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minute = '' + d.getMinutes(),
        seconds = '' + d.getSeconds();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      if (hours.length < 2)
        hours = '0' + hours;
      if (minute.length < 2)
        minute = '0' + minute;
      if (seconds.length < 2)
        seconds = '0' + seconds;

      return [year, month, day].join('-') + ' ' + [hours, minute, seconds].join(':');
    }
    return null;
  }

  formatTimeTo12Hours(datetime) {
    if (datetime) {
      var time = new Date(datetime);
      return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
    return '';
  }

  formatTimeTo24Hours(datetime) {
    if (datetime) {
      var time = new Date(datetime);
      return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })
    }
    return '';
  }

  formatTimeString (time) {
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) {
      time = time.slice (1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join ('');
  }
  
  matrixChange(likelihood_id, consequence_id) {

    if (likelihood_id == 5 && consequence_id == 1) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 4 && consequence_id == 1) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 3 && consequence_id == 1) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 2 && consequence_id == 1) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 1 && consequence_id == 1) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }

    if (likelihood_id == 5 && consequence_id == 2) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 4 && consequence_id == 2) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 3 && consequence_id == 2) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 2 && consequence_id == 2) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 1 && consequence_id == 2) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }

    if (likelihood_id == 5 && consequence_id == 3) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 4 && consequence_id == 3) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 3 && consequence_id == 3) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 2 && consequence_id == 3) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 1 && consequence_id == 3) {
      this.risk_status = 1;
      this.matrixEvent.emit(this.risk_status);
    }

    if (likelihood_id == 5 && consequence_id == 4) {
      this.risk_status = 3;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 4 && consequence_id == 4) {
      this.risk_status = 3;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 3 && consequence_id == 4) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 2 && consequence_id == 4) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 1 && consequence_id == 4) {
      this.risk_status = 2;
      this.matrixEvent.emit(this.risk_status);
    }

    if (likelihood_id == 5 && consequence_id == 5) {
      this.risk_status = 3;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 4 && consequence_id == 5) {
      this.risk_status = 3;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 3 && consequence_id == 5) {
      this.risk_status = 3;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 2 && consequence_id == 5) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    if (likelihood_id == 1 && consequence_id == 5) {
      this.risk_status = 4;
      this.matrixEvent.emit(this.risk_status);
    }
    return this.risk_status;
  }

  getFormPermission(form, isFDC = 0) {
    return this.http.get(
      this.baseUrl + "auth/form-permissions?form=" + form + "&is_fdc=" + isFDC, this.getHttpOptionsAuth())
  }

  downloadZohoDocument(data) {
    return this.http.post(this.baseUrl + 'zoho/download-zoho-signature-document', data, this.getHttpOptionsBlob());
  }

  weekStartDatesFilter = (d): boolean => {
    if(d === null){
      return false;
    }
    let date = new Date(d);
    const day = date.getDay();

    /* Only allow Saturday and Sunday for select. */
    return day == 0 || day == 1 ;
  }


  // change branch
  changeBranch(status) {
    this.changeBranchEvent.emit(status);
  }
  BranchCount(count) {
    return this.getBranchCount.emit(count);
  }

  getCurrentUser() {
    const { user } = JSON.parse(localStorage.getItem('user'));
    return user;
  }
}
