import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AuthService} from "../../core/service/auth.service";
@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss']
})
export class LockedComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  hide = true;
  user: any = [];
  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      user_ref: [this.user['user_ref'], Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authService.loginLocked(this.loginForm.value).subscribe((res) => {
          localStorage.setItem('STATE', 'true');
          localStorage.setItem('access_token', res['access_token']);
          localStorage.setItem('user', JSON.stringify(res['user']));
          this.router.navigate(['/admin/dashboard']);
          this.submitted = false;
        }, (error) => {
          this.error = error.error.error;
          this.submitted = false;
        }
      );
    }
  }
}
