
<div class="row p-15">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <ng-container *ngIf="headerHide != 1">
      <h3>Report Grievance</h3>
      <h6>{{ data.created_at | date: 'longDate' }}</h6>
      <span class="label l-bg-red shadow-style m-b-14" *ngIf="data.is_approval == 2">Declined - {{ data.parent_signature_date | date: 'longDate'}}</span>
      <span class="label l-bg-green shadow-style m-b-14" *ngIf="data.is_approval == 1">Approved - {{ data.parent_signature_date | date: 'longDate'}}</span>
      <span class="label l-bg-orange shadow-style m-b-14" *ngIf="data.is_approval == 0">Pending </span>
      <br/>
    </ng-container>
    <h5 class="form-group-heading"> Complainant Details</h5>
    <table class="table table-sm border-0">
      <tr><td style="width: 20%">Name</td><td>: {{ data['name'] }}</td></tr>
      <tr><td style="width: 20%">Center Name</td><td>: {{ data['center_name'] }}</td></tr>
      <tr><td style="width: 20%">Branch Name</td><td>: {{ data['branch_name'] }}</td></tr>
      <tr><td style="width: 20%">Name</td><td>: {{ data['name'] }}</td></tr>
    </table>
    <p><strong>Please write down your complaint or provide details of event leading to grievance? : </strong>{{ data['recurring_patterns'] }}</p>
    <p><strong>Do any of the Service ’s policies and procedures need to be amended to address the issues raised? : </strong><span *ngIf="data.services_policies == 1"><i>Yes</i></span>
      <span *ngIf="data.services_policies == 0"><i>No</i></span></p>
    <p *ngIf="data.services_policies == 1"><strong>Provide details of the particular policies and procedures and the recommended alterations : </strong>{{ data['services_policies_description'] }}</p>

    <h5 class="form-group-heading">Action/Follow -up Details</h5>
    <p><strong>Name/s of any policies and procedures amended and or reviewed as detailed above : </strong>{{ data['reviewer_name'] }}</p>
    <p><strong>Ensure the reviewed date appears on the affected policies and procedures. List them here with the policy title/s : </strong>{{ data['policy_title'] }}</p>

  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13" *ngIf="data.is_approval != 0">
    <h5 class="form-group-heading">Received By</h5>
    <br/>
    <img src="{{ data['signature'] }}">
    <br/>
    <br/>
    <em>{{ data['print_name'] }} -  {{ data.signature_date | date: 'longDate'}}</em>
  </div>

  <ng-container *ngIf="data.is_approval == 0 && loggedUser['role'] == 'director'">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area">
      <h5 class="form-group-heading">Received By</h5>
      <form class="register-form filter-form" [formGroup]="sectionForm" >
        <div class="row">
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Nominated Supervisor name</mat-label>
              <mat-select formControlName="is_approval_user_id" readonly="">
                <mat-option value="{{ member.user_id }}" *ngFor="let member of allMembers">{{ member.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <br/>
            <br/>

            <mat-form-field class="example-full-width" appearance="outline" style="padding-top: 0 !important;">
              <mat-label>Signature Date</mat-label>
              <input matInput formControlName="signature_date" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <label class="f-12">Signature of Receiver</label>
            <div *ngIf="!isNewSignature">
              <span *ngIf="isEdit"  (click)="removeSignature()" class="badge badge-danger remove-signature pointer b-r-15">x</span>
              <img src="{{ signatureImg }}">
            </div>
            <ng-container *ngIf="isNewSignature">
              <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
            </ng-container>
            <br/>
            <span class="text-danger"  *ngIf="sigError">Signature is required</span>
          </div>
        </div>
        <input type="hidden" formControlName="f1_incident_form_ref">
        <input type="hidden" formControlName="parent_id">

        <br/>
        <br/>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 m-b-14">
          <button mat-flat-button color="primary"  (click)="formSubmit(sectionForm, 1)"  [class.spinner]="submitted" class="pull-right  text-white" type="submit">
            Approved
          </button>
          <button (click)="formSubmit(sectionForm, 2)"  class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" mat-raised-button data-dismiss="modal">Decline</button>
        </div>
      </form>
    </div>
  </ng-container>

</div>
