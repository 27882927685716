import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedComponentService } from "../../shared-component.service";
import { GlobalService } from "../../../global/global.service";
import { AccountService } from "../../../accounts/account.service";

@Component({
  selector: 'app-daily-reflection-view',
  templateUrl: './daily-reflection-view.component.html',
  styleUrls: ['./daily-reflection-view.component.css']
})
export class DailyReflectionViewComponent implements OnInit {

  @Input() rowRef: string;
  @Input() headerHide: number = 0;
  @Input() notificationRef: string = '';
  @Output() closeModal = new EventEmitter<boolean>();
  data: any = [];
  loggedUser: any = [];
  signatureImg = null;
  currentDate = null;
  isNewSignature = true;
  isEdit = false;
  sectionForm: FormGroup;
  submitted = false;
  error = '';
  sigError = false;
  allMembers: any = [];
  selectedChildren: any = [];
  children: any = [];
  parentsFeedback: any = [];

  currentIndex: any = -1;
  showFlag: any = false;

  constructor(
    private shardService: SharedComponentService,
    private fb: FormBuilder,
    private globeService: GlobalService,
    private accountService: AccountService,
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem('user'));
    this.sectionForm = this.fb.group({
      ref: [''],
      table: [''],
      feedback: ['', [Validators.required]],
      parent_id: [''],
      child_id: [''],
      notification_ref: [''],
    });
  }

  ngOnInit(): void {
    this.getAllMembers();
    this.sectionForm.patchValue({
      ref: this.rowRef,
      table: 'daily_reflection_form',
      notification_ref: this.notificationRef,
    });
    this.getNotification();
    if (this.loggedUser['role_id'] == 6) {
      let child = JSON.parse(localStorage.getItem('child'));
      this.sectionForm.patchValue({
        child_id: child['child_id']
      });
    }
  }

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  getNotification() {
    this.shardService.getDailyReflectionView(this.rowRef).subscribe((res) => {
      this.data = res['data'];
      this.children = res['children'];
      this.parentsFeedback = res['parents_feedback'];

      this.sectionForm.patchValue({
        feedback: this.data['feedback']
      });

      if (this.loggedUser['role_id'] != 6) {
        this.data['child_ids'].forEach((obj, key) => {
          this.selectedChildren.push(
            this.children.filter(element => {
              return element.child_id == obj;
            })[0]
          );
        });
      }
    }, (error) => {
      this.error = error.error.error;
      console.log(this.error);
    });
  }

  getAllMembers() {
    this.accountService.getAllMembers().subscribe((res) => {
      this.allMembers = res['members'];
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  formSubmit(sectionForm: any) {
    if (this.sectionForm.invalid) {
      return
    }
    this.accountService.updateParentFeedback(this.sectionForm.value).subscribe((res) => {
      this.globeService.showSuccessNotification('Successfully updated');
      this.modalClose();
    }, (error) => {
      this.error = error.error.error;
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  modalClose() {
    this.closeModal.emit(true);
  }

}
