<ng-container [formGroup]="casualForm">
    <td class="p-l-25 p-r-25" *ngIf="isWithName">
      <span>{{ index == 0 ? session_name : ''}}</span>
    </td>
    <td class="p-l-25 p-r-25">
      <mat-form-field class="example-full-width w-140px" appearance="outline">
          <input type="text" matInput formControlName="description" placeholder="Description..." (blur)="updateData($event)">
      </mat-form-field>
    </td>
    <td class="p-l-25 p-r-25">
    <mat-form-field class="example-full-width w-140px" appearance="outline">
        <input matInput formControlName="fee" placeholder="$$$.cc" (blur)="updateData($event)">
        <mat-error *ngIf="casualForm.get('fee').touched && casualForm.get('fee').hasError('pattern')">
          Must be in the format $$$.cc
        </mat-error>
        <mat-error *ngIf="casualForm.get('fee').touched && casualForm.get('fee').hasError('required')">
          Fee is not valid
        </mat-error>
    </mat-form-field>
    </td>
    <td class="p-l-25 p-r-25">
    <mat-form-field class="example-full-width w-140px" appearance="outline">
        <mat-label>Please select</mat-label>
        <mat-select formControlName="unit" (selectionChange)="updateData($event)">
          <mat-option value="1">Session</mat-option>
          <mat-option value="2">Hourly</mat-option>
        </mat-select>
        <mat-error *ngIf="casualForm.get('unit').touched && casualForm.get('unit').hasError('required')">
          Fee Unit is not valid
        </mat-error>
    </mat-form-field>
    </td>
    <td class="p-l-25 p-r-25">
      <mat-form-field class="example-full-width w-140px" appearance="outline">
          <input type="text" matInput placeholder="HH:mm" formControlName="session_length" (blur)="updateData($event)">
          <mat-error *ngIf="casualForm.get('session_length').touched && casualForm.get('session_length').hasError('pattern')">
            Session length is not a valid time
          </mat-error>
          <mat-error *ngIf="casualForm.get('session_length').touched && casualForm.get('session_length').hasError('required')">
            Session length is not valid
          </mat-error>
      </mat-form-field>
    </td>
    <td class="p-l-25 p-r-25" *ngIf="index == 0 && !isDisabled">
      <button  type="button" mat-mini-fab color="primary" (click)='addRow.emit()' title="Add row">
          <mat-icon class="col-white">add</mat-icon>
      </button>
    </td>
    <td class="p-l-25 p-r-25" *ngIf="index > 0 && !isDisabled">
        <button  type="button" mat-icon-button (click)="removeRow.emit()" class="bg-light-gray m-r-14" aria-label="Remove Row">
            <mat-icon>close</mat-icon>
        </button>
    </td>
</ng-container>
