import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '-- Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/admin/child-enrollment/enrollment-authorisation',
    title: 'Children',
    moduleName: '',
    iconType: 'material-icons color-orange f-23',
    icon: 'open_in_browser',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/admin/child-enrollment/inductions-list',
    title: 'Child Orientation/Waitlist',
    moduleName: '',
    iconType: 'material-icons color-green f-23',
    icon: 'import_contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/admin/accounts/educator-registration',
    title: 'Educator Registration',
    moduleName: '',
    iconType: 'material-icons color-yellow-orange f-23',
    icon: 'assignment_ind',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'Accounts',
    moduleName: 'accounts',
    iconType: 'material-icons color-blue f-23',
    icon: 'group_work',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/accounts/child-care-center',
        title: 'Centres',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/child-care-center-branch',
        title: 'Branches',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/directors',
        title: 'Directors',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/educators',
        title: 'Educators',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/children-list',
        title: 'Children',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/parents-list',
        title: 'Parents',
        moduleName: 'accounts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/accounts/connect-child-educator',
        title: 'Connect Account',
        moduleName: 'accounts', iconType: '',
        icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
    ],
  },
  {
    path: '',
    title: '-- Forms',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'Day to Day Operations',
    moduleName: 'physical-env-form',
    iconType: 'material-icons color-yellow-orange f-23',
    icon: 'backup_table',
    class: 'menu-toggle ',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/physical-env-form/record-of-visitors-to-fdc',
        title: 'Visitor Record',
        moduleName: 'physical-env-form',
        iconType: '', icon: '',
        class: 'ml-menu', groupTitle: false, badge: '',
        badgeClass: '', submenu: [],
      },
      {
        path: '/admin/physical-env-form/daily-home-safety-checklist',
        title: 'Occupational Health and Safety Checklist',
        moduleName: 'physical-env-form',
        iconType: '',
        icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/physical-env-form/food-sleep-rest',
        title: 'Food Sleep Rest Time Daily Chart',
        moduleName: 'physical-env-form',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/physical-env-form/sleep-food-nappy-change-record',
        title: 'Food, Sleep and Nappy Change Record ',
        moduleName: 'physical-env-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/physical-env-form/cleaning-and-maintenance-checklist',
        title: 'Cleaning and Maintenance Checklist',
        moduleName: 'physical-env-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/physical-env-form/toy-cleaning-and-maintenance-record',
        title: 'Resources and Toy Cleaning Record ',
        moduleName: 'physical-env-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Health & Safety',
    moduleName: 'health-safety-form',
    iconType: 'material-icons color-orange f-23',
    icon: 'verified_user',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/health-safety-form/f1-incident-injury',
        title: 'Report Incident, Injury, Trauma and Illness ',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/first-aid-check',
        title: 'First Aid Check',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/authorisation-transport-and-regular-outing-RMP',
        title: 'Authorisation and Risk Assessment for Transport and Excursion',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/child-asthma-mgt-plan',
        title: 'Child Asthma MGT Plan',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/emergency-evacuation-drill',
        title: 'Report Emergency Drill or Evacuation',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/f4-medication',
        title: 'Medication Consent',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/evacuation-and-emergencies-risk-mgt-plan',
        title: 'Evacuation and Emergencies Risk Management Plan',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/health-safety-form/risk-minimisation-and-communication-plan',
        title: 'Risk Minimisation and Communication Plan',
        moduleName: 'health-safety-form', iconType: '', icon: '', class: 'ml-menu', groupTitle: false,
        badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/physical-env-form/health-safety-form/risk-management-plan',
        title: 'Risk Management Plan',
        moduleName: 'health-safety-form',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Management Support',
    moduleName: 'staff-and-educator',
    iconType: 'material-icons color-blue1 f-23',
    icon: 'anchor',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/staff-and-educator/assessment-background-screening',
        title: 'Educator Background Screening',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/pre-assessment-aspirant-assessment',
        title: 'Assessment and Registration of Educator',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/educator-performance-development',
        title: 'Professional Development Appraisal',
        moduleName: 'staff-and-educator',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/staff-and-educator/missed-monitoring-and-support-visit',
        title: 'Missed Monitoring and Support Visit ',
        moduleName: 'staff-and-educator',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/staff-and-educator/monitoring-support-visit',
        title: 'Monitoring Support Visit ',
        moduleName: 'staff-and-educator',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/staff-and-educator/educator-leave',
        title: 'Apply for Temporary Leave',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/educator-permanent-leave',
        title: 'Apply for  Permanent Leave',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/educator-care-relatives',
        title: 'Educator Care Relative',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      // {
      //   path: '/admin/staff-and-educator/food-sleep-rest',
      //   title: 'Food, Sleep and Nappy Change Record',
      //   moduleName: 'staff-and-educator',
      //   iconType: '', icon: '', class: 'ml-menu',
      //   groupTitle: false, badge: '', badgeClass: '', submenu: [],
      // },
      {
        path: '/admin/staff-and-educator/venue-assessment',
        title: 'Residence/Approved Venue Assessment',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/relief-educator',
        title: 'Relief Educator Notification',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/document-checklist',
        title: 'Edu. Document Checklist',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/staff-and-educator/clearance-item-return',
        title: 'Educator Clearance Checklist',
        moduleName: 'staff-and-educator',
        iconType: '', icon: '', class: 'ml-menu',
        groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/management-form/staff-and-educator/grievance-complaint',
        title: 'Grievance / Complaint',
        moduleName: 'staff-and-educator',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/management-form/staff-and-educator/service-policies-feedback',
        title: 'Service Policies Feedback',
        moduleName: 'staff-and-educator',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Learning Environment',
    moduleName: 'learning-env',
    iconType: 'material-icons color-purple-light f-23',
    icon: 'backup_table',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/physical-env-form/learning-env/sustainability-mgt',
        title: 'Sustainability Mgt Plan',
        moduleName: 'learning-env',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Families & Children',
    moduleName: 'families-communities',
    iconType: 'material-icons color-orange2 f-23',
    icon: 'holiday_village', class: 'menu-toggle ',
    groupTitle: false, badge: '', badgeClass: '',
    submenu: [
      {
        path: '/admin/families-communities/f-c-change-details',
        title: 'Families & Children Change of Details',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/families-communities/change-booking',
        title: 'F7-Change of Booking',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/families-communities/leave-absence',
        title: 'Child\'s Leave of Absence',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/families-communities/termination-care',
        title: 'Termination of Care',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/families-communities/vacation-care',
        title: 'Booked Hours of Vacation Care',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
      {
        path: '/admin/families-communities/family-input-sheet',
        title: 'Family Input Sheet',
        moduleName: 'families-communities',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Inclusion & Diversities',
    moduleName: 'inclusion',
    iconType: 'material-icons purple darken-1 f-23',
    icon: 'lightbulb_outline', class: 'menu-toggle',
    groupTitle: false, badge: '', badgeClass: '',
    submenu: [
      {
        path: '/admin/families-communities/inclusion/inclusion-support',
        title: 'Inclusion Support Plan',
        moduleName: 'inclusion',
        iconType: '', icon: '', class: 'ml-menu', groupTitle: false, badge: '', badgeClass: '', submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Education & Program',
    moduleName: 'education-form',
    iconType: 'material-icons color-brown f-23',
    icon: 'api',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/admin/education-form/daily-reflection',
        title: 'Daily Reflection',
        moduleName: 'education-form',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/education-form/weekly-programming',
        title: 'Weekly Programming',
        moduleName: 'education-form',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/admin/education-form/monthly-observation',
        title: 'Monthly Observation',
        moduleName: 'education-form',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  }


];
