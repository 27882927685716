<div class="search-menu">
  <div mat-dialog-content class="search-menu">
    <div class="bg-white p-3-8 border-radius-5">
      <form [formGroup]="addCusForm" (ngSubmit)="onSubmitClick()">
        <table class="w-100-p table-remove-padding-form-fields-0">
          <tr>
            <td width="5%"><i class="material-icons color-main f-34">search</i></td>
            <td>
              <mat-form-field class="example-full-width color-black search-bar" appearance="outline">
                <input class="f-14" matInput formControlName="keyword" type="text" (keyup)="onKey($event)"
                  placeholder="Type here and press ENTER to start your search">
              </mat-form-field>
            </td>
            <td width="5%" class="b-l-splid"><i class="material-icons color-black opacity-4 f-34 p-l-10 pointer p-t-5"
                style="opacity: 0.4 !important;" (click)="closeDialog()">clear</i></td>
          </tr>
        </table>

      </form>
    </div>
    <div *ngIf="showResults" class="bg-white m-t-8 border-radius-5" style="min-height: 240px; height: 240px; overflow-y: auto;">
      <table class="w-100-p text-black f-13" *ngIf="!loadingGif">
        <tr class="h-38 pointer bg-light-blue" (click)="routerNavigate(menu.path)" *ngFor="let menu of results">
          <td class="p-l-20">{{ menu.title | truncatetext : 75 }}</td>
          <td class="text-gray " width="25%"><span>{{ menu.parent_name | truncatetext : 18 }}</span></td>
          <td width="13%" class="text-align-right"><i class="material-icons {{ menu.class }} p-r-10 opacity-4 pull-right"
              style="padding: 0 5px 0 10px;">assignment</i> <span class="text-light-gray pull-right p-t-3">{{ menu.type }}</span></td>
        </tr>
        <tr *ngIf="results.length < 1">
          <td class="p-l-20 text-black f-14 text-align-center">
            <img src="assets/images/banner/no-results.png" width="260px;">
            <h3>No results found.</h3>
          </td>
        </tr>
      </table>
      <div class="" align="center">
        <div class="preloader pl-size-l m-t-30" *ngIf="loadingGif">
          <div class="spinner-layer">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>