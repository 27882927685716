import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnrollmentFormComponent } from './enrollment-form/enrollment-form.component';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MatIconModule } from "@angular/material/icon";
import { NgApexchartsModule } from "ng-apexcharts";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxGaugeModule } from "ngx-gauge";
import { MatDividerModule } from "@angular/material/divider";
import { MatBadgeModule } from "@angular/material/badge";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AuthenticationRoutingModule } from "../authentication/authentication-routing.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatTableModule} from "@angular/material/table";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from '@angular/material/radio';
import { MaterialFileInputModule } from "ngx-material-file-input";
import { MatStepperModule } from "@angular/material/stepper";
import { PipesModule } from "../pipes/pipes.module";
import { NgxMaskModule } from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { SignaturePadModule } from "angular2-signaturepad";
import { CustomDropzoneComponent } from './custom-dropzone/custom-dropzone.component';
import { DndDirective } from "./direcitves/dnd.directive";
import { ProgressComponent } from "./components/progress/progress.component";
import { DropzoneDocListComponent } from './dropzone-doc-list/dropzone-doc-list.component';
import { LoaderComponent } from './loader/loader.component';
import { DropzonePriviewComponent } from './dropzone-priview/dropzone-priview.component';
import { DropzoneRecievedComponent } from './dropzone-recieved/dropzone-recieved.component';
import { SaymorningPipe } from "../core/pipe/saymorning.pipe";
import { ReliefEducatorNotificationViewComponent } from './parent-view/relief-educator-notification-view/relief-educator-notification-view.component';
import { SharedComponentService } from "./shared-component.service";
import { SharedModule } from "../shared/shared.module";
import { AuthorisationRmpNotificationViewComponent } from './parent-view/authorisation-rmp-notification-view/authorisation-rmp-notification-view.component';
import { F1IncidentNotificationViewComponent } from './parent-view/f1-incident-notification-view/f1-incident-notification-view.component';
import { GrievanceComplaintNotificationViewComponent } from './parent-view/grievance-complaint-notification-view/grievance-complaint-notification-view.component';
import { DailyReflectionViewComponent } from './parent-view/daily-reflection-view/daily-reflection-view.component';
import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { WeeklyPragramViewComponent } from './parent-view/weekly-pragram-view/weekly-pragram-view.component';
import { MonthlyObservationViewComponent } from './parent-view/monthly-observation-view/monthly-observation-view.component';
import { EducatorBackgroundScreeingComponent } from './educator-background-screeing/educator-background-screeing.component';
import { EducatorAssessmentRegistrationComponent } from './educator-assessment-registration/educator-assessment-registration.component';
import { FoodSNChangeViewComponent } from './parent-view/food-s-n-change-view/food-s-n-change-view.component';
import { ChildAsthmaMgtViewComponent } from './parent-view/child-asthma-mgt-view/child-asthma-mgt-view.component';
import { RiskMinimizationViewComponent } from './parent-view/risk-minimization-view/risk-minimization-view.component';
import { ChildAbsenceViewComponent } from './parent-view/child-absence-view/child-absence-view.component';
import { ChildTerminationViewComponent } from './parent-view/child-termination-view/child-termination-view.component';
import { FamilyInputSheetViewComponent } from './parent-view/family-input-sheet-view/family-input-sheet-view.component';
import { EducatorDevelopmentAppraisalViewComponent } from './parent-view/educator-development-appraisal-view/educator-development-appraisal-view.component';
import { MissedMonitoringViewComponent } from './parent-view/missed-monitoring-view/missed-monitoring-view.component';
import { MonitoringSupportVisitComponent } from './parent-view/monitoring-support-visit/monitoring-support-visit.component';
import { EducatorLeaveViewComponent } from './parent-view/educator-leave-view/educator-leave-view.component';
import { EducatorPLeaveViewComponent } from './parent-view/educator-p-leave-view/educator-p-leave-view.component';
import { EducatorClearanceItemsViewComponent } from './parent-view/educator-clearance-items-view/educator-clearance-items-view.component';
import { ReportGrievanceViewComponent } from './parent-view/report-grievance-view/report-grievance-view.component';
import { ChangeBookingViewComponent } from './parent-view/change-booking-view/change-booking-view.component';
import { FirstAidCheckViewComponent } from './parent-view/first-aid-check-view/first-aid-check-view.component';
import { EducatorRegistrationViewComponent } from './parent-view/educator-registration-view/educator-registration-view.component';
import { VacationCareViewComponent } from './parent-view/vacation-care-view/vacation-care-view.component';
import { StaffAssessmentRegistrationComponent } from './staff-assessment-registration/staff-assessment-registration.component';
import { StaffBackgroundScreeingComponent } from './staff-background-screeing/staff-background-screeing.component';
import { StaffRegistrationViewComponent } from './parent-view/staff-registration-view/staff-registration-view.component';
import { MedicationViewComponent } from './parent-view/medication-view/medication-view.component';
import { RoutineDayComponent } from './routine-day/routine-day.component';
import { CasualDayComponent } from './casual-day/casual-day.component';
import { ChildrenScheduleComponent } from './children-schedule/children-schedule.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [
    RoutineDayComponent,
    CasualDayComponent,
    EnrollmentFormComponent,
    CustomDropzoneComponent,
    DndDirective,
    ProgressComponent,
    DropzoneDocListComponent,
    LoaderComponent,
    DropzonePriviewComponent,
    DropzoneRecievedComponent,
    SaymorningPipe,
    ReliefEducatorNotificationViewComponent,
    AuthorisationRmpNotificationViewComponent,
    F1IncidentNotificationViewComponent,
    GrievanceComplaintNotificationViewComponent,
    DailyReflectionViewComponent,
    WeeklyPragramViewComponent,
    MonthlyObservationViewComponent,
    FoodSNChangeViewComponent,
    MonthlyObservationViewComponent,
    EducatorBackgroundScreeingComponent,
    EducatorAssessmentRegistrationComponent,
    ChildAsthmaMgtViewComponent,
    RiskMinimizationViewComponent,
    ChildAbsenceViewComponent,
    ChildTerminationViewComponent,
    FamilyInputSheetViewComponent,
    EducatorDevelopmentAppraisalViewComponent,
    MissedMonitoringViewComponent,
    MonitoringSupportVisitComponent,
    EducatorLeaveViewComponent,
    EducatorPLeaveViewComponent,
    EducatorClearanceItemsViewComponent,
    ReportGrievanceViewComponent,
    ChangeBookingViewComponent,
    FirstAidCheckViewComponent,
    EducatorRegistrationViewComponent,
    VacationCareViewComponent,
    StaffAssessmentRegistrationComponent,
    StaffBackgroundScreeingComponent,
    StaffRegistrationViewComponent,
    MedicationViewComponent,
    ChildrenScheduleComponent,
    ConfirmationModalComponent,
  ],
  exports: [
    RoutineDayComponent,
    CasualDayComponent,
    ChildrenScheduleComponent,
    EnrollmentFormComponent,
    CustomDropzoneComponent,
    LoaderComponent,
    DropzonePriviewComponent,
    DropzoneRecievedComponent,
    SaymorningPipe,
    F1IncidentNotificationViewComponent,
    ReliefEducatorNotificationViewComponent,
    AuthorisationRmpNotificationViewComponent,
    GrievanceComplaintNotificationViewComponent,
    DailyReflectionViewComponent,
    WeeklyPragramViewComponent,
    MonthlyObservationViewComponent,
    FoodSNChangeViewComponent,
    MonthlyObservationViewComponent,
    EducatorBackgroundScreeingComponent,
    EducatorAssessmentRegistrationComponent,
    ChildAsthmaMgtViewComponent,
    RiskMinimizationViewComponent,
    ChildAbsenceViewComponent,
    ChildTerminationViewComponent,
    FamilyInputSheetViewComponent,
    EducatorDevelopmentAppraisalViewComponent,
    MissedMonitoringViewComponent,
    MonitoringSupportVisitComponent,
    EducatorLeaveViewComponent,
    EducatorPLeaveViewComponent,
    EducatorClearanceItemsViewComponent,
    ReportGrievanceViewComponent,
    ChangeBookingViewComponent,
    FirstAidCheckViewComponent,
    EducatorRegistrationViewComponent,
    VacationCareViewComponent,
    StaffBackgroundScreeingComponent,
    StaffAssessmentRegistrationComponent,
    StaffRegistrationViewComponent,
    MedicationViewComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    MatTableModule,
    MatIconModule,
    NgApexchartsModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    NgxGaugeModule,
    MatDividerModule,
    MatBadgeModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatListModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSliderModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatProgressButtonsModule,
    MatFormFieldModule,
    MatInputModule,
    AuthenticationRoutingModule,
    MatMenuModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatSelectModule,
    MaterialFileInputModule,
    MatStepperModule,
    PipesModule,
    FormsModule,
    NgxMaskModule,
    NgxSpinnerModule,
    SignaturePadModule,
    SharedModule,
    NgImageFullscreenViewModule,
    MatRadioModule
  ],
  providers: [SharedComponentService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class SharedComponentModule { }
