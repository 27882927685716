import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/accounts/account.service';
import { GlobalService } from 'src/app/global/global.service';

@Component({
  selector: 'app-child-attendance-add',
  templateUrl: './child-attendance-add.component.html',
  styleUrls: ['./child-attendance-add.component.scss']
})
export class ChildAttendanceAddComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  children: any = [];
  selectedRecipients: any = [];
  allSelected = false;
  isLoading = false;
  constructor(
    public modal: NgbActiveModal,
    private globeService: GlobalService,
    private accountService: AccountService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    
    this.accountService.getChildrenList(3).subscribe((res) => {
      this.children = res['children'];
      this.children.sort((a, b) => a.child_givenname.localeCompare(b.child_givenname));
      this.selectedRecipients = this.children;
    }, (error) => {
      this.globeService.showErrorNotification('Something went wrong!');
    }
    );
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) 
        newStatus = false;
    });
    this.allSelected = newStatus;
  }

  // Receive user input and send to search method**
  onKey(value) {
    this.selectedRecipients = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.children.filter(option => option.child_givenname.toLowerCase().startsWith(filter));
  }

  closeModal() {
    this.modal.dismiss();
  }
  saveModal(select: MatSelect): void {
    // Set isLoading to true when the save operation starts
    this.isLoading = true;
    // Simulate an asynchronous save operation (replace this with your actual save logic)
    var data = {
      children_ids: select.value
    };
    this.accountService.updateAllowViewExitedChildAttedance(data).subscribe((res) => {
      this.modal.close(true);
      this.isLoading = false;
    }, (error) => {
      this.globeService.showErrorNotification('Something went wrong!');
      this.isLoading = false;
    })
      
  }
  formatDateWithoutTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  
}
