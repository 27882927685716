

<div class="limiter p-t-5-p">
  <div class="container-login bg-primary-green">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <span class="login100-form-title pb-5">
          Login
        </span>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput formControlName="username" />
              <mat-icon matSuffix>face</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hide = !hide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-sb-m w-full p-t-15 p-b-20">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" value=""> Remember me
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div>
            <a class="txt1" routerLink="/authentication/forgot-password">Forgot Password?</a>
          </div>
        </div>
        <div class="container-login100-form-btn">
          <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
            Login
          </button>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/banner/child-login.png');">
      </div>
    </div>
  </div>
</div>

