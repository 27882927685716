import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/global/global.service';

@Component({
  selector: 'app-attendance-child-table',
  templateUrl: './attendance-child-table.component.html',
  styleUrls: ['./attendance-child-table.component.sass']
})
export class AttendanceChildTableComponent implements OnInit {
  @Input() tableData: any[] = [];
  displayedColumns: string[] = ['check_box', 'child_name', 'child_start_date', 'educator_name', 'action'];

  constructor(private modalService: NgbModal,
    private globeService: GlobalService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    console.log('dataRow');
    console.log(this.tableData);

  }
  openEdit(data) {

  }
  confirmAbsent(id) {
    
  }
  toggleAllSelection(event: any) {

  }

  checkEditEnabled(date) {
    
  }
  formatDateWithoutTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  formatTime(time: string): string {
    // Construct a valid date by using a placeholder date (e.g., 1970-01-01)
    const fullDateTime = `1970-01-01 ${time}`;
    
    // Use DatePipe to format the time
    const formattedTime = this.datePipe.transform(fullDateTime, 'h:mm a');
    
    return formattedTime || ''; // Handle invalid times gracefully
  }
  formatDateToDay(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // Get the day of the week and convert it to the corresponding string
    return daysOfWeek[date.getDay()];
  }
}
