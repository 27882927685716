import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EducatorsService {
  constructor(private http: HttpClient, private globeService: GlobalService) {}

  checkIfEducatorExists(data: any) {
    return this.http.post(
      `${this.globeService.baseUrl}account/check-educator`,
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getEducator(educatorId: number) {
    return this.http.get(
      `${this.globeService.baseUrl}account/educators/${educatorId}`,
      this.globeService.getHttpOptionsAuth()
    );

  }

  getEducators(filters: any = {}): Observable<any> {
    let params = new HttpParams();

    if (filters.name) {
      params = params.set('name', filters.name);
    }

    if (filters.center_id) {
      params = params.set('center_id', filters.center_id.toString());
    }

    if (filters.scheme_type) {
      params = params.set('scheme_type', filters.scheme_type);
    }

    if (filters.status !== undefined && filters.status !== null) {
      params = params.set('status', filters.status.toString());
    }

    if (filters.center_type !== undefined && filters.center_type !== null) {
      params = params.set('center_type', filters.center_type);
    }

    return this.http.get(
      `${this.globeService.baseUrl}account/educators`,
      {
        ...this.globeService.getHttpOptionsAuth(),
        params: params
      }
    );
  }

  getEducatorAuditLogs(educator_id: number): Observable<any> {
    return this.http.get(
      `${this.globeService.baseUrl}account/educator-audit-logs/${educator_id}`,
      this.globeService.getHttpOptionsAuth()
    );
  }
}
