<form class="register-form filter-form" [formGroup]="filterForm">
  <div class="row m-b-10 m-t-20 p-b-15 filter-section tbl-fltr-frm">
    <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Calendar</mat-label>
        <input matInput placeholder="dd/mm/yyyy" [matDatepicker]="date_start" formControlName="created_at"
          (click)="date_start.open()" (dateInput)="formFilters($event)" (dateChange)="formFilters($event)"
          [max]="getMaxDate()">
        <mat-datepicker-toggle matSuffix [for]="date_start"></mat-datepicker-toggle>
        <mat-datepicker #date_start></mat-datepicker>
      </mat-form-field>
    </div> -->
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Weekly / Fortnightly</mat-label>
        <mat-select formControlName="week_range" (selectionChange)="cboSelectionChange($event)">
          <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Calendar </mat-label>
        <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
          <input formControlName="start_date" matStartDate placeholder="Start date" (dateChange)="onStartChange($event); " />
          <input formControlName="end_date" matEndDate placeholder="End date" [value]="endDate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Filter By</mat-label>
        <mat-select formControlName="filterKey">
          <mat-option value="A_Z" selected>A-Z</mat-option>
          <mat-option value="educator">Educator</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <section>
        <mat-checkbox formControlName="showSubmittedReports">Show Submitted</mat-checkbox>
      </section>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 m-b-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label for="searchValue">Search value</mat-label>
        <input matInput formControlName="searchValue" title="" placeholder="Search" />
      </mat-form-field>
    </div>

    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-12 m-b-4 d-flex align-items-start">
      <button mat-flat-button class="pull-right btn btn-secondary text-white m-l-11 mr-1" type="button"
        (click)="clearFilter()">Clear</button>
      <button mat-flat-button color="primary" class="pull-right  text-white" type="submit"
        (click)="searchFunction(filterForm)">Search</button>
    </div>
  </div>
</form>
