import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component implements OnInit {
  home = "/admin/dashboard";
  isEsi = false;

  constructor() {
    this.isEsi =  localStorage.getItem('esi_mode') ? JSON.parse(localStorage.getItem('esi_mode')) : false;
    if (this.isEsi) {
      this.home = "/esi/dashboard";
    } else {
      this.home = "/admin/dashboard";
    }
  }

  ngOnInit() {}
}
