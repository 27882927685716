import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from "../../global/global.service";
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomvalidationService } from "src/app/services/customvalidation.service";

@Component({
  selector: 'app-child-attendance-edit',
  templateUrl: './child-attendance-edit.component.html',
  styleUrls: ['./child-attendance-edit.component.sass']
})
export class ChildAttendanceEditComponent implements OnInit {
  
  @Input()
  public data: any;
  public attendanceForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public globeService: GlobalService,
    private fb: FormBuilder, 
    private validatorService: CustomvalidationService) { 
      this.attendanceForm = this.fb.group({
          start_time: '',
          end_time: ''
      },
      {
        validators: [
          this.validatorService.requiredFieldValidator('start_time', 'end_time')
        ]
      });
    }

  ngOnInit(): void {
    this.attendanceForm.patchValue({
      start_time: this.data['status'] !== 2 ? this.globeService.formatTimeTo24Hours(this.data['sign_in']): '',
      end_time: this.data['status'] !== 2 ? this.globeService.formatTimeTo24Hours(this.data['sign_out']) : ''
    })

    this.attendanceForm.updateValueAndValidity();
  }

  submitUpdates() {
    this.data['status'] = 2;
    var starttime = this.attendanceForm.get('start_time').value;
    if (starttime) {
      let[hours, mins] = starttime.split(":");
      if (!this.data['sign_in']) {
        this.data['sign_in'] = new Date(this.data['date']);
      }
      
      this.data['sign_in'] = this.globeService.formatDateTimetoServerSide(new Date(this.data['sign_in']).setHours(hours,mins));
      this.data['status'] = 0;
    }

    var endTime = this.attendanceForm.get('end_time').value;
    if (endTime) {
      let[hours, mins] = endTime.split(":");
      if (!this.data['sign_out']) {
        this.data['sign_out'] = new Date(this.data['date']);
      }
      this.data['sign_out'] = this.globeService.formatDateTimetoServerSide(new Date(this.data['sign_out']).setHours(hours,mins));
      this.data['status'] = 1;
    }

    this.activeModal.close(this.data);
  }

  cancelUpdates() {
    this.activeModal.close(false);
  }

}
