<div class="modal-header m-t-15">
    <div class="modalHeader clearfix">
        <div class="modal-about contact-header">
            <div class="contact-details-heading">
                <div class="contact-details-name mb-2 w-100-p">{{ data.child_full_name }}'s Attendance</div>
                <div class="badge col-green">{{data.educator_name}}</div>
            </div>
        </div>
    </div>
    <button mat-icon-button (click)="modal.close()" class="bg-light-gray m-r-14" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body p-b-30 p-t-5 p-l-5">
    <mat-card-content>
        <table #attendanceTable mat-table [dataSource]="tableData" class="w-100 mat-table-responsive t-b-1">
            <ng-container matColumnDef="check_box">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="toggleAllSelection($event)" [disabled]="!editMode"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox (change)="toggleSelection($event, element)" [(ngModel)]="element.isSelected"
                    [disabled]="!editMode"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="day">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element; let key = index">{{ formatDateToDay(element.date) }}, {{
                    formatDateWithoutTime(element.date) }} </td>
            </ng-container>
            <ng-container matColumnDef="sched">
                <th mat-header-cell *matHeaderCellDef> Schedule </th>
                <td mat-cell *matCellDef="let element; let key = index">{{ formatTime(element.sched_start) }} - {{
                    formatTime(element.sched_end) }}</td>
            </ng-container>
            <ng-container matColumnDef="sign_in">
                <th mat-header-cell *matHeaderCellDef> Sign In </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_in) }}</span>
                    <span *ngIf="element.status == 2">Absent</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="signed_in_by">
                <th mat-header-cell *matHeaderCellDef> Signed In By </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.signed_in_by}}</span> <br />
                    <span *ngIf="element.signed_in_role">({{element.signed_in_role | titlecase}})</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="sign_out">
                <th mat-header-cell *matHeaderCellDef> Sign Out </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.status != 2">{{ globeService.formatTimeTo12Hours(element.sign_out) }} </span>
                    <span *ngIf="element.status == 2">Absent</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="signed_out_by">
                <th mat-header-cell *matHeaderCellDef> Signed Out By </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.signed_out_by}}</span> <br />
                    <span *ngIf="element.signed_out_role">({{element.signed_out_role | titlecase}})</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" *ngIf="editMode">
                <th mat-header-cell *matHeaderCellDef class="p-r-61 text-align-right"> Action </th>
                <td mat-cell *matCellDef="let element" class="w-180px">
                    <button mat-icon-button color="accent" class="btn-tbl-delete pull-right" title="Delete Row">
                        <mat-icon aria-label="Delete" class="col-white">delete</mat-icon>
                    </button>
                    <button *ngIf="rolePermission.edit" mat-icon-button color="accent"
                        [disabled]="!checkEditEnabled(element.date)" class="btn-tbl-delete pull-right"
                        style="background-color: #fd7e14 !important;"
                        (click)="confirmAbsent(element.child_attendance_id)" title="Mark As Absent">
                        <mat-icon aria-label="report absence" class="col-white">report</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" *ngIf="rolePermission.edit" (click)="openEdit(element)"
                        class="btn-tbl-edit pull-right" title="Edit" [disabled]="!checkEditEnabled(element.date)">
                        <mat-icon aria-label="Edit" class="col-white">edit</mat-icon>
                    </button>
                    <button *ngIf="rolePermission.view" mat-icon-button color="accent" class="btn-tbl-view pull-right"
                        (click)="openView(element)" title="View">
                        <mat-icon aria-label="View" class="col-white">visibility</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
</div>