import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'statusToName'
})
export class StatusToNamePipe implements PipeTransform {

    transform(status, whichList): any {

        if (whichList == 'induction') {
            var status_name = [{ 'status': 'Rejected', 'color': 'l-bg-red' }];
            switch (parseInt(status)) {
                case 0:
                    status_name = [{ 'status': 'Rejected', 'color': 'l-bg-red' }];
                    break;
                case 1:
                    status_name = [{ 'status': 'Enrolment Submitted', 'color': 'l-bg-green' }];
                    break;
                case 2:
                    status_name = [{ 'status': 'Incomplete', 'color': 'l-bg-red' }];
                    break;
                case 3:
                    status_name = [{ 'status': 'Completed', 'color': 'l-bg-orange' }];
                    break;
                case 4:
                    status_name = [{ 'status': 'Enrolment Emailed', 'color': 'l-bg-orange' }];
                    break;
                case 5:
                    status_name = [{ 'status': 'For Verification', 'color': 'l-bg-purple' }];
                    break;
                default:
                    status_name = [{ 'status': 'Incomplete', 'color': 'l-bg-red' }];
            }
            return status_name;
        }


        if (whichList == 'enrollment-authorisation') {
            var status_name = [{ 'status': 'Rejected', 'color': 'l-bg-orange' }];
            switch (parseInt(status)) {
                case 0:
                    status_name = [{ 'status': 'Rejected', 'color': 'l-bg-red' }];
                    break;
                case 1:
                    status_name = [{ 'status': 'Approved', 'color': 'l-bg-green' }];
                    break;
                case 2:
                    status_name = [{ 'status': 'Pending', 'color': 'l-bg-orange' }];
                    break;
                case 3:
                    status_name = [{ 'status': 'Pending Approval', 'color': 'l-bg-blue' }];
                    break;
                default:
                    status_name = [{ 'status': 'Pending', 'color': 'l-bg-cyan' }];
            }

            return status_name;
        }

        if (status == null) {
            return status_name = [{ 'status': 'Not Received', 'color': 'col-yellow' }];
        }
        var status_name = [{ 'status': 'Reject', 'color': 'col-cyan' }];
        switch (parseInt(status)) {
            case 0:
                status_name = [{ 'status': 'Rejected', 'color': 'col-red' }];
                break;
            case 1:
                status_name = [{ 'status': 'Approved', 'color': 'col-green' }];
                break;
            case 2:
                status_name = [{ 'status': 'Not Completed', 'color': 'col-cyan' }];
                break;
            default:
                status_name = [{ 'status': 'Not Complete', 'color': 'col-cyan' }];
        }

        return status_name;
    }
}
