import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MatBadgeModule } from "@angular/material/badge";
import { MatListModule } from "@angular/material/list";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AuthenticationRoutingModule } from "../authentication/authentication-routing.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatTableModule} from "@angular/material/table";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PipesModule } from "../pipes/pipes.module";
import { NgxMaskModule } from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from "../shared/shared.module";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { ChildAttendanceComponent } from './child-attendance/child-attendance.component';
import { ChildAttendanceModalComponent } from './child-attendance-modal/child-attendance-modal.component';
import {SharedComponentModule} from "../shared-component/shared-component.module";
import { ChildAttendanceEditComponent } from './child-attendance-edit/child-attendance-edit.component';
import { ChildAttendanceAddComponent } from './child-attendance-add/child-attendance-add.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { AttendanceTableComponent } from './attendance-table/attendance-table.component';
import { AttendanceTableFilterComponent } from './attendance-table-filter/attendance-table-filter.component';
import { AttendanceExportModalComponent } from './attendance-export-modal/attendance-export-modal.component';
import { ChildAttendanceViewModalComponent } from './child-attendance-view-modal/child-attendance-view-modal.component';
import { AttendanceChildTableComponent } from './attendance-child-table/attendance-child-table.component';
@NgModule({
  declarations: [
    ChildAttendanceComponent,
    ChildAttendanceModalComponent,
    ChildAttendanceEditComponent,
    ChildAttendanceAddComponent,
    AttendanceTableComponent,
    AttendanceTableFilterComponent,
    AttendanceExportModalComponent,
    ChildAttendanceViewModalComponent,
    AttendanceChildTableComponent,
  ],
  exports: [
    ChildAttendanceComponent,
    ChildAttendanceModalComponent,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatBadgeModule,
    MatListModule,
    MatDatepickerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatProgressButtonsModule,
    MatFormFieldModule,
    MatInputModule,
    AuthenticationRoutingModule,
    MatMenuModule,
    MatToolbarModule,
    FlexLayoutModule,
    PipesModule,
    FormsModule,
    NgxMaskModule,
    NgxSpinnerModule,
    SharedModule,
    NgbPaginationModule,
    SharedComponentModule,
    MatSelectModule,
    MatCheckboxModule
  ],  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class ChildAttendanceModule { }
