import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yes_no'
})
export class YesNoPipe implements PipeTransform {

  transform(type): any {
    var result = 'No';
    switch (type) {
      case '1':
        result =  'Yes';
        break;
      case '0':
        result =  'No';
        break;
      case 1:
        result =  'Yes';
        break;
      case 0:
        result =  'No';
        break;
      case true:
        result =  'Yes';
        break;
      case false:
        result =  'Yes';
        break;
      case null:
        result =  'No';
        break;
      default :
        result = 'No'

    }

    return result;
  }

}
