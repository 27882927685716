<mat-sidenav-container class="esi-header-container">
    <mat-sidenav-content>
      <nav class="menu-bar" style="height: 80px; margin-top: 10px;">
        <mat-toolbar class="container">
          <mat-toolbar-row>
            <a><img src="../../../assets/logo.png" class="h-55"></a>
            <span class="menu-spacer"></span>
            <div>
              <p class="esi-header-d">
                {{currentDate | date:'fullDate'}}
              </p>
              <p class="esi-header-t pull-right">{{currentDate | date:'shortTime'}}</p>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </nav>
    </mat-sidenav-content>
</mat-sidenav-container>