import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalService } from "src/app/global/global.service";
import { ChildAttendanceOtherDetails } from "../models/attendance-row";

@Injectable({
  providedIn: "root",
})
export class ChildAttendanceOtherDetailsService {
  constructor(private http: HttpClient, private globeService: GlobalService) {}

  createOrUpdate(childAttendanceOtherDetails: ChildAttendanceOtherDetails) {
    return this.http.post(
      `${this.globeService.baseUrl}child-attendance-other-details`,
      childAttendanceOtherDetails,
      this.globeService.getHttpOptionsAuth()
    );
  }
}
